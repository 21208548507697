import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {
    FindLoad,
    SendPageSave,
    useGlobalStyles,
    Images,
    Colors,
    FindSuccess,
    RolePermission,
    PermissionList,
} from "utils";
import { IdRow, Loader, MinLoader, NoItemsYet, PriceInput, PriceRow, TableNoInfo, TextRow } from 'components';
import {tableStyle} from "../../../components/table/styles";
import {PaginationFillTable, SearchAndFilerFull} from 'components';
import {httpRequestsOnSuccessActions, invoicePaymentActions} from "store";

const activeRowStyles = {
    borderBottom: '1px solid #347AF0',
    background: 'white'
}

const EDIT_TYPE = 'EDIT_PAYMENT_INVOICE'

export const InvoicePaymentTable = ({head, entries, list, loadingType, noText, margin, listCount,}) => {
    const classes = tableStyle();
    const globalStyle = useGlobalStyles();
    const [page, setPage] = useState(1);
    const history = useHistory()
    const info = history?.location?.state
    const loader = FindLoad(loadingType ? loadingType : '')
    const [activeRow, setActiveRow] = useState({})
    const [selectedId, setSelectedId] = useState()
    const dispatch = useDispatch()
    const loadPay = FindLoad(EDIT_TYPE)
    const loadPayInFull = FindLoad('PAY_IN_FULL_PAYMENT_INVOICE')
    const success = FindSuccess(EDIT_TYPE)


    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_TYPE))
            setActiveRow({})
        }
    }, [success])

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history)
    };

    const handleChange = (e) => {
        if (e.target.name === 'paidAMT') {

            const value = e.target.value;
            const convertedValue = parseFloat(value.replace(/[$,]/g, ''));

            // if (e.target.value !== '0') {
                setActiveRow((prevState) => ({...prevState, ['paidAMT']: convertedValue}));
            // }
        } else {
            setActiveRow((prevState) => ({...prevState, [e.target.name]: e.target.value}));
        }
    }

    const payInFull = (i) => {
        setSelectedId(i)
        const body = {}
        activeRow?.paymentRef ? body.paymentRef = activeRow?.paymentRef : i?.paymentRef ? body.paymentRef = i?.paymentRef : ''
        activeRow?.paymentType ? body.paymentType = activeRow?.paymentType : i?.paymentType ? body.paymentType = i?.paymentType : ''
        activeRow?.paidAMT ? body.paymentAMT = activeRow?.paidAMT : i?.paidAMT ? body.paymentAMT = i?.paidAMT : ''
        dispatch(invoicePaymentActions.payInFullInvoice(i?.id, body))
    }

    const handleSave = () => {
        const body = {
            "paymentAMT": +activeRow?.paidAMT,
        }
        activeRow?.paymentRef ? body.paymentRef = activeRow?.paymentRef : ''
        activeRow?.paymentType ? body.paymentType = activeRow?.paymentType : ''
        dispatch(invoicePaymentActions.editPaymentInvoice(activeRow?.id, body))
    }

    const handleCloseInputs = () => {
        setActiveRow({})
        setSelectedId('')
    }

    if (loader.length) return <Loader/>


    return (
        <div style={{width: '100%'}}>
            <div style={margin ? {margin: 0} : {}} className={globalStyle.tableWrapper}>
                <TableContainer className={globalStyle.tableContainer} component={Paper}>
                    <Table
                        stickyHeader
                        className={globalStyle.table}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {head ? head.map((i, j) => ( i &&
                                    <TableCell key={j}>
                                        <SearchAndFilerFull
                                            item={{ ...i, noSearch: true }}
                                        />
                                    </TableCell>
                                )) : ''}
                            </TableRow>
                        </TableHead>

                        <tbody>
                        {list?.length ? list?.map((i, j) => (
                                <React.Fragment key={j}>
                                    <TableRow
                                        className={`${classes.tableRow}  table-small-paddings ${activeRow?.id === i?.id ? 'active' : ''}`}>
                                        <TableCell >
                                            <IdRow id={i?.displayId ? i?.displayId : i?.id}/>
                                        </TableCell>
                                        <TableCell >
                                            <TextRow name={`${i?.client?.firstName ? i?.client?.firstName : '.'} ${i?.client?.lastName ? i?.client?.lastName : '.'}`} textWidth={8}/>
                                        </TableCell>
                                        <TableCell >
                                            <PriceRow info={i?.totalAMT}/>
                                        </TableCell>
                                        <TableCell
                                            style={activeRow?.id === i?.id ? {...activeRowStyles} : {}}
                                        >
                                            <PriceInput
                                                placeholder={activeRow?.paidAMT === '' ? '0' : ''}
                                                name={'paidAMT'}
                                                handleChange={(e) => handleChange(e, j, i)}
                                                value={activeRow?.id === i?.id ? activeRow?.paidAMT === '' ? 0 :
                                                        activeRow?.paidAMT : i?.paidAMT ? i?.paidAMT : 0}
                                                disabled={activeRow?.id !== i?.id}

                                            />

                                            {/*<p style={{position: 'absolute', marginLeft: '-9px', marginTop: '9px'}}>$</p>*/}
                                            {/*<input*/}
                                            {/*    style={{height: '40px', position: 'relative'}}*/}
                                            {/*    name={'paidAMT'}*/}
                                            {/*    onChange={handleChange}*/}
                                            {/*    disabled={activeRow?.id !== i?.id}*/}
                                            {/*    type='number'*/}
                                            {/*    value={activeRow?.id === i?.id ?  activeRow?.paidAMT === '' ? '' : activeRow?.paidAMT :i?.paidAMT}*/}
                                            {/*    placeholder={activeRow?.paidAMT === '' ? '0' : ''}*/}
                                            {/*    // min="0"*/}
                                            {/*    // step="0.01"*/}
                                            {/*/>*/}
                                        </TableCell>
                                        <TableCell >
                                            <PriceRow info={i?.balance}/>
                                        </TableCell>
                                        <TableCell
                                            style={activeRow?.id === i?.id ? {...activeRowStyles} : {}}
                                        >
                                            <input
                                                style={{height: '40px', position: 'relative'}}
                                                name={'paymentType'}
                                                onChange={handleChange}
                                                disabled={activeRow?.id !== i?.id}
                                                value={activeRow?.id === i?.id ? activeRow?.paymentType : i?.paymentType}
                                                placeholder={'N/A'}
                                            />
                                        </TableCell>
                                        <TableCell
                                            style={activeRow?.id === i?.id ? {...activeRowStyles} : {}}
                                        >
                                            <input
                                                style={{height: '40px', position: 'relative'}}
                                                onChange={handleChange}
                                                name={'paymentRef'}
                                                value={activeRow?.id === i?.id ? activeRow?.paymentRef : i?.paymentRef}
                                                disabled={activeRow?.id !== i?.id}
                                                placeholder={'N/A'}
                                                type='number'
                                            />
                                        </TableCell>


                                        {RolePermission([PermissionList.INVOICE_PMT_PAY?.code,]) &&
                                            <TableCell
                                                style={{
                                                    color: '#51566D',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '50px',
                                                    width: '200px'
                                                }}
                                            >
                                                {activeRow?.id === i?.id ?
                                                    <div className={classes.alignCenter}>
                                                        <button className={'save'} onClick={handleSave}>
                                                            {activeRow?.id === i?.id && loadPay?.length ?
                                                                <MinLoader
                                                                    margin={"4px 0 0"}
                                                                    color={Colors.ThemeBlue}
                                                                    position='relative'
                                                                />
                                                                :
                                                                'Save'
                                                            }
                                                        </button>
                                                        <button onClick={handleCloseInputs}
                                                                className={classes.removeButton}>
                                                            <img src={Images.remove} alt={'remove'}/>
                                                        </button>
                                                    </div>
                                                    :
                                                    <button
                                                        style={{opacity: i?.status === "COMPLETED" ? 0.5 : 1}}
                                                        disabled={i?.status === "COMPLETED"}
                                                        className={'edit'} onClick={() => setActiveRow(i)}>
                                                        <img src={Images.edit} alt="icon"/>
                                                    </button>
                                                }

                                                {i?.status !== "COMPLETED" &&
                                                    <button onClick={() => payInFull(i)} className={'pay'}>
                                                        {selectedId?.id === i?.id && loadPayInFull?.length ?
                                                            <MinLoader
                                                                margin={"4px 0 0"}
                                                                color={'white'}
                                                                position='relative'
                                                            />
                                                            :
                                                            'Pay in Full'
                                                        }</button>
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                </React.Fragment>
                            ))
                            :
                            <NoItemsYet  text={`No ${noText} yet`}/>
                        }
                        </tbody>
                    </Table>
                    {listCount > 0 ?
                        <div style={{height: '76px'}}>
                            <PaginationFillTable
                                listLength={list?.length}
                                entries={entries}
                                page={page}
                                handleReturn={(number) => changePage(number)}
                                count={listCount}
                            />
                        </div>
                        : ''
                    }
                </TableContainer>
            </div>
        </div>
    )
}