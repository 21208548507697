import {makeStyles} from "@material-ui/core/styles";


export const reportsWrapper = makeStyles(() => ({
    systemItemWrapper: {
        padding: 20,
        backgroundColor: "white",
        borderRadius: 8,
        position: "relative",
    },

    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        height:'36px',
        marginBottom:'30px',
    },

    headerTitle: {
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '25px',
        color: '#4B5C68',
        marginLeft: '12px',
    }
}))