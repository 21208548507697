import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import { DrawerContext, getMenuTitle, Images, PermissionList, RolePermission } from 'utils';
import { leftBarCommonStyle } from './styles';


const billingSections = [
    RolePermission([
        PermissionList.CLAIMS_READ?.code,
        PermissionList.CLAIMS_CREATE?.code,
        PermissionList.CLAIMS_UPDATE?.code,
        PermissionList.CLAIMS_CLOSE?.code,
    ]) &&
    { label: 'Pending Claims', path: '/pendingClaims', name: 'Billing' },
    { label: 'Generate Claim', path: '/generateClaim', detail: '/pendingClaims', name: 'Billing' },

    RolePermission([
        PermissionList.CLAIMS_READ?.code,
        PermissionList.CLAIMS_CREATE?.code,
        PermissionList.CLAIMS_UPDATE?.code,
        PermissionList.CLAIMS_CLOSE?.code,
    ]) &&
    { label: 'Billed Claims', path: '/billedClaims', name: 'Billing' },

    RolePermission([
        PermissionList.INVOICE_READ?.code,
        PermissionList.INVOICE_CREATE?.code,
        PermissionList.INVOICE_UPDATE?.code,
        PermissionList.INVOICE_CLOSE?.code,
    ]) &&
    { label: 'Invoices', path: '/invoices', name: 'Billing' },
    { label: 'Generate Invoice', path: '/generateInvoice', name: 'Billing' },

];

const postingSections = [
    RolePermission([
        PermissionList.CLAIM_PMT_READ?.code,
        PermissionList.CLAIM_PMT_PAY?.code,
        PermissionList.CLAIM_PMT_SEND_APPEAL?.code,
        PermissionList.CLAIM_PMT_APPEAL_READ?.code,
        PermissionList.CLAIM_PMT_APPEAL_PAY?.code,
    ]) &&
    { label: 'Claim Payments', path: '/claimPayments', name: 'Posting' },
    RolePermission([
        PermissionList.INVOICE_PMT_READ?.code,
        PermissionList.INVOICE_PMT_PAY?.code,
        PermissionList.INVOICE_PMT_CLOSE?.code,
    ]) &&
    { label: 'Invoice Payments', path: '/invoicePayments', name: 'Posting' },
];

const payrollSections = [

    RolePermission([PermissionList.STAFF_TIMESHEET_CREATE.code, PermissionList.STAFF_TIMESHEET_READ.code]) &&
    { label: 'Active', path: '/activePayroll', name: 'Payroll' },

    RolePermission([PermissionList.STAFF_TIMESHEET_CREATE.code, PermissionList.STAFF_TIMESHEET_READ.code]) &&
    { label: 'Processed', path: '/processedPayroll', name: 'Payroll' },
];

export const CustomAccordion = ({ item, linkInfo }) => {
    const classes = leftBarCommonStyle();
    const accordionItems =
        item.name === 'Billing' ? billingSections :
            item.name === 'Posting' ? postingSections :
                item.name === 'Payroll' ? payrollSections :
                    [];
    const { open, handleDrawerOpenClose } = useContext(DrawerContext);
    const [isShown, setIsShown] = useState(null);
    const [expand, setExpand] = useState(null);
    const url = window.location.pathname;
    const menuTitle = getMenuTitle(url);
    const location = useLocation();
    const originalPath = location?.pathname?.split('/')[1];
    const sectionsListClassName = `${classes.sectionsListBoxStyle} ${open && (expand === item?.name) ? 'shown' : ''}`;

    useEffect(() => {
        const findCurrent = accordionItems?.find((i) => i?.path === `/${originalPath}`);
        setIsShown(findCurrent);
        setExpand(findCurrent?.name);
    }, [originalPath]);

    function handleAccordionClick() {
        setExpand(item?.name !== expand ? item?.name : null);
        if (!open) {
            handleDrawerOpenClose(true);
        }
    }

    return (
        <div className="router-accordion-box">
            <div className={classes.linkPathWrapper} onClick={handleAccordionClick}>
                <div className={item?.name === isShown?.name ? classes.activeBorder : classes.passiveBorder} />
                <div style={{ width: open ? '100%' : 'auto', borderRadius: open ? '4px 0px 0px 4px' : '4px' }}
                     className={`${classes.linkWrapper} ${item?.name === isShown?.name ? classes.linkWrapperActive : classes.linkWrapperPassive}`}
                >
                    <div className={classes.linkImageWrapper}>
                        <img src={item?.name === isShown?.name ? item?.activeIcon : item?.icon} alt="icon" />
                    </div>
                    {open &&
                        <>
                            <p>{item?.name}</p>
                            <div
                                className={expand === item?.name || isShown?.name === item?.name ? '' : 'router-accordion-expand-icon'}>
                                <img className={
                                    `${classes.accordArrowStyle} 
                              ${expand === item?.name ? 'rotate' : ''}`}
                                     src={isShown?.name === item?.name ? Images.accordArrowBlue : Images.accordArrowBlack}
                                     alt="icon"
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className={sectionsListClassName}>
                <div>
                    {accordionItems?.map((section, index) => {
                        if (section) {
                            const path = section?.path;
                            const detail = section?.detail;
                            const label = section?.label;
                            const activeClassName = linkInfo === path || menuTitle === label || detail === path ? 'active' : '';
                            if (section?.path !== '/generateClaim' && section?.path !== '/generateInvoice') {
                                return (
                                    <NavLink
                                        onClick={() => setIsShown(item)}
                                        key={index}
                                        to={path}
                                        className={activeClassName}
                                    >
                                        <ListItem button>{label}</ListItem>
                                    </NavLink>
                                );
                            }
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
