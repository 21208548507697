import {call, put, takeLatest} from "redux-saga/effects";
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    httpRequestsOnLoadActions,
} from "store";
import {claimService} from "./claim.service";
import {
    CLOSE_CLAIM,
    GENERATE_CLAIM,
    GET_CLAIMS,
    GET_CLAIMS_SUCCESS,
    GET_CLAIM_BY_ID,
    GET_CLAIM_BY_ID_SUCCESS,
    SUBMIT_CLAIM,
    GET_BILL_INVOICE_PAYMENT,
    GET_BILL_INVOICE_PAYMENT_SUCCESS,
    GET_BILL_CLAIM_PAYMENT_TRANSACTIONS,
    GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS,
    GET_SUBMITTED_CLAIMS,
    GET_SUBMITTED_CLAIMS_SUCCESS,
    SUBMIT_CLAIM_BY_ID,
} from './claim.type';

function* getClaims(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimService.getClaimsService, action?.payload?.data);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLAIMS_SUCCESS,
            payload: {claims: res.data},
        });
    } catch (err) {
        yield put({
            type: GET_CLAIMS_SUCCESS,
            payload: {claims: []},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === "Internal server error") {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
            );
        }
    }
}
function* getSubmittedClaims(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimService.getSubmittedClaimsService, action?.payload?.data);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));

        yield put({
            type: GET_SUBMITTED_CLAIMS_SUCCESS,
            payload: res?.data,
        });
    } catch (err) {
        yield put({
            type: GET_SUBMITTED_CLAIMS_SUCCESS,
            payload: {claims: []},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === "Internal server error") {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
            );
        }
    }
}

function* getClaimById(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    if (action.payload.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    try {
        const res = yield call(claimService.getClaimByIdService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CLAIM_BY_ID_SUCCESS,
            payload: {claimById: res.data},
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === "Internal server error") {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message)
            );
        }
    }
}

function* generateClaim(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.generateClaimService, action?.payload?.group, action?.payload?.merge);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        window.location.replace("/pendingClaims");
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* closeClaim(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(
            claimService.closeClaimService,
            action.payload.id,
            action.payload.details
        );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLAIM_BY_ID,
            payload: {id: action.payload.id},
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* submitClaim(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.submitClaimService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

        yield put({
            type: GET_CLAIMS,
            payload: {data: action?.payload?.requestParams, load: 'noLoad'},
        });
        // yield put({
        //     type: GET_CLAIM_BY_ID,
        //     payload: {id: action.payload.id, load: 'noLoad'},
        // });
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* submitClaimById(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(claimService.submitClaimService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

        yield put({
            type: GET_CLAIM_BY_ID,
            payload: {id: action?.payload?.id, load: 'noLoad'},
        });

    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* billClaimPmtTr(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(claimService.billClaimPmtService, action.payload.id);
        yield put({
            type: GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* billInvoicePmt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(claimService.billInvoicePmtService, action.payload.id);
        yield put({
            type: GET_BILL_INVOICE_PAYMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

export const watchClaim = function* watchClaimSaga() {
    yield takeLatest(GET_CLAIMS, getClaims);
    yield takeLatest(GET_SUBMITTED_CLAIMS, getSubmittedClaims);
    yield takeLatest(GET_CLAIM_BY_ID, getClaimById);
    yield takeLatest(GENERATE_CLAIM, generateClaim);
    yield takeLatest(CLOSE_CLAIM, closeClaim);
    yield takeLatest(SUBMIT_CLAIM, submitClaim);
    yield takeLatest(SUBMIT_CLAIM_BY_ID, submitClaimById);
    /**Bill Details */
    yield takeLatest(GET_BILL_CLAIM_PAYMENT_TRANSACTIONS, billClaimPmtTr);
    yield takeLatest(GET_BILL_INVOICE_PAYMENT, billInvoicePmt);
};
