import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GenerateClaimFragment } from 'fragments';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { CustomBreadcrumbs, Loader } from 'components';
import { FindLoad, FindSuccess } from 'utils';
import moment from 'moment/moment';

const EDIT_APPT_ACTION = 'EDIT_APPOINTMENT';

export const GenerateClaim = () => {
    const dispatch = useDispatch();
    const success = FindSuccess(EDIT_APPT_ACTION);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (success?.length) {
            getAppt('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_APPT_ACTION));
        }
    }, [success]);

    useEffect(() => {
        getAppt();
    }, [info]);

    const getAppt = (load) => {
        const filterInfo = {
            status: 'COMPLETED',
            type: 'SERVICE',
            billStatus: 'NOT_BILLED',
            ...info,
        };
        filterInfo.skip = info?.skip ? info?.skip : 0;
        filterInfo.limit = 50;

        if (info?.firstDate) {
            const startDate = moment.utc(info?.firstDate);
            filterInfo.startDate = startDate.format('YYYY-MM-DD');
        } else {
            delete filterInfo.startDate;
        }

        if (info?.lastDate) {
            const endDate = moment.utc(info?.lastDate);
            filterInfo.endDate = endDate.format('YYYY-MM-DD');
        } else {
            delete filterInfo.endDate;
        }

        dispatch(appointmentActions.getAppointment(filterInfo, load));
    };

    return (
        <>
            <GenerateClaimFragment />
        </>
    );
};
