import React, { useEffect, useState } from "react";

export const useScrollTop = (ref) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScrollTop = (e) => {
      setScrollTop(e.target.scrollTop);

    };

    ref.current?.addEventListener("scroll", handleScrollTop);

    return () => {
      ref.current?.removeEventListener("scroll", handleScrollTop);
    };
  }, []);

  return scrollTop;
};

