import doctors from 'assets/images/icons/doctors.jpg';
import phone from 'assets/images/phone.svg';
import fundingSourcePassive from 'assets/images/icons/fundingSourcePassive.svg';
import fundingSourceActive from 'assets/images/icons/fundingSourceActive.svg';
import staffActive from 'assets/images/icons/staffActive.svg';
import staffPassive from 'assets/images/icons/staffPassive.svg';
import addCircle from 'assets/images/icons/addCircle.svg';
import fundingSourceOutline from 'assets/images/icons/fundingSourceOutline.svg';
import staffOutline from 'assets/images/icons/staffOutline.svg';
import roleManagementActive from 'assets/images/icons/roleManagementActive.svg';
import roleManagementPassive from 'assets/images/icons/roleManagementPassive.svg';
import addYellowIcon from 'assets/images/icons/addYellowIcon.svg';
import employment from 'assets/images/icons/employment.svg';
import removeIcon from 'assets/images/icons/removeIcon.svg';

import offices from 'assets/images/icons/offices.svg';
import officesGray from 'assets/images/icons/officesGray.svg';
import error from 'assets/images/icons/error.svg';
import aToZ from 'assets/images/icons/aToZ.svg';
import zToA from 'assets/images/icons/zToA.svg';
import yellowOffices from 'assets/images/icons/yellowOffices.svg';
import branches from 'assets/images/icons/branches.svg';
import branchesGray from 'assets/images/icons/branchesGray.svg';
import branchGreen from 'assets/images/icons/branchGreen.svg';
import branchFillIcon from 'assets/images/icons/branchesFill.svg';
import humanResources from 'assets/images/icons/humanResources.svg';
import humanResourcesGray from 'assets/images/icons/humanResourcesGray.svg';
import humanResourcesYellow from 'assets/images/icons/humanResourcesFill.svg';
import customer from 'assets/images/icons/customer.svg';
import human from 'assets/images/icons/human.svg';
import customerGray from 'assets/images/icons/customerFill.svg';
import customerFill from 'assets/images/icons/customerGray.svg';
import factoring from 'assets/images/icons/factoring.svg';
import factoringOutline from 'assets/images/icons/factoringOutline.svg';
import accessManagement from 'assets/images/icons/accessManagement.svg';
import accessManagementGray from 'assets/images/icons/accessManagementGray.svg';
import accessManagementUser from 'assets/images/icons/accessManagementUser.svg';
import checked from 'assets/images/icons/checked.svg';
import officeFillBold from 'assets/images/icons/officeFillBold.svg';
import factoringGray from 'assets/images/icons/factoringGray.svg';
import authorityFill from 'assets/images/icons/authorityFill.svg';
import authorityBlue from 'assets/images/icons/authorityBlue.svg';
import authorityBlueFill from 'assets/images/icons/authorityBlueFill.svg';
import userProfile from 'assets/images/icons/userProfile.svg';
import email from 'assets/images/icons/email.svg';
import bluePhone from 'assets/images/icons/phone.svg';
import location from 'assets/images/icons/location.svg';
import edit from 'assets/images/icons/edit.svg';
import signOut from 'assets/images/icons/signOut.svg';
import authorityGray from 'assets/images/icons/authorityGray.svg';
import agents from 'assets/images/icons/agents.svg';
import carrierFill from 'assets/images/icons/carrierFill.svg';
import carrierGray from 'assets/images/icons/carrierGray.svg';
import agentsBlue from 'assets/images/icons/agentsBlue.svg';
import agentsFill from 'assets/images/icons/agentsFill.svg';
import factoringBold from 'assets/images/icons/factoringBold.svg';
import carrier from 'assets/images/icons/carrier.svg';
import carrierBold from 'assets/images/icons/carrierBold.svg';
import generalInfoIcon from 'assets/images/icons/generalInfo.svg';
import address from 'assets/images/icons/address.svg';
import otherDetailsIcon from 'assets/images/icons/otherDetails.svg';
import remove from 'assets/images/icons/remove.svg';
import addLight from 'assets/images/icons/addLight.svg';
import addLight2 from 'assets/images/icons/addLight2.svg';
import clientActive from 'assets/images/icons/clientActive.svg';
import clients from 'assets/images/icons/clients.svg';
import clientsPassive from 'assets/images/icons/clientsPassive.svg';
import clientModalicon1 from 'assets/images/icons/clientModal1.svg';
import clientModalicon2 from 'assets/images/icons/clientModal2.svg';
import clientModalicon3 from 'assets/images/icons/clientModal3.svg';
import checkmark from 'assets/images/icons/checkmark.svg';
import systemActive from 'assets/images/icons/systemActive.svg';
import systemPassive from 'assets/images/icons/systemPassive.svg';
import systemIcon from 'assets/images/icons/systemIcon.svg';
import authIcon from 'assets/images/icons/authIcon.svg';
import errorIcon from 'assets/images/icons/errorIcon.svg';
import successIcon from 'assets/images/icons/successIcon.svg';
import infoIcon from 'assets/images/icons/informationIcon.svg';
import addHours from 'assets/images/icons/addHours.svg';
import authIconGen from 'assets/images/icons/authIconGen.svg';
import addIcon from 'assets/images/icons/Add.svg';
import fileIcon from 'assets/images/icons/fileIcon.svg';
import eventsFill from '../assets/images/icons/eventsFill.svg';
import download from '../assets/images/icons/download.svg';
import forward from '../assets/images/icons/forward.svg';
import authEdit from 'assets/images/icons/authEdit.svg';
import recurrance from 'assets/images/icons/recurrance.svg';
import pngIcon from 'assets/images/icons/pngIcon.svg';
import jpegIcon from 'assets/images/icons/jpegIcon.svg';
import pdfIcon from 'assets/images/icons/pdfIcon.svg';
import csvIcon from 'assets/images/icons/csvIcon.svg';
import eventsPassive from 'assets/images/icons/eventsPassive.svg';
import accessManagementFill from 'assets/images/icons/accessManagementFill.svg';
import accessManagementOutline from 'assets/images/icons/accessManagementOutline.svg';
import dropdownArrow from 'assets/images/icons/dropdownArrow.svg';
import dropdownArrowBlue from 'assets/images/icons/dropdownArrowBlue.svg';
import search from 'assets/images/icons/search.svg';
import checkmarkWhite from 'assets/images/icons/checkmarkWhite.svg';
import dropdownArrowFilledBlue from 'assets/images/icons/dropdownArrowFilledBlue.svg';
import billingFill from 'assets/images/icons/billingFill.svg';
import billingPassive from 'assets/images/icons/billingPassive.svg';
import accordArrowBlue from 'assets/images/icons/accordArrowBlue.svg';
import accordArrowBlack from 'assets/images/icons/accordArrowBlack.svg';
import latestEarliest from 'assets/images/icons/latestEarliest.svg';

import billingOutline from 'assets/images/icons/billingOutline.svg';
import addOrange from 'assets/images/icons/addOrange.svg';
import aToZWhite from 'assets/images/icons/aToZWhite.svg';
import latestEarliestWhite from 'assets/images/icons/latestEarliestWhite.svg';
import dropdownArrowWhite from 'assets/images/icons/dropdownArrowWhite.svg';
import claimInfo from 'assets/images/icons/claimInfo.svg';
import checkmarkBlue from 'assets/images/icons/checkmarkBlue.svg';
import uncheck from 'assets/images/icons/uncheck.svg';
import postingFill from 'assets/images/icons/postingFill.svg';
import postingPassive from 'assets/images/icons/postingPassive.svg';
import success from 'assets/images/icons/success.svg';
import successGreen from 'assets/images/icons/successGreen.svg';
import editPmt from 'assets/images/icons/editPmt.svg';
import forwardBlue from 'assets/images/icons/forwardBlue.svg';
import addLightIcon from 'assets/images/icons/addLightIcon.svg';
import infoWhiteIcon from 'assets/images/icons/infoWhiteIcon.svg';
import warning from 'assets/images/icons/warning.svg';
import earliestLatestBlue from 'assets/images/icons/earliestLatestBlue.svg';
import show from 'assets/images/icons/show.svg';
import toolInfo from 'assets/images/icons/toolInfo.svg';
import addBluePlus from 'assets/images/icons/addBluePlus.svg';
import moreInfo from 'assets/images/icons/moreInfo.svg';
import portfolio from 'assets/images/icons/portfolio.svg';
import bigEmployment from 'assets/images/icons/bigEmployment.svg';
import greenMark from 'assets/images/icons/greenMark.svg';
import aboutInformation from 'assets/images/icons/aboutInformation.svg';
import upload from 'assets/images/icons/upload.svg';
import document from 'assets/images/icons/document.svg';
import deleteDock from 'assets/images/icons/deleteDock.svg';
import editDock from 'assets/images/icons/editDock.svg';
import downloadDock from 'assets/images/icons/downloadDock.svg';
import showDock from 'assets/images/icons/showDock.svg';
import hours from 'assets/images/icons/hours.svg';
import closeVector from 'assets/images/icons/closeVector.svg';
import bigDownload from 'assets/images/icons/bigDownload.svg';
import addIconAndCircle from 'assets/images/icons/addIconAndCircule.svg';
import reportGray from 'assets/images/icons/reportGray.svg';
import reportBlue from 'assets/images/icons/reportBlue.svg';
import downloadPdf from 'assets/images/icons/downloadPdf.svg';
import reportsTab from 'assets/images/icons/reportsTab.svg';
import logo from 'assets/images/icons/logo.svg';
import blueBlackLogo from 'assets/images/icons/blueBlackLogo.svg';
import payrollActive from 'assets/images/icons/payrollActive.svg';
import payrollPassive from 'assets/images/icons/payrollPassive.svg';
import east from 'assets/images/icons/east.svg';
import editGray from 'assets/images/icons/editGray.svg';
import dropDown from 'assets/images/icons/dropDown.svg';
import dropUp from 'assets/images/icons/dropUp.svg';
import time from 'assets/images/icons/time.svg';
import locationGray from 'assets/images/icons/locationGray.svg';
import starVector from 'assets/images/icons/starVector.svg';
import toolInfoBlack from 'assets/images/icons/toolInfoBlack.svg';
import dropdownArrowUpBlue from 'assets/images/icons/dropdownArrowUpBlue.svg';
import dropdownBlue from 'assets/images/icons/dropdownBlue.svg';
import lock from 'assets/images/icons/lock.svg';
import unlock from 'assets/images/icons/unlock.svg';
import insurance from 'assets/images/icons/insurance.svg';
import emailGray from 'assets/images/icons/emailGray.svg';
import call from 'assets/images/icons/call.svg';
import verify from 'assets/images/icons/verify.svg';
import checkCredential from 'assets/images/icons/checkCredential.svg';
import uncheckCredential from 'assets/images/icons/uncheckCredential.svg';
import licenseIcon from 'assets/images/icons/licenseicon.svg';
import rightDropdownArrowBlue from 'assets/images/icons/rightDropdownArrowBlue.svg';
import selectCredential from 'assets/images/icons/selectCredential.svg';
import notes from 'assets/images/icons/notes.svg';
import grayCalendar from 'assets/images/icons/grayCalendar.svg';
import grayEdit from 'assets/images/icons/grayEdit.svg';
import uploadImage from 'assets/images/icons/uploadImage.svg';
import closeBlackVector from 'assets/images/icons/closeBlackVector.svg';
import reConnectedTemplate from 'assets/images/icons/reConnectedTemplate.svg';
import connectedTemplate from 'assets/images/icons/connectedTemplate.svg';
import apptGeneralInfo from 'assets/images/icons/apptGeneralInfo.png';
import repetAppt from 'assets/images/icons/repetAppt.png';
import repetApptBlue from 'assets/images/icons/repetApptBlue.png';
import office from 'assets/images/icons/office.svg';
import reinvite from 'assets/images/icons/reinvite.svg';
import scheduleTime from 'assets/images/icons/scheduleTime.svg';
import scheduleGroup from 'assets/images/icons/scheduleGroup.svg';
import scheduleList from 'assets/images/icons/scheduleList.svg';
import link from 'assets/images/icons/link.svg';
import externalLink from 'assets/images/icons/externalLink.svg';
import note from 'assets/images/icons/note.svg';
import cloudUpload from 'assets/images/icons/cloudUpload.svg';
import pastAuthorizations from 'assets/images/icons/pastAuthorizations.svg';
import googleCalendar from 'assets/images/icons/googleCalendar.svg';
import appleCalendar from 'assets/images/icons/appleCalendar.svg';
import accessTime from 'assets/images/icons/accessTime.svg';
import filters from 'assets/images/icons/filters.svg';
import arrowRight from 'assets/images/icons/arrowRight.svg';
import calendarToday from 'assets/images/icons/calendarToday.svg';
import expandFull from 'assets/images/icons/expandFull.svg';
import timelapse from 'assets/images/icons/timelapse.svg';
import minimize from 'assets/images/icons/minimize.svg';
import logoSmall from 'assets/images/icons/logoSmall.svg';
import downloadCloud from 'assets/images/icons/downloadCloud.svg';
import downloadCloudWhite from 'assets/images/icons/downloadCloudWhite.svg';
import accessManagementIcon from 'assets/images/icons/accessManagementIcon.svg';
import sms from 'assets/images/icons/sms.svg';
import callCalling from 'assets/images/icons/callCalling.svg';
import grayLocation from 'assets/images/icons/grayLocation.svg';
import tableSortBlue from 'assets/images/icons/tableSortBlue.svg';
import tableSortGray from 'assets/images/icons/tableSortGray.svg';
import client from 'assets/images/icons/user.svg';
import accessManagementOutlineGray from 'assets/images/icons/accessManagementOutlineGray.svg';
import alertTriangle from 'assets/images/icons/alertTriangle.svg';
import removeAddress from 'assets/images/icons/removeAddress.svg';
import noteClock from 'assets/images/icons/noteClock.svg';
import noteCalendar from 'assets/images/icons/noteCalendar.svg';
import noteEdit from 'assets/images/icons/noteEdit.svg';
import editTemplate from 'assets/images/icons/editTemplate.svg';
import staffPosition from 'assets/images/icons/staffPosition.svg';
import documentUpload from 'assets/images/icons/documentUpload.svg';
import timePast from 'assets/images/icons/timePast.svg';
import deleteLicenseIcon from 'assets/images/icons/deleteLicenseIcon.svg';
import editBlack from 'assets/images/icons/editBlack.svg';
import hideEye from 'assets/images/icons/hideEye.svg';
import showEye from 'assets/images/icons/showEye.svg';
import noFile from 'assets/images/icons/noFile.svg';
import CSVFile from 'assets/images/icons/CSVFile.svg';
import JPEGFile from 'assets/images/icons/JPEGFile.svg';
import PDFFile from 'assets/images/icons/PDFFile.svg';
import PNGFile from 'assets/images/icons/PNGFile.svg';
import authFile from 'assets/images/icons/authFile.svg';
import uploadAvatar from 'assets/images/icons/uploadAvatar.svg';
import closeButton from 'assets/images/icons/closeButton.svg';
import darkGrayDone from 'assets/images/icons/darkGrayDone.svg';
import trash from 'assets/images/icons/trash.svg';
import uploadCloud from 'assets/images/icons/uploadCloud.svg';
import uploadedFile from 'assets/images/icons/uploadedFile.svg';
import complete from 'assets/images/icons/complete.svg';
import grayTrash from 'assets/images/icons/grayTrush.svg';
import fsIcon from 'assets/images/icons/fsIcon.svg';
import billingTab from 'assets/images/icons/leftBar/billingTab.svg';
import clientTab from 'assets/images/icons/leftBar/clientTab.svg';
import fsTab from 'assets/images/icons/leftBar/fsTab.svg';
import payrollTab from 'assets/images/icons/leftBar/payrollTab.svg';
import postingTab from 'assets/images/icons/leftBar/postingTab.svg';
import reportTab from 'assets/images/icons/leftBar/reportTab.svg';
import roleTab from 'assets/images/icons/leftBar/roleTab.svg';
import scheduleTab from 'assets/images/icons/leftBar/scheduleTab.svg';
import staffTab from 'assets/images/icons/leftBar/staffTab.svg';
import systemTab from 'assets/images/icons/leftBar/systemTab.svg';
import chatSupport from 'assets/images/icons/leftBar/chatSupport.svg';
import leftSideIcon from 'assets/images/icons/leftBar/leftSideIcon.svg';
import redTrash from 'assets/images/icons/redTrash.svg';

import billingTabActive from 'assets/images/icons/leftBar/billingTabActive.svg';
import clientTabActive from 'assets/images/icons/leftBar/clientTabActive.svg';
import fsTabActive from 'assets/images/icons/leftBar/fsTabActive.svg';
import payrollTabActive from 'assets/images/icons/leftBar/payrollTabActive.svg';
import postingTabActive from 'assets/images/icons/leftBar/postingTabActive.svg';
import reportTabActive from 'assets/images/icons/leftBar/reportTabActive.svg';
import roleTabActive from 'assets/images/icons/leftBar/roleTabActive.svg';
import scheduleTabActive from 'assets/images/icons/leftBar/scheduleTabActive.svg';
import staffTabActive from 'assets/images/icons/leftBar/staffTabActive.svg';
import systemTabActive from 'assets/images/icons/leftBar/systemTabActive.svg';
export const Images = {
    billingTabActive,
    clientTabActive,
    fsTabActive,
    payrollTabActive,
    postingTabActive,
    reportTabActive,
    roleTabActive,
    scheduleTabActive,
    staffTabActive,
    systemTabActive,
    redTrash,
    leftSideIcon,
    chatSupport,
    billingTab,
    clientTab,
    fsTab,
    payrollTab,
    postingTab,
    reportTab,
    roleTab,
    scheduleTab,
    staffTab,
    systemTab,
    fsIcon,
    grayTrash,
    complete,
    uploadedFile,
    uploadCloud,
    trash,
    closeButton,
    uploadAvatar,
    authFile,
    CSVFile,
    JPEGFile,
    PDFFile,
    PNGFile,
    noFile,
    showEye,
    hideEye,
    editBlack,
    deleteLicenseIcon,
    timePast,
    documentUpload,
    editTemplate,
    noteEdit,
    noteCalendar,
    noteClock,
    staffPosition,
    removeAddress,
    alertTriangle,
    accessManagementOutlineGray,
    client,
    tableSortBlue,
    tableSortGray,
    downloadCloudWhite,
    grayLocation,
    callCalling,
    sms,
    downloadCloud,
    logoSmall,
    accessManagementIcon,
    minimize,
    timelapse,
    expandFull,
    calendarToday,
    arrowRight,
    filters,

    accessTime,
    appleCalendar,
    googleCalendar,
    pastAuthorizations,
    cloudUpload,
    externalLink,
    note,
    link,
    scheduleGroup,
    scheduleList,
    scheduleTime,
    reinvite,
    office,
    repetApptBlue,
    repetAppt,
    apptGeneralInfo,
    reConnectedTemplate,
    connectedTemplate,
    closeBlackVector,
    uploadImage,
    grayCalendar,
    grayEdit,
    notes,
    selectCredential,
    rightDropdownArrowBlue,
    licenseIcon,
    uncheckCredential,
    checkCredential,
    verify,
    call,
    emailGray,
    insurance,
    lock,
    unlock,
    toolInfoBlack,
    starVector,
    locationGray,
    time,
    dropDown,
    dropUp,
    editGray,
    east,
    payrollActive,
    payrollPassive,
    blueBlackLogo,
    logo,
    reportsTab,
    downloadPdf,
    reportGray,
    reportBlue,
    addIconAndCircle,
    bigDownload,
    closeVector,
    hours,
    showDock,
    deleteDock,
    editDock,
    downloadDock,
    document,
    upload,
    aboutInformation,
    greenMark,
    darkGrayDone,
    bigEmployment,
    portfolio,
    moreInfo,
    addBluePlus,
    doctors: doctors,
    phone: phone,
    fundingSourceActive: fundingSourceActive,
    fundingSourcePassive: fundingSourcePassive,
    staffActive: staffActive,
    staffPassive: staffPassive,
    addCircle: addCircle,
    fundingSourceOutline: fundingSourceOutline,
    roleManagementActive: roleManagementActive,
    roleManagementPassive: roleManagementPassive,
    addYellowIcon: addYellowIcon,
    addHours: addHours,
    authIconGen: authIconGen,
    offices: offices,
    officesGray: officesGray,
    error: error,
    aToZ: aToZ,
    zToA: zToA,
    yellowOffices: yellowOffices,
    branches: branches,
    branchesGray: branchesGray,
    branchGreen: branchGreen,
    branchFill: branchFillIcon,
    humanResources: humanResources,
    humanResourcesGray: humanResourcesGray,
    humanResourcesYellow: humanResourcesYellow,
    human: human,
    customer: customer,
    customerFill: customerFill,
    customerGray: customerGray,
    factoring: factoring,
    factoringOutline: factoringOutline,
    accessManagement: accessManagement,
    accessManagementGray: accessManagementGray,
    accessManagementUser: accessManagementUser,
    checked: checked,
    officeFillBold: officeFillBold,
    factoringGray: factoringGray,
    authorityFill: authorityFill,
    authorityBlue: authorityBlue,
    authorityBlueFill: authorityBlueFill,
    userProfile: userProfile,
    email: email,
    bluePhone: bluePhone,
    location: location,
    edit: edit,
    signOut: signOut,
    authorityGray: authorityGray,
    agents: agents,
    carrierFill: carrierFill,
    carrierGray: carrierGray,
    agentsBlue: agentsBlue,
    agentsFill: agentsFill,
    factoringBold: factoringBold,
    carrier: carrier,
    carrierBold: carrierBold,
    staffOutline: staffOutline,
    generalInfoIcon: generalInfoIcon,
    address: address,
    otherDetailsIcon: otherDetailsIcon,
    remove: remove,
    addLight: addLight,
    addLight2: addLight2,
    clientActive: clientActive,
    clients: clients,
    clientsPassive: clientsPassive,
    clientModalicon1: clientModalicon1,
    clientModalicon2: clientModalicon2,
    clientModalicon3: clientModalicon3,
    checkmark: checkmark,
    systemActive: systemActive,
    systemPassive: systemPassive,
    systemIcon: systemIcon,
    authIcon: authIcon,
    errorIcon: errorIcon,
    successIcon: successIcon,
    infoIcon: infoIcon,
    addIcon: addIcon,
    employment: employment,
    fileIcon: fileIcon,
    eventsFill: eventsFill,
    download: download,
    forward: forward,
    authEdit: authEdit,
    recurrance: recurrance,
    pngIcon: pngIcon,
    jpegIcon: jpegIcon,
    pdfIcon: pdfIcon,
    csvIcon: csvIcon,
    removeIcon: removeIcon,
    eventsPassive: eventsPassive,
    accessManagementFill: accessManagementFill,
    accessManagementOutline: accessManagementOutline,
    dropdownArrow,
    dropdownArrowBlue,
    dropdownArrowUpBlue,
    dropdownBlue,
    search,
    checkmarkWhite,
    dropdownArrowFilledBlue,
    billingFill,
    billingPassive,
    accordArrowBlue,
    accordArrowBlack,
    latestEarliest,
    billingOutline,
    addOrange,
    aToZWhite,
    latestEarliestWhite,
    dropdownArrowWhite,
    claimInfo,
    checkmarkBlue,
    uncheck,
    postingFill,
    postingPassive,
    success,
    successGreen,
    editPmt,
    forwardBlue,
    addLightIcon,
    infoWhiteIcon,
    warning,
    earliestLatestBlue,
    show,
    toolInfo,
};
