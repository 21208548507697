import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentNotification = () => {
  const width = useWidth();


  const images = {
    desktop: {
      design: "/assets/images/controlPanel/cpNotification.png"
    },
    tablet: {
      design: "/assets/images/controlPanel/cpNotificationTablet.png"
    },
    mobile: {
      design: "/assets/images/controlPanel/cpNotificationMobile.png"
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };

  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-menu-design notification-wrapper">
        <h2 className="title">
          Notification
        </h2>

        <p className="description description-margin" style={{ maxWidth: "676px" }}>
          Here you'll find timely updates and alerts tailored to enhance your dining experience.
        </p>

        <p className="description" style={{ maxWidth: "676px" }}>
          You'll receive notifications for various key actions:
        </p>

        <ul className="lists" style={{ maxWidth: "676px" }}>
          <li className="description">
                    <span className="description" style={{ fontWeight: "400" }}>
                      Dine-in Orders:
                    </span>
            Get updates on your food/drink orders. For more details, check the Order section.
          </li>

          <li className="description">
                    <span className="description" style={{ fontWeight: "400" }}>
                     Check Requests:
                    </span>
            Keep track of check requests effortlessly, with detailed information available in the Transaction section.
          </li>

          <li className="description">
                    <span className="description" style={{ fontWeight: "400" }}>
                Call Waiter:
                    </span>
            The user can call a waiter using the app whenever you can see the table number and time.
          </li>
        </ul>

      </div>
    </div>
  );
};

