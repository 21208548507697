import React from 'react';
import { motion } from 'framer-motion';
import { Main } from 'fragments';
import { AboutUsCard, CoreValuesCard, TheMission, GetInTouch } from './fragments';
import { aboutUsCard, coreValuesCard } from './fragments/constants';
import { landingImages } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const AboutUs = () => {
    return (
        <Main>
            <div className='about-us-page'>
                <div className='container'>
                    <div style={{ overflow: 'hidden' }}>
                        <motion.h1
                            className='about-us-title'
                            {...animationParams}
                            variants={animationVariants.textVariant}
                        >
                            Discover our journey, mission, and the passionate team dedicated to transforming ABA
                            practice management
                        </motion.h1>
                    </div>

                    <div className='about-us-banner-images-wrapper'>
                        <motion.div
                            className='about-us-banner-1'
                            {...animationParams}
                            variants={animationVariants.cardsVariant}
                        />

                        <motion.div
                            className='about-us-banner-2'
                            {...animationParams}
                            variants={animationVariants.cardsVariant}
                        />

                        <motion.div
                            className='about-us-banner-3'
                            {...animationParams}
                            variants={animationVariants.cardsVariant}
                        />

                    </div>
                </div>

                <div className='about-us-cards-block'>
                    <div className='container'>
                        <div className='about-us-cards'>
                            {aboutUsCard.map(({ title, Icon, description }, index) => (
                                <AboutUsCard
                                    key={index}
                                    title={title}
                                    Icon={Icon}
                                    description={description}
                                />
                            ))}
                        </div>
                    </div>
                </div>


                <div className='core-values-wrapper'>
                    <div className='container'>
                        <div className='core-values-box'>
                            <p className='core-values-toolTip'>
                                Commitment. Innovation. Integrity. Collaboration
                            </p>

                            <div style={{ overflow: 'hidden' }}>
                                <motion.h2
                                    className='core-values-title'
                                    {...animationParams}
                                    variants={animationVariants.titleVariant}
                                >
                                    Core Values That Drive Us
                                </motion.h2>
                            </div>


                            <div className='core-values-cards'>
                                {coreValuesCard.map(({ title, Icon, description }, index) => (
                                    <CoreValuesCard
                                        className='core-values-card-item'
                                        key={index}
                                        title={title}
                                        Icon={Icon}
                                        description={description}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <TheMission />

                <GetInTouch />
            </div>
        </Main>
    );
};
