import { cardStyle } from './style';
import moment from 'moment';
import { makeCapitalize } from 'utils';
import { TextRow } from '../table';
import { CustomDateSelector } from '../inputs';
import React from 'react';

export const HistoryCard = ({ key, data }) => {
    const classes = cardStyle();
    let correctDate = moment.utc(data?.id || data?._id).format('dddd, MMMM DD, YYYY');

    return (
        <div className={classes.historyCardStyle} key={key}>
            <p className={classes.historyCardDateStyle}>{correctDate}</p>
            {data?.data?.map((i, j) => (
                <div key={j} className={classes.historyCardBoxStyle}>
                    <div className={classes.historyCardBoxTimeAndName}>
                        <p style={{width:'61px'}}>{i.time}</p>
                        <div className={classes.circle} />
                        <p className={classes.userInformation}>
                            <TextRow name={
                                !!i.user?.length ? `${!!i.user[0].firstName ? makeCapitalize(i.user[0].firstName) : ''} ${!!i.user[0].lastName ? makeCapitalize(i.user[0].lastName) : ''}`
                                    : 'N/A'
                            } />
                        </p>
                    </div>
                    <p>
                        {i.title}
                    </p>
                </div>
            ))}
        </div>
    );
};
