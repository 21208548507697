import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { systemItemStyles } from './styles';
import {  FindLoad, Images, token, useModal } from 'utils';
import { CustomDelete, CustomizedSwitch, Loader, SimpleTooltip } from 'components';
import { systemActions } from 'store';

const CONNECT_GOOGLE = 'GET_CONNECT_CALENDARS';
const DISCONNECT_CALENDAR = 'DISCONNECT_CALENDAR';
export const Calendars = () => {
    const calendars = useSelector((state) => state.system.calendars);
    const classes = systemItemStyles();
    const { close, open } = useModal();
    const dispatch = useDispatch();
    const baseUrl = localStorage.getItem('baseUrl');
    const loader = FindLoad(CONNECT_GOOGLE);
    const [google, setGoogle] = useState(false);

    useEffect(() => {
        dispatch(systemActions.getConnectCalendars());
    }, []);

    useEffect(() => {
        if(calendars?.googleCalendar){
            setGoogle(calendars?.googleCalendar?.isActive)
        }
    }, [calendars])

    const handleSetCalendarStatus = (type) => {
        setGoogle(!google);
        dispatch(systemActions.calendarStatus({ type: type }))
    };
    const removeCalendar = (type) => {
        const title = type === 'GOOGLE' ? 'Google' : 'Apple'
            open(<CustomDelete
                text={`Are you sure you want to disconnect your ${title} Calendar from TherapyLake? This action will remove the synchronization of appointments and events between TherapyLake and your ${title} Calendar.`}
                info={`Disconnect ${title} Calendar?`}
                innerText={'Disconnect'}
                align={'start'}
                handleDel={() => dispatch(systemActions.disconnectCalendar({ type: type }))}
                handleClose={() => close()}
                actionType={DISCONNECT_CALENDAR}
            />);
    };

    const connect = (type) => {
        if (type === 'google') {
            window.open(`${baseUrl}/staff/google?token=${token}`, '_parent');
        }
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (

        <div className={classes.companyBox}>
            <p className={classes.title}>Calendar Integration</p>
            <p className={classes.subTitle}>Connect and link TherapyLake with your Google and Apple calendars to
                seamlessly synchronize
                appointments and events.
            </p>


            <div className={classes.calendarBox}>
                <div className={classes.iconAndTitle}>
                    <img src={Images.googleCalendar} alt="google" />
                    <p>Google Calendar</p>
                </div>
                {calendars?.googleCalendar?.isConnected ?
                    <div className={classes.iconAndTitle}>
                        <SimpleTooltip title={<p>{'Deactivate Calendar Sync'}</p>} placement="top-start">
                            <CustomizedSwitch
                                checked={google}
                                handleClick={() => handleSetCalendarStatus('GOOGLE')}
                            />
                        </SimpleTooltip>
                        <SimpleTooltip title={<p>{'Disconnect Google Calendar'}</p>} placement="top-start">
                            <button className={classes.removeBtn} onClick={() => removeCalendar('GOOGLE')}>
                                <img src={Images.remove} alt="icon" />
                            </button>
                        </SimpleTooltip>
                    </div>
                    :
                    <button onClick={() => connect('google')} className={classes.connectBtn}>
                        Connect
                    </button>
                }
            </div>

            {/*<div className={classes.calendarBox}>*/}
            {/*    <div className={classes.iconAndTitle}>*/}
            {/*        <img src={Images.appleCalendar} alt="google" />*/}
            {/*        <p>Apple Calendar</p>*/}
            {/*    </div>*/}
            {/*    <button className={classes.connectBtn}>*/}
            {/*        Connect*/}
            {/*    </button>*/}
            {/*</div>*/}


        </div>

    );
};