import React from "react";
import {PermissionList, RolePermission} from "utils";

// Invoice Payment Table config
export const invoicePaymentHead = [
   { name: "displayId", title: "Invoice ID", smallSize: 7,  custom: false },
   { name: "", rowName: 'firstName', title: "Client", custom: false },
   { name: "totalAMT", title: "Total AMT", smallSize: 5, custom: false },
   { name: "totalUsed", title: "Payment AMT",  smallSize: 10, custom: false, disabled:true },
   { name: "balance", title: "Balance", custom: false },
   { name: "paymentType", title: "Payment Type",  custom: false, disabled:true  },
   { name: "paymentReference", title: "Payment Reference", smallSize: 11, custom: false, disabled:true },
   RolePermission([PermissionList.INVOICE_PMT_PAY?.code,]) &&
   { name: "actions", title: "Actions", custom: false, disabled:true },
];

export const ACTION_TYPE = "GET_INVOICE_PAYMENTS";
