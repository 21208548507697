import React from "react";
import {ErrMessage} from "components";
import {inputsStyle} from "./styles";
import {isNotEmpty} from "../../utils";

export const UserTextArea = ({
                                 className = "",
                                 id = "",
                                 name,
                                 label,
                                 value,
                                 onChange,
                                 typeError = "",
                                 hasText,
                                 maxCharsLabel,
                                 max, margin, errStyles
                             }) => {
    const classes = inputsStyle();

    const condStyles = `${typeError ? "error" : hasText ? "filled" : ""}`;
    const userTextAreaClassName = `${classes.userTextAreaStyle} ${condStyles} ${className}`;
    const errorAndCharsBoxClassName = `${classes.errorAndCharsBoxStyle} ${!!maxCharsLabel ? "hasMaxText" : ""}`;

    return (
        <>
            <div className={userTextAreaClassName} style={margin ? {margin: margin} : {}}>
                <label htmlFor={id}> {label} </label>
                <textarea
                    name={name}
                    id={id}
                    value={isNotEmpty(value) ? value : ""}
                    onChange={onChange}
                    maxLength={max}
                />
            </div>
            <div className={errorAndCharsBoxClassName}>
                <ErrMessage text={typeError} styles={{...errStyles}}/>
                <p className="maxCharacter">{maxCharsLabel}</p>
            </div>
        </>
    );
};
