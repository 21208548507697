import {CREATE_PROCESS_PAYROLL, GET_ACTIVE_PAYROLL, GET_PROCESSED_PAYROLL} from "./staffPayroll.types";

/** Active Payroll */

export const getActivePayroll = (params) => {
    return {
        type: GET_ACTIVE_PAYROLL,
        payload: { params },
    };
};

export const createProcessPayroll = (body, params) => {
    return {
        type: CREATE_PROCESS_PAYROLL,
        payload: { body, params },
    };
};

/** End */

/** Processed Payroll */

export const getProcessedPayroll = (params) => {
    return {
        type: GET_PROCESSED_PAYROLL,
        payload: { params },
    };
};

/** End */
