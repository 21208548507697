import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionList, RolePermission, SaveParams } from 'utils';
import { AddButton, CustomDateSelector, DownloadCsv, SelectTypeAutocomplete } from 'components';
import { adminActions, clientActions, fundingSourceActions } from 'store';

export const ClaimFilters = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const clientList = useSelector((state) => state.client.clientList);
    const adminsList = useSelector((state) => state.admins.adminsList);

    useEffect(() => {
        dispatch(clientActions.getClients());
        dispatch(fundingSourceActions.getFundingSource());
        dispatch(adminActions.getAdmins());
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
        }
        SaveParams(history, { ...info });
    };

    return (
        <div className="table-invoice-filter-section">
            <div className="billing-invoices-filter-box">
                {type === 'submitted' ?
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.staff ? '' : 'All'}
                        name={'staff'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.staff}
                        list={adminsList?.staffs ?
                            [{ id: 'All', firstName: 'All' }, ...adminsList?.staffs]
                            :
                            [{ id: 'All', firstName: 'All' }]
                        }
                        renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                        uiType={'tableFilter'}
                        outLabel={'Staff'}
                        noError={true}
                    />
                    :
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.payer ? '' : 'All'}
                        name={'payer'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.payer}
                        list={fundingSourceList?.funders ?
                            [{ id: 'All', name: 'All' }, ...fundingSourceList?.funders]
                            :
                            [{ id: 'All', name: 'All' }]
                        }
                        renderValue={(i) => i?.name}
                        uiType={'tableFilter'}
                        outLabel={'Funding Source'}
                        noError={true}
                    />
                }

                {type !== 'submitted' &&
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.client ? '' : 'All'}
                        name={'client'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.client}
                        list={clientList?.clients ?
                            [{ id: 'All', firstName: 'All' }, ...clientList?.clients]
                            :
                            [{ id: 'All', firstName: 'All' }]
                        }
                        renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                        uiType={'tableFilter'}
                        outLabel={'Client'}
                        noError={true}
                    />
                }
                <CustomDateSelector outLabel={'DOS'} />
            </div>

            <div className="billing-invoices-filter-box">
                <DownloadCsv type={type === 'submitted' ? 'billedClaims' : 'pendingClaims'} fromModal={true} />
                {RolePermission([PermissionList.CLAIMS_CREATE?.code]) && type === 'pending' &&
                    <div>
                       <AddButton
                            text={'Generate Claim'}
                            handleClick={() => history.push('/generateClaim')}
                        />
                    </div>
                }
            </div>
        </div>
    );
};