import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { httpRequestsOnSuccessActions, noteActions } from 'store';
import { AddNotes, CustomDelete, FullTable } from '..';
import { noteHead, noteBody, ACTION_TYPE } from './constants';
import { NoteInfo } from '.';
import { FindSuccessItem, Images, useModal } from 'utils';

const DELETE_ACTION_TYPE = 'DELETE_GLOBAL_NOTE';
export const Notes = ({ notes, onModel, count = 1, permissions, id }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const deleteSuccess = FindSuccessItem(DELETE_ACTION_TYPE);
    const { open } = useModal();

    useEffect(() => {
        getNotes();
    }, [info]);

    const getNotes = (loading) => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        delete pushInfo.activeTab;
        pushInfo.skip = info?.skip ? info?.skip : 0;
        pushInfo.limit = 10;
        const load =  loading === 'noLoad' ? 'noLoad' : 'load';
        dispatch(noteActions.getGlobalNotes(id, onModel, { ...pushInfo }, load));
    };

    useEffect(() => {
        if (deleteSuccess) {
            getNotes('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE));
        }
    }, [deleteSuccess]);

    const handleClick = (id, item) => {
        open(<NoteInfo
                note={item}
                handleAction={(action) => handleClickButton(item, action)}
                permissions={permissions}
            />,
        );
    };

    const handleClickButton = (item, name) => {
        if (name === 'deleteNote') {
            open(<CustomDelete
                text="Are you sure you want to delete this note? This action cannot be undone."
                info="Delete Note"
                handleDel={() => dispatch(noteActions.deleteGlobalNote(item?.id, item?.onModel))}
                afterSuccess={() => getNotes('noLoad')}
                handleClose={() => close()}
                actionType={DELETE_ACTION_TYPE}
                body={
                    <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                        <img style={{ marginTop: '3px' }} src={Images.editBlack} alt="icon" />
                        <p style={{ fontWeight: 400 }}>{item?.subject}</p>
                    </div>
                }
            />);
        } else {
            open(<AddNotes
                id={item?.resource}
                model={item?.onModel}
                info={item}
                afterSuccess={() => getNotes('noLoad')}
            />);
        }
    };

    return (
        <FullTable
            margin={'0'}
            head={noteHead(permissions)}
            body={noteBody(permissions)}
            loadingType={ACTION_TYPE}
            list={notes}
            listCount={count}
            handleClick={handleClick}
            handleClickButton={handleClickButton}
            noText={'notes'}
        />
    );
};
