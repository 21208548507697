import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { CreateChancel, SelectAutocompleteMultiple, NumericFormatCustom, Input, } from 'components';
import { FindErrorItem, FindLoad, FindSuccess, useModal } from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { createClientStyle } from 'fragments/client';
import { staffModalsStyle } from './styles';
import moment from 'moment';

const ACTION_TYPE = 'CREATE_PAY_CODE';

export const PaycodeModal = ({ info, payCodes }) => {
    const { staff } = useSelector((state) => ({
        staff: state.admins.adminInfoById,
    }));
    const classes = createClientStyle();
    const classes_v2 = staffModalsStyle();
    const dispatch = useDispatch();
    const success = FindSuccess('CREATE_PAY_CODE');
    const loader = FindLoad('CREATE_PAY_CODE');
    const backError = FindErrorItem('CREATE_PAY_CODE');
    const globalPayCodes = useSelector((state) => state.payroll.payCodes);
    const activePaycodes = payCodes?.filter((paycode) => !paycode.terminationDate);
    const { close } = useModal();

    const {
        handleSubmit,
        control,
        clearErrors,
        watch,
    } = useForm({
        defaultValues: {
            payCodeTypeId: '',
            rate: '',
            startDate: '',
        },
    });

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CREATE_PAY_CODE'));
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);


    const currentPayCodes = globalPayCodes.filter((gPayCode) => (
        activePaycodes?.filter((aPayCode) => gPayCode?.id === aPayCode?.payCodeTypeId?._id).length === 0
    ));


    function handleCreate(data) {
        const startDateIsValid = new Date(data.startDate).getTime() < new Date(new Date()).getTime();
        const sendData = { ...data };
        sendData.staffId = staff?.id;

        if (startDateIsValid) {
            dispatch(adminActions.createPayCode(sendData, staff?.id));
        }
    }


    return (
        <form
            onSubmit={handleSubmit(handleCreate)}
        >
            <h1 className='modal-header-title'>
                Add New Paycode
            </h1>

            <div className='modal-body-wrapper'>
                <div className={classes.clientModalBox}>
                    <SelectAutocompleteMultiple
                        name='payCodeTypeId'
                        label={'Paycode name*'}
                        control={control}
                        options={currentPayCodes}
                        rules={{
                            required: 'Paycode name is required.',
                        }}
                        renderValue={(option) => option?.name}
                    />
                    <div className={classes_v2.codeAndTypeBoxStyle}
                         style={{ height: 'fit-content', padding: '12px 16px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <div className={classes_v2.paycodeBox}>
                                <p className={classes_v2.paycodeBoxTitle}>Code:</p>
                                <p className={classes_v2.paycodeBoxText}>
                                    {watch('payCodeTypeId')?.code ? watch('payCodeTypeId').code : ' N/A'}
                                </p>
                            </div>

                            <div
                                className={classes_v2.paycodeBox}
                                style={{ marginBottom: 0 }}
                            >
                                <p className={classes_v2.paycodeBoxTitle}>Type:</p>
                                <p className={classes_v2.paycodeBoxText}>
                                    {watch('payCodeTypeId')?.type ? watch('payCodeTypeId').type : 'N/A'}
                                </p>
                            </div>
                        </div>
                    </div>

                    <NumericFormatCustom
                        name={'rate'}
                        label={'Rate'}
                        prefix={'$'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        control={control}
                        rules={{
                            required: 'Rate is required. Please enter the rate.',
                        }}
                    />

                    <Input
                        name='startDate'
                        label={'Start Date*'}
                        type='date'
                        max={moment.utc().format('YYYY-MM-DD')}
                        variant='outlined'
                        control={control}
                        rules={{
                            required: 'Start date is required.',
                        }}
                    />
                </div>
            </div>
            <div className={classes.clientModalBlock}>
                <CreateChancel
                    type='submit'
                    loader={!!loader.length}
                    create={'Add'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth='224px'
                />
            </div>
        </form>
    );
};
