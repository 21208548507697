import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentMenus = () => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };

  return (
    <div className='control-panel-content-menus'>
      <div className="controlPanelContentMenus-menus" id="createMenu">

        <h2 className="title">
          Menus
        </h2>

        <p className="description">
          Welcome to MenuMango! Let's get started on creating your menu
        </p>

        <p className="description">
          Craft and organize your restaurant's orderings! You can add menus (Main Menu, Happy Hour Menu, and more Special Menus) and activate/deactivate them anytime.
        </p>

        <h3 className="subtitle">
          Create Your Menu
        </h3>

        <div>
          <p className="description">
            It's time to create your menu!
          </p>

          <ul className="lists">
            <li className="description">
              Head to the
              <span style={{ fontWeight: "400" }}>
               Menus
                </span>
              section and click on the
              <span style={{ fontWeight: "400" }}>
                  Add Menu
                </span>
              button.
            </li>

            <li className="description">
              Give your menu a name and description to make it enticing for your customers.
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

