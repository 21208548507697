/** Types */

// Log In
export const LOG_IN = 'LOG_IN';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';

// Log out
export const LOG_OUT = 'LOG_OUT';

// Get Recovery Link
export const GET_RECOVERY_LINK = 'GET_RECOVERY_LINK'
export const GET_RECOVERY_LINK_TRY_AGAIN = 'GET_RECOVERY_LINK_TRY_AGAIN'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'

//Change password
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'

//Access service
export const GET_ACCESS = 'GET_ACCESS'
export const GET_ACCESS_SUCCESS = 'GET_ACCESS_SUCCESS'
export const ASSIGN_ACCESS = 'ASSIGN_ACCESS'
export const REMOVE_ACCESS = 'REMOVE_ACCESS'







