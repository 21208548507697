import React from "react";
// import { Home } from 'fragments'

export const HomePage = () => {
  return (
    <React.Fragment>
      {/*<Home/>*/}
    </React.Fragment>
  );
};
