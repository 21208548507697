import React, { useContext, useState } from 'react';
import { claimDetailsStyle } from './styles';
import { FullTable, MinLoader, ModalContentWrapper, NoItemText, SimpleModal } from 'components';
import {
    Colors,
    DrawerContext,
    FindLoad,
    hooksForTable,
    Images,
    PermissionList,
    RolePermission, useModal,
    useWidth,
} from 'utils';
import { CloseClaimInputs } from './core';
import { claimDetailBody, claimDetailHead, getClaimDetails } from './constants';
import { ReceivableBillTable } from './core/common/core';
import { claimDetailsCoreStyle } from './core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions } from '../../../../store';
import { submitClaimById } from '../../../../store/billing/claim/claim.action';
import { ACTION_TYPE, generateInvoiceBody } from '../../invoices/generateInvoice/constants';

export const ClaimDetailsFragment = ({ claimDetails }) => {
    const classes = claimDetailsStyle();
    const { open: drawerOpen } = useContext(DrawerContext);
    const [open, setOpen] = useState(false);
    const [openTab, setOpenTab] = useState('');
    const { id, displayId, bills, status, details } = claimDetails || {};
    const width = useWidth();
    const { getTextDependsOnWidth } = hooksForTable;
    const modalClass = claimDetailsCoreStyle();

    const filteredDetails = getClaimDetails(claimDetails);
    const claimIsClosed = status === 'CLOSED';
    const closedClaimDesc = `This claim has been deleted for ${details}`;
    const closeButnStyle = `${classes.closeButnStyle} ${claimIsClosed ? 'closed' : ''}`;
    const closeButnText = claimIsClosed ? 'Closed' : 'Close Claim';
    const dispatch = useDispatch();
    const loader = FindLoad('SUBMIT_CLAIM_BY_ID');

    function getDetailDisplay(detail) {
        if (detail && detail !== 'Undefined Undefined') {
            return detail
            // return getTextDependsOnWidth(width, 1480, detail, 14);
        } else {
            return 'Not Set';
        }
    }

    const handleOpenModal = (item) => {
        setOpenTab(item);
    };

    const handleSubmitClaim = () => {
        dispatch(claimActions.submitClaimById(claimDetails?.id));
    };

    return (
        <>
            <div className={classes.claimDetailsContainerStyle}>
                <div className={classes.claimDetailsStyle}>
                    <div className={classes.claimDetailsTitleBoxStyle}>
                        <h2 className={classes.claimDetailsTitleStyle}>Claim Details</h2>
                    </div>
                    <div className="flex-align-center">
                        {RolePermission([PermissionList.CLAIMS_CLOSE?.code]) && claimDetails?.status === 'PENDING' &&
                            <button
                                className={classes.submitClaimBtn}
                                type="button"
                                onClick={handleSubmitClaim}
                            >
                                {loader?.length ?
                                    <MinLoader margin={'0'} color={'white'} position={'relative'} />
                                    :
                                    'Submit Claim'
                                }
                            </button>
                        }
                        {RolePermission([PermissionList.CLAIMS_CLOSE?.code]) &&
                            <button
                                className={closeButnStyle}
                                type="button"
                                onClick={() => setOpen(true)}
                                disabled={claimIsClosed}
                            >
                                {closeButnText}
                            </button>
                        }
                    </div>
                </div>
                <div className={classes.claimDetailsFirstPartStyle}>
                    <div className={classes.claimOutlineStyle}>
                        <span className={classes.claimIdTextBoxStyle}>ID: {displayId ? displayId : id}</span>
                    </div>
                    {!!filteredDetails.length && (
                        <ol className={classes.claimDetailsListStyle}>
                            {filteredDetails.map((item, index) => (
                                <li key={index} className={drawerOpen ? 'narrow' : ''}>
                           <span style={{ display: 'flex', alignItems: 'center' }}>{item.detailText} <em>
                               {getDetailDisplay(item.detail)} </em>
                           </span>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>

                {claimIsClosed && (
                    <div className={classes.claimInfoBoxStyle}>
                        <img src={Images.claimInfo} alt="" />
                        <p className={classes.claimInfoStyle}>{closedClaimDesc}</p>
                    </div>
                )}


                <div className={classes.claimDetailsSecondPartStyle}>
                    <div className={classes.claimDetailsTitleBoxStyle}>
                        <h2 className={classes.claimDetailsTitleStyle} style={{marginBottom: -16}}>Billing entries</h2>
                    </div>
                    <FullTable
                        head={claimDetailHead}
                        body={claimDetailBody}
                        list={claimDetails?.bills?.length ? claimDetails?.bills : []}
                        handleClick={(e) => handleOpenModal(claimDetails?.bills?.find((i) => i?._id === e))}
                        noText={'receivables'}
                        generateTable={true}
                        height={'auto'}
                    />
                </div>
            </div>


            <SimpleModal
                openDefault={!!openTab}
                handleOpenClose={() => setOpenTab('')}
                content={
                    <div className={modalClass.billModalWrapper}>
                        <ReceivableBillTable billById={openTab} />
                    </div>
                }
                secondary={true}
            />

            <SimpleModal
                openDefault={open}
                handleOpenClose={() => setOpen((prevState) => !prevState)}
                content={
                    <ModalContentWrapper
                        textAlign={'start'}
                        wrapperClassName={classes.closeClaimWrapperStyle}
                        onClose={() => setOpen(false)}
                        titleContent={'Close this claim?'}
                        subtitleContent={
                            'Please indicate below the reason for closing the claim.'
                        }
                    >
                        <CloseClaimInputs closeModal={() => setOpen(false)} claimId={id} />
                    </ModalContentWrapper>
                }
                secondary={true}
            />
        </>
    );
};
