export const DetailItem = ({ icon, title, value }) => {
    return (
        <div className='detail-item-wrapper'>
            {icon &&
                <img src={icon} alt='icon' className='detail-icon' />
            }
                <p className='detail-title'>{title ? `${title}:` : ''} </p>

            <p className='detail-value'>{value}</p>
        </div>
    );
};