import axios from "axios";

export const authService = {
   /** Active Payroll */
   getActivePayrollService: (params) =>  axios.get("/payroll/timesheet/active", { auth: true, params:{ ...params} }),

   createProcessPayrollService: (body) =>  axios.post("/payroll/timesheet/process", body,{ auth: true}),
   /** End */

   /** Processed Payroll */

   getProcessedPayrollService: (params) =>  axios.get("/payroll/timesheet/processed",{ auth: true, params:{ ...params} }),

   /** End */


};
