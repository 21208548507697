import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FullTable} from "components";
import {FindSuccess, useModal} from "utils";
import {httpRequestsOnSuccessActions, staffPayrollActions} from "store";
import {activePayrollBody, activePayrollHead, ACTION_TYPE} from "./constants";
import {Filters} from "./fragments/filters";
import {StaffInformation} from "./fragments";

const CREATE_ACTION_TYPE = 'CREATE_PROCESS_PAYROLL'

export const ActivePayroll = ({}) => {
    const {open} = useModal()
    const dispatch = useDispatch()
    const history = useHistory();
    const info = history?.location?.state;
    const activePayrolls = useSelector((state) => state?.staffPayroll?.activePayroll);
    const [selectedList, setSelectedList] = useState([])
    const success = FindSuccess(CREATE_ACTION_TYPE)

    const renderParams = () => {
        const filteredInfo = {
            ...info
        }
        filteredInfo.skip = info?.skip ? info?.skip : 0
        filteredInfo.limit = 50
        delete filteredInfo.page
        delete filteredInfo.activeTab

        if(info?.staffs?.length){
            filteredInfo.staffs = info?.staffs?.map((i) => i.id)
        }else {
            delete filteredInfo.staffs
        }

        if (info?.firstDate) {
            filteredInfo.startDate = info?.firstDate;
            delete filteredInfo.firstDate;
        }else{
            delete filteredInfo.startDate;
        }
        if (info?.lastDate) {
            filteredInfo.endDate = info?.lastDate;
            delete filteredInfo.lastDate;
        }else{
            delete filteredInfo.endDate;
        }

        return filteredInfo
    }

    useEffect(() => {
        if(success?.length){
            setSelectedList([])
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION_TYPE))
        }
    }, [success])
    useEffect(() => {
        dispatch(staffPayrollActions.getActivePayroll({...renderParams()}));
    }, [info]);

    const handleClick = (id, item) => {
        open( <StaffInformation item={item}/>)
    }

    const handleSelectItem = (id) => {
        if(selectedList?.length){
            const currentRow = selectedList.find((i) => i === id)
            if(currentRow){
                const newArr = selectedList?.filter((i) => i !== id)
                setSelectedList(newArr)
            }else{
                setSelectedList([...selectedList, id])
            }
        }else{
            setSelectedList([id])
        }
    }

    const handleSelectAll = (ev) => {
        if(ev.target.checked) {
            const newArr = []
            activePayrolls?.payrolls?.map((i) => newArr.push(i.id))
            setSelectedList(newArr)
        }else{
            setSelectedList([])
        }
    }

    const handleProcess = () => {
        if(selectedList?.length) {
            dispatch(staffPayrollActions.createProcessPayroll({"payrollIds": selectedList}, renderParams()))
        }
    }

    return (
        <div>
            <Filters handleProcess={handleProcess} selectedList={selectedList} type={'active'} />
            <FullTable
                head={activePayrollHead(handleSelectAll, selectedList, activePayrolls?.payrolls)}
                body={activePayrollBody(history, handleSelectItem, selectedList)}
                loadingType={ACTION_TYPE}
                list={activePayrolls?.payrolls}
                listCount={activePayrolls?.count}
                handleClick={handleClick}
                noText={"active payroll"}
                activeRowId={""}
            />
        </div>
    )
}