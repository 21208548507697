import React from 'react';
import { Images } from 'utils';
import { scheduleModalsStyle } from '../styles';
import { getDynamicContent, getDynamicTitle } from '../constants';
import { modalsStyle } from '../../../../../components/modal/styles';
import { CustomizedSwitch, ToolInfo } from 'components';

export const ModalHeader = ({
                                modalDate,
                                multiple,
                                step,
                                setStep,
                                type,
                                createMultiple,
                                setMultipleAppts,
                            }) => {
    const title = getDynamicTitle('TITLE', modalDate, type);
    const classes = scheduleModalsStyle();
    const global = modalsStyle();

    return (
        <>
            <div style={{ marginBottom: '40px' }}>
                {multiple || modalDate?.fromTemplate ?
                    <div>
                        <div style={{textAlign:'center'}}>
                            <p className={global.availableScheduleTitle}>
                                {title}  {multiple ? '(Recurring Template)' : ''}
                                </p>
                        </div>
                        <div className={classes.stepBox}>
                            <div className={classes.stepperBtns}>
                                <button onClick={() => setStep('first')}>
                                    <img src={Images.apptGeneralInfo} alt="icon" />
                                    <p>Appointment Details</p>
                                </button>
                                <div className={classes.lineBox} />
                                <button
                                    // onClick={() => setStep('second')}
                                >
                                    <img src={step === 'second' ? Images.repetApptBlue : Images.repetAppt} alt="icon" />
                                    <p style={{ color: step === 'second' ? '#4B5C68' : '#A3B2BD' }}>Repeat Pattern</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <p className={global.availableScheduleTitle}>{title}</p>
                    </>
                }
            </div>

            {modalDate?.fromTemplate  &&
                <div className="space-between">
                    <div className={classes.multipleServiceNote}>
                        <ToolInfo text={''} />
                        <p>
                            Editing this appointment will <b>disconnect</b> it from the <b>template series.</b>
                            If you want to make changes to the entire series, please use the <b>'Edit
                            Template'</b> action.
                        </p>
                    </div>
                    <div className={classes.signatureStyle}
                         style={{
                             justifyContent: 'flex-start',
                             marginBottom: 0,
                         }}>
                        <p style={{ width: '115px' }}>Edit Template </p>
                        <CustomizedSwitch
                            checked={createMultiple}
                            handleClick={setMultipleAppts}
                        />
                    </div>
                </div>
            }

            {!modalDate &&
                <div className="space-between">
                    {createMultiple &&
                        <div className={classes.multipleServiceNote}>
                            <ToolInfo text={''} />
                            <p>The template will be created with <b>default settings</b> for the
                                selected <b>recurrence mode.</b>
                                Users can later modify the template details, including <b>individual
                                    appointments.</b></p>
                        </div>
                    }
                    <div className={classes.signatureStyle}
                         style={{
                             justifyContent: 'flex-start',
                             marginBottom: 0,
                         }}>
                        <p style={{ width: '115px' }}>Create Multiple</p>
                        <CustomizedSwitch
                            checked={createMultiple}
                            handleClick={setMultipleAppts}
                        />
                    </div>
                </div>
            }
        </>
    );
};