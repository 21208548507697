import {Images, SaveParams} from 'utils';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DateRangePicker} from 'react-date-range';
import {useHistory} from 'react-router-dom';
import {SelectTypeAutocomplete} from 'components';
import {scheduleStatuses, scheduleTypes} from '../constants';
import {navBarStyles} from "../../../menuBar/core/style";

export const CalendarFilters = ({}) => {
    const {clientList, adminsList, company} = useSelector((state) => ({
        clientList: state.client.clientList,
        adminsList: state.admins.adminsList,
        company: state.system.company
    }));
    const classes = navBarStyles();
    let history = useHistory();
    const info = history?.location?.state;
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (info?.firstDate && info?.lastDate) {
            setState([{
                startDate: new Date(info?.firstDate),
                endDate: new Date(info?.lastDate),
                key: 'selection',
            }]);
        }
    }, [info]);
    const handleDateChange = (item) => {
        setState([item.selection]);

        const params = {
            ...info,
        };
        params.firstDate = item?.selection?.startDate;
        params.lastDate = item?.selection?.endDate;
        SaveParams(history, params);
    };

    const handleChange = (e) => {
        const newFilters = {
            ...info,
        };
        if (e.target.value === 'All') {
            delete newFilters[e.target.name];
        } else {
            newFilters[e.target.name] = e.target.value;
        }
        SaveParams(history, {...newFilters});
    };

    return (
        <div className="calendar-filters">
            <div className="calendar-filters-wrapper">
                <div className="company-information">
                    {company?.name &&
                        <div className='company-information-box'>
                        <div className={classes.companyInfoWrapper}>
                            <div className={company?.logo?.url ? classes.companyLogo : classes.companyBox}>
                                <img src={company?.logo?.url ? company?.logo?.url : Images.office} alt="icon"/>
                            </div>
                            <p>{company?.name ? company?.name : ''}</p>
                        </div>
                        </div>
                    }
                </div>
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={state}
                    onChange={handleDateChange}
                    showMonthAndYearPickers={false}
                    showSelectionPreview={false}
                    weekdayDisplayFormat={'EEEEE'}
                />
                <div className="filters-wrapper-row">
                    <div>
                        <p className="select-label">
                            Staff Member
                        </p>
                        <SelectTypeAutocomplete
                            placeholder={info?.staffId ? '' : 'All'}
                            name={'staff'}
                            handleSelect={handleChange}
                            defaultValue={info?.staff}
                            list={adminsList?.staffs ?
                                [{id: 'All', firstName: 'All', lastName: ''}, ...adminsList?.staffs]
                                :
                                [{id: 'All', firstName: 'All', lastName: ''}]
                            }
                            renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                            uiType={'tableFilter'}
                        />
                    </div>
                    <div>
                        <p className="select-label">
                            Client
                        </p>
                        <SelectTypeAutocomplete
                            placeholder={info?.client ? '' : 'All'}
                            name={'client'}
                            handleSelect={handleChange}
                            defaultValue={info?.client}
                            list={clientList?.clients ?
                                [{id: 'All', firstName: 'All', lastName: ''}, ...clientList?.clients]
                                :
                                [{id: 'All', firstName: 'All', lastName: ''}]
                            }
                            renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                            uiType={'tableFilter'}
                        />
                    </div>
                    <div>
                        <p className="select-label">
                            Event Type
                        </p>
                        <SelectTypeAutocomplete
                            placeholder={info?.type ? '' : 'All'}
                            name={'type'}
                            handleSelect={handleChange}
                            defaultValue={info?.type}
                            list={scheduleTypes}
                            renderValue={(i) => i?.name}
                            uiType={'tableFilter'}
                        />
                    </div>
                    <div>
                        <p className="select-label">
                            Event Status
                        </p>
                        <SelectTypeAutocomplete
                            placeholder={info?.status ? '' : 'All'}
                            name={'status'}
                            handleSelect={handleChange}
                            defaultValue={info?.status}
                            list={scheduleStatuses}
                            renderValue={(i) => i?.name}
                            uiType={'tableFilter'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};