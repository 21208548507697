
/** Active Payroll */

export const GET_ACTIVE_PAYROLL = "GET_ACTIVE_PAYROLL"
export const GET_ACTIVE_PAYROLL_SUCCESS = "GET_ACTIVE_PAYROLL_SUCCESS"

export const CREATE_PROCESS_PAYROLL = "CREATE_PROCESS_PAYROLL"

/** Processed Payroll */

export const GET_PROCESSED_PAYROLL = "GET_PROCESSED_PAYROLL"
export const GET_PROCESSED_PAYROLL_SUCCESS = "GET_PROCESSED_PAYROLL_SUCCESS"

