import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel, ErrMessage, TimeInput, ValidationInput } from 'components';
import { modalsStyle } from 'components/modal/styles';
import { scheduleModalsStyle } from './styles';
import { appointmentActions } from 'store';
import { ErrorText, FindLoad, useModal } from 'utils';
import { DailyPattern, MonthlyPattern, WeeklyPattern } from './modePatterns/modePatterns';
import { Mode, RecurEventDates } from './common';
import { ConfMultiple } from './confMultiple';
import moment from 'moment/moment';
import { renderTimes } from './constants';
import { EditMultiple } from './editMultiple';
import { CancellingAppts } from './serviceAppt/cancellingAppts';
import { TimeIsGreat } from '../../../../utils/hooks';

const initialInputs = {
    mode: 'DAILY',
    startDate: '',
    endDate: '',
    repeatCountWeek: '1',
    repeatDayMonth: '',
    repeatMonth: '1',
    repeatConsecutive: '',
    repeatCount: '1',
    repeatCountCheckbox: '',
};

export const Recur = ({ date, multiple, handleClose, setStep, dateTime, staffTb, modalDate }) => {
    const global = modalsStyle();
    const classes = scheduleModalsStyle();
    const ACTION_TYPE = modalDate ? 'EDIT_APPOINTMENT' : 'CREATE_APPOINTMENT';
    const dispatch = useDispatch();
    const loader = FindLoad('APPOINTMENT_REPEAT');
    const [inputs, setInputs] = useState(initialInputs);
    const [occurrence, setOccurrence] = useState(0);
    const [state, setState] = React.useState([]);
    const [error, setError] = React.useState('');
    const { close, open } = useModal();
    const [times, setTimes] = useState(dateTime ? { ...dateTime } : {});

    useEffect(() => {
        if (modalDate) {
            setTimes({ startTime: modalDate?.startTime?.slice(11, 16), endTime: modalDate?.endTime?.slice(11, 16) });
        }
    }, [modalDate]);

    const closeModal = () => {
        if (multiple) {
            setStep();
        } else {
            handleClose && handleClose();
            close();
        }
    };

    function handleChange(e) {
        e.target.name === 'mode' && setOccurrence(0);
        e.target.name === 'repeatCountCheckbox' && setOccurrence(0),
            delete inputs['repeatConsecutive'];
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (error === ErrorText.globalError || error === ErrorText.dateError || error === e.target.name) setError('');
    }

    useEffect(() => {
        if (inputs?.mode === 'DAILY' && inputs.startDate && inputs.endDate && inputs?.repeatCount) {
            handleDaily();
        }
        if (inputs?.mode === 'WEEKLY' && inputs.startDate && inputs.endDate && inputs?.repeatCountWeek) {
            handleWeekly();
        }
        if (inputs?.mode === 'MONTHLY' && inputs.startDate && inputs.endDate && inputs?.repeatDayMonth && inputs?.repeatMonth) {
            handleMonthly(+inputs?.repeatDayMonth, +inputs?.repeatMonth);
        }
    }, [inputs, state]);

    const handleDaily = (e) => {
        const startDate = new Date(inputs.startDate);
        const endDate = new Date(new Date(inputs.endDate).setHours(23, 59, 59));
        let count = 0;
        let dates = [],
            x;

        const enteredDateCount = e ? +e.target.value : +inputs?.repeatCount;

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + enteredDateCount)) {
            if (enteredDateCount !== 0) count++;
            if (enteredDateCount === 0) break;
            x = new Date(d.getTime());
            dates.push(x);
        }
        setOccurrence(count);
    };

    function handleChangeDay(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
        // setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value, }));
        handleDaily(e);
    }

    function handleChangeConsecutive(e) {
        e.target.name === 'repeatConsecutive' && setOccurrence(0),
        delete inputs['repeatCount'] && delete inputs['repeatCountCheckbox'];
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

        const startDate = new Date(inputs.startDate);
        const endDate = new Date(new Date(inputs.endDate).setHours(23, 59, 59));
        let count = 0;
        const curDate = new Date(startDate.getTime());
        let dates = [],
            x;
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                x = new Date(curDate.getTime());
                dates.push(x);
                count++;
            }
            curDate.setDate(curDate.getDate() + 1);
        }
        setOccurrence(count);
    }

    function handleChangeWeek(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
        handleWeekly(e);
    }

    const handleWeekly = (e) => {
        const weekdays = [...state];
        const repeatCount = e ? +e?.target?.value : inputs.repeatCountWeek ? inputs.repeatCountWeek : 1;
        let occurrences = 0;
        const momentStart = moment.utc(inputs.startDate).startOf('day');
        const momentEnd = moment.utc(inputs.endDate).endOf('day');
        const current = momentStart.clone();
        while (current.isSameOrBefore(momentEnd)) {
            const dayOfWeek = current.day();
            if (
                weekdays.includes(dayOfWeek) &&
                (repeatCount === 1 || current.diff(momentStart, 'weeks') % repeatCount === 0)
            ) {
                ++occurrences;
            }
            current.add(1, 'day');
        }
        setOccurrence(occurrences);
    };

    function handleChangeWeeks(e) {
        const newState = [...state];
        if (e.target.checked) {
            newState.push(+e.target.value);
        } else {
            for (let i = 0; i < state.length; i++) {
                if (state[i] === +e.target.value) {
                    newState.splice(i, 1);
                }
            }
        }
        setState(newState);
    }

    function handleChangeMonth(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value === '0' ? 1 : e.target.value }));
    }

    const handleMonthly = (repeatDayMonth, repeatMonth) => {
        let count = 0;
        const start = moment.utc(inputs.startDate);
        const end = moment.utc(inputs.endDate);
        const current = moment.utc(start);

        while (current <= end) {
            current.date(repeatDayMonth);
            if (current > end) {
                break;
            }
            if (current >= start) {
                count++;
            }
            current.month(current.month() + repeatMonth);
        }
        setOccurrence(count);
    };

    const handleUpdate = () => {
        if (times?.startTime && times?.endTime && TimeIsGreat(times.startTime, times.endTime)) {
            const startTime = moment.utc(modalDate?.startDate);
            startTime.set({
                hour: times?.startTime?.slice(0, 2),
                minute: times?.startTime?.slice(3, 5),
                second: '00',
            });
            const endTime = moment.utc(modalDate?.startDate);
            endTime.set({
                hour: times?.endTime?.slice(0, 2),
                minute: times?.endTime?.slice(3, 5),
                second: '00',
            });

            const modalInfo = {
                ...modalDate,
            };
            modalInfo.startTime = startTime.format();
            modalInfo.endTime = endTime.format();

            open(<EditMultiple
                modalDate={modalInfo}
                handleSubmit={() => dispatch(appointmentActions.editAppointment({
                    ...date,
                    startTime: startTime.format(),
                    endTime: endTime.format(),
                }, modalDate?.id))}
                loadType={ACTION_TYPE}
                occurrence={occurrence}
            />);
        } else {
            setError(
                !times.startTime ? 'startTime' :
                    !times.endTime ? 'endTime' :
                        !TimeIsGreat(times.startTime, times.endTime) ? ErrorText.timeError :
                            '',
            );
        }
    };

    function handleRecur() {
        const repeat = !!inputs.repeatCount
            ? inputs.repeatCount
            : !!inputs.repeatConsecutive
                ? inputs.repeatConsecutive
                : '';

        const dateComparingIsValid = new Date(inputs.startDate).getTime() < new Date(inputs.endDate).getTime();

        const typeBool =
            inputs.mode === 'DAILY'
                ? !!inputs.startDate && !!inputs.endDate && dateComparingIsValid && !!repeat
                : inputs.mode === 'WEEKLY'
                    ? !!inputs.startDate &&
                    !!inputs.endDate &&
                    dateComparingIsValid &&
                    !!inputs.repeatCountWeek &&
                    !!state.length
                    : !!inputs.startDate &&
                    !!inputs.endDate &&
                    dateComparingIsValid &&
                    !!inputs.repeatDayMonth &&
                    !!inputs.repeatMonth;

        const week = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
            repeatCountWeek: +inputs.repeatCountWeek,
            repeatCheckWeek: [...state],
        };

        const mounthObject = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
            repeatDayMonth: +inputs.repeatDayMonth,
            repeatMonth: +inputs.repeatMonth,
        };

        !inputs.repeatDayMonth ? delete mounthObject['repeatDayMonth'] : '';
        !inputs.repeatMonth ? delete mounthObject['repeatMonth'] : '';

        const newObject =
            inputs.repeatConsecutive === 'repeatConsecutive'
                ? {
                    startDate: new Date(inputs.startDate),
                    endDate: new Date(inputs.endDate),
                    mode: inputs.mode,
                    repeatConsecutive: true,
                }
                : {
                    startDate: new Date(inputs.startDate),
                    endDate: new Date(inputs.endDate),
                    mode: inputs.mode,
                    repeatCount: +inputs.repeatCount,
                };

        const obj =
            inputs.mode === 'WEEKLY' ? week
                : inputs.mode === 'MONTHLY' ? mounthObject :
                    newObject;

        if (typeBool) {

            if (multiple) {
                const timeComparingIsValid = !!times.startTime && !!times.endTime && TimeIsGreat(times.startTime, times.endTime);
                // times.endTime > times.startTime;

                if (inputs.startDate && inputs.endDate && times.startTime && times.endTime && timeComparingIsValid) {

                    const startTime = moment.utc(obj?.startDate);
                    startTime.set({
                        hour: times?.startTime?.slice(0, 2),
                        minute: times?.startTime?.slice(3, 5),
                        second: '00',
                    });
                    const endTime = moment.utc(obj?.endDate);
                    endTime.set({
                        hour: times?.endTime?.slice(0, 2),
                        minute: times?.endTime?.slice(3, 5),
                        second: '00',
                    });


                    const createApptDate = {
                        ...date,
                        startTime: startTime.format(),
                        endTime: endTime.format(),
                        startDate: moment(obj?.startDate).format('YYYY-MM-DD'),
                        template: {
                            ...obj,
                        },
                    };

                    const checkParams = {
                        staffId: date?.staff,
                        startTime: startTime.format().slice(0, 19),
                        endTime: endTime.format().slice(0, 19),
                    };

                    open(
                        <ConfMultiple
                            recurInfo={obj}
                            handleSubmit={() => dispatch(appointmentActions.createAppointment(createApptDate))}
                            loadType={ACTION_TYPE}
                            occurrence={occurrence}
                            checkParams={checkParams}
                            staffTb={staffTb}
                        />,
                    );
                } else {
                    setError(
                        !inputs.startDate ? 'startDate' :
                            !inputs.endDate ? 'endDate' :
                                !times.startTime ? 'startTime' :
                                    !times.endTime ? 'endTime' :
                                        !timeComparingIsValid ? ErrorText.timeError :
                                            '',
                    );
                }
            } else {
                dispatch(appointmentActions.appointmentRepeat(date?.id, obj));
            }

        } else {
            setError(
                !inputs.startDate ? 'startDate' :
                       !inputs.endDate ? 'endDate' :
                           !dateComparingIsValid ? ErrorText.dateError :
                               ErrorText.globalError,
            );
        }
    }

    const handleChangeDate = (e) => {
        setTimes((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        (e.target.name === error || error === ErrorText.timeError) && setError('');
    };

    const disableRecur = () => {
        if (multiple) {
            if(staffTb){
                return inputs?.endDate > moment.utc(staffTb?.expireDate).format('YYYY-MM-DD') && !times?.startTime ? true : !times?.endTime;
            }else{
                return !times?.startTime ? true : !times?.endTime;
            }
        } else {
            return occurrence === 0;
        }
    };

    return (
        <div className={classes.recurModalWrapper} style={multiple ? { width: '100%' } : {}}>

            {!multiple &&
                <p className={global.availableScheduleTitle}>Recur Event</p>
            }

            <div className={classes.recurBody}>
                {!modalDate?.template &&
                    <>
                        <RecurEventDates  error={error} inputs={inputs} handleChange={handleChange} />


                        {staffTb && inputs?.endDate > moment.utc(staffTb?.expireDate).format('YYYY-MM-DD') &&
                            <div className={classes.confirmModalErrBox}>
                                <p style={{ margin: 0 }}>
                                    <span>The staff TB expires on</span>
                                    <span style={{ fontWeight: 600, margin: '0 4px' }}>
                                        {moment.utc(staffTb?.expireDate).format('MM/DD/YYYY')}
                                    </span>
                                    <span>Please enter an earlier date or adjust TB expiration.</span>
                                </p>
                            </div>
                        }
                    </>
                }

                {multiple &&
                    <>
                        <p className={classes.recurTitle} style={{ marginBottom: '16px' }}>Time</p>
                        <div className={classes.dateInputs} style={{ margin: '8px 0 4px 0', gap:'16px' }}>
                                <TimeInput
                                    onChange={handleChangeDate}
                                    label={'Start Time*'}
                                    name={'startTime'}
                                    typeError={
                                        error === 'startTime' ? `Start time ${ErrorText.isRequired}` :
                                            error === ErrorText.overlappingError('Appointments')
                                                ? ErrorText.overlappingError('Appointments')
                                                : ''
                                    }
                                    defaultValue={modalDate ?
                                        times?.startTime ? renderTimes(times.startTime) || times.startTime : ''
                                        : null}
                                />
                                <TimeInput
                                    onChange={handleChangeDate}
                                    label={'End Time*'}
                                    name={'endTime'}
                                    typeError={
                                        error === 'endTime' ? `End time ${ErrorText.isRequired}` :
                                            error === ErrorText.timeError ? ErrorText.timeError
                                                : ''
                                    }
                                    defaultValue={modalDate ?
                                        times?.endTime ? renderTimes(times.endTime) || times.endTime : ''
                                        : null
                                    }
                                />
                                {/*<ValidationInput*/}
                                {/*    variant={'outlined'}*/}
                                {/*    onChange={handleChangeDate}*/}
                                {/*    value={times?.startTime ? renderTimes(times.startTime) || times?.startTime : ''}*/}
                                {/*    type={'time'}*/}
                                {/*    label={'Start Time*'}*/}
                                {/*    name="startTime"*/}
                                {/*    style={classes.startTime}*/}
                                {/*    typeError={*/}
                                {/*        error === 'startTime' ? `Start time ${ErrorText.isRequired}` :*/}
                                {/*            error === ErrorText.overlappingError('Appointments')*/}
                                {/*                ? ErrorText.overlappingError('Appointments')*/}
                                {/*                : ''*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<ValidationInput*/}
                                {/*    variant={'outlined'}*/}
                                {/*    onChange={handleChangeDate}*/}
                                {/*    value={times?.endTime ? renderTimes(times.endTime) || times?.endTime : ''}*/}
                                {/*    type={'time'}*/}
                                {/*    label={'End Time*'}*/}
                                {/*    name="endTime"*/}
                                {/*    disabled={!times?.startTime}*/}
                                {/*    typeError={*/}
                                {/*        error === 'endTime' ? `End time ${ErrorText.isRequired}` :*/}
                                {/*            error === ErrorText.timeError ? ErrorText.timeError*/}
                                {/*                : ''*/}
                                {/*    }*/}
                                {/*/>*/}

                        </div>
                    </>
                }

                {!modalDate?.template &&
                    <div className={classes.dayWeekMounth}>
                        <Mode inputs={inputs} handleChange={handleChange} />
                    </div>
                }

                {!modalDate?.template &&
                    <div className={classes.dayWeekMounth}>
                        <p className={classes.recurTitle}>Patterns</p>
                        <div>
                            {inputs.mode === 'DAILY' ? (
                                <DailyPattern
                                    handleChange={handleChange}
                                    handleChangeDay={handleChangeDay}
                                    handleChangeConsecutive={handleChangeConsecutive}
                                    inputs={inputs}
                                />
                            ) : inputs.mode === 'WEEKLY' ? (
                                <WeeklyPattern
                                    inputs={inputs}
                                    handleChangeWeek={handleChangeWeek}
                                    handleChangeWeeks={handleChangeWeeks}
                                />
                            ) : inputs.mode === 'MONTHLY' ? (
                                <MonthlyPattern
                                    inputs={inputs}
                                    handleChangeMounth={handleChangeMonth}
                                    handleChangeMounthDay={handleChangeMonth}
                                />
                            ) : null}
                        </div>
                    </div>
                }

                {!modalDate?.template &&
                    <div className={classes.occurance}>
                        <p>Occurrence:</p>
                        <span>{inputs?.startDate && inputs?.endDate ? occurrence : 0}</span>
                    </div>
                }

                {error &&
                    error !== ErrorText.timeError &&
                    error !== 'startDate' &&
                    error !== 'endDate' &&
                    error !== 'startTime' &&
                    error !== 'endTime' &&
                    <ErrMessage text={error ? error : ''} />
                }

                {modalDate && modalDate?.fromTemplate &&
                    <CancellingAppts apptDate={modalDate} />
                }

                <CreateChancel
                    disabled={disableRecur()}
                    loader={!!loader.length}
                    create={
                        modalDate?.template ? 'Update' :
                            multiple ? `Add ${occurrence ? occurrence : 0} Appointments` : 'Recur'}
                    chancel={'Cancel'}
                    onCreate={modalDate ? handleUpdate : handleRecur}
                    onClose={closeModal}
                    buttonWidth="48%"
                />
            </div>
        </div>
    );
};