import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { managementFragments } from './style';
import { DeleteButton, Loader, NoItemText, TextRow, CustomDelete, PaginationFillTable, } from 'components';
import { Images, PermissionList, RolePermission, useModal, SendPageSave, FindLoad } from 'utils';
import { roleActions } from 'store';
import { AddRoleModal } from './addRoleModal';

const DELETE_ACTION = 'DELETE_ROLE';
const GET_ROLE = 'GET_ROLE';
export const Role = ({
                         key,
                         roleInfo,

                         rolesCount,
                         info,
                         afterSuccess,
                     }) => {
    const classes = managementFragments();
    const history = useHistory();
    const dispatch = useDispatch();
    const { open } = useModal();
    const [activeRole, setActiveRole] = useState('');
    const [page, setPage] = useState(1);
    const roleLoader = FindLoad(GET_ROLE);

    useEffect(() => {
        setActiveRole('');
    }, []);

    const handleDelete = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open(
            <CustomDelete
                text={`This action is not reversible.`}
                info="Are you sure you want to delete this role?"
                handleDel={() => dispatch(roleActions.deleteRole(item?.id))}
                handleClose={() => close()}
                actionType={DELETE_ACTION}
                afterSuccess={afterSuccess}
            />,
        );
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history, location);
    };

    const openRolePermission = (role) => {
        dispatch(roleActions.openRole(role));
        setActiveRole(role.title);
    };

    return (
        <>
            <div key={key} className={classes.tableStyle}>
                <div className={classes.tableHeadStyle}>
                    <p>Role</p>
                </div>
                <div className={classes.scroll}>
                    {roleLoader?.length ? (
                        <Loader circleSize={50} />
                    ) : (
                        <div style={{height:'60vh', overflow:'auto'}}>
                            {roleInfo?.length ? (
                                roleInfo.map((item, j) => (
                                    <div
                                        onClick={() => openRolePermission(item)}
                                        key={j}
                                        className={activeRole === item.title ? classes.tableBodyBottomActive : classes.tableBodyBottom}
                                    >
                                        <div className={classes.tableBodyStyle}>
                                            <div className="full-width">
                                                <img
                                                    src={
                                                        activeRole === item.title ?
                                                            Images.accessManagementOutline
                                                            :
                                                            Images.accessManagementOutlineGray
                                                    }
                                                    alt={'accessManagementUser'}
                                                />
                                                <p className={classes.roleTitle}>
                                                    <TextRow name={item?.title} />
                                                </p>
                                            </div>
                                            {RolePermission([PermissionList.ROLES_CREATE?.code]) &&
                                                <div className="flex-align-center">
                                                    <button
                                                        onClick={() => open(<AddRoleModal item={item} />)}
                                                        className={classes.editBtn}
                                                    >
                                                        <img src={Images.edit} alt="icon" />
                                                    </button>

                                                    <DeleteButton
                                                        toolTipTitle={'Remove Role'}
                                                        handleClick={(e) => handleDelete(e, item)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <NoItemText text={'No roles yet'} />
                            )}
                        </div>
                    )}

                    <div>
                        {rolesCount > 0 &&
                            <div style={{ height: '76px' }}>
                                <PaginationFillTable
                                    hideGoTo={true}
                                    listLength={roleInfo?.length}
                                    page={page}
                                    handleReturn={(number) => changePage(number)}
                                    count={rolesCount}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
