import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'components';
import { systemItemStyles } from './styles';
import { FindLoad } from 'utils';
import { systemActions } from 'store';
import { CredentialsList } from './credentials';
import { CoveredCredentials } from './credentials/coveredCredentials';

const ACTION_TYPE = 'GET_CREDENTIAL_GLOBAL';

export const Credential = ({  }) => {
    const dispatch = useDispatch();
    const classes = systemItemStyles();
    const getLoader = FindLoad(ACTION_TYPE);

    useEffect(() => {
        dispatch(systemActions.getCredentialGlobal());
        dispatch(systemActions.removeCredential());
    }, []);

    if (getLoader?.length) {
        return <Loader />;
    }

    return (
        <Fragment>
            <div className={classes.credentialsFlexAble}>
                <CredentialsList />
                <CoveredCredentials />
            </div>
        </Fragment>
    );
};
