import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ValidationInput,
    CreateChancel,
    ModalHeader,
    ErrMessage,
    MinLoader,
    NoYet, CustomSelect, SelectTypeAutocomplete,
} from 'components';
import { createClientStyle } from './styles';
import {
    Colors,
    ErrorText,
    FindErrorItem,
    FindLoad,
    FindSuccess,
    hooksForErrors,
    isNotEmpty, useModal,
} from 'utils';
import {
    clientActions,
    fundingSourceActions,
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
} from 'store';
import axios from 'axios';

const SERVICE_ACTION = 'GET_FUNDING_SOURCE_SERVICE_BY_ID';
export const AddAuthorizationService = ({ info, fundingId, authId, services }) => {
    const classes = createClientStyle();
    const dispatch = useDispatch();
    const ACTION_TYPE = !!info ? 'EDIT_CLIENT_AUTHORIZATION_SERV' : 'CREATE_CLIENT_AUTHORIZATION_SERV';
    const fSelect = useSelector((state) => state.fundingSource.fundingSourceServices);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState(info ? { ...info,
        total: info?.totalUnits,
        name: info?.service?.cptCode,
        modifiers: info?.service?._id,
    } : {});

    const [modCheck, setModCheck] = useState([]);
    const [modif, setModif] = useState([]);
    const [checkModifiersLoader, setCheckModifiersLoader] = useState(false);
    const activeModifiers = modif?.filter((modifier) => modifier);

    let modifierExists = false;


    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const authServiceDefaultErrorText = hooksForErrors.getAuthServiceDefaultErrorText(error, backError);
    const { close } = useModal();


    for (let i = 0; i < info?.chargeRates?.length; i++) {
        modifierExists = activeModifiers.includes(info?.chargeRates[i]);
    }

    useEffect(() => {
        if (info) {
            axios.post(`/authService/authorizations/${authId}/fundingService/${info?.service?._id}/checkChargeRates`, null, { auth: true })
                .then((res) => {
                    setModif(res.data);
                }).finally(() => {
                setCheckModifiersLoader(false);
            });
        }
    }, [info]);

    useEffect(() => {
        dispatch(fundingSourceActions.getFoundingSourceServiceById(fundingId, { active: true }));
    }, []);

    useEffect(() => () => {
        httpRequestsOnErrorsActions.removeError(ACTION_TYPE);
    }, []);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);


    function handleChange(e) {

        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        if (e.target.name === 'modifiers' && e.target.value) {
            if (e.target.value === '') return;
            setCheckModifiersLoader(true);
            setModCheck([]);
            const _fundingService = fSelect.find((item) => item.id === e.target.value);

            axios.post(`/authService/authorizations/${authId}/fundingService/${_fundingService?.id}/checkChargeRates`, null, { auth: true })
                .then((res) => {
                    setModif(res.data);
                }).finally(() => {
                setCheckModifiersLoader(false);
            });
        }else{
            setModCheck([]);
            setModif([]);
        }


        if (error === e.target.name || !!backError) {
            setError('');
        }
        if (!!backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    }

    function handleChangeTotal(e) {
        if (e.target.value >= 0 && e.target.value.indexOf('.') === -1 && e.target.value <= 10000) {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
            if (error === e.target.name || !!backError) {
                setError('');
            }
            if (!!backError) {
                dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
            }
        }
    }

    function handleCreate() {
        let modifiersPost = [];

        if (info?.chargeRates?.length) {
            info?.chargeRates?.forEach((i) => modifiersPost.push(i?.id));
        }

        modCheck.forEach((item) => {
            return activeModifiers.forEach((item2, index2) => {
                if (item === index2) {
                    modifiersPost.push(item2?.id);
                }
            });
        });
        const modifiersAreValid = !!modifiersPost?.length;
        const authServiceDataIsValid = !!info ? isNotEmpty(inputs.totalUnits) : isNotEmpty(inputs.totalUnits) && modifiersAreValid;

        if (authServiceDataIsValid) {
            const createAuthServiceData = {
                totalUnits: +inputs.totalUnits,
                // default: defaultIsChecked,
                chargeRateIds: modifiersPost,
            };
            if (!!info) {
                const editAuthServiceData = {
                    ...createAuthServiceData,
                    authorizationId: authId,
                    // fundingServiceId: inputs.modifiers,
                    serviceId: inputs?.service?._id,
                };
                dispatch(clientActions.editClientsAuthorizationsServ(editAuthServiceData, info.id, authId));
            } else {
                dispatch(clientActions.createClientsAuthorizationsServ(createAuthServiceData, authId, inputs.modifiers));
            }
        } else {
            const authServiceDataErrorText = !!info
                ? !isNotEmpty(inputs.totalUnits) ? 'totalUnits' : '' :
                !inputs.modifiers ? 'modifiers' :
                    !modifiersAreValid ? 'modifiersPost' :
                        !isNotEmpty(inputs.totalUnits) ? 'totalUnits' : '';
            setError(authServiceDataErrorText);
        }
    }

    function onModifier(index) {
        error === 'modifiersPost' && setError('');
        let arr = new Set([...modCheck]);
        if (arr.has(index)) {
            arr.delete(index);
        } else {
            arr.add(index);
        }
        let newArr = [];
        arr.forEach((item) => {
            newArr.push(item);
        });
        setModCheck(newArr);
    }

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">{info ? 'Edit Authorization Service' : 'Add Authorized Service'}</p>
            <div className="modal-body-wrapper">
                <SelectTypeAutocomplete
                    disabled={!!info}
                    loadType={SERVICE_ACTION}
                    title={'Service Code*'}
                    name={'modifiers'}
                    handleSelect={handleChange}
                    defaultValue={inputs.modifiers}
                    list={fSelect?.length ? fSelect : []}
                    error={error}
                    typeError={error === 'modifiers' ? `Service Code ${ErrorText.isRequired}` : ''}
                    renderValue={(i) => `${i?.cptCode}`}
                />

                <div
                    className={`${classes.displayCodeBlock2} ${error === 'modifiersPost' || !!authServiceDefaultErrorText ? 'error' : ''}`}>
                    <p className={classes.displayCodeBlockText}>Charge Rates</p>
                    <div
                        className={`${classes.availableModfiers} ${!inputs.modifiers || checkModifiersLoader === true ? 'notApplicable' : ''}`}>
                        {checkModifiersLoader === true ? (
                            <div className={classes.loaderBoxStyle}>
                                <MinLoader margin={'0'} color={Colors.ThemeBlue} position={'relative'} />
                            </div>
                        ) : (
                            <div className={classes.serviceModifiersContainerStyle}>
                                {!inputs.modifiers ? (
                                        <div className={`${classes.notApplicableStyle} ${!!info ? 'hidden' : ''}`}>
                                            <NoYet text="No charge rates available" />
                                        </div>
                                    )
                                    :
                                    (
                                        !activeModifiers?.length && (
                                            <NoYet text="No charge rates available" />
                                        )
                                    )
                                }

                                {
                                    <>
                                        <>
                                            {!!activeModifiers?.length ? activeModifiers.map((item, index) => {
                                                    const modifierButnStyle = `${classes.availableModfier}
                                                ${modCheck.includes(index) ? 'checked' : modifierExists ? 'chosen' : ''}`;
                                                    if (item?.status !== false) {
                                                        return (
                                                            <button
                                                                key={index}
                                                                className={modifierButnStyle}
                                                                onClick={() => onModifier(index)}
                                                                disabled={modifierExists}
                                                            >
                                                                {item.name}
                                                            </button>
                                                        );
                                                    }
                                                })
                                                : null}
                                        </>
                                    </>
                                }
                            </div>
                        )}
                    </div>
                </div>
                <ErrMessage text={authServiceDefaultErrorText} />
                <ValidationInput
                    variant={'outlined'}
                    onChange={handleChangeTotal}
                    value={inputs.totalUnits === 0 ? '0' : inputs.totalUnits}
                    label={'Total Available Units*'}
                    name="totalUnits"
                    typeError={error === 'totalUnits' ? `Available units ${ErrorText.isRequired}` : ''}
                />
                {error !== 'totalUnits' && <p className={classes.maxList}>Max 10,000</p>}
            </div>

            <CreateChancel
                loader={!!loader.length}
                create={!!info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={handleCreate}
                onClose={close}
                buttonWidth="100%"
            />

        </div>
    );
};
