import axios from "axios";

export const authService = {

   getSignatureReportsService: (params) =>  axios.get("/reports/appt/signature", { auth: true, params:{ ...params} }),

   downloadPdf: (params) =>  axios.get("/reports/appt/signature/pdf", { auth: true, params:{ ...params} }),



};
