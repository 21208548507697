import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouterSwitcher } from './root/routerSwitcher';
import { Footer, TopBar } from './fragments';
import { Toast } from './components';
import { ToastFail, ToastSuccess } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

function App() {
    const { httpOnError, httpOnSuccess } = useSelector((state) => ({
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
    }));
    const dispatch = useDispatch();
    const route = useLocation();
    const token = localStorage.getItem('access-token');
    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const errorMessage = httpOnError.length && httpOnError[0].error;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error, errorMessage);

    useEffect(() => {
        if (!!toastSuccess) {
        // if (!!success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    useEffect(() => {
        if (!!toastFail) {
        // if (!!error) {
            dispatch(httpRequestsOnErrorsActions.removeError(error));
        }
    }, [toastFail]);


    const checkBar = () => {
        return route?.pathname === '/' ||
            route?.pathname === '/features' ||
            route?.pathname === '/about-us' ||
            route?.pathname === '/contact-us' ||
            route?.pathname === '/help-center' ||
            route?.pathname === '/help-center/getting-started' ||
            route?.pathname === '/help-center/control-panel' ||
            route?.pathname === '/terms-and-conditions' ||
            route?.pathname === '/privacy-policy';
    };

    const dontShowFooter = () => {
        return route?.pathname === '/help-center/getting-started' ||
            route?.pathname === '/help-center/control-panel';
    };

    return (
        <div>
            {checkBar() ?
                <div className="App">
                    {!token && <TopBar />}
                    <RouterSwitcher />
                    {!token && !dontShowFooter() && <Footer />}
                </div>
                :
                <RouterSwitcher />
            }

            <Toast
                type={toastSuccess ? 'success' : toastFail ? 'error' : ''}
                text={toastSuccess ? toastSuccess : toastFail ? toastFail : ''}
                info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ''}
            />
        </div>
    );
}

export default App;
