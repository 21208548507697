import {
   createAdmin,
   reinviteAdmin,
   getAdminById,
   getAdmins,
   changeAdminStatus,
   editAdminById,
   createCredential,
   getCredential,
   editCredentialById,
   deleteCredentialById,
   getEmployment,
   createEmployment,
   getPayCode,
   editEmployment,
   createPayCode,
   createStaffService,
   getStaffService,
   deleteStaffService,
   isClinician,
   getTimesheet,
   createTimesheet,
   getAllAdmins,
   editPayCode,
   getTimesheetById,
   editTimesheet,
   terminatePayCode,
   getAllPayCodes,
   clearAllPayCodes,
   createAdminDetails,
   getActiveEmployment,
   uploadStaffDocument,
   getStaffDocument,
   deleteStaffDocument,
   editStaffDocument,
   removeTimesheetById,
   getAllServices,
   deleteEmployment,
   uploadAvatar,
   getStaffForAppt,
} from './admin.action';

export { adminReducer } from "./admin.reducer";
export { watchAdmin } from "./admin.saga";

export const adminActions = {
   /** Staff */
   createAdmin,
   reinviteAdmin,
   createAdminDetails,
   getAdmins,
   getAllAdmins,
   getAdminById,
   changeAdminStatus,
   editAdminById,
   uploadAvatar,

   /** End */

   /** Staff Credentials */
   createCredential,
   getCredential,
   editCredentialById,
   deleteCredentialById,
   /** End */

   /** Staff Employment */
   getEmployment,
   getActiveEmployment,
   createEmployment,
   editEmployment,
   deleteEmployment,
   /** End */

   /** Staff PayCodes */
   getPayCode,
   createPayCode,
   terminatePayCode,
   editPayCode,
   getAllPayCodes,
   clearAllPayCodes,
   /** End */

   /** Staff Service */
   createStaffService,
   getStaffService,
   deleteStaffService,
   /** End */

   isClinician,
   getTimesheet,
   getTimesheetById,
   removeTimesheetById,
   createTimesheet,
   editTimesheet,

   /** Staff Documents */
   uploadStaffDocument,
   editStaffDocument,
   getStaffDocument,
   deleteStaffDocument,
   /** End */

   getAllServices,

   getStaffForAppt,
};
