import { DateRow, DateRowStartEnd, DownloadLink, PriceRow, SimpleTooltip, TextRow, UnitsRow } from 'components';
import { convertHours, hooksForTable, Images, makeCapitalize, manageStatus, manageStatusCircle, useModal } from 'utils';
import React from 'react';
import { FormType1500 } from '../../../../pages/billing/claims/formType1500';
import { Checkbox } from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';

export function getClaimDetails(claim) {
    const { open } = useModal();
    const {
        createdDate,
        submittedDate,
        dateRange,
        funder,
        client,
        totalAmount,
        paidAmount,
        status,
    } = claim || {};

    const { handleCreatedAtDate, addSignToValueFromStart, getValueByFixedNumber } = hooksForTable;
    const early = handleCreatedAtDate(dateRange?.early);
    const latest = handleCreatedAtDate(dateRange?.latest);

    const claimDetails = [

            {
                detailText: 'Staff:',
                detail: client?.renderingProvider && makeCapitalize(`${client?.renderingProvider?.firstName} ${client?.renderingProvider?.lastName}`),
            },
            {
                detailText: 'Client:',
                detail: !!client && makeCapitalize(`${client?.firstName} ${client?.lastName}`),
            },
            {
                detailText: 'Founding Source:',
                detail: !!funder && makeCapitalize(funder?.name),
            },
            {
                detailText: 'DOS:',
                detail: `${early} - ${latest}`,
            },
            {
                detailText: 'Created Date:',
                detail: createdDate ? <DateRow date={createdDate} /> : 'N/A',
            },
            {
                detailText: 'Submitted Date:',
                detail: submittedDate ? <DateRow date={submittedDate} /> : 'N/A',
            },
            {
                detailText: 'Total Amount:',
                detail: totalAmount ? addSignToValueFromStart(getValueByFixedNumber(totalAmount)) : '',
            },
            {
                detailText: 'Paid Amount:',
                detail: addSignToValueFromStart(getValueByFixedNumber(paidAmount)),
            },
            {
                detailText: 'Status:',
                detail: <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{manageStatusCircle(status)}</div>
                    <div>{manageStatus(status)}</div>
                </div>,
            },

            {
                detailText: '1500 Form:',
                detail: <button onClick={() => open(<FormType1500 item={claim} />)} className="download-1500">
                    <p>Download</p>
                    <img src={Images.download} alt="" />
                </button>,
                // <DownloadLink
                //     customDownload={'1500Form'}
                //     linkInnerText={'Download'}
                //     id={claim?.id}
                //     small={true}
                // />,

            },
        ]
    ;

    return claimDetails;
}


export const claimDetailHead = (
    [
        { name: '', title: 'ID', width: '120px', noSearch: true, custom: false },
        { name: '', title: 'DOS', noSearch: true, custom: false },
        { name: '', title: 'Place of Service', noSearch: true, custom: false },
        { name: '', title: 'CPT Code', noSearch: true, custom: false },
        { name: '', title: 'Modifier', noSearch: true, custom: false },
        { name: '', title: 'Total Units', noSearch: true, custom: false },
        { name: '', title: 'Total Billed', noSearch: true, custom: false },
    ]
);

export const claimDetailBody = (
    [
        {
            rowText: (item) => <TextRow
                name={`${item?.displayId?.prefix}${item?.displayId?.suffix ? item?.displayId?.suffix : ''}`} />,
        },
        { rowText: (item) => <DateRow date={item?.dateOfService} /> },
        { rowText: (item) => <TextRow name={item?.placeOfService?.name} /> },
        { rowText: (item) => <TextRow name={item?.cptCode} /> },
        { rowText: (item) => <TextRow name={item?.chargeRate?.name} /> },
        { rowText: (item) => <UnitsRow info={item?.units} /> },
        { rowText: (item) => <PriceRow info={item?.billedAmount} /> },
    ]
);

