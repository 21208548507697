import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from '@material-ui/core';
import moment from 'moment';
import { Images, PermissionList, RolePermission, useModal, formatAMPM, FindSuccessItem } from 'utils';
import { CustomDelete, LinkRow, SimpleTooltip } from 'components';
import { getBorderColorAndText, getCurrentText } from '../constants';
import { ServiceItems } from './serviceItems';
import { OtherItems } from './otherItems';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { billStatuses, BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../../constants';
import { InfoModal } from '../modals/infoModal';
import { SignatureNoteModal } from '../common/signatureNoteModal';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const CONNECT_RECONNECT = 'CONNECT_RECONNECT_TEMPLATE';
const DELETE_TYPE = 'DELETE_APPOINTMENT'
export const ListViewTable = ({
                                  item,
                                  openCloseRecur,
                                  handleEdit,
                                  expanded,
                                  setExpanded,
                                  handleSelect,
                                  selected,
                                  selectAllList,
                              }) => {
    const dispatch = useDispatch();

    const { open, close } = useModal();
    const _isNotRendered = item?.status === 'PENDING';
    const checkEditPermission =
        item?.type === SERVICE ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) :
            item?.type === STAFF ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_STAFF_CREATE?.code]) :
                item?.type === BREAK ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_BREAK_CREATE?.code]) :
                    item?.type === DRIVE ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_DRIVE_CREATE?.code]) :
                        item?.type === PAID ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_PTO_CREATE?.code]) :
                            item?.type === UNPAID ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_UNPAID_CREATE?.code]) :
                                item?.type === SICK_TIME ? RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SICK_TIME_CREATE?.code]) :
                                    false;
    const successConnect = FindSuccessItem(CONNECT_RECONNECT);

    useEffect(() => {
        if (successConnect?.type === CONNECT_RECONNECT) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CONNECT_RECONNECT));
            close();
        }
    }, [successConnect]);

    const handleChange = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const currentId = expanded === id ? '' : id;
        setExpanded(currentId);
    };

    const renderStatus = (currentItem) => {
        const { color: borderColor, text } = getBorderColorAndText(currentItem?.status, currentItem?.type);
        return { color: borderColor, text };
    };

    const handleOpenRecur = (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        openCloseRecur(item);
    };

    const openEdit = (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        handleEdit(item);
    };

    const deleteAppt = (ev) => {
        ev.stopPropagation();
        ev.stopPropagation();
        open(<CustomDelete
            text={`Are you sure you want to delete this appointment?`}
            info="Delete appointment"
            handleDel={() => dispatch(appointmentActions.deleteAppointment(item?.id))}
            handleClose={close}
            actionType={DELETE_TYPE}
        />);
    };

    const handleLockUnlock = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const subText = item?.locked ?
            'Are you sure you want to unlock this appointment? Once unlocked, it can be edited again.'
            :
            'Are you sure you want to lock this appointment? Once locked, it cannot be edited until unlocked.';

        const list = {
            'apptIds': [item?.id],
            'all': false,
        };
        open(
            <InfoModal
                loaderType={'CHANGE_APPOINTMENT_LOCK_UNLOCK'}
                titleText={!item?.locked ? 'Confirm Lock Action' : 'Confirm Unlock Action'}
                btnText={!item?.locked ? 'Lock' : 'Unlock'}
                handleSubmit={() => dispatch(appointmentActions.changeApptLockUnlock(list, !item?.locked, ))}
                subText={subText}
            />,
        );
    };

    const connectReconnect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const checkConnect = !item?.fromTemplate;
        const subText = checkConnect ?
            'This appointment was previously disconnected from its recurring template. Reconnecting it will associate it back with the template and align it with the template\'s recurring pattern.'
            :
            'Are you sure you want to disconnect this appointment from its recurring template? Disconnection will make this appointment independent of the template. You can always reconnect it later if needed.';

        const params = {
            'id': item?.template,
            'connect': !item?.fromTemplate,
        };

        open(
            <InfoModal
                loaderType={CONNECT_RECONNECT}
                titleText={checkConnect ? 'Reconnect Appointment to Template' : 'Disconnect Appointment to Template'}
                btnText={checkConnect ? 'Reconnect' : 'Disconnect'}
                handleSubmit={() => dispatch(appointmentActions.connectReconnect(item?.id, params))}
                subText={subText}
            />,
        );
    };

    return (
        <>
            <Accordion
                expanded={expanded === item?.id}
                onChange={(e) => handleChange(e, item?.id)}
                style={{ borderLeft: `4px solid ${renderStatus(item)?.color}` }}
            >
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id={item?.id}
                >
                    <div className="accordion-body-wrapper">
                        {RolePermission([PermissionList.APPT_LOCK?.code]) &&
                            <div className="accordion-body-item"
                                 style={{ width: '4%' }}
                                 onClick={(ev) => {
                                     ev.stopPropagation();
                                     ev.preventDefault();
                                 }}
                            >
                                <Checkbox
                                    {...label}
                                    disableRipple={true}
                                    onClick={(e) => handleSelect(e, item)}
                                    name={'checkBox'}
                                    style={{ padding: 0, color: '#347AF0', width: '35px', height: '30px' }}
                                    checked={selectAllList ? selectAllList : selected?.includes(item?.id || item?._id)}
                                />
                            </div>
                        }
                        <div className="accordion-body-item" style={{ width: '10%' }}>
                            {item?.displayId}
                        </div>
                        <div className="accordion-body-item" style={{ width: '15%' }}>
                            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
                            <p className="schedule-time">
                                {`${formatAMPM(item?.startTime)} - ${formatAMPM(item?.endTime)}`}
                            </p>
                        </div>
                        <div className="accordion-body-item link" style={{ width: '15%' }}>
                            <LinkRow
                                name={item?.staff ? `${item?.staff?.firstName} ${item?.staff?.lastName ? item?.staff?.lastName : ''}` : 'N/A'}
                                href={
                                    RolePermission([PermissionList.STAFF_READ.code]) ?
                                        `/staff/${item?.staff?.id || item?.staff?._id}` : ''
                                }
                                checkPermission={item?.staff ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                                textWidth={8}
                            />
                        </div>
                        <div className="accordion-body-item link" style={{ width: '15%' }}>
                            {item?.client ?
                                <LinkRow
                                    name={item?.client ?
                                        `${item?.client?.code ? `(${item?.client?.code})` : ''} ${item?.client?.firstName} ${item?.client?.lastName ? item?.client?.lastName : ''} `
                                        :
                                        'N/A'
                                    }
                                    href={
                                        RolePermission([PermissionList.CLIENT_READ?.code]) ?
                                            `/client/${item?.client?.id || item?.client?._id}` : ''}
                                    checkPermission={item?.client ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                                    textWidth={8}
                                />
                                :
                                'N/A'
                            }
                        </div>
                        <div className="accordion-body-item" style={{ width: '10%' }}>
                            {getCurrentText(item?.type)?.detailText}
                        </div>
                        <div className="accordion-body-item-center" style={{ width: '13%', alignItems: 'flex-start' }}>
                            <div className="status-circle" style={{ backgroundColor: renderStatus(item)?.color }} />
                            <div>
                                <p>{renderStatus(item)?.text}</p>
                                {item?.type === SERVICE && item?.billStatus &&
                                    <p style={{ marginTop: '2px' }}>{
                                        item?.billStatus === billStatuses?.BILLED ? '(Billed)' :
                                            item?.billStatus === billStatuses?.NOT_BILLED ? '(Not Billed)' :
                                                item?.billStatus === billStatuses?.ON_CLAIM ? '(On Claim)' : ''
                                    }</p>
                                }
                            </div>
                        </div>
                        <div className="accordion-body-item"
                             style={{ width: '14%', display: 'flex', alignItems: 'center' }}>

                            {RolePermission([PermissionList.APPT_RECURE?.code]) && !item?.template && item?.status !== 'CANCELLED' ?
                                <div className="action-button-width">
                                    <SimpleTooltip
                                        title={<p>{'Recur Event'}</p>}
                                        placement="top-end"
                                    >
                                        <button onClick={(e) => handleOpenRecur(e, item)}>
                                            <img src={Images.recurrance} alt="icon" />
                                        </button>
                                    </SimpleTooltip>
                                </div>
                                :
                                item?.template && item?.status !== 'CANCELLED' &&
                                <div className="action-button-width">
                                    <SimpleTooltip
                                        title={
                                            <p>{item?.fromTemplate ? 'Disconnect to Template' : 'Reconnect to Template'}</p>
                                        }
                                        placement="top-end"
                                    >
                                        <button onClick={(e) => connectReconnect(e)}>
                                            <img
                                                src={item?.fromTemplate ? Images.reConnectedTemplate : Images.connectedTemplate}
                                                alt="icon"
                                            />
                                        </button>
                                    </SimpleTooltip>
                                </div>
                            }

                            {RolePermission([PermissionList.APPT_LOCK?.code]) && item?.status !== 'CANCELLED' &&
                                <div className="action-button-width">
                                    <SimpleTooltip
                                        title={<p>{item?.locked ? 'Unlock Appointment' : 'Lock Appointment'}</p>}
                                        placement="top-end"
                                    >
                                        <button className="lock-unlock-btn" onClick={handleLockUnlock}>
                                            <img src={item?.locked ? Images.lock : Images.unlock} alt="icon" />
                                        </button>
                                    </SimpleTooltip>
                                </div>
                            }

                            {item?.status !== 'CANCELLED' && checkEditPermission &&
                                <div className="action-button-width">
                                    <button
                                        style={RolePermission([PermissionList.LOCKED_APPT_EDIT?.code]) ? {} : item?.locked ? { opacity: 0.5 } : {}}
                                        disabled={RolePermission([PermissionList.LOCKED_APPT_EDIT?.code]) ? false : item?.locked}
                                        onClick={(e) => openEdit(e, item)}
                                    >
                                        <img src={Images.edit} alt="icon" />
                                    </button>
                                </div>
                            }

                            {RolePermission([PermissionList.UNRENDERED_APPT_DELETE?.code]) && _isNotRendered &&
                                <div className="action-button-width">
                                    <SimpleTooltip
                                        title={<p>{'Delete Appointment'}</p>}
                                        placement="top-end"
                                    >
                                        <button onClick={(e) => deleteAppt(e)}>
                                            <img src={Images.remove} alt="icon" />
                                        </button>
                                    </SimpleTooltip>
                                </div>
                            }
                        </div>

                        <div className="accordion-body-item" style={{ width: '2%', padding: '0 12px 0 0' }}>
                            <img
                                style={{ marginBottom: '5px' }}
                                src={expanded === item?.id ? Images.dropdownArrowUpBlue : Images.dropdownBlue}
                                alt="icon"
                            />
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="schedule-details-body">
                        <p className="schedule-details-title">Details</p>
                        <div className="full-width">
                            <div className="detail-information">
                                {item?.type === SERVICE ?
                                    <ServiceItems item={item} />
                                    :
                                    <OtherItems item={item} />
                                }
                            </div>
                            <SignatureNoteModal item={item} />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};