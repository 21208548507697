import {
   getInvoices,
   getInvoiceById,
   generateInvoice,
   editInvoice,
   deleteInvoice, getInvoiceForGenerate,
} from "./invoice.action";

export { invoiceReducer } from "./invoice.reducer";
export { watchInvoice } from "./invoice.saga";

export const invoiceActions = {
   getInvoices,
   getInvoiceById,
   generateInvoice,
   editInvoice,
   deleteInvoice,

   /**Get Invoices for generate  */
   getInvoiceForGenerate
};