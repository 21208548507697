import { HistoryCard } from "components/card";
import { Loader, NoItemText } from "components";
import React from "react";
import { FindLoad, useGlobalStyles } from "utils";

export const FundingSourceSingleHistories = ({ data }) => {
   const classes = useGlobalStyles();
   const loader = FindLoad("GET_HISTORY");

   return (
      <div>
         {!data?.length ? (
            <NoItemText text="There is no history in this date" />
         ) : !!loader.length ? (
            <Loader />
         ) : (
            <div className={classes.globalHistory}>
               {data &&
                  data.map((item, index) => {
                     return (
                        <div key={index}>
                           <HistoryCard data={item} />
                        </div>
                     );
                  })}
            </div>
         )}
      </div>
   );
};
