import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FindLoad } from "utils";
import { CustomBreadcrumbs, Loader } from "components";
import { claimActions } from "store";
import { ClaimDetailsFragment } from "fragments";

const ACTION_TYPE = 'GET_CLAIM_BY_ID'
export const ClaimDetails = () => {
   const params = useParams();
   const dispatch = useDispatch();
   const claimById = useSelector((state) => state.claim.claimById);
   const loader = FindLoad(ACTION_TYPE);
   const history = useHistory();
   const info = history?.location?.state;
   const parentLink =
       info?.from === "pending" ? "/pendingClaims" :
        info?.from === "submitted" ? "/billedClaims" : ""

   useEffect(() => {
       if(params?.id) {
           dispatch(claimActions.getClaimById(params.id));
       }
   }, [params.id]);

   if (!!loader.length) return <Loader />;

   return (
      <>

         <ClaimDetailsFragment
             claimDetails={claimById}
         />
      </>
   );
};
