import React, {useContext, useState} from "react";
import { invoiceDetailsStyle } from "./styles";
import {NoItemText, SimpleModal} from "components";
import { DrawerContext, handleTooltip, hooksForTable, useWidth } from "utils";
import { InvoiceReceivableTable } from "./core";
import { getInvoiceDetails } from "./constants";
import {ReceivableBillTable} from "../../claims/claimDetails/core/common/core";
import {claimDetailsCoreStyle} from "../../claims/claimDetails/core/styles";

export const InvoiceDetailsFragment = ({ invoiceDetails }) => {
   const classes = invoiceDetailsStyle();
   const modalClass = claimDetailsCoreStyle();
   const { displayId, _id, bills, receivable } = invoiceDetails || {};
   const { open: drawerOpen } = useContext(DrawerContext);
   const filteredDetails = getInvoiceDetails(invoiceDetails).filter((invoiceDtl) => !!invoiceDtl.detail);
   const width = useWidth();
   const [openTab, setOpenTab] = useState('')

   const { getTextDependsOnWidth } = hooksForTable;

   function getDetailDisplay(detail) {
      if (detail && detail !== "Undefined Undefined") {
         return getTextDependsOnWidth(width, 1480, detail, 14);
      } else {
         return "Not Set";
      }
   }
   const handleOpenModal = (item) => {
      setOpenTab(item)
   }

   return (
      <>
         <div className={classes.invoiceDetailsContainerStyle}>
            <div className={classes.invoiceDetailsStyle}>
               <div className={classes.invoiceDetailsTitleBoxStyle}>
                  <h2 className={classes.invoiceDetailsTitleStyle}>Invoice Details</h2>
               </div>
            </div>
            <div className={classes.invoiceDetailsFirstPartStyle}>
               <div className={classes.invoiceOutlineStyle}>
                  <span className={classes.invoiceIdTextBoxStyle}>ID: {displayId ? displayId : _id}</span>
               </div>
               {!!filteredDetails.length && (
                  <ol className={classes.invoiceDetailsListStyle}>
                     {filteredDetails.map((item, index) => (
                        <li key={index} className={drawerOpen ? "narrow" : ""}>
                           <span>
                              {item.detailText}{" "}
                              <em>
                                 {" "}
                                 {item.detailText === "Date Range:"
                                    ? handleTooltip(
                                         item.detail,
                                         getDetailDisplay(item.detail)
                                      )
                                    : getDetailDisplay(item.detail)}{" "}
                              </em>
                           </span>
                        </li>
                     ))}
                  </ol>
               )}
            </div>

            <div className={classes.invoiceDetailsSecondPartStyle}>
               <div className={classes.invoiceDetailsTitleBoxStyle}>
                  <h2 className={classes.invoiceDetailsTitleStyle}>Bills</h2>
               </div>
               {!!bills?.length ? (
                  <div className={classes.receivablesTableBoxStyle}>
                     <InvoiceReceivableTable invoiceReceivables={bills} handleOpenModal={handleOpenModal}/>
                  </div>
               ) : (
                  <NoItemText text={"No bills yet"} />
               )}
            </div>

            <SimpleModal
                openDefault={!!openTab}
                handleOpenClose={() => setOpenTab('')}
                content={
                   <div className={modalClass.billModalWrapper}>
                      <ReceivableBillTable billById={ openTab } activeTab={'invoice'}/>
                   </div>
                }
                secondary={true}
            />
         </div>
      </>
   );
};
