import { Button } from '@material-ui/core';
import { Colors } from 'utils';
import { MinLoader } from '../loader';

export const CreateChancel = ({
                                  create,
                                  chancel,
                                  onCreate,
                                  onClose,
                                  classes,
                                  buttonWidth,
                                  disabled,
                                  loader,
                                  butnClassName,
                                  createButnMargin,
                                  justifyContent, alignItems, redBtn,image, type
                              }) => {
    return (
        <div
            className={classes}
            style={{
                display: 'flex',
                justifyContent: justifyContent ? justifyContent : 'space-between',
                alignItems: alignItems ? alignItems : 'center',
                width: '100%',
            }}
        >
            <button
                type={'button'}
                style={{width: buttonWidth,  marginRight: '16px',}}
                onClick={onClose} className="close-btn-box">
                {chancel}
            </button>
            {/*<Button*/}
            {/*    className={`${butnClassName} cancel`}*/}
            {/*    style={{*/}
            {/*        textTransform: 'capitalize',*/}
            {/*        width: buttonWidth,*/}
            {/*        height: '48px',*/}
            {/*        backgroundColor: Colors.BackgroundWater,*/}
            {/*        borderRadius: '8px',*/}
            {/*        fontSize: '16px',*/}
            {/*        fontWeight: '600',*/}
            {/*        color: Colors.TextPrimary,*/}
            {/*        marginRight: '16px',*/}
            {/*    }}*/}
            {/*    onClick={onClose}*/}
            {/*>*/}
            {/*    {chancel}*/}
            {/*</Button>*/}
            <Button
                type={type}
                className={`${butnClassName} create`}
                style={{
                    textTransform: 'capitalize',
                    width: buttonWidth,
                    height: '48px',
                    backgroundColor:
                        redBtn ? '#FE7070' :
                            disabled ? 'rgba(52,122,240,.5)' : Colors.BackgroundBlue,
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: Colors.TextWhite,
                    marginLeft: createButnMargin || '0px',
                }}
                onClick={onCreate}
                disabled={disabled || loader}
            >
                {image && !loader && <img src={image} alt='' style={{marginRight: '8px'}} /> }
                {loader ? <MinLoader margin={'0'} color={Colors.TextWhite} /> : create}
            </Button>
        </div>
    );
};
