import {call, put, takeLatest} from "redux-saga/effects";
import {httpRequestsOnErrorsActions} from "../http_requests_on_errors";
import {httpRequestsOnLoadActions} from "../http_requests_on_load";
import {httpRequestsOnSuccessActions} from "../http_requests_on_success";
import {
    CREATE_PROCESS_PAYROLL,
    GET_ACTIVE_PAYROLL,
    GET_ACTIVE_PAYROLL_SUCCESS,
    GET_PROCESSED_PAYROLL, GET_PROCESSED_PAYROLL_SUCCESS
} from "./staffPayroll.types";
import {authService} from "./staffPayroll.service";

/** Active Payroll */

function* getActivePayroll(action) {
    if(action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getActivePayrollService, action?.payload?.params);
        yield put({
            type: GET_ACTIVE_PAYROLL_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* createProcessPayroll(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createProcessPayrollService, action?.payload?.body);
        yield put({
            type: GET_ACTIVE_PAYROLL,
            payload: { params: action?.payload?.params, load:'noLoad'},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}


/** End */

/** Processed Payroll */

function* getProcessedPayroll(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getProcessedPayrollService, action?.payload?.params);
        yield put({
            type: GET_PROCESSED_PAYROLL_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));

    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

export const watchStaffPayroll = function* watchStaffPayrollSaga() {
    /** Active Payroll */
    yield takeLatest(GET_ACTIVE_PAYROLL, getActivePayroll);
    yield takeLatest(CREATE_PROCESS_PAYROLL, createProcessPayroll);
    /** End */

    /** Processed Payroll */
    yield takeLatest(GET_PROCESSED_PAYROLL, getProcessedPayroll);
    /** End */

};