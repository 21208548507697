import {DateRow, FullTable, PriceConvertor, TextRow} from "components";
import {informationBody, informationHead} from "../constants";
import {manageType} from "utils";

export const StaffInformation = ({item}) => {

    return (
        <div className='staff-information-wrapper'>
            <p className='title'>
                {item?.payCode?.payCodeTypeId?.name ?
                    `${item?.payCode?.payCodeTypeId?.name} (${manageType(item?.payCode?.payCodeTypeId?.type?.toLowerCase())})`
                : 'Fixed'
                }
            </p>
            <div className='information-wrapper'>
                <p style={{width: '100%'}}><TextRow name={item?.description}/></p>
                <p><DateRow date={item?.timesheetDate}/></p>
            </div>
            <FullTable
                height={'auto'}
                head={informationHead}
                body={informationBody}
                loadingType={''}
                list={item?.overtimes?.length ? item?.overtimes : [{...item}]}
                noText={"Overtimes"}
                activeRowId={""}
            />
            <div className='total-wrapper'>
                {item?.regularHours !== 'NaNhNaNm' &&
                    <div className='total-text-wrapper'>
                        <p className='title'>Total Hours: </p>
                        <p className='description'>{item?.regularHours?.replace('h', 'h ')}</p>
                    </div>
                }
                <div className='total-text-wrapper'>
                    <p className='title'>Total Amount: </p>
                    <p className='description' style={{fontWeight: 700}}>
                        <PriceConvertor price={+item?.totalAmount}/>
                    </p>
                </div>
            </div>
        </div>
    )
}