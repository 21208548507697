/**Get Pending Claims */
export const GET_CLAIMS = "GET_CLAIMS";
export const GET_CLAIMS_SUCCESS = "GET_CLAIMS_SUCCESS";

export const GET_SUBMITTED_CLAIMS = "GET_SUBMITTED_CLAIMS";
export const GET_SUBMITTED_CLAIMS_SUCCESS = "GET_SUBMITTED_CLAIMS_SUCCESS";

/**Get Claim by Id */
export const GET_CLAIM_BY_ID = "GET_CLAIM_BY_ID";
export const GET_CLAIM_BY_ID_SUCCESS = "GET_CLAIM_BY_ID_SUCCESS";

/**generate Claim */
export const GENERATE_CLAIM = "GENERATE_CLAIM";

/**close Claim */
export const CLOSE_CLAIM = "CLOSE_CLAIM";

/**submit Claim */
export const SUBMIT_CLAIM = "SUBMIT_CLAIM";
export const SUBMIT_CLAIM_BY_ID = "SUBMIT_CLAIM_BY_ID";

/**Bill Details */
export const GET_BILL_CLAIM_PAYMENT_TRANSACTIONS = "GET_BILL_CLAIM_PAYMENT_TRANSACTIONS";
export const GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS = "GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS";

export const GET_BILL_INVOICE_PAYMENT = "GET_BILL_INVOICE_PAYMENT";
export const GET_BILL_INVOICE_PAYMENT_SUCCESS = "GET_BILL_INVOICE_PAYMENT_SUCCESS";
