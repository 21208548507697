import axios from "axios";

export const claimService = {
   getClaimsService: (data) => axios.get("/claim", { auth: true, params: { ...data } }),

   getSubmittedClaimsService: (data) => axios.get("/claim/submitted", { auth: true, params: { ...data } }),

   getClaimByIdService: (id) => axios.get(`/claim/${id}`, { auth: true }),

   generateClaimService: (group, merge) => axios.post(`/claim/generate`, group, { auth: true, params:{merge:merge } }),

   closeClaimService: (id, details) => axios.post(`/claim/${id}/close?details=${details}`, {}, { auth: true }),

   submitClaimService: (id) => axios.patch(`/claim/submit`, { "claimIds": id }, { auth: true }),

   /**Bill Details */
   billClaimPmtService: (id) => axios.get(`/txn/claim/${id}`, { auth: true }),

   billInvoicePmtService: (id) => axios.get(`/txn/invoice/${id}`, { auth: true }),

};
