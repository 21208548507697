import React, { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { InputMinLoader } from '../../inputs';
import { Input } from './inputMain';


const getNestedError = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const TextareaCustom = forwardRef((
    {
        control,
        name,
        rules,
        maxRows,
        styles,
        classNameWrapper,
        className,
        variant,
        label,
        autoComplete,
        loader,
        maxLength,
        disabled,
        multiline,
        allowCharacter = false,
        ...rest
    }, ref) => {
    const [isFocused, toggleFocus] = useState(false);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getNestedError(errors, name);

                return (
                    <div className={`main-textarea-wrapper ${classNameWrapper ? classNameWrapper : ''}`}>
                        {label &&
                            <p className={`main-textarea-label ${(isFocused || field?.value) ? 'active-label' : ''}`}>
                                {label}
                            </p>
                        }

                        <TextareaAutosize
                            {...field}
                            {...rest}
                            ref={ref}
                            rowsMax={maxRows}
                            style={{ ...styles }}
                            className={`main-textarea-box ${!!error?.message ? 'error-textarea-box' : ''} ${className ? className : ''}`}
                            variant={variant}
                            label={label}
                            id='standard-basic'
                            autoComplete={autoComplete}
                            onFocus={() => toggleFocus(true)}
                            onBlur={() => toggleFocus(false)}
                            error={!!error?.message}
                            disabled={disabled}
                            maxLength={maxLength}
                            multiline={multiline}
                            props={{
                                endAdornment: loader && <InputMinLoader />,
                            }}
                        />
                        {allowCharacter
                            &&
                            <p className='allow-character'>
                                {field?.value?.length || 0}/{maxLength} characters
                            </p>
                        }


                        <p className='error-message'>{error?.message}</p>
                    </div>
                );
            }
            }
        />
    );
});

TextareaCustom.displayName = 'TextareaCustom';
