import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { UserTextArea, ValidationInput } from '../inputs';
import { ErrorText, FindLoad, FindSuccessItem, Images, useModal } from 'utils';
import { ErrMessage } from '../messages';
import { CreateChancel } from '../buttons';
import {
    adminActions,
    clientActions,
    fundingSourceActions,
    httpRequestsOnSuccessActions,
    UploadServices,
} from 'store';
import { tableStyle } from './styles';
import { TextRow } from './tableRows';
import { EDIT_STAFF_DOCUMENT } from '../../store/admin/admin.types';
import { EDIT_FS_DOCUMENT } from '../../store/fundingSource/fundingSource.types';
import { EDIT_CLIENT_DOCUMENT } from '../../store/client/client.types';

export const UploadModal = ({ onResource, editActionType, currentId, defaultValue }) => {
    const classes = tableStyle();
    const actionType =
        defaultValue ?
            onResource === 'staff' ? 'EDIT_STAFF_DOCUMENT' :
                onResource === 'fs' ? 'EDIT_FS_DOCUMENT' :
                    onResource === 'client' ? 'EDIT_CLIENT_DOCUMENT' : ''
            :
            onResource === 'staff' ? 'UPLOAD_STAFF_DOCUMENT' :
                onResource === 'fs' ? 'UPLOAD_FS_DOCUMENT' :
                    onResource === 'client' ? 'UPLOAD_CLIENT_DOCUMENT' : '';
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadLoader, setUploadLoader] = useState(false);
    const dispatch = useDispatch();
    const addLoader = FindLoad(actionType);
    const editLoader = FindLoad(editActionType);
    const success = FindSuccessItem(actionType);
    const successEdit = FindSuccessItem(editActionType);
    const { close } = useModal();

    useEffect(() => {
        if (defaultValue) {
            setUploadedFiles(defaultValue);
            setInputs({ name: defaultValue?.name, description: defaultValue?.description });
        }
    }, [defaultValue]);

    const handleUpload = (e) => {
        error === 'currentFile' && setError('');
        if (e && inputs.name) {

            const pos = e.type.slice(e.type.search('/'));
            const fileType = pos.substring(1);


            const info = {
                file: e,
                name: inputs?.name,
                description: inputs?.description,
                format: fileType,

            };

            setUploadedFiles([info, ...uploadedFiles]);
            setInputs({});

            // let formData = new FormData();
            // formData.append('files', e);
            // try {
            //     setUploadLoader(true);
            //     const res = await UploadServices.UploadImage(formData);
            //
            //     const pos = e.type.slice(e.type.search('/'));
            //     const fileType = pos.substring(1);
            //
            //     const info = {
            //         file: {
            //             ...res?.data,
            //             format: fileType,
            //             size: e?.size / 1024,
            //         },
            //     };
            //     setUploadedFiles([...uploadedFiles, info]);
            //     setUploadLoader(false);
            // } catch (e) {
            //     setUploadLoader(false);
            // }
        }
    };

    const handleSubmit = async () => {
        if (uploadedFiles?.length) {

            let formData = new FormData();
            uploadedFiles?.map((e) => {
                formData.append('files', e.file);
            });

            setUploadLoader(true);
            const res = await UploadServices.UploadManyImage(formData);

            const mixedList = res?.data?.map((file, index) => ({
                file: {
                    ...file,
                    format: uploadedFiles[index]?.format,
                },
                name: uploadedFiles[index]?.name,
                description: uploadedFiles[index]?.description || '',
            }));

            const sendList = {
                documents: mixedList,
            };
            setUploadLoader(false);
            if (onResource === 'staff') {
                dispatch(adminActions.uploadStaffDocument(sendList, currentId));
            } else if (onResource === 'fs') {
                dispatch(fundingSourceActions.uploadFsDocument(sendList, currentId));
            } else if (onResource === 'client') {
                dispatch(clientActions.uploadClientDocument(sendList, currentId));
            }
        } else {
            setError(
                !inputs.name ? 'name' :
                    !uploadedFiles ? 'currentFile' : '',
            );
        }
    };

    const handleEdit = () => {
        if (inputs.name && defaultValue) {
            const sendFile = {
                name: inputs?.name,
                description: inputs?.description,
                id: defaultValue?._id,
                file: {
                    ...defaultValue?.file,
                },
            };
            if (onResource === 'staff') {
                dispatch(adminActions.editStaffDocument(currentId, defaultValue?._id, sendFile));
            } else if (onResource === 'fs') {
                dispatch(fundingSourceActions.editFsDocument(currentId, defaultValue?._id, sendFile));
            } else if (onResource === 'client') {
                dispatch(clientActions.editClientDocument(currentId, defaultValue?._id, sendFile));
            }
        } else {
            setError(
                !inputs.name ? 'name' : '',
            );
        }
    };

    const removeStates = () => {
        // setCurrentFile('')
        setInputs({});
        setError('');
    };

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
            removeStates();
            close();
        }
    }, [success]);


    useEffect(() => {
        if (successEdit) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(editActionType));
            removeStates();
        }
    }, [successEdit]);

    // useEffect(() => {
    //     if (successDelete) {
    //         dispatch(httpRequestsOnSuccessActions.removeSuccess(successDelete));
    //         removeStates();
    //     }
    // }, [successDelete]);

    function handleChange(e) {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    }


    const renderSize = (item) => {
        const bytes = item?.size;
        const KB = 1024;
        const MB = KB * 1024;

        if (bytes >= MB) {
            return (bytes / MB).toFixed(2) + ' MB';
        } else if (bytes >= KB) {
            return (bytes / KB).toFixed(2) + ' KB';
        } else {
            return bytes + ' bytes';
        }
    };

    const removeFile = (item, key) => {
        const updatedItems = uploadedFiles?.filter((item, k) => k !== key);
        setUploadedFiles(updatedItems);
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">Upload Document</p>
            <p className="select-document-desc">Select a document to upload and add optional details.</p>
            <div className="modal-body-wrapper">
                <ValidationInput
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Name*'}
                    label={'Document Name*'}
                    typeError={error === 'name' ? ErrorText.field : ''}
                    Length={50}
                />
                <UserTextArea
                    className={classes.commentTextAreaStyle}
                    name={'description'}
                    label={'Document Description'}
                    value={inputs?.description}
                    onChange={handleChange}
                    hasText={!!inputs?.description}
                    typeError={error === 'description' && ErrorText.field}
                    max={100}
                    maxCharsLabel={`${inputs?.description?.length ? inputs?.description?.length : 0}/100 characters `}
                />
                {defaultValue ?
                    <div className="loaded-documents-box">
                        <img src={Images.uploadedFile} alt="icon" />
                        <div className="loaded-document-info-box">
                            <p className="loaded-document-name">
                                <TextRow name={defaultValue?.name} />
                            </p>
                            <div className="loaded-document-detail-box">
                                <div className="flex-align-center" style={{ gap: '8px' }}>
                                    {defaultValue?.type && <p>{defaultValue?.file?.format}</p>}
                                    {/*<p>{renderSize(defaultValue?.file)}</p>*/}
                                </div>
                                {/*<div className="loaded-circle" />*/}
                            </div>
                        </div>
                        {/*<button className="remove-button" onClick={() => removeFile(i, j)}>*/}
                        {/*    <img src={Images.grayTrash} alt="icon" />*/}
                        {/*</button>*/}
                    </div>
                    :
                    <FileUploader
                        disabled={!inputs?.name}
                        handleChange={handleUpload} name="file"
                        style={!inputs?.name ? { opacity: '0.5' } : {}}
                    >
                        <div
                            style={!inputs?.name ? { opacity: '0.5' } : {}}
                            // style={error === 'currentFile' ? { border: '2px dashed #F07379' } : {}}
                            className="drag-drop-component"
                        >
                            <img src={Images.upload} alt="icon" />
                            <label className="custom-file-upload">
                                <input
                                    onClick={(event) => (event.target.value = null)}
                                    disabled={!inputs?.name}
                                    onChange={handleUpload}
                                    type="file"
                                    id="file"
                                />
                                <i className="fa fa-cloud-upload" />
                                <span className="upload">
                                <p>Drag and Drop or <span style={{ color: '#438AFE' }}>Choose file</span> to upload</p>
                            </span>
                            </label>
                        </div>
                    </FileUploader>
                }

                <ErrMessage text={error === 'currentFile' ? 'Please select file' : ''} />
                {!!uploadedFiles?.length &&
                    uploadedFiles.map((i, j) => (
                        <div className="loaded-documents-box" key={j}>
                            <img src={Images.uploadedFile} alt="icon" />
                            <div className="loaded-document-info-box">
                                <p className="loaded-document-name">
                                    <TextRow name={i?.name} />
                                </p>
                                <div className="loaded-document-detail-box">
                                    <div className="flex-align-center" style={{ gap: '8px' }}>
                                        {i?.type && <p>{i?.file?.type?.replace('image/', '')}</p>}
                                        <p>{renderSize(i?.file)}</p>
                                    </div>
                                    <div className="loaded-circle" />
                                    <div className="flex-align-center" style={{ gap: '4px' }}>
                                        <img src={Images.complete} alt="icon" />
                                        <p>Completed</p>
                                    </div>
                                </div>
                                <div className="full-loaded">
                                    <div className={classes.fullLoaded} />
                                    <p>100%</p>
                                </div>
                            </div>
                            <button className="remove-button" onClick={() => removeFile(i, j)}>
                                <img src={Images.grayTrash} alt="icon" />
                            </button>
                        </div>),
                    )}
            </div>
            <CreateChancel
                loader={uploadLoader || !!addLoader?.length || !!editLoader?.length}
                create={defaultValue ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onCreate={defaultValue ? handleEdit : handleSubmit}
                onClose={close}
                buttonWidth="223px"
                createButnMargin={'16px'}
                disabled={defaultValue ? false :
                !uploadedFiles?.length}
            />
        </div>
    );
};