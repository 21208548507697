import { formatMiles, makeCapitalize } from 'utils';
import moment from 'moment';
import { BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../constants';

export function getBorderColorAndText(status, type) {
    if (type === 'SERVICE') {
        switch (status) {
            case 'PENDING':
                return { color: '#6FD231', background: '#F1FAEA', text: 'Not Rendered' };
            case 'COMPLETED':
                return { color: '#2A8E6D', background: '#EAF4F0', text: 'Rendered' };
            case 'CANCELLED':
                return { color: '#A3B2BD', background: '#EAEAEA', text: 'Cancelled' };
            default:
                return { color: '', text: '' };
        }
    } else {
        switch (status) {
            case 'NOTRENDERED':
                return { color: '#6FD231', background: '#F1FAEA', text: 'Not Rendered' };
            case 'RENDERED':
                return { color: '#2A8E6D', background: '#EAF4F0', text: 'Rendered' };
            case 'CANCELLED':
                return { color: '#A3B2BD',  background: '#EAEAEA', text: 'Cancelled' };
            case 'PENDING':
                return { color: '#347AF080', background: '#EBF2FD',  text: 'Pending' };
            case 'COMPLETED':
                return { color: '#347AF0', background: '#EBF2FD', text: 'Completed' };
            default:
                return { color: '', text: '' };
        }
    }
}

export function getCurrentText(type) {
    switch (type) {
        case DRIVE:
            return { cardText: 'Drive', detailText: 'Drive' };
        case PAID:
            return { cardText: 'Paid', detailText: 'Paid' };
        case BREAK:
            return { cardText: 'Break', detailText: 'Break' };
        case SERVICE:
            return { cardText: 'Service', detailText: 'Service' };
        case STAFF:
            return { cardText: 'Staff', detailText: 'Staff' };
        case UNPAID:
            return { cardText: 'Unpaid', detailText: 'Unpaid' };
        case SICK_TIME:
            return { cardText: 'Sick', detailText: 'Sick' };
        default:
            return { cardText: '', detailText: '' };
    }
}

export function getServiceAppmtDetails(serviceAppmt) {
    const {
        client,
        authorizedService,
        staff,
        staffPayCode,
        miles,
        placeService,
        creator,
        editor,
    } = serviceAppmt || {};

    const address = client?.addresses?.length ?
        client?.addresses?.find((i) => i.default === true)
        : null;

    const serviceAppmtDetails = [
        {
            detailText: 'Client:',
            detail: !!client && makeCapitalize(`${client?.firstName} ${client?.lastName} ${client?.code ? `( ${client?.code} )` : ''}`),
        },
        {
            detailText: 'Authorized Service:',
            detail: authorizedService?.authorization?.authId,
        },
        {
            detailText: 'CPT Code:',
            detail: authorizedService?.service?.cptCode,
        },
        {
            detailText: 'Staff Member:',
            detail: !!staff && makeCapitalize(`${staff?.firstName} ${staff?.lastName}`),
        },
        {
            detailText: 'Charge Rate:',
            detail: serviceAppmt?.chargeRateName,
        },
        {
            detailText: 'Staff Paycode:',
            detail: staffPayCode?.payCodeTypeId?.name ? makeCapitalize(staffPayCode?.payCodeTypeId?.name) : staffPayCode,
        },
        miles &&
        {
            detailText: 'Miles:',
            detail: formatMiles(miles),
        },
        {
            detailText: 'Client Address:',
            detail: address?.address?.formattedAddress,
        },
        {
            detailText: 'Place of Service:',
            detail: placeService?.name,
        },
        {
            detailText: 'Funding Source:',
            detail: serviceAppmt?.payer?.name,
        },
        {
            detailText: 'Creator:',
            detail: creator,
        },
        {
            detailText: 'Editor:',
            detail: editor,
        },
    ];

    return serviceAppmtDetails.filter((serviceAppmt) => serviceAppmt?.detail);
}


export const scheduleStatuses = [
    { id: 'All', name: 'All' },
    { id: 'RENDERED', name: 'Rendered' },
    { id: 'COMPLETED', name: 'Completed' },
    { id: 'NOT_RENDERED', name: 'Not Rendered' },
    { id: 'PENDING', name: 'Pending' },
    { id: 'CANCELLED', name: 'Cancelled' },
];

export const scheduleTypes = [
    { id: 'All', name: 'All' },
    { id: SERVICE, name: 'Service' },
    { id: STAFF, name: 'Staff' },
    { id: DRIVE, name: 'Drive' },
    { id: BREAK, name: 'Break' },
    { id: PAID, name: 'Paid' },
    { id: UNPAID, name: 'Unpaid' },
    { id: SICK_TIME, name: 'Sick' },
];