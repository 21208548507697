import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDateSelector, SelectTypeAutocomplete, ValidationInput } from 'components';
import { clientActions } from 'store';
import { SaveParams } from 'utils';

export const BillFiltersSelectors = ({ }) => {
   const dispatch = useDispatch();
   const clientList = useSelector((state) => state.client.clientList);
   const history = useHistory();
   const info = history?.location?.state;

   useEffect(() => {
      dispatch(clientActions.getClients())
   }, []);

   const handleChange = (e) => {
      const pushInfo = {
         ...info,
      };

      if (e.target.value === 'All' || !e.target.value) {
         delete pushInfo[e.target.name];
      } else {
         pushInfo[e.target.name] = e.target.value;
      }
      SaveParams(history, { ...pushInfo });
   };

   return (
      <div className='generate-claim-filter-section' >
         <SelectTypeAutocomplete
             placeholder={info?.client ? '' : 'All'}
             name={'client'}
             handleSelect={handleChange}
             defaultValue={info?.client}
             list={clientList?.clients ?
                 [{ id: 'All', firstName: 'All' }, ...clientList?.clients]
                 :
                 [{ id: 'All', firstName: 'All' }]
             }
             renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
             uiType={'tableFilter'}
             outLabel={'Client'}
             noError={true}
         />
         <CustomDateSelector outLabel={'DOS'} />


            {/*<ValidationInput*/}
            {/*   keepLabelArea={true}*/}
            {/*   inputLabel={`Service Date`}*/}
            {/*   variant={"outlined"}*/}
            {/*   name={"filterDate"}*/}
            {/*   onChange={handleChange}*/}
            {/*   value={info?.filterDate}*/}
            {/*   type={"date"}*/}
            {/*   size={"small"}*/}
            {/*   // style={addStyle(classes.dateInputStyle)}*/}
            {/*/>*/}
      </div>
   );
};
