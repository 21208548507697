import React from 'react';
import { useWidth } from 'utils';

export const ControlPanelContentMenuDesign = () => {
    const width = useWidth();


    const images = {
        desktop: {
            design: '/assets/images/gifs/Design.gif',
        },
        tablet: {
            design: '/assets/images/gifs/DesignTablet.gif',
        },
        mobile: {
            design: '/assets/images/gifs/DesignMobile.gif',
        },
    };


    const renderImage = (src) => {
        if (width > 1240) {
            return images.desktop[src];
        } else if (width > 767) {
            return images.tablet[src];
        } else {
            return images.mobile[src];
        }
    };

    return (
        <div className='control-panel-content-menus menu-items'>
            <div className='controlPanelContentMenus-menu-design'>
                <div className='menus-items_text-desc' style={{ maxWidth: '676px' }}>
                    <h2 className='title'>
                        Menu Design
                    </h2>

                    <p className='description' style={{ margin: '16px 0 8px' }}>
                        The Menu Designfeature becomes available when you select the
                        <span style={{ fontWeight: '500' }}>
                         Starter
                         </span>
                        any package above it. Here, you can get creative and design different looks for your presets,
                        which you can easily attach to your menus.
                    </p>

                    <div className='description'>
                        Click on
                        <span style={{ fontWeight: '500' }}>
                            Add Preset
                        </span>
                        then add a preset name, customize the theme color and fonts, and assign it to the menu.

                        <p style={{ fontWeight: '500' }}>
                            You can create presets and assign them to the menu anytime.
                        </p>
                    </div>

                    <ul className='lists' style={{ margin: '8px 0 8px 25px' }}>
                        <li className='description'>
                            Click
                            <span style={{ fontWeight: '500' }}>Design</span>
                            to start customizing.
                        </li>

                        <li className='description'>
                            <span className='description' style={{ fontWeight: '500' }}>
                                Add Preset Name:
                             </span>
                            Enter a name for your preset to easily identify it later.
                        </li>
                    </ul>

                    <p className='description' style={{ marginTop: '8px' }}>
                        Through these steps users customize their menus easily, making them visually appealing and aligned with their brand.
                    </p>
                </div>
            </div>
        </div>
    );
};

