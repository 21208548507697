import {createProcessPayroll, getActivePayroll, getProcessedPayroll} from "./staffPayroll.action";
export { staffPayrollReducer } from './staffPayroll.reducer';
export { watchStaffPayroll } from './staffPayroll.saga';

export const staffPayrollActions = {
  /** Active Payroll */
  getActivePayroll,
  createProcessPayroll,
  /** End */


  /** Processed Payroll */
  getProcessedPayroll,
  /** End */

}

