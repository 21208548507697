import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, DownloadCsv, FullTable } from 'components';
import { CreateStaff } from 'fragments';
import { adminActions, systemActions } from 'store';
import { ACTION_TYPE, staffBody, staffHead } from './constants';
import { PermissionList, RolePermission, useModal } from 'utils';
import { wrapperStyle } from '../../components/wrapper/styles';

export const Staff = () => {
    const classes = wrapperStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const adminsList = useSelector((state) => state.admins.adminsList);
    const globalDepartments = useSelector((state) => state.system.departments);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const { open } = useModal();

    const getStaffs = () => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.limit = 50;
        filteredInfo.skip = info?.skip ? info?.skip : 0;

        if (info?.department) {
            filteredInfo.departmentIds = info?.department;
            delete filteredInfo.department;
        } else {
            delete filteredInfo.department;
        }

        if (info?.status === 'ALL') {
            delete filteredInfo.status;
        } else if (info?.status) {
            filteredInfo.status = info?.status;
        }

        delete filteredInfo.page;
        delete filteredInfo.activeTab;
        delete filteredInfo.name;
        dispatch(adminActions.getAdmins(filteredInfo));
        return filteredInfo;
    };

    useEffect(() => {
        getStaffs();
    }, [info, selectedDepartment]);

    useEffect(() => {
        dispatch(systemActions.getDepartments());
    }, [dispatch]);

    const handleNavigate = (id) => {
        if (RolePermission([PermissionList.STAFF_READ.code])) {
            history.push(`/staff/${id}`);
        }
    };

    return (
        <>
            <div className={classes.buttonsTabStyleEnd}>
                <div className={classes.alignEnd} />
                <div className="flex-align-center" style={{ gap: '24px' }}>
                    <DownloadCsv type={'staff'} />
                    {RolePermission([PermissionList.STAFF_CREATE.code]) && (
                        <div className={classes.addButton}>
                            <AddButton
                                text={'Add Staff Member'}
                                handleClick={() => open(<CreateStaff afterSuccess={getStaffs} />)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <FullTable
                head={staffHead(globalDepartments)}
                body={staffBody}
                loadingType={ACTION_TYPE}
                list={adminsList?.staffs}
                listCount={adminsList?.count}
                handleClick={handleNavigate}
                noText={'staffs'}
            />
        </>
    );
};