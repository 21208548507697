import {
    GET_FUNDING_SOURCE_BY_ID_SUCCESS,
    GET_FUNDING_SOURCE_SUCCESS,
    GET_FUNDING_SOURCE_SERVICE_BY_ID_SUCCESS,
    GET_FS_DOCUMENT_SUCCESS, GET_FUNDING_MODIFIERS_SUCCESS,
} from "./fundingSource.types";

const initialState = {
    fundingSourceList: [],
    fSelect: [],
    fundingSourceServices: [],
    fsDocuments: null,

    serviceChargeRates: null

};

export const fundingSourceReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Founding Source */
        case GET_FUNDING_SOURCE_SUCCESS:
            return {
                ...state,
                fSelect: action.payload,
                fundingSourceList: action.payload,
            };

        case GET_FUNDING_SOURCE_BY_ID_SUCCESS:
            return {
                ...state,
                fundingSourceItem: action.payload,
            };
        /** End */

        /** Founding Source Service */
        case GET_FUNDING_SOURCE_SERVICE_BY_ID_SUCCESS:
            return {
                ...state,
                fundingSourceServices: action.payload.reverse(),
            };
        /** End */

        case GET_FS_DOCUMENT_SUCCESS:
            return {
                ...state,
                fsDocuments: action.payload,
            };

            case GET_FUNDING_MODIFIERS_SUCCESS:
            return {
                ...state,
                serviceChargeRates: action.payload,
            };

        default:
            return state;
    }
};
