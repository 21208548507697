import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AddModalButton, MinLoader} from "components";
import {appointmentActions, UploadServices} from "store";
import {FindLoad, Images, FindSuccessItem, Colors, DownloadFile} from "utils";
import {CircularProgress} from "@material-ui/core";
import {scheduleCommonStyle} from "./styles";

const SUCCESS_TYPE = 'APPEND_SIGNATURE_TO_APPMT'

export const SignatureUploader = ({handleClose, appt}) => {
    // const {geoPos} = useSelector((state) => ({
    //     geoPos: state.appointment.geoPos,
    // }));
    const classes = scheduleCommonStyle();
    const dispatch = useDispatch()
    const [currentFile, setCurrentFile] = useState(null);
    const [currentShow, setCurrentShow] = useState(null);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [position, setPosition] = useState(true);
    const success = FindSuccessItem(SUCCESS_TYPE)
    const addToLoader = FindLoad(SUCCESS_TYPE)
    const {  loadDownload, handleDownload, } = DownloadFile()

    useEffect(() => {
        if (success) {
            // dispatch(httpRequestsOnSuccessActions.removeSuccess(SUCCESS_TYPE))
            handleClose()
        }
    }, [success])

    const handleFileChange = async (e) => {
        const pos = e?.target?.files[0]?.type.slice(e.target.files[0].type.search('/'));
        setCurrentShow({file: e.target.files[0], type: pos.substring(1)})
        if (e.target.files[0]) {
            let formData = new FormData();
            formData.append('files', e.target.files[0]);
            try {
                setUploadLoader(true)
                const res = await UploadServices.UploadImageThumb(formData);
                const info = {
                    file: {
                        ...res?.data,
                        name: e.target.files[0].name,
                    },
                };
                setCurrentFile(info)
                setUploadLoader(false)
            } catch (e) {
                setUploadLoader(false)
            }
        }
    }

    const handleAddFile = async () => {
        // dispatch(appointmentActions.appendSignatureToAppmt(appt._id, {...currentFile, signatureLocation: {...geoPos}}));
        dispatch(appointmentActions.appendSignatureToAppmt(appt.id, {...currentFile}));
    };

    const handleRemove = () => {
        setCurrentShow(null)
        setCurrentFile(null)
    }

    return (
        <div>
            <div>
                <div className='drag-drop-signature-component'>
                    {position === false ?
                        <div className={classes.warning}>
                            <img src={Images.warning} alt="icon"/>
                            <p>For Upload Signature Please allow access <br/> to your location</p>
                        </div>
                        :
                        <label className='custom-file-upload'>
                            <input
                                onClick={(event) => (event.target.value = null)}
                                onChange={handleFileChange}
                                type='file'
                                id='file'
                            />
                            <i className='fa fa-cloud-upload'/>
                            <span className='upload-signature'>
                            <img src={Images.download} alt={'icon'}/>
                                <p>Upload Signature</p>
                            </span>
                        </label>
                    }
                </div>

                {uploadLoader && currentShow &&
                    <div className={classes.showFileWrapper}>
                        <div className={classes.imageAndText}>
                            <div className={classes.fileToShow}>
                                <div>
                                    <img className={classes.showImage} src={Images.csvIcon} alt={'icon'}/>
                                    <div className={classes.showType}/>
                                </div>
                            </div>
                            <p className={classes.showFileName}>{currentShow?.file?.name}</p>
                        </div>
                        <div>
                            <CircularProgress style={{color: '#347AF0'}}/>
                        </div>
                    </div>
                }

                {!uploadLoader && currentFile &&
                    <div className={classes.showFileWrapper}>
                        <div className={classes.imageAndText}>
                            <div>
                                <button onClick={handleRemove} className={classes.removeButton}>
                                    <img src={Images.removeIcon} alt=""/>
                                </button>
                                <div className={classes.fileToShow}>
                                    <div>
                                        <img className={classes.showImage} src={Images.csvIcon} alt={'icon'}/>
                                        <div className={classes.showType}/>
                                    </div>
                                </div>
                            </div>
                            <p className={classes.showFileName}>{currentShow?.file?.name}</p>
                        </div>
                        <div>
                            <button onClick={() => handleDownload(currentFile?.file)} className={classes.downloadButton}>
                                {loadDownload ?
                                    <MinLoader margin={"0"} color={Colors.ThemeBlue} position={'relative'}/>
                                    :
                                    <img src={Images.bigDownload} alt={'download'}/>
                                }
                            </button>
                        </div>
                    </div>
                }
            </div>


            {currentFile &&
                <AddModalButton
                    handleClick={handleAddFile}
                    loader={!!addToLoader.length}
                    text="Done"
                />
            }
        </div>
    )
}