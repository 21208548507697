import React, { useEffect } from 'react';
import { Router } from 'root/router';
import { useDispatch, useSelector } from 'react-redux';
import { TopBar, LeftBar } from './core';
import { navBarStyles } from './core/style';
import { authActions, permissionsActions, systemActions } from 'store';

export const MenuBar = ({}) => {
    const { accessToken } = useSelector((state) => ({
        accessToken: state.auth.accessToken,
    }));
    const classes = navBarStyles();
    const dispatch = useDispatch();
    const token = accessToken ? accessToken : localStorage.getItem('access-token');

    useEffect(() => {
        if(token) {
            dispatch(authActions.getMyProfile());
            dispatch(systemActions.getCompany());
            dispatch(permissionsActions.getMyPermissions());
        }
    }, [dispatch, token]);

    return (
        <div className={classes.root}>

            <TopBar />
            <LeftBar />

            <main style={{ height: '100%', minHeight: '100vh' }} className={classes.content}>
                <Router />
            </main>
        </div>
    );
};
