import {GET_ACTIVE_PAYROLL_SUCCESS, GET_PROCESSED_PAYROLL_SUCCESS} from "./staffPayroll.types";

const initialState = {
    activePayroll: null,
    processedPayroll: null
};

export const staffPayrollReducer =  (state = initialState, action) => {
    switch (action.type) {

        /** Active Payroll */

        case  GET_ACTIVE_PAYROLL_SUCCESS:
            return {
                ...state,
                activePayroll: action?.payload
            };

        /** End */


        /** Processed Payroll */

        case  GET_PROCESSED_PAYROLL_SUCCESS:
            return {
                ...state,
                processedPayroll: action?.payload
            };

        /** End */

        default:
            return state;
    }
};
