import { fork } from "redux-saga/effects";
import { watchAuth } from "../auth";
import { watchPermission } from "../permissions";
import { watchRole } from "../role";
import { watchAdmin } from "../admin";
import { watchFundingSource } from "../fundingSource";
import { watchClient } from "../client";
import { watchSystem } from "../system";
import { watchNotes } from "../notes";
import { watchPayroll } from "../payroll";
import { watchAvailabilitySchedule } from "../availabilitySchedule";
import { watchAppointments } from "../appointment";
import { watchClaim, watchInvoice } from "../billing";
import { watchClaimPayment, watchInvoicePayment } from "../posting";
import { watchHistory } from "../history";
import {watchReports} from "../reports";
import {watchStaffPayroll} from "../staffPayroll";

/** Combined Sagas */
export const appSaga = function* startForman() {
   yield fork(watchAuth);
   yield fork(watchAdmin);
   yield fork(watchRole);
   yield fork(watchPermission);
   yield fork(watchFundingSource);
   yield fork(watchClient);
   yield fork(watchSystem);
   yield fork(watchNotes);
   yield fork(watchPayroll);
   yield fork(watchAvailabilitySchedule);
   yield fork(watchAppointments);
   yield fork(watchClaim);
   yield fork(watchInvoice);
   yield fork(watchClaimPayment);
   yield fork(watchInvoicePayment);
   yield fork(watchHistory);
   yield fork(watchReports);
   yield fork(watchStaffPayroll);
};
