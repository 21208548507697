import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserTextArea, CreateChancel } from 'components';
import { ErrorText, FindLoad, FindSuccess, isNotEmpty } from 'utils';
import { claimActions, httpRequestsOnSuccessActions } from 'store';
import { claimDetailsCoreStyle } from './styles';

const ACTION_TYPE ='CLOSE_CLAIM'

export const CloseClaimInputs = ({ closeModal, claimId }) => {
    const classes = claimDetailsCoreStyle();
    const dispatch = useDispatch();
    const closingClaimLoader = FindLoad(ACTION_TYPE);
    const closingClaimSuccess = FindSuccess(ACTION_TYPE);
   const [closingComment, setClosingComment] = useState('');
   const [error, setError] = useState('');

    useEffect(() => {
        if (!!closingClaimSuccess.length) {
            closeModal();
            httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE);
        }
    }, [closingClaimSuccess]);

    function handleChange(event) {
        setClosingComment(event.target.value);
        !!error && setError('');
    }

    function handleSubmit() {
        if (isNotEmpty(closingComment)) {
            dispatch(claimActions.closeClaim(claimId, closingComment));
        } else {
            setError('closingComment');
        }
    }

    return (
        <div>
            <UserTextArea
                margin={'0'}
                className={classes.commentTextAreaStyle}
                id={'closingComment'}
                name={'closingComment'}
                label={'Add your comment here ...*'}
                value={closingComment}
                onChange={handleChange}
                hasText={!!closingComment}
                typeError={error === 'closingComment' && `Comment ${ErrorText.isRequired}`}
            />
            <CreateChancel
                butnClassName={classes.closeOrCancelButnStyle}
                loader={!!closingClaimLoader.length}
                create={'Close'}
                chancel={'Cancel'}
                onCreate={handleSubmit}
                onClose={closeModal}
            />
        </div>
    );
};
