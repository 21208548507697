import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receivableBillTableStyle } from './styles';
import { claimActions } from 'store';
import { AppointmentsTable } from './common';
import { FullTable, ModalFullTable, TableNoInfo } from 'components';
import {
    billTotalBody,
    billTotalHead,
    claimPaymentTableBody,
    claimPaymentTableHead,
    invoicePaymentTableBody,
    invoicePaymentTableHead,
} from './constants';

const activeButtonStyle = {
    fontWeight: 700,
    borderBottom: '4px solid #FFCA33',
};

export const ReceivableBillTable = ({ billById, activeTab }) => {
    const { billClaimPmt, invoiceClaimPmt } = useSelector((state) => ({
        billClaimPmt: state.claim.billClaimPmt,
        invoiceClaimPmt: state.claim.invoiceClaimPmt,
    }));
    const classes = receivableBillTableStyle();
    const [activeButton, setActiveButton] = useState(activeTab ? activeTab : 'claim');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(claimActions.bilClaimPmtTr(billById?._id));
        dispatch(claimActions.bilInvoicePmt(billById?._id));
    }, []);


    const handleChange = (type) => {
        setActiveButton(type);
    };

    return (
        <div className={classes.recBillTableStyle}>
            <div className={classes.recBillContainerStyle}>
                <h6 className={classes.recBillTitleStyle}>Bill Details</h6>
                <div>
                    <FullTable
                        head={billTotalHead}
                        body={billTotalBody}
                        list={[{
                            billRate: billById?.billRate,
                            billedAmount: billById?.billedAmount,
                            units: billById?.units,
                            fsTotal: billById?.fsTotal,
                            fsPaid: billById?.fsPaid,
                            fsBalance: billById?.fsBalance,
                            clientTotal: billById?.clientTotal,
                            clientPaid: billById?.clientPaid,
                            clientBalance: billById?.clientBalance,
                        }]}
                        noText={'receivables'}
                        generateTable={true}
                        height={'auto'}
                    />
                </div>

                <div style={{marginTop: -100, position:'relative'}}>
                    <p className={classes.billTotals}>Other Details</p>
                    <div className={classes.buttonsTabWrapper}>
                        <button onClick={() => handleChange('claim')}
                                style={activeButton === 'claim' ? { ...activeButtonStyle } : {}}>Claim Payment
                            Transaction
                        </button>
                        <button onClick={() => handleChange('invoice')}
                                style={activeButton === 'invoice' ? { ...activeButtonStyle } : {}}>Invoice Payment
                            Transaction
                        </button>
                        <button onClick={() => handleChange('appointments')}
                                style={activeButton === 'appointments' ? { ...activeButtonStyle } : {}}>Appointments
                        </button>
                    </div>


                    {activeButton === 'claim' &&
                        <>
                            {billClaimPmt?.txns?.length ?
                                <ModalFullTable
                                    head={claimPaymentTableHead}
                                    body={claimPaymentTableBody}
                                    loadingType={'GET_BILL_CLAIM_PAYMENT_TRANSACTIONS'}
                                    list={billClaimPmt?.txns}
                                    noText={'claim payment transactions'}
                                    activeRowId={''}
                                />
                                :
                                <TableNoInfo text={`No claim payment transaction yet`} flexCenter={true} styles={{ height: '300px' }} />
                            }
                        </>
                    }

                    {activeButton === 'invoice' &&
                        <>
                            {invoiceClaimPmt?.txns?.length ?
                                <ModalFullTable
                                    head={invoicePaymentTableHead}
                                    body={invoicePaymentTableBody}
                                    list={invoiceClaimPmt?.txns}
                                    loadingType={'GET_BILL_INVOICE_PAYMENT'}
                                    noText={'invoice payment transaction'}
                                    activeRowId={''}
                                />
                                :
                                <TableNoInfo text={`No invoice payment transaction yet`} flexCenter={true}
                                             styles={{ height: '300px' }} />
                            }
                        </>
                    }

                    {activeButton === 'appointments' &&
                        <>
                            {billById?.appointments?.length ?
                                <AppointmentsTable appts={billById?.appointments} />
                                :
                                <TableNoInfo text={`No appointments yet`} flexCenter={true}
                                             styles={{ height: '300px' }} />
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};
