import { IdRow, DateRowStartEnd, TextRow, TypeRow, PriceRow, DisplayIdRow, LinkRow, SimpleTooltip } from 'components';
import { Checkbox } from '@material-ui/core';
import { PermissionList, RolePermission } from '../../../utils';
import React from 'react';

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export const claimHead = (handleSelect, fullList, selected) => {
    return [
        {
            renderView: <SimpleTooltip
                    title={<p>Select All</p>}
                    placement="top-start"
                >
                <Checkbox
                    {...label}
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={fullList?.length === selected?.length}
                />
                </SimpleTooltip>,
        },
        { name: 'displayId', title: 'ID', custom: false, width: '120px' },
        { name: 'dateRange', title: 'DOS', icon: 'date', disabled: true, custom: false},
        { name: 'funder', rowName:'name',  title: 'Funding Source' },
        { name: 'client', rowName:'firstName',  title: 'Client' },
        { name: 'totalAmount', title: 'Total Charged', custom: false, },
        { name: 'paidAmount', title: 'Total Paid', custom: false,},
        { name: 'remaining', title: 'Remaining', custom: false, },
    ];
};

export const claimBody = (handleSelect, fullList, selected) => {
    return [
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                <Checkbox
                    {...label}
                    onClick={(e) => handleSelect(e, item)}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={selected?.includes(item?.id)}
                />
                </SimpleTooltip>
            ),   notClickable: true,
        },
        { rowText: (item) => <DisplayIdRow id={item?.displayId || item?.id} size={7} /> },
        { rowText: (item) => <DateRowStartEnd start={item?.dateRange?.early} end={item?.dateRange?.latest} /> },
        { rowText: (item) => <TextRow name={item?.funder?.name} /> },
        { rowText: (item) => <LinkRow
                name={item?.client ?
                    `${item?.client?.firstName} ${item?.client?.lastName ? item?.client?.lastName : ''} ` :
                    'N/A'
                }
                href={
                    RolePermission([PermissionList.CLIENT_READ?.code]) ?
                        `/client/${item?.client?.id || item?.client?._id}` : ''}
                checkPermission={item?.client ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                textWidth={8}
            />
        },
        { rowText: (item) => <PriceRow info={item?.totalAmount ? item?.totalAmount : 0} /> },
        { rowText: (item) => <PriceRow info={item?.paidAmount ? item?.paidAmount : 0} /> },
        { rowText: (item) => <PriceRow info={item?.remaining ? item?.remaining : 0} /> },
    ];
};

export const ACTION_TYPE = 'GET_CLAIMS';