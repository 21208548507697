import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { CreateChancel, Input, SelectAutocompleteMultiple, MaskInputCustom, CheckboxCustom } from 'components';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import {
    FindLoad,
    FindErrorItem,
    enumValues,
    states,
    FindSuccessItem,
    useModal,
    languagesById,
    RolePermission, PermissionList,
} from 'utils';
import { residencyEnumTypes } from './constants';
import { staffCoreCommonStyle } from './style';
import { DateInput } from '../../../../components/form/inputs/dateInputForm';

export const StaffDetails = ({ staffGeneral }) => {
    const ACTION_TYPE = 'CREATE_ADMIN_DETAILS';
    const { close } = useModal();
    const classes = staffCoreCommonStyle();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const {
        handleSubmit,
        control,
        clearErrors,
        reset,
        watch,
        setError,
    } = useForm({
        defaultValues: {
            license: {
                driverLicense: '',
                expireDate: '',
                state: '',
            },
            gender: '',
            birthday: '',
            residency: '',
            ssn: '',
            DOJ: {
                type: 'DOJ',
                startDate: '',
                expireDate: '',
                checked: false,
            },

            TB: {
                type: 'TB',
                startDate: '',
                expireDate: '',
                checked: false,
            },

            languages: [],
            npi: '',
        },
    });

    const checkLicense = watch('license.driverLicense') || watch('license?.state') || watch('license?.driverLicense');

    useEffect(() => {
        if (backError?.error?.length) {
            if (backError?.error[0] === 'Social security number is incorrect') {
                setError('ssn', {
                    message: 'Social security number is incorrect.',
                });
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            }
        }
    }, [backError]);

    useEffect(() => {
        if (staffGeneral?.id) {
            const params = {};
            const getDOJ = staffGeneral?.credentials?.find((i) => i?.type === 'DOJ');
            const getTB = staffGeneral?.credentials?.find((i) => i?.type === 'TB');
            if (staffGeneral?.license?.expireDate) {

                params.license = {
                    ...staffGeneral?.license,
                    state: states.find(({ id }) => id === staffGeneral?.license?.state),
                    expireDate: moment.utc(staffGeneral?.license?.expireDate).format().substring(0, 10),
                };
            }

            if (getDOJ?.checked) {
                params.DOJ = {
                    ...staffGeneral?.credentials?.find((i) => i?.type === 'DOJ'),
                    startDate: moment.utc(getDOJ?.startDate).format()?.substring(0, 10),
                };
            }

            if (getTB?.checked) {
                params.TB = {
                    ...staffGeneral?.credentials?.find((i) => i?.type === 'TB'),
                    startDate: moment.utc(getTB?.startDate).format()?.substring(0, 10),
                    expireDate: moment.utc(getTB?.expireDate).format()?.substring(0, 10),
                };
            }

            if (staffGeneral?.npi) params.npi = staffGeneral?.npi;
            if (staffGeneral?.ssn) params.ssn = staffGeneral?.ssn;
            if (staffGeneral?.residency) params.residency = staffGeneral?.residency;
            if (staffGeneral?.gender) params.gender = staffGeneral?.gender;
            if (staffGeneral?.birthday) params.birthday = moment.utc(staffGeneral?.birthday).format().substring(0, 10);
            if (staffGeneral?.languages) params.languages = staffGeneral?.languages?.map((i) => ({ id: i, label: i }));

            reset(params);
        }
    }, [staffGeneral]);

    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    function handleCreate(data) {
        const sendData = {};
        const checkLicense = (data?.license?.driverLicense && data?.license?.state && data?.license?.expireDate);

        if (checkLicense) {
            sendData.license = {
                driverLicense: data?.license?.driverLicense,
                expireDate: data?.license?.expireDate && new Date(data?.license?.expireDate).toISOString(),
                state: data?.license?.state?.id ? data?.license?.state?.id : data?.license?.state
            };
        }
        if (data?.npi) sendData.npi = data.npi;
        if (data?.ssn) sendData.ssn = data.ssn;
        if (data?.gender) sendData.gender = data.gender;
        if (data?.birthday) sendData.birthday = data?.birthday && new Date(data?.birthday)?.toISOString();
        if (data?.residency) sendData.residency = data.residency;
        if (data?.languages.length) sendData.languages = data?.languages?.map((i) => i?.id);

        if (data?.DOJ?.checked || data?.TB?.checked) {
            sendData.credentials = [];
            if (data?.DOJ?.checked) {
                sendData.credentials.push({
                    type: 'DOJ',
                    checked: true,
                    startDate: data?.DOJ?.startDate && new Date(data?.DOJ?.startDate)?.toISOString(),
                });
            }
            if (data?.TB?.checked) {
                sendData.credentials.push({
                    type: 'TB',
                    checked: true,
                    startDate: data?.TB?.startDate && new Date(data?.TB?.startDate)?.toISOString(),
                    expireDate: data?.TB?.expireDate && new Date(data?.TB?.expireDate)?.toISOString(),
                });
            }
        }
        dispatch(adminActions.createAdminDetails(staffGeneral?.id, sendData));
    }

    return (
        <form onSubmit={handleSubmit(handleCreate)} style={{ width: 470 }}>
            <p className="modal-header-title">
                {staffGeneral?.residency ? 'Edit Other Details' : 'Add Other Details'}
            </p>
            <div className="modal-body-wrapper">
                <p className={classes.otherDetailsTitle}>Driver’s License</p>
                <Input
                    name="license.driverLicense"
                    label={checkLicense ? 'License Number*' : 'License Number'}
                    variant="outlined"
                    control={control}
                    maxLength={20}
                    rules={{
                        maxLength: {
                            value: 20,
                            message: 'Max length is 20 digits.',
                        },
                        required: {
                            value: checkLicense,
                            message: 'Driver license is required.',
                        },
                    }}
                />
                <div className={classes.flexContainer} style={{ gap: '16px' }}>
                    <SelectAutocompleteMultiple
                        name="license.state"
                        label={checkLicense ? 'Issuing State*' : 'Issuing State'}
                        control={control}
                        options={states}
                        rules={{
                            required: {
                                value: checkLicense,
                                message: 'Issuing state is required.',
                            },
                        }}
                        renderValue={(option) => option?.name}
                    />
                    {/*<Input*/}
                    {/*    name="license.expireDate"*/}
                    {/*    label={checkLicense ? 'Expiration Date*' : 'Expiration Date'}*/}
                    {/*    type="date"*/}
                    {/*    // min={moment.utc().format('YYYY-MM-DD')}*/}
                    {/*    variant="outlined"*/}
                    {/*    control={control}*/}
                    {/*    rules={{*/}
                    {/*        required: {*/}
                    {/*            value: checkLicense,*/}
                    {/*            message: 'Expiration date is required.',*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <DateInput
                        value={watch('license.expireDate')}
                        name="license.expireDate"
                        label={checkLicense ? 'Expiration Date*' : 'Expiration Date'}
                        control={control}
                        rules={{
                            required: {
                                value: checkLicense,
                                message: 'Expiration date is required.',
                            },
                        }}
                    />
                </div>
                <p className={`${classes.otherDetailsTitle} ${classes.titlePadding}`}>Other</p>
                <Input
                    name="npi"
                    label={'NPI Number'}
                    variant="outlined"
                    control={control}
                    maxLength={20}
                    rules={{
                        maxLength: {
                            value: 20,
                            message: 'Max length is 20 digits.',
                        },
                    }}
                />
                <SelectAutocompleteMultiple
                    name="residency"
                    label={'Residency Status'}
                    options={residencyEnumTypes}
                    control={control}
                    renderValue={(option) => option?.label}
                />
                {RolePermission([PermissionList.STAFF_SSN_VIEW?.code]) &&
                    <MaskInputCustom
                        name="ssn"
                        label="SSN Number"
                        mask={'999-99-9999'}
                        variant="outlined"
                        control={control}
                        originalValue={true}
                    />
                }
                <div className={classes.flexContainer} style={{ gap: '16px' }}>
                    <SelectAutocompleteMultiple
                        options={enumValues.GENDER_OPTIONS}
                        label={'Gender'}
                        name="gender"
                        control={control}
                        renderValue={(option) => option?.label}
                    />
                    <DateInput
                        name="birthday"
                        label={'Date of Birth'}
                        control={control}
                        max={moment.utc().format('YYYY-MM-DD')}
                        value={watch('birthday')}
                    />
                    {/*<Input*/}
                    {/*    name="birthday"*/}
                    {/*    label={'Date of Birth'}*/}
                    {/*    type="date"*/}
                    {/*    max={moment.utc().format('YYYY-MM-DD')}*/}
                    {/*    variant="outlined"*/}
                    {/*    control={control}*/}
                    {/*/>*/}
                </div>
                <SelectAutocompleteMultiple
                    options={languagesById || []}
                    multiple={true}
                    name="languages"
                    label={'Languages Spoken'}
                    selectedType={'id'}
                    control={control}
                    renderValue={(option) => option?.label}
                />
                <p className={`${classes.otherDetailsTitle} ${classes.titlePadding}`}>Credentials</p>
                <div className={classes.flexAble} style={{ gap: '16px', alignItems: 'center' }}>
                    <CheckboxCustom
                        name="DOJ.checked"
                        label="DOJ"
                        control={control}
                        wrapperStyles={{ marginBottom: '18px', minWidth: '71px' }}
                    />
                    <DateInput
                        name="DOJ.startDate"
                        label={watch('DOJ')?.checked ? 'Select the Date*' : 'Select the Date'}
                        control={control}
                        disabled={!watch('DOJ.checked')}
                        value={watch('DOJ.startDate')}
                        rules={{
                            required: {
                                value: watch('DOJ.checked'),
                                message: 'DOJ date is required.',
                            },
                        }}
                        />
                    {/*<Input*/}
                    {/*    name="DOJ.startDate"*/}
                    {/*    label={watch('DOJ')?.checked ? 'Select the Date*' : 'Select the Date'}*/}
                    {/*    type="date"*/}
                    {/*    // min={moment.utc().format('YYYY-MM-DD')}*/}
                    {/*    variant="outlined"*/}
                    {/*    control={control}*/}
                    {/*    disabled={!watch('DOJ.checked')}*/}
                    {/*    rules={{*/}
                    {/*        required: {*/}
                    {/*            value: watch('DOJ.checked'),*/}
                    {/*            message: 'DOJ date is required.',*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
                <div className={classes.flexAble} style={{ gap: '16px', alignItems: 'center' }}>
                    <CheckboxCustom
                        name="TB.checked"
                        label="TB"
                        control={control}
                        wrapperStyles={{ marginBottom: '18px', minWidth: '71px' }}
                    />
                    <DateInput
                        name="TB.startDate"
                        label={watch('TB.checked') ? 'Start Date*' : 'Start Date'}
                        control={control}
                        disabled={!watch('TB.checked')}
                        value={watch('TB.startDate')}
                        max={watch('TB.expireDate') ? moment.utc(watch('TB.expireDate')).format('YYYY-MM-DD') : ''}
                        rules={{
                            required: {
                                value: watch('TB.checked'),
                                message: 'TB start date is required.',
                            },
                        }}
                    />
                    <DateInput
                        name="TB.expireDate"
                        label={watch('TB.checked') ? 'Exp.Date*' : 'Exp.Date'}
                        min={watch('TB.startDate') ? moment.utc(watch('TB.startDate')).format('YYYY-MM-DD') : ''}
                        disabled={!watch('TB')?.checked}
                        value={watch('TB.expireDate')}
                        control={control}
                        rules={{
                            required: {
                                value: watch('TB.checked'),
                                message: 'TB end date is required.',
                            },
                        }}
                    />
                    {/*<Input*/}
                    {/*    name="TB.startDate"*/}
                    {/*    label={watch('TB.checked') ? 'Start Date*' : 'Start Date'}*/}
                    {/*    type="date"*/}
                    {/*    max={watch('TB.expireDate') ? moment.utc(watch('TB.expireDate')).format('YYYY-MM-DD') : ''}*/}
                    {/*    // min={moment.utc().format('YYYY-MM-DD')}*/}
                    {/*    variant="outlined"*/}
                    {/*    disabled={!watch('TB.checked')}*/}
                    {/*    control={control}*/}
                    {/*    rules={{*/}
                    {/*        required: {*/}
                    {/*            value: watch('TB.checked'),*/}
                    {/*            message: 'TB start date is required.',*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<div className={classes.inputBox}>*/}
                    {/*    <Input*/}
                    {/*        name="TB.expireDate"*/}
                    {/*        label={watch('TB.checked') ? 'Exp.Date*' : 'Exp.Date'}*/}
                    {/*        type="date"*/}
                    {/*        min={watch('TB.startDate') ? moment.utc(watch('TB.startDate')).format('YYYY-MM-DD') : ''}*/}
                    {/*        variant="outlined"*/}
                    {/*        disabled={!watch('TB')?.checked}*/}
                    {/*        control={control}*/}
                    {/*        rules={{*/}
                    {/*            required: {*/}
                    {/*                value: watch('TB.checked'),*/}
                    {/*                message: 'TB end date is required.',*/}
                    {/*            },*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>
            <CreateChancel
                type="submit"
                loader={!!loader.length}
                buttonWidth="100%"
                create={'Save'}
                chancel={'Cancel'}
                onClose={close}
            />
        </form>
    );
};
