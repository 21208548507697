import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors, Images } from 'utils';

export const serviceSingleStyles = makeStyles(() => ({
    noteTableStyle: {},

    staffGeneralWrapper: {
        display: 'flex',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            marginLeft: 15,
        },
    },
    dateInput: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: 38,
            width: 200,
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },

    avatar: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        position: 'relative',
        border: `1px solid ${Colors.BackgroundBlue}`,
        objectFit: 'cover',
        padding: 3,
    },
    nameContent: {
        marginLeft: 19,
    },
    name: {
        fontSize: 18,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        lineHeight: '25px',
        marginBottom: 8,
    },
    tagContent: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: 12,
            color: Colors.TextSecondary,
            fontWeight: 600,
            backgroundColor: Colors.BackgroundWater,
            padding: '0 16px',
            lineHeight: '25px',
            borderRadius: 13,
        },
        '& p:not(:last-child)': {
            marginRight: 8,
        },
    },
    select: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
            marginTop: 10,
            marginRight: 30,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
    },

    deleteModal: {
        width: '500px',
        height: 'auto',
        background: Backgrounds.whiteModal,
        borderRadius: '8px',
        padding: '8px 0 40px 0',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    clearBoth: {
        width: 24,
    },
    tableName: {
        textOverflow: 'ellipsis',
        width: 150,
        overflow: 'hidden',
    },
    sircule: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginRight: '15px',
            fontSize: '14px',
        },
    },
    tableID: {
        textOverflow: 'ellipsis',
        width: 60,
        overflow: 'hidden',
    },
    loadStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    iconDeleteStyle: {
        cursor: 'pointer',
        marginLeft: 16,
    },
    notesWrap: {
        marginTop: -24,
        width: '100%',

    },
    headerRight: {
        display: 'flex',
    },
    inputTextField: {
        alignItems: 'flex-end',
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
    },
    radio: {
        color: '#D263E4',
        '&:hover': {
            backgroundColor: 'white',
        },
        '&$checked': {
            color: '#D263E4',
            '&:hover': {
                backgroundColor: 'white',
            },
        },
    },
    radioInputLabel: {
        fontSize: 16,
        color: Colors.TextSecondary,
    },
    checked: {
        color: 'green',
    },
    authorizationServices: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '34px',
    },
    authorizationServicesTitle: {
        fontSize: 18,
        color: Colors.TextPrimary,
        fontWeight: 'bold',
    },
    modifiers: {
        fontSize: '14px',
        color: '#4B5C68',
    },
    contentStyle: {
        fontSize: 16,
        color: Colors.TextSecondary,
        fontWeight: 'regular',
    },
    contentIconStyle: {
        fontSize: 16,
        color: Colors.BackgroundBlue,
        fontWeight: 'bold',
        '&.starIcon': { color: `${Colors.ThemeRed}!important` },
    },
    addAuthFilesButnStyle: { marginTop: '16px' },
    infoTextForAuthStyle: {
        display: 'flex',
        '&::before': {
            content: '\'\'',
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundImage: `url(${Images.infoWhiteIcon})`,
            backgroundRepeat: 'no-repeat',
            BackgroundSize: 'contain',
            backgroundPosition: 'center',
            marginRight: '8px',
        },
    },
    authServiceContainerStyle: {
        width: '100%',
        padding: '24px 0 0',
    },
    pastTitle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#4B5C68',
        margin: '24px 0 16px',
    },

    spaceBetween: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        // alignItems:'center'
    },
    addButton: {
        background: 'transparent',
        border: 'none',
        height: '40px',
    },
    accordionItem: {
        height: '310px',
        overflow: 'auto',
        borderRadius: '8px',

        '& .MuiAccordion-root': {
            margin: '0 0 8px 0',
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: '0 0 8px 0',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            borderRadius: '8px 8px 0 0!important',
        },
        '& .MuiAccordionSummary-root': {
            background: '#F2F4F8',
            borderRadius: '8px',
            height: '48px',
            minHeight: '48px',
            maxHeight: '48px',
        },
        '& .MuiCollapse-entered': {
            borderRadius: '0px 0px 4px 4px',
            boxShadow: '0px 0px 6px rgba(138, 138, 138, 0.24)',
        },
        '& .MuiAccordion-root:before': {
            background: 'transparent',
        },
    },


    titleAndActions: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        background: '#EBF2FD',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '8px',
        '& button': {
            border: 'none',
            background: 'transparent',
        },

    },
    boldText: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B5C68',
        width: '100%',
        marginBottom: '4px',
    },
    regularText: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: 'rgba(75, 92, 104, 0.5)',
        width: '100%',
    },
    titleAndSubTitle: {
        marginBottom: '16px',
        '& p': {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#A3B2BD',
            marginBottom: '4px',
        },
        '& span': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#4B5C68',
            marginBottom: '4px',
        },
    },
    buttonsWrapper: {
        width: 'auto',
        minWidth: '73px',
        '& button': {
            width: '35px',
        },
    },
    default: {
        margin: '8px 0 16px',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#347AF0',
    },
    otherAddresses: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#4B5C68',
        margin: '24px 0 16px',
    },
    modalContentWrapperWrapper: {
        width: '520px',
    },
    flexAble: {
        display: 'flex',
        alignItems: 'center',
    },
    flexContainer: { display: 'flex' },
    credentialInputStyle: {
        maxWidth: '387px',
        minWidth: '161px',
        width: '100%',
        marginRight: 16,
        '& .MuiOutlinedInput-root': {
            height: '36px!important',
            color: Colors.TextPrimary,
            fontSize: 14,
        },
    },
    staffRole: {
        maxWidth: '300px',
        minWidth: '300px',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            height: '36px!important',
            color: Colors.TextPrimary,
            fontSize: 14,
        },
    },
    codInputStyle: {
        minWidth: '150px',
        width: '100%',
        marginRight: 16,
        '& .MuiOutlinedInput-root': {
            height: '36px!important',
            color: Colors.TextPrimary,
            fontSize: 14,
        },
    },
    title: {
        fontSize: 18,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: '-6px',
    },

    smallSelect: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiAutocomplete-input ':{
            padding: '9.5px 14px !important'
        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
            width: '350px',
            padding: 0,
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },
    smallSelectError: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeRed,
        },
        '& .MuiAutocomplete-input ':{
            padding: '9.5px 14px !important'
        },
        '& .MuiOutlinedInput-root': {
            height: '36px',
            width: '350px',
            padding: 0,
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeRed,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },
    insuredTitle: {
        color: '#4B5C68',
        fontSize: '16px',
        fontWeight: '600',
    },
    rowTitle: {
        color: '#4B5C68',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '24px',
    },
    statusWrapper: {
        padding: '15px 18px 15px 16px',
        borderRadius: '8px',
        background: '#F2F4F8',
    },
}));

export const editButtonStyle = {
    height: 36,
    paddingInline: 24,
};
export const inputStyle = {
    marginRight: 16,
    height: 36,
    width: 164,
};
