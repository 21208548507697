import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { LinearProgress } from '@material-ui/core';
import { Images, FindSuccess, FindLoad } from 'utils';
import { ErrMessage, MinLoader } from 'components';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, UploadServices } from 'store';
import { myProfileFragment } from './style';

const ACTION_TYPE = 'UPLOAD_STAFF_AVATAR';
export const UploadAvatar = ({}) => {
    const classes = myProfileFragment();
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const successType = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const user = useSelector((state) => state.auth.userInfo);
    const [uploadedFIle, setUploadedFile] = useState(null);
    const [uploadLoader, setUploadLoader] = useState(false);

    useEffect(() => {
        if (!!successType.length) {
            setShowInputs(false);
            setUploadedFile(null);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [successType]);

    const handleChancel = () => {
        setShowInputs(false);
        setError('');
        setUploadedFile(null);
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    };

    const handleUpload = async (e, k) => {
        error === 'currentFile' && setError('');
        if (e) {
            let formData = new FormData();
            formData.append('files', e);
            try {
                setUploadLoader(true);
                const res = await UploadServices.UploadImage(formData);
                const pos = e.type.slice(e.type.search('/'));
                const fileType = pos.substring(1);

                const info = {
                    file: {
                        ...res?.data,
                        format: fileType,
                    },
                };
                setUploadedFile(info);
                setUploadLoader(false);
            } catch (e) {
                setUploadLoader(false);
            }
        }
    };

    const handleSaveAvatar = () => {
        if(user?.avatar?.url !== uploadedFIle?.file?.url) {
            dispatch(adminActions.uploadAvatar(user.id, uploadedFIle));
        }
    };

    const handleRemove = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        setUploadedFile(null);
    };

    return (
        <div className={classes.infoBoxWrapper} style={{ marginBottom: 8 }}>
            <div className={classes.changePasswordContent}>
                <div>
                    <p className={classes.infoBoxTitle}> Upload Profile Picture </p>
                    {showInputs && <p className={classes.infoBoxDesc}>This will be displayed on your profile.</p>}
                </div>

                {!showInputs &&
                    <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowInputs(true);
                        setUploadedFile( user?.avatar ? { file:user?.avatar } : null);
                    }}>
                        <img className={classes.editBlack} src={Images.edit} alt="edit" />
                    </div>
                }
            </div>
            {showInputs && (
                <div>
                    <div style={{ marginTop: '24px', position: 'relative' }}>

                        {uploadedFIle?.file?.url && !uploadLoader &&
                            <button className={classes.deleteAvatarBtn} onClick={handleRemove}>
                                <img src={Images.closeButton} alt="delete" />
                            </button>
                        }

                        <FileUploader handleChange={handleUpload} name="file">
                            <div style={{
                                border: '2px dashed #E4E7EC',
                                background: 'rgba(235, 242, 253, 0.15)',
                                boxShadow: 'none',
                            }} className="drag-drop-component">
                                {uploadLoader ?
                                    <div className={classes.avatarLoader}>
                                        <LinearProgress style={{ background: '#F2F4F7' }} />
                                        <p>Image Uploading </p>
                                    </div>
                                    :
                                    <>
                                        {uploadedFIle?.file?.url ?
                                            <div className={classes.staffAvatar} style={{ marginBottom: 12 }}>
                                                <img src={uploadedFIle?.file?.url} alt="icon" style={{ marginBottom: 0 }} />
                                            </div>
                                            :
                                            <img src={Images.uploadAvatar} alt="icon" style={{ marginBottom: 12 }} />
                                        }
                                        <label className="custom-file-upload">
                                            <input
                                                onClick={(event) => (event.target.value = null)}
                                                onChange={handleUpload}
                                                type="file"
                                                id="file"
                                            />
                                            <i className="fa fa-cloud-upload" />
                                            <span className="upload">
                                        <p><span style={{ color: '#438AFE', fontWeight: 600 }}>Click to upload </span> or drag and drop </p>
                                        <p style={{ fontSize: '12px' }}>JPEG, PNG or JPG (max size 2MB)</p>
                            </span>
                                        </label>
                                    </>
                                }
                            </div>
                        </FileUploader>
                        <ErrMessage text={error === 'currentFile' ? 'Please select file' : ''} />
                    </div>

                    <div className={classes.actionsBox} style={{ marginTop: '12px' }}>
                        <button
                            type={'button'}
                            className={classes.cancelButton}
                            onClick={handleChancel}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={uploadLoader}
                            type={'button'}
                            className={classes.saveButton} onClick={handleSaveAvatar}>
                            {!!loader.length ? (
                                <MinLoader margin={'0'} color={'white'} position={'relative'} />
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
