import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions } from 'store';
import {
    DateRow,
    handleSetName,
    Loader,
    PriceRow,
    TableNoInfo,
    TextRow,
    SearchAndFilerFull, PaginationFillTable, DownloadLink, LinkRow, NoItemsYet,
} from 'components';
import { filterFromTable, FindLoad, Images, PermissionList, RolePermission, SendPageSave, useModal } from 'utils';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ClaimFilters } from './claimFilters';
import { FormType1500 } from './formType1500';

const ACTION_TYPE = 'GET_SUBMITTED_CLAIMS';
export const SubmittedClaims = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const submittedClaimsData = useSelector((state) => state.claim.submittedClaims);
    const loader = FindLoad(ACTION_TYPE);
    const [expanded, setExpanded] = useState('');
    const [listInfo, setListInfo] = useState(submittedClaimsData?.batches);
    const [searched, setSearched] = useState({ name: '', text: '' });
    const { open } = useModal();

    useEffect(() => {
        if (submittedClaimsData?.batches) {
            setListInfo(submittedClaimsData?.batches);
        }
    }, [submittedClaimsData]);

    const renderParams = () => {
        const sendInfo = {
            ...info,
        };
        delete sendInfo.page;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.limit = 50;
        if (info?.firstDate) {
            sendInfo.from = info?.firstDate;
            delete sendInfo.firstDate;
        } else {
            delete sendInfo.from;
        }
        if (info?.lastDate) {
            sendInfo.to = info?.lastDate;
            delete sendInfo.lastDate;
        } else {
            delete sendInfo.to;
        }
        return sendInfo;
    };

    useEffect(() => {
        dispatch(claimActions.getSubmittedClaims({ ...renderParams() }));
    }, [info]);

    const handleChange = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const currentId = expanded === id ? '' : id;
        setExpanded(currentId);
    };

    const changePage = (number) => {
        SendPageSave(number, info, history);
    };

    const changeSearch = (e, nameRow) => {
        setSearched({
            name: e.target.name,
            value: e.target.value,
        });
        if (e.target.name) {
            if (!e.target.value) {
                setListInfo(submittedClaimsData?.batches);
            } else {
                const info = filterFromTable(e, nameRow, submittedClaimsData?.batches);
                setListInfo(info);
            }
        }
    };

    const openDownloadModal = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        open(<FormType1500 item={item} batch={true} />);
    };

    const submittedHeader = [
        { title: 'Batch ID', name: 'displayId', custom: false, width: '15%' },
        { title: 'Submitted Date', name: 'submittedDate', custom: false, disabled: true, width: '20%' },
        { title: 'Submitted By', name: 'submittedBy', rowName: 'firstName', custom: false, width: '23%' },
        { title: 'DOS', name: 'dos', custom: false, disabled: true, width: '20%' },
        { title: 'Claims Forms File', name: 'claimsFormsFile', disabled: true, custom: false, width: '18%' },
        { title: ' ', name: 'action', custom: false, noSearch: true, width: '2%' },
    ];

    return (
        <div>
            <ClaimFilters type="submitted" />
            {loader?.length ?
                <Loader />
                :
                <>
                    <div className="accordion-head-wrapper" style={{ marginTop: '32px' }}>
                        {submittedHeader?.map((item, i) => (
                            <div className="accordion-head-item" style={{ width: item?.width }} key={i}>
                                <SearchAndFilerFull
                                    handleSearch={(e) => changeSearch(e, item?.rowName)}
                                    searched={searched}
                                    item={item}
                                />
                            </div>
                        ))}
                    </div>

                    {listInfo?.length ?
                        <div className="accordion-body-box">
                            {listInfo?.map((item, j) => (
                                <React.Fragment key={j}>
                                    <Accordion
                                        expanded={expanded === item?.id}
                                        onChange={(e) => handleChange(e, item?.id)}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1bh-content"
                                            id={item?.id}
                                        >
                                            <div className="accordion-body-wrapper">
                                                <div className="accordion-body-item" style={{ width: '15%' }}>
                                                    {item?.displayId}
                                                </div>
                                                <div className="accordion-body-item" style={{ width: '20%' }}>
                                                    <DateRow date={item?.submittedDate} />
                                                </div>
                                                <div className="accordion-body-item" style={{ width: '23%' }}>
                                                    <LinkRow
                                                        name={item?.submittedBy ? `${item?.submittedBy?.firstName} ${item?.submittedBy?.lastName ? item?.submittedBy?.lastName : ''}` : 'N/A'}
                                                        href={
                                                            RolePermission([PermissionList.STAFF_READ.code]) ?
                                                                `/staff/${item?.submittedBy?.id || item?.submittedBy?._id}` : ''
                                                        }
                                                        checkPermission={item?.submittedBy ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                                                        textWidth={8}
                                                    />
                                                </div>
                                                <div className="accordion-body-item" style={{ width: '20%' }}>
                                                    <DateRow date={item?.dos?.early} /> - <DateRow
                                                    date={item?.dos?.latest} />
                                                </div>
                                                <div className="accordion-body-item action-btn"
                                                     style={{ width: '18%' }}>
                                                    <button
                                                        onClick={(e) => openDownloadModal(e, item)}
                                                        className="download-1500">
                                                        <p>Download</p>
                                                        <img src={Images.download} alt="" />
                                                    </button>
                                                </div>
                                                <div style={{ width: '2%' }}>
                                                    <img
                                                        className="down-up-icon"
                                                        style={expanded === item?.id ? { transform: 'rotate(-180deg)' } : {}}
                                                        src={Images.dropDown} alt="icon" />
                                                </div>
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div>
                                                <div className="batch-claims-head">
                                                    <p style={{ width: '15%' }}>Claim ID</p>
                                                    <p style={{ width: '15%' }}>DOS</p>
                                                    <p style={{ width: '15%' }}>Funding Source</p>
                                                    <p style={{ width: '15%' }}>Client</p>
                                                    <p style={{ width: '13%' }}>Total Charged</p>
                                                    <p style={{ width: '13%' }}>Total Paid</p>
                                                    <p style={{ width: '13%' }}>Remaining</p>
                                                </div>
                                                {item?.claims?.length ? item?.claims?.map((i) => (
                                                        <div key={i?.id} className="batch-claims-body"
                                                             onClick={() => history.push(`/billedClaim/${i?.id}`, {
                                                                 from: 'submitted',
                                                             })
                                                             }
                                                        >
                                                            <div style={{ width: '15%' }}
                                                                 className="batch-item">{i?.displayId}</div>
                                                            <div style={{ width: '15%' }} className="batch-item">
                                                                <DateRow date={i?.dateRange?.early} /> - <DateRow
                                                                date={i?.dateRange?.latest} />
                                                            </div>
                                                            <div style={{ width: '15%' }} className="batch-item">
                                                                <TextRow name={i?.funder?.name} />
                                                            </div>
                                                            <div style={{ width: '15%' }} className="batch-item">
                                                                <TextRow
                                                                    name={`${i?.client?.firstName} ${i?.client?.lastName ? i?.client?.lastName : ''}`} />
                                                            </div>
                                                            <div style={{ width: '13%' }} className="batch-item">
                                                                <PriceRow info={i?.totalAmount} />
                                                            </div>
                                                            <div style={{ width: '13%' }} className="batch-item">
                                                                <PriceRow info={i?.paidAmount} />
                                                            </div>
                                                            <div style={{ width: '13%' }} className="batch-item">
                                                                <PriceRow info={i?.remaining} />
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    // <NoItemsYet text={'No claims yet'} />
                                                    <TableNoInfo text={`No claims yet`} />
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>
                            ))
                            }
                        </div>
                        :
                        <div style={{ position: 'relative' }}>
                            <NoItemsYet text={'No claims yet'} />
                        </div>
                    }

                    {!!submittedClaimsData?.count > 0 &&
                        <div style={{ height: '76px' }}>
                            <PaginationFillTable
                                listLength={submittedClaimsData?.batches?.length}
                                page={info?.page ? info?.page : 1}
                                handleReturn={(number) => changePage(number)}
                                count={submittedClaimsData?.count}
                            />
                        </div>
                    }
                </>
            }
        </div>
    );
};
