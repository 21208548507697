import React, { useState } from 'react';
import { claimPaymentsStyle } from './styles';
import { Loader, NoItemsYet, NoItemText, PaginationFillTable } from 'components';
import { ClaimPaymentBody, ClaimPaymentHead } from './core/claimPaymentTable';
import { FindLoad, SendPageSave } from 'utils';
import { useHistory } from 'react-router-dom';

const ACTION_TYPE = 'GET_CLAIM_PAYMENTS';

export const ClaimPaymentsFragment = ({ claimsList }) => {
    const classes = claimPaymentsStyle();
    const loader = FindLoad(ACTION_TYPE);
    const [activeRow, setActiveRow] = useState();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;

    const handleEdit = (id) => {
        setActiveRow(id);
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.postTableWrapper}>
            {claimsList?.claims?.length ?
                <>
                    <ClaimPaymentHead />
                    <div className={classes.claimPaymentBodyBox}>
                        {claimsList?.claims?.map((i, j) => (
                            <div key={j}>
                                <ClaimPaymentBody
                                    item={i}
                                    activeRow={activeRow}
                                    setActiveRow={setActiveRow}
                                    handleEdit={handleEdit}
                                />
                            </div>
                        ))}
                    </div>
                    {!!claimsList?.claims?.length > 0 &&
                        <div style={{ height: '76px' }}>
                            <PaginationFillTable
                                listLength={claimsList?.claims?.length}
                                page={page}
                                handleReturn={(number) => changePage(number)}
                                count={claimsList?.count}
                            />
                        </div>
                    }

                </>
                :
                <div style={{ position: 'relative' }}>
                    <NoItemsYet text={'No claim payments yet'} />
                </div>
                // <NoItemText
                //     styles={{ height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                //     text="No claim payments yet"
                // />
            }
        </div>
    );
};
