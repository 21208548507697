import React, { useEffect, useState } from 'react';
import { CustomDelete, DownloadLink, TextRow, ValidationInput } from 'components';
import ReactFileReader from 'react-file-reader';
import { Images, ImgUploader, isNotEmpty, useModal } from 'utils';
import { CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from 'store';

const REMOVE_ACTION = 'REMOVE_FILES_FROM_CLIENT_AUTH';
export const AuthorizationFiles = ({ authId }) => {
    const [dockName, setDockName] = useState('');
    const [error, setError] = useState('');
    const [loaderUpload, setLoaderUpload] = useState(false);
    const [files, setFiles] = useState([]);
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    const { open, close } = useModal();
    const dispatch = useDispatch();

    useEffect(() => {
        if (clientsAuthorizations) {
            const active = clientsAuthorizations?.find((i) => i?.status === 'ACTIVE');
            setFiles(active?.documents);
        }
    }, [clientsAuthorizations]);
    const changeDockName = (e) => {
        setDockName(e.target.value);
    };

    function handleFileChange(imageList) {
        !!error && setError('');

        for (let item of imageList) {
            const _fileSizeIsLarge = item?.size > 2097152;
            const _fileTypeIsAllowed =
                item?.type === 'application/pdf' ||
                item?.type === 'image/png' ||
                item?.type === 'text/csv' ||
                item?.type === 'image/jpeg';

            if (_fileSizeIsLarge) {
                setError('Please, choose a file with smaller size!');
                return;
            }
            if (!_fileTypeIsAllowed) {
                setError('Only PDF , PNG , CSV & JPEG formats are supported!');
                return;
            }
        }

        setLoaderUpload(true);
        ImgUploader(Array.from(imageList), false).then((uploadedFile) => {
            setLoaderUpload(false);
            const newList = [
                ...files,
                {
                    file: { ...uploadedFile },
                    name: dockName,
                },
            ];
            setFiles(newList);
            setDockName('');

            const fileData = {
                file: uploadedFile,
                name: dockName,
            };
            dispatch(clientActions.addFilesToClientAuth(authId, fileData));
        });
    }


    const uploadCardLoading = () => {
        return (
            <div className="upload-card-box">
                <div className="uploaded-file-box">
                    <CircularProgress />
                </div>
                <div className="uploaded-file-name-box">
                    <p className="uploaded-file-name"><TextRow name={' '} /></p>
                    <ValidationInput
                        label={'File Type'}
                        variant={'outlined'}
                        onChange={changeDockName}
                        value={dockName}
                        type={'text'}
                        errorFalse={true}
                    />
                </div>
                <div>
                    <button className="reset-btn">
                        <img src={Images.download} alt="" style={{ width: 32, height: 32 }} />
                    </button>
                </div>
            </div>
        );
    };

    const uploadCard = (item) => {
        const fileType = item?.file?.originalName?.substring(item?.file?.originalName?.lastIndexOf('.') + 1);

        const fileIcon =
            fileType === 'jpeg' || fileType === 'jpg' ? Images.JPEGFile :
                fileType === 'pdf' ? Images.PDFFile :
                    fileType === 'png' ? Images.PNGFile :
                        fileType === 'csv' ? Images.CSVFile : '';

        return (
            <div className="upload-card-box">
                <div className="uploaded-file-box">
                    <button onClick={() => open(
                        <CustomDelete
                            text="Are you sure you want to delete this file? This action cannot be undone."
                            info="Delete File"
                            handleDel={() => dispatch(clientActions.removeFilesFromClientAuth(authId, item?._id))}
                            handleClose={() => close()}
                            actionType={REMOVE_ACTION}
                            body={
                                <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                                    <img style={{ marginTop: '3px' }} src={Images.authFile} alt="icon" />
                                    <p style={{ fontWeight: 400 }}>
                                        <TextRow name={item?.name} />
                                    </p>
                                </div>
                            }
                        />,
                    )}>
                        <img src={Images.removeIcon} alt="removeIcon" />
                    </button>
                    <img src={fileIcon} alt="type" />
                </div>
                <div className="uploaded-file-name-box">
                    <p className="uploaded-file-name"><TextRow name={item?.file?.originalName} textWidth={10} /></p>
                    <ValidationInput
                        label={'File Type'}
                        variant={'outlined'}
                        onChange={() => {}}
                        value={item?.name}
                        type={'text'}
                        errorFalse={true}
                    />
                </div>
                <div style={{ width: '50px', marginTop: '30px' }}>
                    <DownloadLink
                        iconStyles={{ width: 32, height: 32, margin: 0 }}
                        file={item?.file}
                    />
                </div>
            </div>
        );
    };

    return (
        <div style={{ width: 400 }}>
            <p className="modal-header-title">Uploaded Files</p>
            <p className="authorization-file-desktop">Please fulfill the file type to upload a file.</p>
            <p className="authorization-file-type-info">
                <h6>*</h6>Only <span>PDF, PNG, CSV</span> & <span>JPEG</span> formats are supported.
            </p>

            <div className="upload-file-box">
                <ValidationInput
                    label={'File Type'}
                    variant={'outlined'}
                    onChange={changeDockName}
                    value={dockName}
                    type={'text'}
                />

                <ReactFileReader
                    disabled={!isNotEmpty(dockName)}
                    handleFiles={handleFileChange}
                    fileTypes={['image/*', 'application/pdf', 'text/csv']}
                >
                    <button disabled={!dockName}>
                        Upload a File
                    </button>
                </ReactFileReader>
            </div>
            <p className="upload-files-title">Upload Files</p>


            <div className="cards-wrapper">
                {loaderUpload &&
                    <div>{uploadCardLoading()}</div>
                }

                {!files?.length ?
                    <div className="no-files-yet">
                        <img src={Images.noFile} alt="icon" />
                        <p>No Files Yet</p>
                    </div>
                    :
                    <div>
                        {files?.map((item, index) => (
                            <React.Fragment key={index}>
                                {uploadCard(item)}
                            </React.Fragment>
                        ))}
                    </div>
                }
            </div>

            <button className="done-btn" onClick={close}>
                Done
            </button>
        </div>
    );
};