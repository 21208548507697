import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const claimDetailsCoreStyle = makeStyles(() => ({
   receivableContainerStyle: {
      width: "100%",
      marginTop: "16px",
   },
   commentTextAreaStyle: {
      "& textarea": {
         maxWidth: "406px",
         width: "100%",
         height: "160px",
      },
   },
   closeOrCancelButnStyle: {
      width: "100%",
      "&.cancel": { backgroundColor: `${Colors.BackgroundWater} !important` },
      "&.create": { backgroundColor: `${Colors.ThemeRed} !important` },
   },
   billModalWrapper:{
      width:'1250px',
      height:'85vh',
      background:'white',
      boxShadow: '0px 0px 6px rgba(52, 122, 240, 0.2)',
      borderRadius: '8px',
      overflow:'auto',

      "@media (min-width: 1700px)": {
         width:'1605px',
      },
   }
}));
