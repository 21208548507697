import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentTransactions = () => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };
  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-menu-design orders-wrapper gap-32-16-24 transaction-wrapper">
        <div className="orders_text-desc">
          <h2 className="title">
            Transactions
          </h2>

          <div className="description" style={{ maxWidth: "676px" }}>
            <p>
              The transactions section includes all orders from the selected table. Until the check request, the transaction is open. After the payment, they are closed. Here you can manage all financial aspects related to transactions. Also, you can check the transaction start and end date.
            </p>

            <p>
              Manage all the transactions related to orders here. There are three main sections:
            </p>
          </div>
        </div>
      </div>
    </div>
  )
    ;
};

