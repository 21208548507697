import React, { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';


export const NumericFormatCustom = forwardRef(
    ({
         name,
         placeholder,
         label,
         thousandSeparator,
         fixedDecimalScale,
         decimalScale,
         prefix,
         control,
         rules,
         selectedValue = 'floatValue',
     }, ref) => {

        const [labelClassName, setClassName] = useState('numeric-autocomplete-label');

        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field, formState: { errors } }) => {
                    const allowFields = { ...field };
                    if (allowFields?.onChange) delete allowFields?.onChange;

                    return (
                        <div className='custom-numeric-format-box'>
                            <p className={`${field?.value || field?.value?.value ? 'numeric-autocomplete-label-active' : labelClassName}
                            ${errors[field?.name]?.message ? 'numeric-autocomplete-label-error' : ''}
                            
                            `}>
                                {label} {prefix ? ` (${prefix})` : ''}
                            </p>

                            <NumericFormat
                                className={errors[field?.name]?.message ? 'numeric-format-input-error' : 'numeric-format-input'}
                                ref={ref}
                                {...allowFields}
                                onValueChange={(formats) => {
                                    const changedFormat = formats[selectedValue];
                                    field.onChange(changedFormat);
                                }}
                                thousandSeparator={thousandSeparator}
                                fixedDecimalScale={fixedDecimalScale}
                                decimalSeparator='.'
                                allowNegative={false}
                                isNumericString
                                prefix={prefix ? prefix : ''}
                                placeholder={placeholder}
                                decimalScale={decimalScale}
                                focus={true}
                                onFocus={(e) => {
                                    setClassName('numeric-autocomplete-label-active');
                                }}

                                onBlur={(e) => {
                                    setClassName('numeric-autocomplete-label');
                                    field?.onBlur(e);
                                }}
                            />
                            <p className='error-message'>{errors[field?.name]?.message}</p>
                        </div>
                    );
                }}
            />
        );
    });

NumericFormatCustom.displayName = 'NumericFormatCustom';
