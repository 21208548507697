import React from "react";
import { Card } from "components";
import { fundingSourceSingleStyles } from "./styles";
import {
   getFundingSourceAddressInfo,
   getFundingSourceContactInfo,
   getFundingSourceGeneralInfo,
} from "./constants";

export const FundingSourceSingleGeneral = ({ data }) => {
   const classes = fundingSourceSingleStyles();

   return (
      <React.Fragment>
            {/*<Card*/}
            {/*   cardInfo={getFundingSourceGeneralInfo(data)}*/}
            {/*   maxWidth={"510px"}*/}
            {/*   width={"32.5%"}*/}
            {/*   isBig={true}*/}
            {/*/>*/}
            <Card
               cardInfo={getFundingSourceContactInfo(data)}
               maxWidth={"510px"}
               width={"32.5%"}
               isBig={true}
            />
            {/*<Card*/}
            {/*   cardInfo={getFundingSourceAddressInfo(data)}*/}
            {/*   maxWidth={"510px"}*/}
            {/*   width={"32.5%"}*/}
            {/*   isBig={true}*/}
            {/*/>*/}
      </React.Fragment>
   );
};
