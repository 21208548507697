import { BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../../constants';

export function getDynamicContent(content, info, type) {
    const staticText = content === 'TITLE' ? '' :
        content === 'SUBTITLE' ? ', please fulfill the below fields.' : '';

    const dynamicText =
        content === 'TITLE' ? info ? 'Edit ' : 'Add' :
            content === 'SUBTITLE' ? info ? 'To edit the' : 'To add a' : '';

    switch (type) {
        case PAID:
            return `${dynamicText} Paid Time Off${staticText}`;
        case STAFF:
            return `${dynamicText} Staff Appointment${staticText}`;
        case BREAK:
            return `${dynamicText} Break${staticText}`;
        case DRIVE:
            return `${dynamicText} Drive${staticText}`;
        case SERVICE:
            return `${dynamicText} Service Appointment${staticText}`;
        case UNPAID:
            return `${dynamicText} Unpaid Time Off${staticText}`;
        case SICK_TIME:
            return `${dynamicText} Sick Time ${staticText}`;
        default:
            return '';
    }
}

export function getDynamicTitle(content, info, type) {

    const dynamicText =
        content === 'TITLE' ? info ? 'Edit ' : 'Add' :
            content === 'SUBTITLE' ? info ? 'To edit the' : 'To add a' : '';

    switch (type) {
        case PAID:
            return `${dynamicText} Paid Time Off Appointment`;
        case STAFF:
            return `${dynamicText} Staff Appointment`;
        case BREAK:
            return `${dynamicText} Break Appointment`;
        case DRIVE:
            return `${dynamicText} Drive Appointment`;
        case SERVICE:
            return `${dynamicText} Service Appointment`;
        case UNPAID:
            return `${dynamicText} Unpaid Time Off Appointment`;
        case SICK_TIME:
            return `${dynamicText} Sick Time Appointment`;
        default:
            return '';
    }
}


export const renderTimes = (item) => {
    return item?.slice(11, 16);
};