import React from "react";
import {SimpleTabs} from "components";
import {reportsWrapper} from "./styles";
import {SignatureReports} from "fragments";
import {tabsLabels} from "./constants";
import {Images} from "utils";

export const Report = ({}) => {
    const classes = reportsWrapper()
    const tabsContent = [
        {
            tabComponent: (
                <SignatureReports/>
            ),
            tab: 'Signature'
        },
    ];

    return (

        <div className={classes.systemItemWrapper}>
            <div className={classes.headerWrapper}>
                <img src={Images.reportsTab} alt="icon"/>
                <p className={classes.headerTitle}>Reports</p>
            </div>
            <SimpleTabs
                defaultTab={'Signature'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
            />
        </div>

    )
}