import React, {useCallback, useContext, useState} from "react";
import {invoiceReceivableTHeadTBodyStyle} from "./styles";
import { SearchAndFilter, TextRow } from 'components';
import {DrawerContext, hooksForTable, useWidth} from "utils";
import {bodyList, headList, renderBodyRow} from "./constants";

export const InvoiceReceivableTable = ({invoiceReceivables = [], handleOpenModal }) => {
    const classes = invoiceReceivableTHeadTBodyStyle();
    const {getTextDependsOnWidth} = hooksForTable;
    const [currentWidth, setCurrentWidth] = useState(0);
    const width = useWidth();
    const {open} = useContext(DrawerContext);

    function getReceiveTitle(givenTitle = "", moreSlice) {
        const size = open ? 1855 : 1700;
        const limit = moreSlice ? moreSlice : open ? 6 : 7;
        return getTextDependsOnWidth(width, size, givenTitle, limit);
    }

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    return (
        <div className={classes.receivableContainerStyle}>
            <div className={classes.tableTheadStyle}>
                {headList?.map((i, j) => (
                    <div key={j} className={classes.thStyle} style={{width: i?.width}}>
                        <TextRow name={i?.name} color={'white'} textWidth={8}/>
                        {/*<SearchAndFilter*/}
                        {/*    tooltipTitle={i?.name}*/}
                        {/*    title={i?.slice ? getReceiveTitle(i?.name) : i?.name}*/}
                        {/*    custom={false}*/}
                        {/*/>*/}
                    </div>
                ))}
            </div>
            {invoiceReceivables.map((item, index) => (
                <div key={index} className={classes.tbodyContainerStyle} onClick={() => handleOpenModal(item)}>
                    <div className={classes.tbodyRowStyle}>
                        {bodyList?.map((body, k) => (
                            <div className={classes.tdStyle} style={{width: body?.width}} key={k} ref={curr}>
                                {renderBodyRow(item, body, currentWidth)}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
