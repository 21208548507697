import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generateInvoiceStyle } from './styles';
import { CreateChancel, BillFiltersSelectors, FullTable } from 'components';
import { FindLoad, PermissionList, RolePermission } from 'utils';
import { invoiceActions } from 'store';
import { generateInvoiceBody, generateInvoiceHead, ACTION_TYPE } from './constants';

export const GenerateInvoiceFragment = ({}) => {
    const classes = generateInvoiceStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const generateInvoiceLoader = FindLoad('GENERATE_INVOICE');
    const notInvoicedBills = useSelector((state) => state.invoice.billForGenerate);
    const [selectedList, setSelectedList] = useState([]);

    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (name === 'all') {
            let allList = notInvoicedBills?.length === selectedList?.length ? [] : notInvoicedBills?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            const uniqSet = new Set(list);

            if (!uniqSet.has(item?.id)) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };

    return (
        <div className={classes.generateInvoiceContainerStyle}>
            <div className={classes.generateInvoiceActionsStyle}>
                <BillFiltersSelectors />
            </div>
            <FullTable
                head={generateInvoiceHead(handleCheck, notInvoicedBills, selectedList)}
                body={generateInvoiceBody(handleCheck, notInvoicedBills, selectedList)}
                loadingType={ACTION_TYPE}
                list={notInvoicedBills}
                noText={'invoices'}
                generateTable={true}
            />
            <div className={classes.notInvoicedBillsFooterStyle}>
                {RolePermission([PermissionList.INVOICE_CREATE?.code]) &&
                    <CreateChancel
                        classes={classes.generateOrCancelButnStyle}
                        loader={!!generateInvoiceLoader.length}
                        create={'Generate Invoices'}
                        chancel={'Cancel'}
                        onCreate={() =>
                            dispatch(
                                invoiceActions.generateInvoice({
                                    bills: selectedList,
                                }),
                            )
                        }
                        onClose={() => history.push('/invoices')}
                        disabled={!selectedList?.length}
                    />
                }
            </div>
        </div>
    );
};
