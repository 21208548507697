import axios from 'axios';

export const appointmentService = {
    /** Create, Edit Appointment */
    createAppointmentService: (body) => axios.post(`/appt`, body, { auth: true }),

    editAppointmentService: (body, id) => axios.patch(`/appt/${id}`, body, { auth: true }),

    editApptLockUnlockService: ({ list, type, params }) => axios.patch(`/appt/lock/${type}`, list, { auth: true, params:{...params} }),
    /** end */

    /** Get Appointment */
    getAppointmentService: (info) => axios.get(`/appt`, { auth: true, params: { ...info } }),

    getAppointmentByIdService: (id) => axios.get(`/appt/${id}`, { auth: true }),
    /** end */

    /** Delete Appointment */
    deleteAppointmentService: (id) => axios.delete(`/appt/${id}`, { auth: true }),
    /** end */

    /** Appointment Status */
    setAppointmentStatusService: (id, statusName, reason) => {
        if (statusName === 'cancel') {
            return axios.patch(`/appt/${id}/${statusName}?reason=${reason ? reason : ''}`, null, { auth: true });
        } else {
            return axios.patch(`/appt/${id}/${statusName}`, null, { auth: true });
        }
    },
    /** end */

    /** Appointment Repeat */
    appointmentRepeatService: (id, body) => axios.post(`/appt/repeat/${id}`, body, { auth: true }),
    /** end */

    appendSignatureToAppmtService: (id, signature) => axios.post(`/appt/${id}/documents`, signature, { auth: true }),

    getClientStaffsService: (params) => axios.get(`/appt/staffs/suggestion`, {
        auth: true,
        params: { clientId: params?.clientId, serviceId: params?.serviceId, authServiceId: params?.authServiceId },
    }),

    manageNotesService: (id, params) => axios.post(`/appt/${id}/notes`, params, { auth: true }),

    connectReconnectService: (id, params) => axios.patch(`/appt/${id}/template/disconnect`, { }, { auth: true }),
    // connectReconnectService: (id, params) => axios.patch(`/appt/${id}/template/connect`, params, { auth: true }),

    checkApptOverlapping: (params, body) => axios.patch(`/appt/repeat/available`, body, { auth: true, params: params }),

    checkApptUpdateOverlapping: (params) => axios.get('/appt/check/update', { auth: true, params: params }),

    cancelApptService: (id) => axios.patch(`/appt/template/${id}/cancel`, null, { auth: true }),

    deleteAllApptService: (id) => axios.delete(`/appt/template/${id}`, { auth: true }),

};
