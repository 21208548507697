window.process = {};
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/allStyles.scss';
import { Provider } from 'react-redux';
import { store } from './store';
import { DrawerContextProvider, initAxiosInterceptors, } from 'utils';
import { AllModals, ModalProvider } from './utils/contexts/modalContext';
import { ScrollToTop } from './utils/hooks';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

initAxiosInterceptors();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Provider store={store}>
                <DrawerContextProvider>
                    <ModalProvider>
                        <App />
                        <AllModals />
                    </ModalProvider>
                </DrawerContextProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
serviceWorkerRegistration.register();
