import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
    FundingSource,
    FundingSourceSingle,
    Management,
    Staff,
    SingleStaff,
    Client,
    ClientSingle,
    System,
    Schedule,
    PendingClaims,
    ClaimDetails,
    Invoices,
    InvoiceDetails,
    GenerateClaim,
    GenerateInvoice,
    ClaimPayments,
    InvoicePayments,
    Report,
    NoInfo, PassivePayroll, ActivePayroll, SubmittedClaims,
} from 'pages';
import { PermissionList, RolePermission } from '../utils';

export const Router = () => {
    return (
        <Switch>
            <Route path="/" exact component={NoInfo} />

            {RolePermission([
                    // PermissionList.ROLES_CREATE.code,
                    PermissionList.ROLES_READ.code,
                ]) &&
                <Route path="/management" component={Management} />
            }

            {RolePermission([
                    PermissionList.FS_LIST_READ?.code,
                    // PermissionList.FS_READ?.code,
                    // PermissionList.FS_CREATE?.code,
                    // PermissionList.FS_STATUS?.code,
                    // PermissionList.FS_SERVICE_READ?.code,
                    // PermissionList.FS_SERVICE_CREATE?.code,
                    // PermissionList.FS_NOTE_READ?.code,
                    // PermissionList.FS_NOTE_MANAGE?.code,
                    // PermissionList.FS_HISTORY_READ?.code,
                    // PermissionList.FS_FILE_READ?.code,
                    // PermissionList.FS_FILE_MANAGE?.code,
                ]) &&
                <Route path="/fundingSource" exact component={FundingSource} />
            }

            {RolePermission([
                    PermissionList.FS_READ?.code,
                    // PermissionList.FS_CREATE?.code,
                    // PermissionList.FS_STATUS?.code,
                    // PermissionList.FS_SERVICE_READ?.code,
                    // PermissionList.FS_SERVICE_CREATE?.code,
                    // PermissionList.FS_NOTE_READ?.code,
                    // PermissionList.FS_NOTE_MANAGE?.code,
                    // PermissionList.FS_HISTORY_READ?.code,
                    // PermissionList.FS_FILE_READ?.code,
                    // PermissionList.FS_FILE_MANAGE?.code,
                ]) &&
                <Route path="/fundingSource/:id" exact component={FundingSourceSingle} />
            }
            {RolePermission([
                    PermissionList.STAFF_LIST_READ.code,
                    // PermissionList.STAFF_READ.code,
                    // PermissionList.STAFF_CREATE.code,
                    // PermissionList.STAFF_STATUS.code,
                    //
                    // PermissionList.STAFF_TIMESHEET_READ.code,
                    // PermissionList.STAFF_TIMESHEET_CREATE.code,
                    //
                    // PermissionList.STAFF_PAYCODE_READ.code,
                    // PermissionList.STAFF_PAYCODE_MANAGE.code,
                    //
                    // PermissionList.STAFF_CREDENTIAL_READ.code,
                    // PermissionList.STAFF_CREDENTIAL_MANAGE.code,
                    //
                    // PermissionList.STAFF_SERVICE_READ.code,
                    // PermissionList.STAFF_SERVICE_MANAGE.code,
                    //
                    // PermissionList.STAFF_ROLES_MANAGE.code,
                    //
                    // PermissionList.STAFF_HISTORY_READ.code,
                    //
                    // PermissionList.STAFF_NOTE_READ.code,
                    // PermissionList.STAFF_NOTE_MANAGE.code,
                    //
                    // PermissionList.STAFF_FILE_READ.code,
                    // PermissionList.STAFF_FILE_MANAGE.code,
                ]) &&
                <Route path="/staff" exact component={Staff} />
            }
            {RolePermission([
                    PermissionList.STAFF_READ.code,
                    // PermissionList.STAFF_CREATE.code,
                    // PermissionList.STAFF_STATUS.code,
                    // PermissionList.STAFF_TIMESHEET_READ.code,
                    // PermissionList.STAFF_TIMESHEET_CREATE.code,
                    // PermissionList.STAFF_PAYCODE_READ.code,
                    // PermissionList.STAFF_PAYCODE_MANAGE.code,
                    // PermissionList.STAFF_CREDENTIAL_READ.code,
                    // PermissionList.STAFF_CREDENTIAL_MANAGE.code,
                    // PermissionList.STAFF_SERVICE_READ.code,
                    // PermissionList.STAFF_SERVICE_MANAGE.code,
                    // PermissionList.STAFF_ROLES_MANAGE.code,
                    // PermissionList.STAFF_HISTORY_READ.code,
                    // PermissionList.STAFF_NOTE_READ.code,
                    // PermissionList.STAFF_NOTE_MANAGE.code,
                    // PermissionList.STAFF_FILE_READ.code,
                    // PermissionList.STAFF_FILE_MANAGE.code,
                ]) &&
                <Route path="/staff/:id" exact component={SingleStaff} />
            }

            {RolePermission([
                    PermissionList.CLIENT_LIST_READ?.code,
                    PermissionList.CONNECTED_CLIENT_READ?.code,
                    // PermissionList.CLIENT_READ?.code,
                    // PermissionList.CLIENT_CREATE?.code,
                    // PermissionList.CLIENT_STATUS?.code,
                    // PermissionList.CLIENT_ENROLLMENT_READ?.code,
                    // PermissionList.CLIENT_ENROLLMENT_MANAGE?.code,
                    // PermissionList.CLIENT_AUTHORIZATION_READ?.code,
                    // PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code,
                    // PermissionList.CLIENT_CONTACT_READ?.code,
                    // PermissionList.CLIENT_CONTACT_MANAGE?.code,
                    // PermissionList.CLIENT_HISTORY_READ?.code,
                    // PermissionList.CLIENT_NOTE_READ?.code,
                    // PermissionList.CLIENT_NOTE_MANAGE?.code,
                    // PermissionList.CLIENT_FILE_READ?.code,
                    // PermissionList.CLIENT_FILE_MANAGE?.code,

                    // PermissionList.CLIENT_STAFF_ASSIGNMENT?.code,
                ]) &&
                <Route path="/client" exact component={Client} />
            }

            {RolePermission([
                    PermissionList.CLIENT_READ?.code,
                    // PermissionList.CLIENT_CREATE?.code,
                    // PermissionList.CLIENT_STATUS?.code,
                    // PermissionList.CLIENT_ENROLLMENT_READ?.code,
                    // PermissionList.CLIENT_ENROLLMENT_MANAGE?.code,
                    // PermissionList.CLIENT_AUTHORIZATION_READ?.code,
                    // PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code,
                    // PermissionList.CLIENT_CONTACT_READ?.code,
                    // PermissionList.CLIENT_CONTACT_MANAGE?.code,
                    // PermissionList.CLIENT_HISTORY_READ?.code,
                    // PermissionList.CLIENT_NOTE_READ?.code,
                    // PermissionList.CLIENT_NOTE_MANAGE?.code,
                    // PermissionList.CLIENT_FILE_READ?.code,
                    // PermissionList.CLIENT_FILE_MANAGE?.code,
                    // PermissionList.CLIENT_STAFF_ASSIGNMENT?.code,
                ]) &&
                <Route path="/client/:id" exact component={ClientSingle} />
            }

            {RolePermission([
                    PermissionList.SYSTEM_SERVICE_TYPES?.code,
                    PermissionList.SYSTEM_PAYCODE_TYPES?.code,
                    PermissionList.SYSTEM_CREDENTIALS?.code,
                    PermissionList.SYSTEM_DEPARTMENTS?.code,
                    PermissionList.SYSTEM_JOB_TITLES?.code,
                    PermissionList.SYSTEM_PLACE_OF_SERVICE?.code,
                    PermissionList.SYSTEM_MILEAGE?.code,
                    PermissionList.SYSTEM_OVERTIME?.code,
                ]) &&
                <Route path="/system" component={System} />
            }

            {/*{RolePermission([*/}
            {/*        PermissionList.APPT_READ?.code,*/}
            {/*        PermissionList.APPT_SERVICE_CREATE?.code,*/}
            {/*        PermissionList.APPT_DRIVE_CREATE?.code,*/}
            {/*        PermissionList.APPT_BREAK_CREATE?.code,*/}
            {/*        PermissionList.APPT_PTO_CREATE?.code,*/}
            {/*        PermissionList.APPT_UNPAID_CREATE?.code,*/}
            {/*        PermissionList.APPT_STAFF_CREATE?.code,*/}
            {/*        PermissionList.APPT_SIGNATURE_TOGGLE_MANAGE?.code,*/}
            {/*        PermissionList.APPT_CANCEL?.code,*/}
            {/*        PermissionList.APPT_RENDER_COMPLETE?.code,*/}
            {/*        PermissionList.APPT_RECURE?.code,*/}
            {/*        PermissionList.APPT_CONNECTED_CREATE?.code,*/}
            {/*        PermissionList.UNRENDERED_APPT_DELETE?.code,*/}
            {/*        PermissionList.APPT_NOTE_TOGGLE_MANAGE?.code,*/}
            {/*        PermissionList.APPT_LOCK?.code,*/}
            {/*        PermissionList.LOCKED_APPT_EDIT?.code,*/}
            {/*        PermissionList.APPT_SELF_CREATE?.code,*/}
            {/*        PermissionList.APPT_CONNECTED_READ?.code,*/}
            {/*    ]) &&*/}
            <Route path="/schedule" exact component={Schedule} />
            {/*}*/}

            {RolePermission([
                    PermissionList.CLAIMS_READ?.code,
                    // PermissionList.CLAIMS_CREATE?.code,
                    // PermissionList.CLAIMS_UPDATE?.code,
                    // PermissionList.CLAIMS_CLOSE?.code,
                ]) &&
                <Route path="/pendingClaims" exact component={PendingClaims} />
            }

            {RolePermission([
                    PermissionList.CLAIMS_READ?.code,
                    // PermissionList.CLAIMS_CREATE?.code,
                    // PermissionList.CLAIMS_UPDATE?.code,
                    // PermissionList.CLAIMS_CLOSE?.code,
                ]) &&
                <Route path="/billedClaims" exact component={SubmittedClaims} />
            }

            {RolePermission([
                    PermissionList.CLAIMS_READ?.code,
                    // PermissionList.CLAIMS_CREATE?.code,
                    // PermissionList.CLAIMS_UPDATE?.code,
                    // PermissionList.CLAIMS_CLOSE?.code,
                ]) &&
                <Route path="/claim/:id" exact component={ClaimDetails} />
            }

            {RolePermission([PermissionList.CLAIMS_READ?.code,]) &&
                <Route path="/billedClaim/:id" exact component={ClaimDetails} />
            }

            {RolePermission([
                    PermissionList.CLAIMS_CREATE?.code,
                    PermissionList.CLAIMS_UPDATE?.code,
                ]) &&
                <Route path="/generateClaim" exact component={GenerateClaim} />
            }
            {RolePermission([
                    PermissionList.INVOICE_READ?.code,
                    // PermissionList.INVOICE_CREATE?.code,
                    // PermissionList.INVOICE_UPDATE?.code,
                    // PermissionList.INVOICE_CLOSE?.code,
                ]) &&
                <Route path="/invoices" exact component={Invoices} />
            }
            {RolePermission([
                    PermissionList.INVOICE_READ?.code,
                    // PermissionList.INVOICE_CREATE?.code,
                    // PermissionList.INVOICE_UPDATE?.code,
                    // PermissionList.INVOICE_CLOSE?.code,
                ]) &&
                <Route path="/invoice/:id" exact component={InvoiceDetails} />
            }
            {RolePermission([
                    PermissionList.INVOICE_CREATE?.code,
                ]) &&
                <Route path="/generateInvoice" exact component={GenerateInvoice} />
            }
            {RolePermission([
                    PermissionList.CLAIM_PMT_READ?.code,
                    // PermissionList.CLAIM_PMT_PAY?.code,
                    // PermissionList.CLAIM_PMT_SEND_APPEAL?.code,
                    // PermissionList.CLAIM_PMT_APPEAL_READ?.code,
                    // PermissionList.CLAIM_PMT_APPEAL_PAY?.code,
                ]) &&
                <Route path="/claimPayments" exact component={ClaimPayments} />
            }
            {RolePermission([
                    PermissionList.INVOICE_PMT_READ?.code,
                    // PermissionList.INVOICE_PMT_PAY?.code,
                    // PermissionList.INVOICE_PMT_CLOSE?.code,
                ]) &&
                <Route path="/invoicePayments" exact component={InvoicePayments} />
            }
            {RolePermission([
                    PermissionList.REPORT_MANAGE?.code,
                ]) &&
                <Route path="/report" exact component={Report} />
            }


            {RolePermission([
                    PermissionList.STAFF_TIMESHEET_READ.code,
                    // PermissionList.STAFF_TIMESHEET_CREATE.code,
                ]) &&
                <Route path={'/processedPayroll'} exact component={PassivePayroll} />
            }

            {RolePermission([
                    PermissionList.STAFF_TIMESHEET_READ.code,
                    // PermissionList.STAFF_TIMESHEET_CREATE.code,
                ]) &&
                <Route path={'/activePayroll'} exact component={ActivePayroll} />
            }

            <Redirect to={'/'} />
        </Switch>
    );
};