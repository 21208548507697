import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { scheduleModalsStyle } from './styles';
import { modalsStyle } from 'components/modal/styles';
import { Images, PermissionList, RolePermission } from 'utils';
import { Break } from './break';
import { Service } from './service';
import { BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../../constants';

export const CreateEvent = ({ handleOpenClose, date, modalDate }) => {
    const classes = scheduleModalsStyle();
    const global = modalsStyle();
    const history = useHistory();
    const info = history?.location?.state;
    const [screenType, setScreenType] = useState(modalDate ? modalDate?.type : '');

    useEffect(() => {
        if (info?.type) {
            const types =
                info?.type === SERVICE && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) ? SERVICE :
                    info?.type === STAFF && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_STAFF_CREATE?.code]) ? STAFF :
                        info?.type === BREAK && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_BREAK_CREATE?.code]) ? BREAK :
                            info?.type === DRIVE && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_DRIVE_CREATE?.code]) ? DRIVE :
                                info?.type === PAID && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_PTO_CREATE?.code]) ? PAID :
                                    info?.type === UNPAID && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_UNPAID_CREATE?.code]) ? UNPAID :
                                        info?.type === SICK_TIME && RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SICK_TIME_CREATE?.code]) ? SICK_TIME :
                                            null;

            if (types) {
                setScreenType(types);
            }
        }
    }, [info]);

    const handleChange = (type) => {
        setScreenType(type);
    };

    const handleCloseModal = () => {
        handleOpenClose && handleOpenClose();
    };

    const dateTime =
            {
                startTime: date?.startTime?.slice(11, 16) || modalDate?.startTime?.slice(11, 16),
                endTime: date?.endTime?.slice(11, 16) || modalDate?.endTime?.slice(11, 16),
            };

    const day = date && {
        startDate: date.startDate,
    };

    return (
        <>
            <div>

                {screenType ? (
                        <div>
                            {screenType === SERVICE &&
                                <div className={classes.bigModal}>
                                    <Service
                                        modalDate={modalDate}
                                        date={dateTime}
                                        day={day}
                                        type={screenType}
                                        handleOpenClose={handleCloseModal}
                                    />
                                </div>
                            }
                            {screenType !== SERVICE &&
                                <div className={global.inactiveModalBody} style={{ width: '700px' }}>
                                    <Break
                                        modalDate={modalDate}
                                        date={dateTime}
                                        day={day}
                                        type={screenType}
                                        handleOpenClose={handleCloseModal}
                                    />
                                </div>
                            }
                        </div>
                    )
                    :
                    (
                        <div className={global.inactiveModalBody}>
                            <p className={global.availableScheduleTitle}>Select the Event Type</p>
                            <div className={classes.typesWrapper}>

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(SERVICE)}
                                        className={classes.typesItem}
                                    >
                                        <p>Service Appointment</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_STAFF_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(STAFF)}
                                        className={classes.typesItem}
                                    >
                                        <p>Staff Appointment</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_SICK_TIME_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(SICK_TIME)}
                                        className={classes.typesItem}
                                    >
                                        <p>Sick Time</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_BREAK_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(BREAK)}
                                        className={classes.typesItem}
                                    >
                                        <p>Break</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_DRIVE_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(DRIVE)}
                                        className={classes.typesItem}
                                    >
                                        <p>Drive Time</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_PTO_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(PAID)}
                                        className={classes.typesItem}
                                    >
                                        <p>Paid Time Off</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                                {RolePermission([PermissionList.APPT_SELF_CREATE?.code, PermissionList.APPT_UNPAID_CREATE?.code]) &&
                                    <div
                                        onClick={() => handleChange(UNPAID)}
                                        className={classes.typesItem}
                                    >
                                        <p>Unpaid Time Off</p>
                                        <img src={Images.forward} alt="icon" />
                                    </div>
                                }

                            </div>
                        </div>
                    )}
            </div>
        </>
    );
};
