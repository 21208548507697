import React, { useEffect } from 'react';
import { GenerateInvoiceFragment } from 'fragments';
import { useDispatch } from 'react-redux';
import { invoiceActions } from 'store';
import { CustomBreadcrumbs, Loader } from 'components';
import { useHistory } from 'react-router-dom';


export const GenerateInvoice = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        const sendInfo = {
            ...info,
        };
        delete sendInfo.page;
        // sendInfo.skip = info?.skip ? info?.skip : 0;
        // sendInfo.limit = 50;
        if (info?.firstDate) {
            sendInfo.from = info?.firstDate;
            delete sendInfo.firstDate;
        } else {
            delete sendInfo.from;
        }
        if (info?.lastDate) {
            sendInfo.to = info?.lastDate;
            delete sendInfo.lastDate;
        } else {
            delete sendInfo.to;
        }
        dispatch(invoiceActions.getInvoiceForGenerate(sendInfo));
    }, [info]);


    return (
        <>
            <GenerateInvoiceFragment />
        </>
    );
};
