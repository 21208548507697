import React from 'react';
import { useHistory } from 'react-router-dom';
import { receivableBillTHeadTBodyStyle } from './styles';
import { DownloadLink, NoItemText, SearchAndFilter } from 'components';
import { receivableBillTableStyle } from '../styles';
import { Images, PermissionList, RolePermission } from 'utils';

export const AppointmentsTable = ({ appts }) => {
    const tableStyle = receivableBillTHeadTBodyStyle();
    const classes = receivableBillTableStyle();
    const id = getReceivableBillTitle('ID');
    const status = getReceivableBillTitle('Signature');
    const history = useHistory();

    function getReceivableBillTitle(givenTitle = '') {
        return <SearchAndFilter title={givenTitle} custom={false} />;
    }

    const checkPermissions = RolePermission([
        PermissionList.APPT_READ?.code,
        PermissionList.APPT_SERVICE_CREATE?.code,
        PermissionList.APPT_SIGNATURE_TOGGLE_MANAGE?.code,
        PermissionList.APPT_CANCEL?.code,
    ]);

    const handleNavigateSchedule = () => {
        if (checkPermissions) {
            history.push('/schedule');
        }
    };

    return (
        <div>
            <div>
                {appts?.length ?
                    <div className={classes.receivableBillContainerStyle}>
                        <div className={tableStyle.tableTheadStyle}>
                            <div className={tableStyle.thMaxStyle} style={{ width: '50%' }}>{id}</div>
                            <div className={tableStyle.thMaxStyle} style={{ width: '50%' }}> {status}</div>
                        </div>
                        <div>
                            {appts?.map((item, index) => (
                                <div key={index} className={tableStyle.tbodyContainerStyle}>
                                    <div style={{ minHeight: '38px' }} className={tableStyle.tbodyRowStyle}>
                                        <div className={tableStyle.tdMaxStyle} style={{ width: '50%' }}>
                                            <div onClick={handleNavigateSchedule}
                                                 className={tableStyle.linksButtonWrapper}>
                                                <button style={!checkPermissions ? { textDecoration: 'none', color: 'black', } : {}}>
                                                    {item?.displayId?.prefix ?
                                                    `${item?.displayId?.prefix}${item?.displayId?.suffix}` :
                                                    item?.displayId
                                                }</button>
                                            </div>
                                        </div>
                                        <div className={tableStyle.tdMaxStyle} style={{ width: '50%' }}>
                                            {item?.signature?.id || item?.signature?._id?
                                                <div className={tableStyle.linksWrapper}>
                                                    <div className={tableStyle.linksButtonWrapper}>
                                                        <a href={item?.signature?.url} target={'_blank'}>
                                                            <button>View</button>
                                                            <img src={Images.show} alt="icon" />
                                                        </a>
                                                    </div>
                                                    <div className={tableStyle.linksButtonWrapper}>
                                                        <DownloadLink
                                                            linkInnerText={'Download'}
                                                            file={item?.signature}
                                                            small={true}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <p style={{ color: 'rgba(75, 92, 104, 0.5' }}>Not Set</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    : (
                        <NoItemText text={'No appointments yet'} />
                    )}
            </div>
        </div>
    );
};