import { DateRow, PriceRow, SimpleTooltip, TextRow, TypeRow } from 'components';
import { Checkbox } from '@material-ui/core';
import { makeCapitalize } from '../../utils';
import React from 'react';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const ACTION_TYPE = 'GET_ACTIVE_PAYROLL';


export const activePayrollHead = (handleSelectAll, selectedList, activePayrolls) => {
    return [
        {
            renderView: <SimpleTooltip title={<p>Select All</p>} placement="top-start">
                <Checkbox
                {...label}
                style={{ padding: '0', color: '#347AF0' }}
                onClick={(e) => handleSelectAll(e)}
                checked={activePayrolls?.length ? selectedList?.length === activePayrolls?.length : false}
            /></SimpleTooltip>,  width: '50px'
        },
        { name: 'displayId', title: 'Timesheet ID', custom: false, noSearch: true, width: '15%' },
        { name: 'staff', rowName: 'firstName', title: 'Staff name', custom: false, noSearch: true, width: '15%' },
        {
            name: 'type', title: 'Type', disabled: true, width: '10%',
            filterList: [
                { name: 'All', id: 'All' },
                { name: 'Hourly', id: 'HOURLY' },
                { name: 'Salary', id: 'SALARY' },
                { name: 'Fixed', id: 'FIXED' },
                { name: 'Mileage', id: 'MILEAGE' },
            ], noSearch: true
        },
        { name: 'timesheetDate', title: 'Created Date', icon: 'date', disabled: true, custom: false, noSearch: true, width: '15%'  },
        { name: 'cptCode', title: 'CPT Code', custom: false, noSearch: true, width: '15%' },
        { name: 'hours', title: 'Total hours', custom: false, noSearch: true, width: '15%' },
        { name: 'totalAmount', title: 'Total Amount', custom: false, noSearch: true, width: '15%' },
    ];
};

export const activePayrollBody = (history, handleSelectItem, selectedList) => {
    return [
        {
            button: (click, item) => <SimpleTooltip title={<p>Select</p>} placement="top-start">
                <Checkbox
                {...label}
                style={{ padding: '0', color: '#347AF0' }}
                onClick={() => handleSelectItem(item?.id)}
                checked={selectedList?.includes(item?.id)}
            /></SimpleTooltip>,
            notClickable: true,
        },
        { rowText: (item) => <TextRow name={item?.displayId} textWidth={10} /> },
        {
            rowText: (item) => <div className="staff-name-btn"
                                    onClick={() => history.push(`/staff/${item?.staff?._id}`)}>
                <TextRow name={`${item?.staff?.firstName} ${item?.staff?.lastName ? item?.staff?.lastName : ''}`}
                         textWidth={10} />
            </div>,
            notClickable: true,
        },
        {
            rowText: (item) => <TypeRow
                text={item?.payCode?.payCodeTypeId ? item?.payCode?.payCodeTypeId?.type : 'Fixed'} />,
        },
        { rowText: (item) => <DateRow date={item?.timesheetDate} textWidth={10} /> },
        { rowText: (item) => <TextRow name={item?.cptCode ? item?.cptCode : 'N/A'} textWidth={10} /> },
        {
            rowText: (item) => <TextRow
                name={item?.regularHours === 'NaNhNaNm' ? 'N/A' : item?.regularHours?.replace('h', 'h ')}
                textWidth={10} />,
        },
        { rowText: (item) => <PriceRow info={item?.totalAmount} textWidth={10} /> },
    ];
};

export const informationHead = [
    { name: 'rateType', title: 'Rate Type', custom: false, noSearch: true },
    { name: 'rate', title: 'Rate', custom: false, noSearch: true },
    { name: 'hours', title: 'Hours', custom: false, noSearch: true },
    { name: 'amount', title: 'Amount', custom: false, noSearch: true },
];

export const informationBody = [
    {
        rowText: (item) => <TextRow
            name={item?.payCode?.payCodeTypeId?.type ? makeCapitalize(item?.payCode?.payCodeTypeId?.type) : 'Fixed'}
            textWidth={10} />,
    },
    {
        rowText: (item) => <PriceRow
            info={!!item?.amount && !!item?.hours ? item.amount / item.hours : item?.payCode?.rate} textWidth={10} />,
    },
    {
        rowText: (item) => <TextRow name={item?.regularHours === 'NaNhNaNm' ? 'N/A' : item?.regularHours || item?.hours}
                                    textWidth={10} />,
    },
    { rowText: (item) => <PriceRow info={item.totalAmount ? item?.totalAmount : 0} textWidth={10} /> },
];


export const PROCESSED_ACTION_TYPE = 'GET_PROCESSED_PAYROLL';


export const processedHead = [
    { name: 'id', title: 'Batch ID', custom: false },
    { name: 'staffName', title: 'Processor', custom: false },
    { name: 'startDate', title: 'Start Date', custom: false },
    { name: 'endDate', title: 'End Date', custom: false },
    { name: 'processed', title: 'Processed Date', custom: false },
    { name: 'totalPay', title: 'Total Pay', custom: false },
    { name: ' ', title: ' ', custom: false, width: '55px' },
];

export const activeHead = [
    { name: 'id', title: 'Timesheet ID', custom: false },
    { name: 'staffName', title: 'Staff name', custom: false },
    { name: 'startDate', title: 'Type', custom: false },
    { name: 'endDate', title: 'End Date', custom: false },
    { name: 'processed', title: 'Processed Date', custom: false },
    { name: 'totalPay', title: 'Total Pay', custom: false },
    { name: ' ', title: ' ', custom: false, width: '73px' },
];

export const processedBody = [
    { rowText: (item) => <TextRow name={item?.batchId} textWidth={10} /> },
    {
        rowText: (item) => <div className="staff-name-btn" onClick={() => history.push(`/staff/${item?.staff?._id}`)}>
            <TextRow name={`${item?.staff?.firstName} ${item?.staff?.lastName ? item?.staff?.lastName : ''}`}
                     textWidth={10} />
        </div>,
        notClickable: true,
    },
    { rowText: (item) => <DateRow date={item?.startDate} textWidth={10} /> },
    { rowText: (item) => <DateRow date={item?.endDate} textWidth={10} /> },
    { rowText: (item) => <DateRow date={item?.processedDate} textWidth={10} /> },
    { rowText: (item) => <PriceRow info={item?.totalPay} textWidth={10} /> },
];