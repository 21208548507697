export const ErrorText = {
   field: "Input is required",
   globalError: "Inputs are not field",
   emailValid: "Invalid Email Format",
   passwordValid: "Your password does not meet the criteria.",
   selectField: "Input is not field",
   // selectField: "One of the options must be selected",
   dateError: "End date cannot be before the start date",
   timeError: "End Time must be greater than Start Time",
   startDateError: "Start Date cannot be in the future, if you currently work!",
   endDateError: "End Date must be entered!",
   overlappingError: (value = "") =>
      `${value} cannot overlap, please change the date or the time!`,
   phoneError: "Phone number must be a valid phone number",
   emailError: "User already exists",
   equalityError: (min, max) => `${min} must be smaller than ${max}`,
   websiteError: "Invalid Website",
   existenceError: (value = "") => `${value} already exists`,
   enrollmentError: "Can not be two active Enrollment with the same Funding Source",
   enrollmentPrimaryError:
      "Can not set primary because enrollment has a termination date",
   availableModifierError: "Please select some charge rate",
   authServiceDefaultError: "Can not be two default modifiers with same funding service",
   ssnError: "The Social Security Number (SSN) must contain 9 digits",
   paycodeActiveError: "Can not be two active payCodes with same employment",
   mileageCompensationError: "All the mileages should be terminated",

   isRequired: 'is required.',
   isRequiredSelect: 'must be selected.',
   requiredAddress: 'Street address is required.'
};




