import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentQRCode = () => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };
  ;

  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-menu-design  qr-wrapper">

        <div>
          <h2 className="title">
            QR Code
          </h2>

          <p className="description qr-subtitle-margin" style={{ maxWidth: "676px" }}>
            Create and customize QR codes to enhance your restaurant's digital experience. Here's what you can do:
          </p>

          <div className="controlPanelContentMenus-start_adding" id="createQRCode" style={{ maxWidth: "676px" }}>
            <div className="create-qr-wrapper">
              <h3 className="subtitle">
                Create QR Code
              </h3>

              <ul className="lists">
                <li className="description">
                  Located in the right corner, you'll find the.
                  <span className="description" style={{ fontWeight: "400" }}>
                   Create QR code
                    </span>
                  button.
                </li>

                <li className="description">
                  Click here to start designing your QR code.
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div className="controlPanelContentMenus-start_adding" id="qrDesignCustomization" style={{ maxWidth: "676px" }}>

          <h3 className="subtitle">
            QR Design Customization
          </h3>

          <ul className="lists">
            <li className="description">
              Customize your QR code style to match your brand identity.
            </li>

            <li className="description">
              Choose the QR code color, border color, and background color to create a unique design that reflects your brand's identity.
            </li>
          </ul>
        </div>

        <div className="controlPanelContentMenus-start_adding" id="qrDesignPreview" style={{ maxWidth: "676px" }}>

          <h3 className="subtitle">
            QR Design Preview
          </h3>

          <ul className="lists">
            <li className="description">
              See your QR code style changes in action with the preview located in the right corner.
            </li>

            <li className="description">
              This preview feature enables you to visualize the adjustments you make instantly, ensuring your QR code reflects your desired design accurately.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

