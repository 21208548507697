import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {FullTable} from "components";
import { useModal, } from "utils";
import {serviceSingleStyles} from "./styles";
import {useDispatch} from "react-redux";
import {adminActions} from "store";
import {timesheetBody, timesheetHead, TIMESHEET_ACTION_TYPE} from "./constants";
import { TimesheetDetails } from './modals/timesheetDetails';

export const StaffTimesheet = ({staffTimesheets = []}) => {
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const {open} = useModal()
    const params = useParams();
    const history = useHistory();
    const info = history?.location?.state;
    const [active, setActive] = useState("ACTIVE");

    useEffect(() => {
        if (info) {
            const pushInfo = {
                ...info,
                status: active
            }
            delete pushInfo.page
            delete pushInfo.activeTab
            delete pushInfo.skip
            delete pushInfo.limit
            dispatch(adminActions.getTimesheet(params.id, {...pushInfo}));
        }
    }, [info, active]);

    useEffect(() => {
        dispatch(adminActions.removeTimesheetById())
        return () => dispatch(adminActions.clearAllPayCodes());
    }, []);

    const handleClick = (id) => {
        dispatch(adminActions.getTimesheetById(id));
        open(<TimesheetDetails activeTab={active}/>)
    };

    const handleChangeType = (type) => {
        setActive(type)
        dispatch(adminActions.removeTimesheetById())
    }

    return (
        <>
            <div className={classes.switcher}>
                <p className={active === "ACTIVE" ? classes.switcherActive : classes.switcherProcessed}
                   onClick={() => handleChangeType("ACTIVE")}
                >
                    Active
                </p>
                <p className={active === "PROCESSED" ? classes.switcherActive : classes.switcherProcessed}
                   onClick={() => handleChangeType("PROCESSED")}
                >
                    Processed
                </p>
            </div>
            <div className={classes.timesheetWrapper}>
                <FullTable
                    head={timesheetHead}
                    body={timesheetBody}
                    loadingType={TIMESHEET_ACTION_TYPE}
                    handleClick={handleClick}
                    list={staffTimesheets || []}
                    noText={"timesheets"}
                />
            </div>
        </>
    );
};
