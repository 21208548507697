import React from "react";
import { fundingSourceCommonCoreStyle } from "./styles";
import {PermissionList, RolePermission} from "utils";

export const ModifierTHead = () => {
   const classes = fundingSourceCommonCoreStyle();

   return (
      <div className={classes.tableTheadStyle}>
         <div className={classes.thStyle}>{"Title"}</div>
         <div className={classes.thStyle}>{"Modifier"}</div>
         <div className={classes.thStyle}>{"Credential"}</div>
         <div className={classes.thStyle}>{"Amount"}</div>
         <div className={classes.thStyle}>{"Type"}</div>
          {RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) &&
              <div className={classes.thStyle}>{"Action"}</div>
          }
         <div className={classes.thStyle}>{"Status"}</div>
      </div>
   );
};
