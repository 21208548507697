import React from 'react';
import { useDispatch } from 'react-redux';
import { appointmentActions } from 'store';
import { ToolInfo } from 'components';
import { Images, useModal } from 'utils';
import { scheduleModalsStyle } from '../styles';
import { InfoModal } from '../infoModal';

export const CancellingAppts = ({ apptDate }) => {
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch()
    const { open } = useModal();

    const handleCancel = () => {
        open(<InfoModal
                titleText={'Confirm Cancel All Appointments'}
                subText={'Cancelling all appointments associated with this template will remove the entire series. This action cannot be undone. Are you sure you want to proceed?'}
                btnText={'Yes, Cancel All'}
                chancelText={'Keep Appointments'}
                redBtn={true}
                loaderType={'CANCEL_APPT'}
                handleSubmit={() => dispatch(appointmentActions.cancelAppt(apptDate?.template))}
            />,
        );
    };

    const handleDelete = () => {
        open(<InfoModal
                titleText={'Confirm Delete All Appointments'}
                subText={'Deleting all appointments associated with this template will remove the entire series. This action cannot be undone. Are you sure you want to proceed?'}
                btnText={'Yes, Delete All'}
                chancelText={'Keep Appointments'}
                redBtn={true}
                loaderType={'DELETE_ALL_APPT'}
                handleSubmit={() => dispatch(appointmentActions.deleteAllAppt(apptDate?.template))}
            />,
        );
    };

    return (
        <div style={{marginBottom:'24px'}}>
            <div className={classes.cancellingOptionBox}>
                <p className={classes.cancellingTitle}>Cancelling Option</p>
                <div className={classes.informationBox}>
                    <div className={classes.multipleServiceNote}>
                        <ToolInfo text={''} />
                        <p style={{fontSize: 14}}>
                            Triggering this action will cancel all pending appointments associated with this template.
                        </p>
                    </div>
                    <button onClick={handleCancel}>
                        <img src={Images.removeIcon} alt='icon' />
                        <p> Cancel All Appointments</p>
                    </button>
                </div>
            </div>

            <div className={classes.cancellingOptionBox}>
                <p className={classes.cancellingTitle}>Delete Option</p>
                <div className={classes.informationBox}>
                    <div className={classes.multipleServiceNote}>
                        <ToolInfo text={''} />
                        <p style={{ fontSize: 14 }}>
                            Triggering this action will delete all pending appointments associated with this template.
                        </p>
                    </div>
                    <button onClick={handleDelete}>
                        <img src={Images.removeIcon} alt='icon' />
                        <p> Delete All Appointments</p>
                    </button>
                </div>
            </div>
        </div>
    );
};