import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { invoicePaymentActions } from 'store';
import { ACTION_TYPE, invoicePaymentHead } from './constants';
import { invoicePaymentsStyle } from './styles';
import { InvoiceFilters } from './invoiceFilters';
import { InvoicePaymentTable } from 'fragments';

export const InvoicePayments = () => {
    const invoicePaymentsData = useSelector((state) => state.invoicePayment.invoicePayments);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const classes = invoicePaymentsStyle();

    useEffect(() => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        pushInfo.skip = info?.skip ? info?.skip : 0;
        pushInfo.limit = 50;

        if (!info?.client) {
            delete pushInfo.client;
        }
        if (!info?.status) {
            delete pushInfo.status;
        }

        dispatch(invoicePaymentActions.getInvoicePayments({ ...pushInfo }));
    }, [info]);

    return (
        <div>
            <div className={classes.addButton}>
                <InvoiceFilters />
            </div>
            <InvoicePaymentTable
                head={invoicePaymentHead}
                loadingType={ACTION_TYPE}
                list={invoicePaymentsData?.invoices}
                listCount={invoicePaymentsData?.count}
                noText={'invoice payments'}
                activeRowId={''}
            />
        </div>
    );
};
