import React from 'react';
import { Checkbox } from '@material-ui/core';
import { convertHours, Images } from 'utils';
import { DateRow, DownloadLink, PriceRow, SimpleTooltip, TextRow } from 'components';

export const generateInvoiceHead = (handleSelect, fullList, selected) => {
    return [
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: 'white' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>, width: '50px',
        },
        { name: '', title: 'Bill ID', width: '120px', noSearch: true, custom: false },
        { name: '', title: 'Service Code', icon: 'date', noSearch: true, custom: false },
        { name: '', title: 'Place of Service', noSearch: true, custom: false },
        { name: '', title: 'Service Date', noSearch: true, custom: false },
        { name: '', title: 'Client', noSearch: true, custom: false },
        { name: '', title: 'Hours', noSearch: true, custom: false },
        { name: '', title: 'Invoice Amount', noSearch: true, custom: false },
        { name: '', title: 'Signature', noSearch: true, custom: false, width: '160px' },
    ];
};

export const generateInvoiceBody = (handleSelect, fullList, selected, handleEdit) => {
    return [
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{ padding: 0, color: '#0C66E4' }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) => <TextRow name={item?.displayId ? item?.displayId : 'N/A'} />,
        },
        {
            rowText: (item) => <TextRow name={item?.serviceCode ? item?.serviceCode : 'N/A'} />,
        },

        { rowText: (item) => <TextRow name={item?.placeOfService} /> },
        { rowText: (item) => <DateRow date={item?.dateOfService} /> },
        { rowText: (item) => <TextRow name={item?.client} /> },
        { rowText: (item) => <div>{convertHours(item?.hours)}</div> },
        { rowText: (item) => <PriceRow info={item?.invoiceAmount} /> },
        {
            rowText: (item) => item?.signature?.url ?
                <div style={{ gap: 8 }} className="flex-align-center">
                    <div style={{ width: 90 }}>
                        <DownloadLink
                            file={item?.signature}
                            linkInnerText={'Signature'}
                        />
                    </div>
                    <a href={item?.signature?.url} target={'_blank'}>
                        <img src={Images.show} alt={'download'} />
                    </a>
                </div>
                :
                <p>N/A</p>,
        },

    ];
};

export const ACTION_TYPE = 'GET_INVOICES_FOR_GENERATE';
