import React from 'react';
import { DetailItem } from '../detailItem';
import { formatMiles, makeCapitalize } from 'utils';
import { DRIVE, UNPAID } from '../../constants';

export const OtherItems = ({ item }) => {
    return (
        <>{item?.type === UNPAID ?
            <div className='detail-information'>
                <div className='full-width'>
                    <DetailItem
                        title={'Creator'}
                        value={item?.creator ? item.creator : 'N/A'}
                    />
                    <DetailItem
                        title={'Last Editor'}
                        value={item?.editor ? item?.editor : 'N/A'}
                    />
                </div>
                <div className='full-width' style={{ marginLeft: '8px' }}>
                    <DetailItem
                        title={'Staff Member'}
                        value={makeCapitalize(`${item?.staff?.firstName} ${item?.staff?.lastName}`)}
                    />
                </div>
            </div>
            :
            <div className='detail-information'>
                <div className='full-width'>
                    <DetailItem
                        title={'Staff Member'}
                        value={makeCapitalize(`${item?.staff?.firstName} ${item?.staff?.lastName}`)}
                    />
                    <DetailItem
                        title={'Staff Paycode'}
                        value={makeCapitalize(item?.staffPayCode)}
                    />
                    {item?.type === DRIVE && <DetailItem title={'Miles'} value={formatMiles(item?.miles)} />}
                </div>
                <div className='full-width' style={{ marginLeft: '8px' }}>
                    <DetailItem
                        title={'Creator'}
                        value={item?.creator ? item.creator : 'N/A'}
                    />
                    <DetailItem
                        title={'Last Editor'}
                        value={item?.editor ? item?.editor : 'N/A'}
                    />
                </div>
            </div>
        }
        </>
    );
};