import { scheduleModalsStyle } from './styles';
import { CreateChancel } from 'components';
import React from 'react';
import { FindLoad, useModal } from 'utils';


export const InfoModal = ({ handleSubmit, titleText, subText, btnText, loaderType, redBtn, chancelText }) => {
    const classes = scheduleModalsStyle()
    const loader = FindLoad(loaderType)
    const { close } = useModal()


    return(
        <div className={classes.lockUnlockWrapper}>
            <p className={classes.confirmTitle}>{titleText}</p>
            <p className={classes.confirmSubTitle}>{subText}</p>

            <CreateChancel
                loader={!!loader?.length }
                create={btnText}
                chancel={chancelText ? chancelText :  "Cancel"}
                onCreate={ handleSubmit}
                onClose={() => close()}
                buttonWidth="223px"
                createButnMargin={"16px"}
                redBtn={redBtn}
            />
        </div>
    )
}