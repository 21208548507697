import {Images, useGlobalTextStyles, useModal} from "utils";
import {staffModalsStyle} from "./styles";
import {TimesheetModal} from "./timesheetModal";


export const ModalSelector = ({  }) => {
    const globalStyle = useGlobalTextStyles();
    const classes = staffModalsStyle()
    const { open } = useModal()

    const handleOpen = (type) => {
        open(<TimesheetModal type={type} />)
    }

    return (
        <div>
            <p className={globalStyle.modalTitle}>{'Add Timesheet'}</p>
            <p className={globalStyle.modalText}>{'Please select timesheet type.'}</p>
            <button className={classes.typeBtn} onClick={() => handleOpen('fixed')}>
                <p>Fixed Pay</p>
                <img src={Images.east} alt=""/>
            </button>

            <button className={classes.typeBtn} onClick={() => handleOpen('payCodes')}>
                <p> From Paycodes</p>
                <img src={Images.east} alt=""/>
            </button>
        </div>
    )
}