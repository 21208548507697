import React, { useState } from 'react';
import { Colors, Images } from 'utils';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { buttonsStyle } from '../../../../../components/buttons/styles';

export const DownloadInvoicePdf = ({}) => {
    const classes = buttonsStyle();
    const [loader, setLoader] = useState(false);
    const params = useParams();
    const token = localStorage.getItem('access-token');

    const saveFile = async () => {
        setLoader(true);
        axios({
            url: `/invoice/${params?.id}/pdf`,
            method: 'GET',
            responseType: 'blob',
            headers: { 'access-token': `${token}` },
        }).then((response) => {
            setLoader(false);

            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${response?.data?.fileName ? response?.data?.fileName : 'invoice'}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => setLoader(false));
    };

    return (
        <button
            style={{ border: 'none', background: 'transparent', cursor: 'transparent', padding: 0 }}
            className={classes.downloadPdfBtn}
            onClick={saveFile}
        >
            <>
                Download
                {loader ?
                    <CircularProgress
                        style={{ color: Colors.BackgroundBlue, width: '15px', height: '15px', marginLeft: '8px' }} />
                    :
                    <img src={Images.download} alt='' />
                }
            </>
        </button>
    );
};
