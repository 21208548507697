import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentOrders = ( ) => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };

  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-menu-design orders-wrapper">
        <div className="orders_text-desc">
          <h2 className="title">
            Orders
          </h2>

          <p className="description">
            Manage and process orders with details like ordered items, order ID, table number, and total amount.
          </p>


          <p className="description">
            You can manage all incoming orders. There are two main categories:
            <span style={{ fontWeight: "500" }}>
             Pending
            </span>
            and Processed
          </p>
        </div>

        <div className="controlPanelContentMenus-start_adding" id="pendingOrders" style={{ maxWidth: "661px" }}>
          <div className="organize-box">
            <h2 className="subtitle">
              Pending Orders
            </h2>

            <div>
              <ul className="lists">
                <li className="description">
                  Pending orders are those awaiting action. What you'll find for each pending order:
                </li>

                <li className="description">
                    <span style={{ fontWeight: "500" }}>
                     Order ID:
                    </span>
                  A unique identifier for the order, helping you track and manage it efficiently.
                </li>

                <li className="description">
                    <span style={{ fontWeight: "500" }}>
                      Table:
                    </span>
                  The table number associated with the order, allows your staff to deliver the order to the correct location.
                </li>

                <li className="description">
                    <span style={{ fontWeight: "500" }}>
                      Order Total:
                    </span>
                  The total amount due for the order, helping you keep track of revenue.
                </li>
              </ul>
              <p className="description">
                Each item begins with a simple click, making it easy to get started.
              </p>
            </div>
            <p className="description">
              Additionally, the manager can approve or reject the order before it is processed.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
    ;
};

