import { call, put, takeLatest } from 'redux-saga/effects';
import { appointmentService } from './appointment.service';
import {
    APPEND_SIGNATURE_TO_APPMT,
    APPOINTMENT_REPEAT, CANCEL_APPT, CHANGE_APPOINTMENT_LOCK_UNLOCK, CONNECT_RECONNECT_TEMPLATE,
    CREATE_APPOINTMENT, DELETE_ALL_APPT,
    DELETE_APPOINTMENT, DELETE_APPOINTMENT_SUCCESS,
    EDIT_APPOINTMENT,
    GET_APPOINTMENT,
    GET_APPOINTMENT_BY_ID,
    GET_APPOINTMENT_BY_ID_SUCCESS,
    GET_APPOINTMENT_SUCCESS, GET_CLIENT_STAFFS, GET_CLIENT_STAFFS_SUCCESS, MANAGE_NOTES,
    SET_APPOINTMENT_STATUS,
} from './appointment.type';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

/** Create, Edit Appointment */
function* createAppointmentSaga(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        const res = yield call(appointmentService.createAppointmentService, action?.payload?.body);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: res.data?.id || res.data?._id },
        });
        // yield put({ type: GET_APPOINTMENT, payload: { load: "noload" } });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editAppointmentSaga(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.editAppointmentService, action?.payload?.body, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'appointment overlapping') {
            yield put(httpRequestsOnErrorsActions.appendError('APPOINTMENT_OVERLAPPING_TOASTER', err?.data?.message));
        }
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));

    }
}

function* editApptLockUnlock(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.editApptLockUnlockService, action?.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));

        // yield put(httpRequestsOnErrorsActions.appendError('APPOINTMENT_OVERLAPPING_TOASTER', err?.data?.message));
        // yield put(httpRequestsOnErrorsActions.appendError('APPOINTMENT_OVERLAPPING', err?.data?.message));
    }
}

/** end */

/** Get Appointment */
function* getAppointmentSaga(action) {
    if (action.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(appointmentService.getAppointmentService, action?.payload?.params);
        yield put({
            type: GET_APPOINTMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_APPOINTMENT_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getAppointmentByIdSaga(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(appointmentService.getAppointmentByIdService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_APPOINTMENT_BY_ID_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
        // yield put({
        //     type: GET_GLOBAL_NOTES_SUCCESS,
        //     payload: [],
        // });
    }
}

/** end */

/** Delete Appointment */
function* deleteAppointmentSaga(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.deleteAppointmentService, action.payload.id);
        // yield put({
        //     type: DELETE_APPOINTMENT_SUCCESS,
        //     payload: action.payload.id,
        // });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action?.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        // yield put({ type: GET_APPOINTMENT, payload: { load: 'noLoad', params: action?.payload?.params } });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** end */

/** Appointment Status */
function* setAppointmentStatusSaga(action) {
    const loaderType =
        action?.payload?.statusName === 'complete' ? 'SET_APPOINTMENT_STATUS_COMPLETE' :
            action?.payload?.statusName === 'render' ? 'SET_APPOINTMENT_STATUS_COMPLETE' :
                'SET_APPOINTMENT_STATUS_CANCEL';
    yield put(httpRequestsOnLoadActions.appendLoading(loaderType));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.setAppointmentStatusService, action?.payload?.id, action?.payload?.statusName, action?.payload?.reason);
        yield put(httpRequestsOnLoadActions.removeLoading(loaderType));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(loaderType));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** end */

/** Appointment Repeat */
function* appointmentRepeatSaga(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(
            appointmentService.appointmentRepeatService,
            action.payload.id,
            action.payload.body,
        );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** end */

function* appendSignatureToAppmt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(
            appointmentService.appendSignatureToAppmtService,
            action.payload.id,
            action.payload.signature,
        );
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: action.payload.id, load: 'noLoad' },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getClientStaffs(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(appointmentService.getClientStaffsService, action?.payload);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        // yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_CLIENT_STAFFS_SUCCESS,
            payload: res?.data,
        });
    } catch (err) {
        yield put({
            type: GET_CLIENT_STAFFS_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* manageNotes(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        const res = yield call(appointmentService.manageNotesService, action.payload.id, action.payload.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put({
            type: GET_APPOINTMENT_BY_ID,
            payload: { id: action.payload.id },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* connectReconnect(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.connectReconnectService, action?.payload?.id, action?.payload?.params);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* cancelAppt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.cancelApptService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteAllAppt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess('RENDER_APPOINTMENTS_LIST'));
    try {
        yield call(appointmentService.deleteAllApptService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess('RENDER_APPOINTMENTS_LIST'));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

export const watchAppointments = function* watchAppointmentsSaga() {
    /** Create, Edit Appointment */
    yield takeLatest(CREATE_APPOINTMENT, createAppointmentSaga);
    yield takeLatest(EDIT_APPOINTMENT, editAppointmentSaga);
    yield takeLatest(CHANGE_APPOINTMENT_LOCK_UNLOCK, editApptLockUnlock);
    /** end */

    /** Get Appointment */
    yield takeLatest(GET_APPOINTMENT, getAppointmentSaga);
    yield takeLatest(GET_APPOINTMENT_BY_ID, getAppointmentByIdSaga);
    /** end */

    /** Delete Appointment */
    yield takeLatest(DELETE_APPOINTMENT, deleteAppointmentSaga);
    /** end */

    /** Appointment Status */
    yield takeLatest(SET_APPOINTMENT_STATUS, setAppointmentStatusSaga);
    /** end */

    /** Appointment Repeat */
    yield takeLatest(APPOINTMENT_REPEAT, appointmentRepeatSaga);
    /** end */

    yield takeLatest(APPEND_SIGNATURE_TO_APPMT, appendSignatureToAppmt);
    yield takeLatest(GET_CLIENT_STAFFS, getClientStaffs);
    yield takeLatest(MANAGE_NOTES, manageNotes);
    yield takeLatest(CONNECT_RECONNECT_TEMPLATE, connectReconnect);
    yield takeLatest(CANCEL_APPT, cancelAppt);
    yield takeLatest(DELETE_ALL_APPT, deleteAllAppt);
};
