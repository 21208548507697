import {Images} from "../../utils";
import React from "react";
import {SimpleTooltip} from "./tooltip";

export const ToolInfo = ({text}) => {
    return (
        <div>
            <SimpleTooltip title={<div>{text}</div>} placement="top-start">
                <img src={Images.toolInfoBlack} alt={'icon'}/>
            </SimpleTooltip>
        </div>
    )
}