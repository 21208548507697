import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { serviceSingleStyles } from './styles';
import { FindLoad, Images, makeCapitalize, PermissionList, RolePermission, useModal } from 'utils';
import {
    AddButton,
    AddModalButton,
    AddNotes,
    ValidationInput,
    SimpleTooltip,
    DetailInfoCard,
    NameAndAvatar,
    UploadModal, UserInputsDropdown,
} from 'components';
import { AddContact, AddAuthorization } from 'fragments/client';
import { historyActions } from 'store';
import { CLIENT_STATUSES } from './constants';

const filterBtn = {
    width: 93,
    height: 36,
};

export const TabsHeader = ({ data }) => {
    const client = useSelector((state) => state.client.clientItemInfo);
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const allEnrolments = useSelector((state) => state?.client?.clientEnrollment);
    const activeEnrollments = allEnrolments?.active?.filter((item) => item.type === 'PRIMARY');
    const [searchDate, setSearchDate] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    const clientHistoryLoader = !!FindLoad('GET_HISTORY').length;
    let history = useHistory();
    const info = history?.location?.state;
    const { open } = useModal();

    const handleChangeDate = (e) => {
        setIsDisabled(false);
        setSearchDate(e.target.value);
    };

    const handleSubmit = () => {
        setIsDisabled(true);
        const paramsForClientHistory = {
            onResource: data?.id,
            start: searchDate && new Date(searchDate).toISOString(),
        };
        dispatch(historyActions.getHistory('Client', paramsForClientHistory));
    };

    return (
        <Fragment>

            <div className="tabs-wrapper">
                <div className="full-width">
                    <div className="space-between-flex-start">
                        <NameAndAvatar
                            src={data?.avatar}
                            name={makeCapitalize(`${data?.firstName} ${data?.lastName}`)}
                        />
                        <div className="flex-align-start" style={{ gap: 16 }}>
                            {RolePermission([PermissionList.CLIENT_STATUS?.code]) &&
                                <UserInputsDropdown
                                    dropdownOptions={CLIENT_STATUSES}
                                    selected={data?.status}
                                    type={'client'}
                                    client={client}
                                />
                            }
                            {info?.activeTab === 'History' ? (
                                    <>
                                        <div className={classes.searchContainer}>
                                            <ValidationInput
                                                className={classes.dateInput}
                                                errorFalse={true}
                                                variant={'outlined'}
                                                onChange={(e) => handleChangeDate(e)}
                                                value={searchDate}
                                                type={'date'}
                                                name="searchDate"
                                            />
                                            <AddModalButton
                                                handleClick={handleSubmit}
                                                text="Search"
                                                btnStyles={filterBtn}
                                                loader={clientHistoryLoader}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </>
                                ) :
                                info?.activeTab === 'Authorization' && !activeEnrollments?.length &&
                                RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) ? (
                                    <SimpleTooltip
                                        title={
                                            <p className={classes.infoTextForAuthStyle}>
                                                You can only add Authorization if you have at least one
                                                Enrollment.
                                            </p>
                                        }
                                        placement="top-end"
                                    >
                                        <div>
                                            <AddButton text={'Add Authorization'} disabled={true} />
                                        </div>
                                    </SimpleTooltip>
                                ) : (
                                    info?.activeTab === 'Contacts' && RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) ?
                                        <AddButton text={'Add Contact'}
                                                   handleClick={() => open(<AddContact />)} />
                                        :
                                        info?.activeTab === 'Authorization' &&
                                        RolePermission([
                                            PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code,
                                        ]) ?
                                            <AddButton text={'Add Authorization'}
                                                       handleClick={() => open(<AddAuthorization
                                                           allList={clientsAuthorizations} />)} />
                                            :
                                            info?.activeTab === 'Notes' && RolePermission([PermissionList.CLIENT_NOTE_MANAGE?.code]) ?
                                                <AddButton
                                                    text="Add Notes"
                                                    handleClick={() => open(<AddNotes model="Client"
                                                                                      id={data?.id} />)}
                                                />
                                                :
                                                info?.activeTab === 'Files' && RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) ?
                                                    <AddButton
                                                        Icon={true}
                                                        customIcon={Images.uploadCloud}
                                                        text={'Upload Document'}
                                                        handleClick={() => open(
                                                            <UploadModal
                                                                currentId={data?.id}
                                                                onResource={'client'}
                                                            />,
                                                        )}
                                                    />
                                                    : null
                                )}
                        </div>
                    </div>


                    <div className={classes.staffGeneralWrapper}>
                        {data?.code &&
                            <DetailInfoCard image={Images.verify} title={data?.code} />
                        }
                        {data?.email &&
                            <DetailInfoCard image={Images.sms} title={data?.email} type={'email'} />
                        }
                        {data?.phoneNumber &&
                            <DetailInfoCard image={Images.callCalling} title={data?.phoneNumber}
                                            type={'phone'} />
                        }
                    </div>

                </div>
            </div>


            {/*<ul className="tabs-wrapper" style={{ alignItems: 'flex-start' }}>*/}
            {/*    <div>*/}
            {/*        <NameAndAvatar*/}
            {/*            src={data?.avatar}*/}
            {/*            name={makeCapitalize(`${data?.firstName} ${data?.lastName}`)}*/}
            {/*        />*/}
            {/*        <div className={classes.staffGeneralWrapper}>*/}
            {/*            {data?.code &&*/}
            {/*                <DetailInfoCard image={Images.verify} title={data?.code} />*/}
            {/*            }*/}
            {/*            {data?.email &&*/}
            {/*                <DetailInfoCard image={Images.sms} title={data?.email} type={'email'} />*/}
            {/*                        }*/}
            {/*                        {data?.phoneNumber &&*/}
            {/*                            <DetailInfoCard image={Images.callCalling} title={data?.phoneNumber}*/}
            {/*                                            type={'phone'} />*/}
            {/*                        }*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <li className={classes.headerRight}>*/}
            {/*                    {info?.activeTab === 'History' ? (*/}
            {/*                            <>*/}
            {/*                                <div className={classes.searchContainer}>*/}
            {/*                                    <ValidationInput*/}
            {/*                                        className={classes.dateInput}*/}
            {/*                                        errorFalse={true}*/}
            {/*                                        variant={'outlined'}*/}
            {/*                                        onChange={(e) => handleChangeDate(e)}*/}
            {/*                                        value={searchDate}*/}
            {/*                                        type={'date'}*/}
            {/*                                        name="searchDate"*/}
            {/*                                    />*/}
            {/*                                    <AddModalButton*/}
            {/*                                        handleClick={handleSubmit}*/}
            {/*                                        text="Search"*/}
            {/*                                        btnStyles={filterBtn}*/}
            {/*                                        loader={clientHistoryLoader}*/}
            {/*                                        disabled={isDisabled}*/}
            {/*                                    />*/}
            {/*                                </div>*/}
            {/*                            </>*/}
            {/*                        ) :*/}
            {/*                        info?.activeTab === 'Authorization' && !activeEnrollments?.length &&*/}
            {/*                        RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) ? (*/}
            {/*                            <SimpleTooltip*/}
            {/*                                title={*/}
            {/*                                    <p className={classes.infoTextForAuthStyle}>*/}
            {/*                                        You can only add Authorization if you have at least one*/}
            {/*                                        Enrollment.*/}
            {/*                                    </p>*/}
            {/*                                }*/}
            {/*                                placement="top-end"*/}
            {/*                            >*/}
            {/*                                <div>*/}
            {/*                                    <AddButton text={'Add Authorization'} disabled={true} />*/}
            {/*                                </div>*/}
            {/*                            </SimpleTooltip>*/}
            {/*                        ) : (*/}
            {/*                            (info?.activeTab === 'General' || info?.activeTab === undefined) && RolePermission([PermissionList.CLIENT_CREATE?.code]) ?*/}
            {/*                                <AddModalButton*/}
            {/*                                    btnStyles={editButtonStyle}*/}
            {/*                                    handleClick={() => open(<ClientDetails info={data} />)}*/}
            {/*                                    text="Edit"*/}
            {/*                                />*/}
            {/*                                :*/}
            {/*                                info?.activeTab === 'Contacts' && RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code]) ?*/}
            {/*                                    <AddButton text={'Add Contact'}*/}
            {/*                                               handleClick={() => open(<AddContact />)} />*/}
            {/*                                    :*/}
            {/*                                    info?.activeTab === 'Authorization' &&*/}
            {/*                                    RolePermission([*/}
            {/*                                        PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code,*/}
            {/*                                    ]) ?*/}
            {/*                                        <AddButton text={'Add Authorization'}*/}
            {/*                                                   handleClick={() => open(<AddAuthorization*/}
            {/*                                                       allList={clientsAuthorizations} />)} />*/}
            {/*                                        :*/}
            {/*                                        info?.activeTab === 'Notes' && RolePermission([PermissionList.CLIENT_NOTE_MANAGE?.code]) ?*/}
            {/*                                            <AddButton*/}
            {/*                                                text="Add Notes"*/}
            {/*                                                handleClick={() => open(<AddNotes model="Client"*/}
            {/*                                                                                  id={data?.id} />)}*/}
            {/*                                            />*/}
            {/*                                            :*/}
            {/*                                            info?.activeTab === 'Files' && RolePermission([PermissionList.CLIENT_FILE_MANAGE?.code]) ?*/}
            {/*                                                <AddButton*/}
            {/*                                                    Icon={true}*/}
            {/*                                                    customIcon={Images.uploadCloud}*/}
            {/*                                                    text={'Upload Document'}*/}
            {/*                                                    handleClick={() => open(*/}
            {/*                                                        <UploadModal*/}
            {/*                                                            currentId={data?.id}*/}
            {/*                                                            onResource={'client'}*/}
            {/*                                                        />,*/}
            {/*                                                    )}*/}
            {/*                                                />*/}
            {/*                                                : null*/}
            {/*                        )}*/}
            {/*                </li>*/}
            {/*            </ul>*/}
        </Fragment>
    );
};
