import {makeStyles} from "@material-ui/core/styles";
import {Colors, Shadow} from "utils";

export const receivableBillTableStyle = makeStyles(() => ({
    recBillTableStyle: {
        width: "100%",
        padding: "16px 24px",

        // boxShadow: Shadow.modalShadow,
        // borderRadius: "0 0 8px 8px",
        "@media(min-width: 1919px)": {
            padding: "40px",
        },
    },
    bodyWrapper:{
        maxHeight:'250px',
        height:'auto',
        overflow:'auto'
    },
    recBillContainerStyle: {width: "100%"},
    recBillTitleStyle: {
        fontSize: "24px",
        fontWeight: 700,
        color: Colors.TextSecondary,
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0 40px'
    },
    billTotals: {
        fontSize: "18px",
        fontWeight: 700,
        color: Colors.TextSecondary,
        margin: '40px 0 16px',
    },
    receivableBillContainerStyle: {
        width: "100%",
        marginTop: "25px",
    },
    buttonsTabWrapper: {
        width: '100%',
        display: 'flex',
        borderBottom: '4px solid #EBF2FD',
        borderRadius: '4px',

        '& button': {
            background: 'transparent',
            border: 'none',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#4B5C68',
            padding:'0 24px 12px',
            marginBottom:'-4px'
        }
    }
}));
