import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentSettings = () => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };


  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-menu-design  settings-wrapper">

        <div>
          <h2 className="title">
            Settings
          </h2>

          <p className="description settings-subDescription" style={{ maxWidth: "676px" }}>
            Customize details about your restaurant and connect your social media accounts. Here's how:
          </p>
        </div>


        <div className="settings-observer" id="restaurantSettings" style={{ maxWidth: "676px" }}>

          <h3 className="subtitle settings-subtitle-margin">
            Restaurant Settings
          </h3>

          <ul className="lists">
            <li className="description">
                <span style={{ fontWeight: "400" }}>
                 Restaurant Name:
                </span>
              Write your restaurant's name so customers can easily recognize it.
            </li>

            <li className="description">
                <span style={{ fontWeight: "400" }}>
                  About Restaurant:
                </span>
              Share a short story about your restaurant, telling people what makes it special.
            </li>

            <li className="description">
                <span style={{ fontWeight: "400" }}>
              Address:
                </span>
              Put in your restaurant's location so customers know where to find you.
            </li>
          </ul>
        </div>


        <div className="settings-observer" id="socialAccounts" style={{ maxWidth: "676px" }}>
          <h3 className="subtitle settings-subtitle-margin">
            Social Accounts
          </h3>
        </div>

        <p className="description" style={{ maxWidth: "676px", marginTop: "8px" }}>
          Make sure your restaurant's details are accurate and that customers can easily find you both online and in person.
        </p>
      </div>
    </div>
  );
};

