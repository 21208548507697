import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { HtmlTooltip } from '../tabs/fullTableCore/htmlTool';
import { SlicedText } from '../messages';
import { NumericFormat } from 'react-number-format';
import { formatDuration, PermissionList, RolePermission } from '../../utils/hooks';


export const TextRow = ({ name, textWidth, color }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';

    const text = (name ? name : '...') + '';
    return (
        <div ref={curr} className='text-style'>{RenderText(width, text, textWidth, color)}</div>
    );
};

export const LinkRow = ({ name, textWidth, color, href, checkPermission }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';

    const text = (name ? name : '...') + '';

    const handleOpen = () => {
        if (name && name !== 'N/A' && href) {
            window.open(href);
        }
    };

    const activeStyles =   {
        color:checkPermission ? '#347AF0' : '#4B5C68',
        textDecoration: checkPermission ? 'underline' : 'none',
        width:'fit-content',
        cursor: checkPermission ? 'pointer' : 'default'
    }

    return (
        <div ref={curr} className='text-style'>
           <p onClick={handleOpen} style={activeStyles}>{RenderText(width, text, textWidth, color)}</p>
        </div>
    );
};


export const IconAndTitle = ({ icon, title, textWidth, imgClass, label }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 'auto';

    return (
        <div ref={curr} className={`info-and-image ${label}`}>
            <img style={{ margin: '0 8px 0 0' }} className={imgClass ? `${imgClass}` : ''} src={icon} alt='Icon' />
            <div style={{ fontWeight: 'bold' }} className='full-width'>{RenderText(width, title, textWidth)}</div>
        </div>
    );
};

export const PriceRow = ({ info, textWidth }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;
    const price = (info ? info.toFixed(2) : 0) + '';

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);

        return <div ref={curr} className='text-style'>
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </p>
                    }
                    placement='top-start'
                >
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} prefix={'$'} />...
                    </p>
                </HtmlTooltip>
                :
                <p>{
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                }</p>
            }
        </div>;
    } else {
        return '...';
    }
};


export const UnitsRow = ({ info, textWidth, renderTime }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;

    const price = (info ? info.toFixed(2) : 0) + '';

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);
        return <div ref={curr} className='text-style'>
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                        </p>
                    }
                    placement='top-start'
                >
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} />...
                    </p>
                </HtmlTooltip>
                :
                <p>{
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                }</p>
            }
        </div>;
    } else {
        return '...';
    }
};

const convertHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${hours.toString().padStart(2, '0')}h${remainingMinutes.toString().padStart(2, '0')}m`;
}

export const UnitsRowByHour = ({ info, textWidth, size }) => {
    const [currentWidth, setCurrentWidth] = useState(null);

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const width = currentWidth ? currentWidth : 250;
    const price = (info ? info.toFixed(2) : 0) + '';
    let hour = size * info
    const hoursCoverted = size ? `${formatDuration(convertHours(hour))}` : ''

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);
        return <div ref={curr} className='text-style'>
            {price?.length > count ?
                <HtmlTooltip
                    title={
                        <p>
                            <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                            {hour !== 0 && <p >{hoursCoverted}</p>}
                        </p>
                    }
                    placement='top-start'
                >
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} />...
                    </p>
                </HtmlTooltip>
                :
                <p>
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} />
                    {hour !== 0 && <p >{hoursCoverted}</p>}
                </p>
            }
        </div>;
    } else {
        return '...';
    }
};


export const DateRow = ({ date }) => {
    return (
        date ? moment.utc(date).format('MM/DD/YYYY') : 'Not Set'
    );
};


export const DateRowStartEnd = ({ start, end }) => {
    return (
        `${moment.utc(start).format('MM/DD/YYYY')} - ${moment.utc(end).format('MM/DD/YYYY')}`
    );
};


export const IdRow = ({ id, size }) => {
    return (
        <SlicedText type={'id'} size={size ? size : 10} data={id} />
    );
};

export const DisplayIdRow = ({ id }) => {
    return (
        <p>{id ? id : '...'}</p>
    );
};


export const PhoneRow = ({ phone }) => {
    const converted = phone ? '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 6) : 'N/A';
    return (
        converted
    );
};

export const TypeRow = ({ text }) => {
    if (text && text !== undefined) {
        return (
            text?.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                return g1.toUpperCase() + g2.toLowerCase();
            })
        );
    }else{
        return '...';
    }
};

const RenderText = (width, text, textWidth, color) => {
    if (text) {
        const count = Math.ceil(width / (textWidth ? textWidth : 9));
        const slicedText = '' + text?.slice(0, count);
        return <div className='text-style' style={color ? { color: color } : {}}>
            {text?.length > count ?
                <HtmlTooltip
                    title={<p>{text}</p>}
                    placement='top-start'
                >
                    <p className='showTextStyle' style={{ textOverflow: 'ellipsis', whiteSpace:'nowrap' }}>
                        {`${slicedText}...`}
                    </p>
                </HtmlTooltip>
                :
                <p>{text}</p>
            }
        </div>;
    } else {
        return '...';
    }
};


export const PriceConvertor = ({ price }) => {
    const convertedPrice = price ? price.toFixed(2) : 0;
    return <NumericFormat value={convertedPrice ? convertedPrice : '0'} displayType={'text'} thousandSeparator={true}
                          prefix={'$'} />;

};

export const StatusRow = ({ status, noCircle }) => {

    const renderCircle =
        status === 'ACTIVE' ? '#6FD231' :
            status === 'FBA' ? '#ADD8E6' :
                status === 'ON_HOLD' ? '#FFCA33' :
                    status === 'TERMINATED' ? '#FE7070' :
                        status === 'WAIT_LIST' ? '#FE7B43' :
                            status === 'GRADUATED' ? '#51439E' :
                                status === 'INACTIVE' ? '#4B5C68' :
                                    '';

    const renderText = status === 'ACTIVE' ? 'Active' :
        status === 'FBA' ? 'FBA' :
            status === 'ON_HOLD' ? 'On Hold' :
                status === 'TERMINATED' ? 'Terminated' :
                    status === 'WAIT_LIST' ? 'Wait List' :
                        status === 'GRADUATED' ? 'Graduated' :
                            status === 'INACTIVE' ? 'Inactive' :
                                '';

    return (
        <div className='flex-align-center'>
            {!noCircle && <div className={`circle-box`} style={{ background: renderCircle }} />}
            <p className='status-circle-text'>{renderText}</p>
        </div>
    );
};