import {DOWNLOAD_PDF, GET_SIGNATURE_REPORTS} from "./reports.types";

/** Signature Reports */

export const getSignatureReports = (params) => {
    return {
        type: GET_SIGNATURE_REPORTS,
        payload: {params},
    };
};

export const downloadPdf = (params) => {
    return {
        type: DOWNLOAD_PDF,
        payload: {params},
    };
};

/** End */