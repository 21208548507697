import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FindLoad, PermissionList, RolePermission, SaveParams } from 'utils';
import { AddButton, CheckboxesTags, CustomDateSelector, DownloadCsv } from 'components';
import { adminActions } from 'store';

export const Filters = ({ handleProcess, selectedList, noBtn, type }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pushInfo = history?.location?.state;
    const staff = useSelector((state) => state.admins.adminsList);
    const loader = FindLoad('CREATE_PROCESS_PAYROLL');

    useEffect(() => {
        dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
    }, []);

    const handleSelectValue = (list) => {
        const params = {
            ...pushInfo,
        };
        list?.length ? params.staffs = list : delete params.staffs;
        SaveParams(history, { ...params });
    };

    return (

        <div className="payroll-filters-wrapper">
            <div className="filter-inputs-align-end">
                <CheckboxesTags
                    name="staffs"
                    handleChange={handleSelectValue}
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    value={pushInfo?.staffs ? pushInfo?.staffs : []}
                    permissionsList={staff?.staffs || []}
                    outLabel={type === 'active' ? 'Staff' : 'Processor'}
                    placeholder={pushInfo?.staffs?.length ? pushInfo?.staffs?.map((i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`).join(', ') : 'All'}
                    uiType={'tableFilter'}
                    notShowError={true}
                />
                {/*<SelectTypeAutocomplete*/}
                {/*    placeholder={pushInfo?.staffId ? '' : 'All'}*/}
                {/*    name={'staffId'}*/}
                {/*    handleSelect={handleChange}*/}
                {/*    defaultValue={pushInfo?.staffId}*/}
                {/*    list={staff?.staffs ?*/}
                {/*        [{ id: 'All', firstName: 'All' }, ...staff?.staffs]*/}
                {/*        :*/}
                {/*        [{ id: 'All', firstName: 'All' }]*/}
                {/*    }*/}
                {/*    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}*/}
                {/*    uiType={'tableFilter'}*/}
                {/*    outLabel={type === 'active' ? 'Staff' : 'Processor'}*/}
                {/*    noError={true}*/}
                {/*/>*/}
                <CustomDateSelector outLabel={'DOS'} />
            </div>

            <div className={'export-csv-box'}>
                <DownloadCsv fromModal={true} type={type === 'active' ? 'activePayroll' : 'passivePayroll'} />
                {!noBtn && RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) &&
                    <div>
                        <AddButton
                            styles={{ width: '160px' }}
                            disabled={!selectedList?.length}
                            Icon={false}
                            loader={loader?.length}
                            type={'CREATE_PROCESS_PAYROLL'}
                            text={'Process Payroll'}
                            handleClick={handleProcess}
                        />
                    </div>
                }
            </div>
        </div>
    );
};