import React from "react";
import { Images } from "utils";
import {TextRow} from "components";

export const systemTypeHead = [
   { name: "name", title: "Name" },
   { name: "displayCode", title: "Display Code", custom: false },
   { name: "category", title: "Category", custom: false },
   { name: "action", title: "Action", custom: false, disabled: true, width: '100px' },
];

export const systemTypeBody = [
   { rowText:  (item) => <TextRow name={item?.name} textWidth={10}   /> },
   { rowText:  (item) => <TextRow name={item?.displayCode} textWidth={10}   />  },
   { rowText:  (item) => <TextRow name={item?.category} textWidth={10}   />  },
   {
      button: (cb) => (
         <div onClick={() => cb("edit")}>
            <img src={Images.edit} alt="edit" />
         </div>
      ),
      notClickable: true,
   },
];

export const ACTION_TYPE = "GET_SERVICES";


export const credentialTypes = [
   {name:'Degree', id:'DEGREE'},
   {name:'Clearance', id:'CLEARANCES'},
   {name:'License', id:'LICENSE'},
]
