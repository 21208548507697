import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { CreateChancel, Address, Input, MaskInputCustom, SelectAutocompleteMultiple } from 'components';
import { fundingSourceActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { EmailValidator, enumValues, FindLoad, FindErrorItem, OnlyNumber, useModal, FindSuccessItem } from 'utils';

export const CreateFundingSource = ({ info, closeToSuccess }) => {
    const ACTION_TYPE = info ? 'EDIT_FUNDING_SOURCE' : 'CREATE_FUNDING_SOURCE';
    const dispatch = useDispatch();
    const [enteredAddress, setEnteredAddress] = useState();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const { close } = useModal();
    const { handleSubmit, watch, control, setError, clearErrors, reset } = useForm();

    useEffect(() => {
        if (info) {
            const result = { ...info };
            delete result?.canDelete;
            delete result?.termination;
            reset(result);
        }
    }, [info]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            if (closeToSuccess) {
                closeToSuccess();
            }
        }
    }, [success]);

    useEffect(() => {
        if (backError?.error?.length) {
            if (backError?.error[0] === 'website must be a URL address') {
                setError('website', {
                    message: 'Website must be a URL address.',
                });
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            }
        }
        if (backError?.error?.length) {
            if (backError?.error[0] === 'phoneNumber must be a valid phone number') {
                setError('phoneNumber', {
                    message: 'Phone number must be a valid phone number',
                });
                dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            }
        }
        if (backError?.error === 'Funder already exists') {
            setError('email', {
                message: 'Funder already exists.',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
    }, [backError]);


    const handleCreate = (data) => {
        const result = _.omitBy(data, value => (typeof value === 'undefined')
            || (typeof value === 'string' && !value?.trim())
            || (typeof value === 'object' && _.isEmpty(value)),
        );

        const sandedData = {
            ...result,
            status: 'ACTIVE',
        };

        if (enteredAddress?.address) sandedData.address = enteredAddress;

        if (info) {
            dispatch(fundingSourceActions.editFundingSource(info.id, sandedData));
        } else {
            dispatch(fundingSourceActions.createFundingSource(sandedData));
        }
    };

    return (
        <div style={{ width: 860 }}>
            <p className="modal-header-title">{info ? 'Edit Funding Source' : 'Add Funding Source'}</p>
            <form onSubmit={handleSubmit(handleCreate)}>
                <div className="modal-body-wrapper">
                    <div className="flex-align-start" style={{ gap: '32px' }}>
                        <div className="full-width">
                            <Input
                                name="name"
                                label="Funding Source Name*"
                                variant="outlined"
                                control={control}
                                maxLength={40}
                                rules={{
                                    maxLength: {
                                        value: 40,
                                        message: 'Max length is 40 digits.',
                                    },
                                    required: 'Funding source name is required.',
                                }}
                            />
                            <Input
                                name="email"
                                label="Email Address*"
                                variant="outlined"
                                control={control}
                                rules={{
                                    required: 'Email address is required.',
                                    pattern: {
                                        value: EmailValidator,
                                        message: 'Valid email address is required (e.g., name@example.com).',
                                    },
                                }}
                            />
                            <MaskInputCustom
                                name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                control={control}
                                rules={{
                                    pattern: {
                                        value: OnlyNumber,
                                        message: 'Please enter a valid 10 digit number.',
                                    },
                                    minLength: {
                                        value: 10,
                                        message: 'Please enter a valid 10 digit number.',
                                    },
                                }}
                            />
                            <SelectAutocompleteMultiple
                                options={enumValues.FUNDING_SOURCE_TYPES}
                                renderValue={(option) => option?.label}
                                label={'Type*'}
                                name="type"
                                control={control}
                                rules={{
                                    required: 'Please select a type from the dropdown.',
                                }}
                            />
                            <Input
                                name="contact"
                                label="Contact Person"
                                variant="outlined"
                                control={control}
                                maxLength={40}
                                rules={{
                                    maxLength: {
                                        value: 40,
                                        message: 'Max length is 40 digits.',
                                    },
                                }}
                            />
                            <Input
                                name="payorId"
                                label="Payor ID"
                                variant="outlined"
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 20,
                                        message: 'Max length is 20 digits.',
                                    },
                                }}
                                maxLength={20}
                            />
                        </div>
                        <div className="full-width">
                            <Input
                                name="website"
                                label="Website"
                                variant="outlined"
                                control={control}
                            />
                            <Address
                                placeholder={'Street Address'}
                                setAddress={setEnteredAddress}
                                defaultValue={info?.address}
                            />
                        </div>
                    </div>
                </div>
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    create={info ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth="100%"
                    gap={'32px'}
                    cancelRight={'0'}
                />
            </form>
        </div>
    );
};