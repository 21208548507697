import React from "react";
import Modal from "@material-ui/core/Modal";
import { Backdrop } from "@material-ui/core";
import { AddButton } from "../buttons";
import { CloseButton } from "components";
import { Backgrounds, Colors } from "utils";

export const SimpleModal = ({
   openDefault,
   handleOpenClose,
   content,
   backdropCustom,
   addButton,
   secondary,
   calendarView,
                                bigSize
}) => {
   const [open, setOpen] = React.useState(false);

   const handleOpenOrClose = () => {
      if (handleOpenClose) {
         handleOpenClose();
      } else {
         setOpen((prevState) => !prevState);
      }
   };

   const body = (
      <div style={{ position: "relative", width: bigSize ? '100%' : 'auto' }} className={'open-sans'}>
         {/*<CloseButton*/}
         {/*   styles={{*/}
         {/*      position: "absolute",*/}
         {/*      top: calendarView ? "24px" : "8px",*/}
         {/*      right: "8px",*/}
         {/*      zIndex: "9",*/}
         {/*      backgroundColor: secondary ? Colors.BackgroundWater : Backgrounds.darkGrey,*/}
         {/*   }}*/}
         {/*   handleCLic={handleOpenClose}*/}
         {/*/>*/}
         <div
             className={bigSize ? 'modal-body-full' : 'modal-body-small'}
         >
            {content}
         </div>
      </div>
   );

   return (
      <div>
         {addButton && <AddButton text={addButton} handleClick={handleOpenOrClose} />}

         <Modal
            open={openDefault ? openDefault : open}
            onClose={handleOpenOrClose}
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            className={backdropCustom === true ? "my-profile" : ""}
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
               timeout: 500,
            }}
         >
            {body}
         </Modal>
      </div>
   );
};






export const CustomModal = ({
                                openDefault,
                                handleOpenClose,
                                content,
                                backdropCustom,
                                addButton,
                                secondary,
                                calendarView,
                            }) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        if (handleOpenClose) {
            handleOpenClose();
        } else {
            setOpen(false);
        }
    };


    const body = <div>{content}</div>;

    return (
        <div className={'open-sans'}>

            <Modal
                disableScrollLock={true}
                open={openDefault ? openDefault : open}
                onClose={handleClose}
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={backdropCustom === true ? "my-profile" : ""}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // zIndex: 2500,
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {body}
            </Modal>
        </div>
    );
};
