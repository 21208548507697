import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { Images, SaveParams } from 'utils';
import { Popper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';

export const DateSelector = ({ outLabel }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let history = useHistory();
    const info = history?.location?.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const currentDate = moment();
    const currentWeekStart = currentDate.clone().startOf('isoWeek');
    const currentWeekEnd = currentDate.clone().endOf('isoWeek');
    const [state, setState] = useState([
        {
            startDate: moment(currentWeekStart).format(),
            endDate: moment(currentWeekEnd).format(),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (info?.firstDate && info?.lastDate) {
            setState([{
                startDate: new Date(info?.firstDate),
                endDate: new Date(info?.lastDate),
                key: 'selection',
            }]);
        }
    }, [info]);
    const handleDateChange = (item) => {
        setState([item.selection]);
        const params = {
            ...info,
        };
        params.firstDate = item?.selection?.startDate;
        params.lastDate = item?.selection?.endDate;
        SaveParams(history, params);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    return (
        <div className="date-selector-box">
            {outLabel && <p className='date-selector-label'>{outLabel}</p>}
            <button className="date-range-selector" aria-describedby={id} type="button" onClick={handleClick}>
                <p>
                    {info?.firstDate ? moment(info?.firstDate).format('MM/DD/YYYY') :
                    currentWeekStart ? moment(currentWeekStart).format('MM/DD/YYYY') :
                        'Start Date'}
                </p>
                <img src={Images.arrowRight} alt="icon" />
                <p>
                    {info?.lastDate ? moment(info?.lastDate).format('MM/DD/YYYY') :
                    currentWeekEnd ? moment(currentWeekEnd).format('MM/DD/YYYY') :
                        'End Date'}
                </p>
                <img src={Images.calendarToday} alt="icon" />
            </button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'custom-date-selector-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 20 }}
            >
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={state}
                    onChange={handleDateChange}
                    locale={customLocale}
                />
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};
