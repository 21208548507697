import React, { useState } from 'react';
import { navBarStyles } from './style';
import { Images as images } from 'utils';
import { MyProfile } from '../../myProfile';
import { Drawer } from '@material-ui/core';
import { TextRow } from '../../../components';
import { useSelector } from 'react-redux';

export const UserInfo = ({}) => {
    const classes = navBarStyles();
    const user = useSelector((state) => state.auth.userInfo);
    const userInfo = user || JSON.parse(localStorage.getItem('wellUserInfo'));
    const [drawerPosition, setDrawerPosition] = useState({ right: false });

    const toggleDrawer = (anchor, open) => {
        setDrawerPosition({ ...drawerPosition, [anchor]: open });
    };

    return (
        <div
            style={{ color: 'black', position: 'relative' }}
            className={classes.boxWrapper}
        >
            <div onClick={() => toggleDrawer('right', true)} className={classes.userInfo}>
                <img src={user?.avatar?.url ? user?.avatar?.url : images.userProfile} alt="Avatar" />
                {/*<p className={classes.userInfoText}>*/}
                {/*    {userInfo && <TextRow name={`${userInfo.firstName} ${userInfo.lastName}`} textWidth={8} />}*/}
                {/*</p>*/}
                <div className={classes.online}/>
            </div>
            <Drawer
                className={classes.myProfileDrawerStyle}
                anchor={'right'}
                open={drawerPosition.right}
                onClose={() => toggleDrawer('right', false)}
            >
                <MyProfile info={userInfo} />
            </Drawer>
        </div>
    );
};
