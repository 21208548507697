import axios from "axios";

export const authService = {
   /** Founding Source */

   createFundingSourceService: (body) => axios.post("/funding", body, { auth: true }),

   editFundingSourceService: (id, body) => axios.patch(`/funding/${id}`, body, { auth: true }),

   getFundingSourceService: (data) =>  axios.get("/funding", { auth: true, params: { ...data } }),

   getFoundingSourceByIdService: (id) => axios.get(`/funding/${id}`, { auth: true }),

   changeFundingSourceStatusService: (id, path, status, body) => axios.patch(`/${path}/${id}/${status }`, body, { auth: true }),

   deleteFundingSourceService: (id) => axios.delete(`/funding/${id}`, { auth: true }),

   /** End */

   /** Founding Source Service */

   getFSServiceByIdService: (id, data) => axios.get(`/funding/${id}/service`, { auth: true, params: { ...data } }),

   createFSServiceByIdService: (id, body) => axios.post(`/funding/${id}/service`, body, { auth: true }),

   editFSServiceService: (id, body) => axios.patch(`/funding/service/${id}`, body, { auth: true }),

   changeFundingSourceServiceStatus: (id, body) => axios.patch(`/funding/service/${id}`, body, { auth: true }),

   /** End */

   /** Modifiers */

   getServiceModifierService: (serviceId) => axios.get(`/chargeRate/service/${serviceId}`, { auth: true }),

   createModifierService: (body) => axios.post(`/chargeRate`, body, { auth: true }),

   editModifierService: (id, body) => axios.patch(`/chargeRate/${id}`, body, {auth: true,}),

   modifierStatusService: (chargeRateId) => axios.patch(`/chargeRate/${chargeRateId}/inactivate`, null, {auth: true,}),

   /** End */

   setStatusService: (id, status) => axios.patch(`/funding/${id}/status/${(status === 'ACTIVE')}`, null, { auth: true }),

   /** Staff Documents */

   uploadService: (files, id ) => axios.post(`/funding/${id}/documents`, files, { auth: true }),

   editService: ({id, dockId, file} ) => axios.patch(`/funding/${id}/documents/${dockId}`, {...file}, { auth: true }),

   getFilesService: ( id ) => axios.get(`/funding/${id}/documents`, { auth: true }),

   deleteFilesService: (fsId, id ) => axios.delete(`/funding/${fsId}/documents/${id}`, { auth: true }),

   /** End */
};
