



export const NoItemsYet = ({ text, styles, containerStyles }) => {

    return (
        <div className='no-items-box' style={{...containerStyles}}>
           <p style={{ ...styles }}>{text}</p>
        </div>
    );
}