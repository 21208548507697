import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    CreateChancel,
    PriceRow,
    NumericFormatCustom,
    SelectAutocompleteMultiple,
    Input,
    MaskInputCustom,
    TextareaCustom,
} from 'components';
import { ErrorText, FindLoad, FindSuccess, FindSuccessItem, useModal } from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { payrollActions } from 'store/payroll';
import { createClientStyle } from 'fragments/client';
import { staffModalsStyle } from './styles';
import { useForm } from 'react-hook-form';

export const TimesheetModal = ({ info, type, activeTab }) => {
    const { allPayCodes,staff } = useSelector((state) => ({
        allPayCodes: state.admins.allPaycodes,
        staff: state.admins.adminInfoById,
    }));
    const classes = createClientStyle();
    const classes_v2 = staffModalsStyle();
    const ACTION_TYPE = info ? 'EDIT_TIMESHEET' : 'CREATE_TIMESHEET';
    const { close } = useModal();
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const title = type === 'fixed' ? 'Add Fixed Pay' : 'Add Paycode Timesheet';
    const editTitle = type === 'fixed' ? 'Edit Fixed Pay' : 'Edit Paycode Timesheet';
    const payCodes = allPayCodes?.filter((item) => !item?.terminationDate)
    const { handleSubmit, control, clearErrors, reset, watch, } = useForm({ });

    useEffect(() => {
        dispatch(payrollActions.getPayCodeGlobal());
    }, []);

    useEffect(() => {
        if (info) {
            const params = {
                ...info,
                timesheetDate: moment.utc(info.timesheetDate).format('YYYY-MM-DD'),
            };
            if (info?.payCode) params.payCode = { ...info?.payCode, id: info.payCode?._id };
            if (type === 'fixed') {
                params.rate = info?.totalAmount;
            }
            if (info?.hours) {
                const match = info?.hours?.match(/(\d+)h(\d+)m/);
                if (match) {
                    const hours = match[1];
                    const minutes = match[2];
                    params.hours = `${hours}:${minutes}`;
                }
            }
            reset(params);
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    const handleCreate = (data) => {
        const sandedData = {
            staffId: staff?.id,
        };
        if (data?.description?.trim()) sandedData.description = data?.description;
        if (data?.payCode) sandedData.payCode = data.payCode?.id ? data.payCode?.id : data?.payCode;
        if (data?.hours && !data?.hours.includes('NaN')) sandedData.hours =   data.hours;
        if (data?.timesheetDate) sandedData.timesheetDate = data.timesheetDate;
        if (data?.rate) sandedData.rate = data.rate;

        if (info) {
            const currentPayCode = allPayCodes?.find((i) => i?.id === data?.payCode?.id);
            if (!currentPayCode?.terminationDate) {
                dispatch(adminActions.editTimesheet(sandedData, data.id, staff?.id, activeTab));
            }
        } else {
            dispatch(adminActions.createTimesheet(sandedData));
        }
    };


    return (
        <form
            style={{ width: 420 }}
            onSubmit={handleSubmit(handleCreate)}
        >
            <p className='modal-header-title'>{info ? editTitle : title}</p>
            <div className='modal-body-wrapper'>
                {type === 'fixed' ?
                    <>
                        <NumericFormatCustom
                            name={'rate'}
                            label={'Amount*'}
                            prefix={'$'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            control={control}
                            rules={{
                                required: 'Amount is required. Please enter the charge amount.',
                            }}
                        />
                    </>
                    :
                    <>
                        <SelectAutocompleteMultiple
                            name='payCode'
                            label={'Paycode*'}
                            control={control}
                            options={payCodes}
                            rules={{ required: 'Paycode is required.' }}
                            renderValue={(i) => `${i?.payCodeTypeId?.name ? i?.payCodeTypeId?.name : ''}`}
                            fullValue={true}
                        />

                        <div className={classes.displayCodeBlock}>
                            <div className={classes_v2.paycodeBox} style={{ width: '100%' }}>
                                <p className={classes_v2.paycodeBoxTitle}>Amount:</p>
                                <p className={classes_v2.paycodeBoxText} style={{ width: '100%' }}>
                                    <PriceRow info={watch('payCode')?.rate ? watch('payCode')?.rate : 0} />
                                </p>
                            </div>
                            <div className={classes_v2.paycodeBox} style={{ marginBottom: 0 }}>
                                <p className={classes_v2.paycodeBoxTitle}>Type:</p>
                                <p className={classes_v2.paycodeBoxText}>
                                    {watch('payCode') ? watch('payCode')?.payCodeTypeId?.type : 'N/A'}
                                </p>
                            </div>
                        </div>
                    </>
                }

                <TextareaCustom
                    control={control}
                    name='description'
                    label='Description'
                    variant={'outlined'}
                    allowCharacter={true}
                    maxLength={100}
                />

                {type !== 'fixed' &&
                    <MaskInputCustom
                        name='hours'
                        label='Hours*'
                        mask={'99:99'}
                        variant='outlined'
                        control={control}
                        rules={{
                            required: 'Hours is required.',
                            // validate: {
                            //     validTime: (value) => {
                            //         const [hours, minutes] = value.split(':').map(Number);
                            //         return (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) || 'Invalid time format';
                                // },
                            // },
                        }}
                    />
                }

                <div style={{ display: 'flex' }}>
                    <Input
                        name='timesheetDate'
                        label={'Created Date*'}
                        type='date'
                        max={moment.utc().format('YYYY-MM-DD')}
                        variant='outlined'
                        control={control}
                        rules={{
                            required: 'Created date is required.',
                        }}
                    />
                </div>
            </div>
            <CreateChancel
                type='submit'
                loader={!!loader.length}
                create={info ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onClose={close}
                buttonWidth='100%'
            />
        </form>
    );
};
