import React, { useState } from 'react';
import { Images, useModal } from 'utils';
import { MinLoader } from '../loader';
import { CsvService } from 'store';
import { DownloadPayrollCsv, DownloadPendingClaims, DownloadBillingInvoice } from './csv';


export const DownloadCsv = ({ type, fromModal }) => {
    const [load, setLoad] = useState(false);
    const { close, open } = useModal();

    const handleDownload = async () => {

        let response = '';

        if (fromModal) {
            if (type === 'activePayroll') {
                open(<DownloadPayrollCsv status={'ACTIVE'} />);
            }
            if (type === 'passivePayroll') {
                open(<DownloadPayrollCsv status={'PROCESSED'} />);
            }
            if (type === 'pendingClaims' || type === 'billedClaims') {
                open(<DownloadPendingClaims type={type} />);
            }
            if (type === 'billingInvoice') {
                open(<DownloadBillingInvoice />);
            }
        } else {
            setLoad(true);
            try {
                if (type === 'staff') {
                    response = await CsvService.getStaffCsv();
                }
                if (type === 'client') {
                    response = await CsvService.getClientCsv();
                }
                if (type === 'schedule') {
                    response = await CsvService.getScheduleCsv();
                }
                if (type === 'fundingSource') {
                    response = await CsvService.getFundingSourceCsv();
                }
                if (type === 'claimPayment') {
                    response = await CsvService.getClaimPaymentCsv();
                }
                if (type === 'invoicePayment') {
                    response = await CsvService.getInvoicePaymentCsv();
                }


                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${type}.csv`);
                document.body.appendChild(link);
                link.click();
                setLoad(false);
            } catch (e) {
                setLoad(false);
            }
        }
    };

    return (
        <div>
            <button onClick={handleDownload} className="download-csv-btn">
                {load ?
                    <MinLoader margin={'0'} color={'#4B5C68'} />
                    :
                    <>
                        <img src={Images.downloadCloud} alt="" />
                        <p>Download CSV</p>
                    </>
                }
            </button>

        </div>
    );
};