import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const fundingSourceSingleStyles = makeStyles(() => ({
    inputTextField: {
        alignItems: 'flex-end',
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
    },
    fundingSourceSingleHeaderStyles: {
        display: 'flex',
        alignItems: 'center',
        '& > img': { marginRight: '8px' },
    },
    fundingSourceSingleHeaderWrapStyles: {
        marginBottom: 26,
        display: 'flex',
        flexDirection:'column',
        // justifyContent: 'space-between',
        // alignItems: 'flex-start',
    },
    title: {
        fontSize: '18px',
        color: Colors.TextPrimary,
        fontWeight: 'bold',
        textTransform: 'capitalize',
    },
    foundingIcon: {
        width: 32,
        height: 32,
        marginLeft: 8,
    },
    modifierBoxStyle: {
        width: '100%',
        height: '100%',
        padding: '48px 24px 24px 24px',
        position: 'relative',
        '& > button': {
            backgroundColor: Colors.BackgroundWater,
            position: 'absolute',
            top: '8px',
            right: '8px',
        },
    },
    modifierTitleStyle: {
        fontSize: 24,
        color: Colors.TextPrimary,
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
    },
    fundingSourceSingleGeneralStyles: {
        display: 'flex',
        '& > div:not(:last-of-type)': {
            marginRight: '24px',
        },
    },
    clear: {
        height: 36,
        width: 74,
    },
    iconCursor: {
        cursor: 'pointer',
    },
    iconCursordelete: {
        marginLeft: 16,
        cursor: 'pointer',
    },
    tableTitle: {
        width: 130,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: 19,
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            marginLeft: 15,
        },
    },
    dateInput: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiOutlinedInput-root': {
            height: 38,
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: Colors.TextPrimary,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.BackgroundBlue,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: 14,
            color: Colors.TextLightGray,
        },
    },
    noItemWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            fontSize: 18,
            color: Colors.TextLightGray,
            fontWeight: 'bold',
            marginTop: '50px',
        },
    },
    modifierTitleBoxStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modifierActionsStyle: {
        display: 'flex',
        alignItems: 'center',
    },
    modifiersDrawerStyle: {

        '& div.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16':
            {
                top: '37.5%',
                right: '68px',
                width: '900px',
                maxHeight: '50vh',
                height: '100%',
                overflowY: 'auto',
                borderRadius: '8px',
                boxShadow: '0px 0px 6px #8a8a8a29',
                border: '1px solid #347af080',
                background: '#ffffff 0% 0% no-repeat padding-box',

                '@media (min-width: 1919px)': {
                    width: '1200px',
                },
            },
    },
    buttonsAndInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    buttonsTabWrapper: {
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 0px 6px 0px rgba(138, 138, 138, 0.24)',
        width: 'auto',
        height: 'auto',
        padding: '2px',
    },
    activeBtn: {
        border: 'none',
        borderRadius: '8px',
        background: '#347AF0',
        width: '106px',
        height: '36px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '600',
    },
    passiveBtn: {
        border: 'none',
        width: '106px',
        height: '36px',
        color: '#4B5C68',
        fontSize: '14px',
        fontWeight: '600',
        background: 'transparent',
    },
    toolBtn: {
        border: 'none',
        background: 'transparent',
        padding: 0,
    },
    fsSourceInfoWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
}));

export const editButtonStyle = {
    height: 36,
    paddingInline: 24,
};
