import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    SimpleTabs,
    InactiveModal,
    NoItemText,
    Loader,
    Notes, FilesComponent,
} from 'components';
import {
    ClientGeneral,
    ClientContact,
    TabsHeader,
    ClientEnrollment,
    ClientHistory,
    ClientAuthorization, StaffAssignment,
} from './core';
import { FindLoad, makeCapitalize, PermissionList, RolePermission } from 'utils';

const tabsLabels = [
    RolePermission([PermissionList.CLIENT_READ?.code, PermissionList.CLIENT_CREATE?.code, PermissionList.CLIENT_STATUS?.code]) &&
    { label: 'General' },
    RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code, PermissionList.CLIENT_CONTACT_READ?.code]) &&
    { label: 'Contacts' },
    RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code, PermissionList.CLIENT_ENROLLMENT_READ?.code]) &&
    { label: 'Enrollments' },
    RolePermission([PermissionList.CLIENT_AUTHORIZATION_READ?.code, PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
    { label: 'Authorization' },
    RolePermission([PermissionList.CLIENT_NOTE_READ?.code, PermissionList.CLIENT_NOTE_MANAGE?.code]) &&
    { label: 'Notes' },
    RolePermission([PermissionList.CLIENT_HISTORY_READ?.code]) &&
    { label: 'History' },
    RolePermission([PermissionList.CLIENT_FILE_READ?.code, PermissionList.CLIENT_FILE_MANAGE?.code]) &&
    { label: 'Files' },
    RolePermission([PermissionList.CLIENT_STAFF_ASSIGNMENT?.code]) &&
    { label: 'Staff Assignment' },
];

export const ClientItem = () => {
    const params = useParams();
    const load = FindLoad('GET_CLIENT_BY_ID');
    const clientDocuments = useSelector((state) => state.client.clientDocuments);
    const data = useSelector((state) => state.client.clientItemInfo);
    const clientContact = useSelector((state) => state.client.clientContacts);
    const enrolments = useSelector((state) => state.client.clientEnrollment);
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    const clientHistory = useSelector((state) => state.history.history);
    const clientNotes = useSelector((state) => state.note.notes);


    const tabsContent = [
        RolePermission([PermissionList.CLIENT_READ?.code, PermissionList.CLIENT_CREATE?.code, PermissionList.CLIENT_STATUS?.code]) &&
        {
            tabComponent: load.length ? <Loader /> : <ClientGeneral data={data} />,
            tab: 'General',
        },
        RolePermission([PermissionList.CLIENT_CONTACT_MANAGE?.code, PermissionList.CLIENT_CONTACT_READ?.code]) &&
        {
            tabComponent: (
                <ClientContact
                    clientContacts={clientContact}
                />
            ),
            tab: 'Contacts',
        },
        RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code, PermissionList.CLIENT_ENROLLMENT_READ?.code]) &&
        {
            tabComponent: <ClientEnrollment info={enrolments} data={data} />,
            tab: 'Enrollments',
        },
        RolePermission([PermissionList.CLIENT_AUTHORIZATION_READ?.code, PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
        {
            tabComponent: clientsAuthorizations.length ? (
                <ClientAuthorization info={clientsAuthorizations} />
            ) : (
                <NoItemText text={'No authorization yet'} />
            ),
            tab: 'Authorization',
        },
        RolePermission([PermissionList.CLIENT_NOTE_READ?.code, PermissionList.CLIENT_NOTE_MANAGE?.code]) &&
        {
            tabComponent: <Notes id={data?.id} notes={clientNotes} onModel={'Client'}
                                 permissions={[PermissionList.CLIENT_NOTE_MANAGE?.code]} />,
            tab: 'Notes',

        },
        RolePermission([PermissionList.CLIENT_HISTORY_READ?.code]) &&
        {
            tabComponent: clientHistory.length ? (
                <ClientHistory info={clientHistory} />
            ) : (
                <NoItemText text={'There is no history in this date'} />
            ),
            tab: 'History',
        },
        RolePermission([PermissionList.CLIENT_FILE_READ?.code, PermissionList.CLIENT_FILE_MANAGE?.code]) &&
        {
            tabComponent: (
                <FilesComponent
                    currentId={params?.id}
                    onResource={'client'}
                    documents={clientDocuments}
                    loadTable={'GET__DOCUMENT'}
                    permissions={[PermissionList.CLIENT_FILE_MANAGE?.code]}
                />
            ),
            tab: 'Files',
        },
        RolePermission([PermissionList.CLIENT_STAFF_ASSIGNMENT?.code]) &&
        {
            tabComponent: (
                <StaffAssignment />
            ),
            tab: 'Staff Assignment',
        },
    ];

    return (
        <div className="tab-box-wrapper">
            <TabsHeader data={data} />
            <SimpleTabs
                defaultTab={
                    RolePermission([PermissionList.CLIENT_READ?.code, PermissionList.CLIENT_CREATE?.code, PermissionList.CLIENT_STATUS?.code]) ?
                        'General' : ''
                }
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
            />
        </div>
    );
};
