import React from "react";
import { TextRow } from 'components';
import {claimPostStyles} from "./styles";
import {PermissionList, RolePermission} from "utils";

export const ClaimPaymentHead = ({}) => {
    const classes = claimPostStyles()

    const headerList = [
        {name:'ID', width: '10%'},
        {name:'DOS', width: '15%'},
        {name:'Funding Source', width: '20%'},
        {name:'Client', width: '20%'},
        {name:'Payment Reference', width: '15%'},
            {name:' ', width: '22%'},
        // RolePermission([
        //     PermissionList.CLAIM_PMT_PAY?.code,
        //     PermissionList.CLAIM_PMT_SEND_APPEAL?.code,
        //     PermissionList.CLAIM_PMT_APPEAL_PAY?.code,
        // ]) &&
        // {name:'Actions', width: '20%'}
    ]

    return (
        <div>
            <div className={classes.postTheadStyle}>
                {headerList?.map((i,j) => i && (
                    <div key={j} style={{ width: i?.width }} className={classes.postThStyle}>
                        <TextRow name={i?.name} />
                    </div>
                ))}
            </div>
        </div>
    )
}