import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminActions, appointmentActions, clientActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { FindSuccessItem, SaveParams, useModal } from 'utils';
import { SimpleModal } from 'components';
import { Filters, scheduleStyle, Selectable, CreateEvent, Recur, CalendarPage } from './core';
import { ScheduleList } from './core/scheduleList';

const RENDER_APPOINTMENTS_LIST = 'RENDER_APPOINTMENTS_LIST';
const CREATE_APPT = 'CREATE_APPOINTMENT';
const EDIT_APPT = 'EDIT_APPOINTMENT';

export const ScheduleFragment = ({}) => {
    const classes = scheduleStyle();
    const dispatch = useDispatch();
    const [openEvtModal, setOpen] = useState(false);
    const [date, setDate] = useState('');
    const [modalDate, setModalDate] = useState('');
    const [fullScreen, setFullScreen] = useState(false);
    const renderAppointments = FindSuccessItem(RENDER_APPOINTMENTS_LIST);
    const history = useHistory();
    const info = history?.location?.state;
    const createApptSuccess = FindSuccessItem(CREATE_APPT);
    const editApptSuccess = FindSuccessItem(EDIT_APPT);
    const { open, close } = useModal();

    useEffect(() => {
        if (renderAppointments?.type === RENDER_APPOINTMENTS_LIST) {
            setOpen(false);
            close();
            getEvents('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_APPOINTMENTS_LIST));
        }
        if (createApptSuccess?.type === CREATE_APPT) {
            setOpen(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_APPT));
        }
        if (editApptSuccess?.type === EDIT_APPT) {
            setOpen(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_APPT));
        }
    }, [renderAppointments, createApptSuccess, editApptSuccess]);

    useEffect(() => {
        dispatch(clientActions.getClients());
        dispatch(adminActions.getAdmins({ status: 'ACTIVE' }));
        // dispatch(adminActions.getStaffForAppt())
        dispatch(systemActions.getPlaces());
        const newFilters = {
            ...info,
        };
        SaveParams(history, { ...newFilters });
    }, []);

    const renderParams = useMemo(() => {
        const moment = require('moment');
        const currentDate = moment().utc();
        const currentWeekStart = currentDate.clone().startOf('isoWeek'); // ISO week starts on Monday
        const currentWeekEnd = currentDate.clone().endOf('isoWeek');
        const startDate = moment(info?.firstDate ? info?.firstDate : currentWeekStart);
        // startDate.set({ hour: '00', minute: '00', second: '01' });

        const filterInfo = {
            startDate: startDate.format('YYYY-MM-DD'),
        };
        filterInfo.skip = info?.skip ? info?.skip : 0;
        filterInfo.limit = 50;

        const endDate = moment(info?.lastDate ? info?.lastDate : currentWeekEnd);
        // endDate.set({ hour: '23', minute: '59', second: '59' });
        filterInfo.endDate = endDate.format('YYYY-MM-DD');

        if (info?.sortType) {
            filterInfo.sortType = info.sortType;
            filterInfo.orderType = info.orderType === 'DESC' ? 'ASC' : 'DESC';
        }

        if (info?.status) {
            if (info?.status === 'RENDERED') {
                filterInfo.status = 'COMPLETED';
            } else if (info?.status === 'COMPLETED') {
                filterInfo.status = 'COMPLETED';
            } else if (info?.status === 'CANCELLED') {
                filterInfo.status = 'CANCELLED';
            } else {
                filterInfo.status = 'PENDING';
            }
        } else {
            delete filterInfo.status;
        }

        info?.type ? filterInfo.type = info.type : delete filterInfo.type;
        info?.client ? filterInfo.client = info.client : delete filterInfo.client;
        info?.staff ? filterInfo.staff = info.staff : delete filterInfo.staff;
        return filterInfo;
    }, [info]);

    const getEvents = (load) => {
        dispatch(appointmentActions.getAppointment(renderParams, load));
    };

    useEffect(() => {
        getEvents('load');
        const timer = setInterval(() => {
            getEvents('noLoad');
        }, 30000);
        return () => clearInterval(timer);
    }, [info]);

    const handleOpen = () => {
        setOpen((prevState) => !prevState);
        setModalDate('');
        setDate('');
    };

    const handleOpenClose = (i, date) => {
        setModalDate('');
        if (date) {
            setDate(date);
        }
        setOpen((prevState) => !prevState);
    };

    const openCloseRecur = (date) => {
        open(<Recur date={date} />);
    };

    const handleEdit = (item) => {
        dispatch(adminActions.getAllPayCodes(item.staff?._id));
        setModalDate(item);
        setOpen((prevState) => !prevState);
    };

    const openCloseFullScreen = () => {
        setFullScreen(!fullScreen);
    };

    return (
        <div className={classes.wrapper}>
            <div className={info?.viewType === 'calendar' ? 'calendar-view-box' : ''}>
                <div className={info?.viewType === 'calendar' ? 'calendar-filter-box' : ''}>
                    <Filters
                        handleOpen={handleOpen}
                        openCloseFullScreen={openCloseFullScreen}
                    />
                </div>
                <>
                    {info?.viewType === 'calendar' ? (
                        <Selectable
                            renderParams={renderParams}
                            handleOpen={handleOpen}
                            openCloseRecur={openCloseRecur}
                            handleOpenClose={handleOpenClose}
                            handleEdit={handleEdit}
                        />
                    ) : (
                        <ScheduleList
                            handleEdit={handleEdit}
                            openCloseRecur={openCloseRecur}
                            renderParams={renderParams}
                        />
                    )}
                </>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={openEvtModal}
                content={
                    <CreateEvent
                        renderParams={renderParams}
                        date={date}
                        modalDate={modalDate}
                        handleOpenClose={handleOpenClose}
                    />
                }
                secondary={true}
            />

            <SimpleModal
                handleOpenClose={openCloseFullScreen}
                openDefault={fullScreen}
                secondary={true}
                bigSize={true}
                content={
                    <CalendarPage
                        renderParams={renderParams}
                        handleOpen={handleOpen}
                        openCloseRecur={openCloseRecur}
                        handleOpenClose={handleOpenClose}
                        handleEdit={handleEdit}
                        closeFullScreen={() => setFullScreen(false)}
                    />
                }
            />
        </div>
    );
};
