import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FullTable, TableWrapper } from 'components';
import { clientActions, httpRequestsOnSuccessActions } from 'store';
import { FindSuccess, PermissionList, RolePermission, useModal } from 'utils';
import { CreateClient } from 'fragments';
import { ACTION_TYPE, clientHead, clientBody, renderBorderColor } from './constants';

const CREATE_ACTION = 'CREATE_CLIENT';

export const Client = () => {
    const clientList = useSelector((state) => state.client.clientList);
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const success = FindSuccess(CREATE_ACTION);
    const { open, close } = useModal();
    const getClients = () => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 50;
        if (info?.status === 'ALL') {
            delete filteredInfo.status;
        } else if (info?.status) {
            filteredInfo.statuses = [info?.status];
        }
        delete filteredInfo.page;
        delete filteredInfo.activeTab;
        delete filteredInfo.name;
        dispatch(clientActions.getClients(filteredInfo));
    };

    useEffect(() => {
        getClients();
    }, [info]);

    useEffect(() => {
        if (!!success?.length) {
            close();
            getClients();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION));
        }
    }, [success]);

    const handleNavigate = (id) => {
        if (RolePermission([PermissionList.CLIENT_READ?.code])) {
            history.push(`/client/${id}`);
        }
    };


    return (
        <TableWrapper
            buttonsTab={true}
            buttonsTabAddButton={RolePermission([PermissionList.CLIENT_CREATE?.code])}
            addButtonText={'Add Client'}
            csvType={'client'}
            handleOpenClose={() => open(<CreateClient />)}
        >
            <FullTable
                head={clientHead}
                body={clientBody}
                loadingType={ACTION_TYPE}
                list={clientList?.clients}
                listCount={clientList?.count}
                handleClick={handleNavigate}
                noText={'clients'}
                renderBorder={renderBorderColor}
            />
        </TableWrapper>
    );
};
