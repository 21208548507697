import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './admin.service';
import {
    CREATE_ADMIN,
    GET_ADMIN_BY_ID,
    GET_ADMIN_BY_ID_SUCCESS,
    GET_ADMINS,
    GET_ADMINS_SUCCESS,
    EDIT_ADMIN_BY_ID,
    CREATE_CREDENTIAL,
    GET_CREDENTIAL,
    GET_CREDENTIAL_SUCCESS,
    EDIT_CREDENTIAL_BY_ID,
    DELETE_CREDENTIAL_BY_ID,
    GET_EMPLOYMENT,
    CREATE_EMPLOYMENT,
    GET_EMPLOYMENT_SUCCESS,
    GET_PAY_CODE_SUCCESS,
    GET_PAY_CODE,
    CREATE_PAY_CODE,
    EDIT_EMPLOYMENT,
    CREATE_STAFF_SERVICE,
    GET_STAFF_SERVICE_SUCCESS,
    GET_STAFF_SERVICE,
    DELETE_STAFF_SERVICE,
    GET_TIMESHEET_SUCCESS,
    GET_TIMESHEET,
    CREATE_TIMESHEET,
    GET_ALL_PAY_CODES,
    GET_ALL_PAY_CODES_SUCCESS,
    EDIT_TIMESHEET,
    GET_ALL_ADMINS_SUCCESS,
    GET_ALL_ADMINS,
    EDIT_PAY_CODE,
    IS_CLINICIAN,
    GET_TIMESHEET_BY_ID,
    GET_TIMESHEET_BY_ID_SUCCESS,
    GET_ALL_PAY_CODES_FAIL,
    CHANGE_ADMIN_STATUS,
    TERMINATE_PAY_CODE,
    CREATE_ADMIN_DETAILS,
    GET_ACTIVE_EMPLOYMENT,
    GET_ACTIVE_EMPLOYMENT_SUCCESS,
    UPLOAD_STAFF_DOCUMENT,
    GET_STAFF_DOCUMENT,
    GET_STAFF_DOCUMENT_SUCCESS,
    DELETE_STAFF_DOCUMENT,
    EDIT_STAFF_DOCUMENT,
    GET_FOUNDING_ALL_SERVICES,
    GET_FOUNDING_ALL_SERVICES_SUCCESS,
    REINVITE_ADMIN, DELETE_EMPLOYMENT, UPLOAD_STAFF_AVATAR, GET_STAFF_FOR_APPT, GET_STAFF_FOR_APPT_SUCCESS,
} from './admin.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';
import { GET_MY_PROFILE, GET_MY_PROFILE_SUCCESS } from '../auth/auth.types';

/** Staff */

function* createAdmin(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createAdminService, action.payload.body);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* reinviteAdmin(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.reinviteAdminService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* createAdminDetails(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createAdminDetailsService, action.payload.id, action.payload.body);
        yield put({
            type: GET_ADMIN_BY_ID,
            payload: { adminId: action.payload?.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getAdmins(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getAdminsService, action.payload.data);
        yield put({
            type: GET_ADMINS_SUCCESS,
            payload: { staff: res.data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_ADMINS_SUCCESS,
            payload: { staff: { staff: [], count: 0 } },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getAllAdmins(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getAdminsService, action.payload);
        yield put({
            type: GET_ALL_ADMINS_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getAdminById(action) {
    if (action.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getAdminByIdService, action.payload.adminId);
        yield put({
            type: GET_ADMIN_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* changeAdminStatus(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.changeAdminStatusService, action.payload.id, action.payload.status);
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        const res = yield call(authService.getAdminByIdService, action.payload.id);
        yield put({
            type: GET_ADMIN_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editAdminById(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.editAdminByIdService, action.payload.id, action.payload.body);
        yield put({
            type: GET_ADMIN_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* uploadAvatar(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.uploadStaffAvatar, action.payload.body);
        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: res.data,
        });
        yield put({
            type: GET_ADMIN_BY_ID,
            payload: { adminId: action.payload.id },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

/** Staff Credentials */

function* createCredential(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createCredentialService, action.payload.body);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getCredential(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getCredentialService, action.payload?.credentialId, action.payload?.data);
        yield put({
            type: GET_CREDENTIAL_SUCCESS,
            payload: { credentials: res.data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_CREDENTIAL_SUCCESS,
            payload: { credentials: { credentials: [], count: 0 } },
        });
        if (err?.data?.message === 'Staff Credential with this id was not found') {
            yield put({
                type: GET_CREDENTIAL_SUCCESS,
                payload: '',
            });
        }
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* editCredentialById(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editCredentialByIdService, action.payload.id, action.payload.body);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteCredentialById(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteCredentialByIdService, action.payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

/** Staff Employment */

function* getEmployment(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getEmploymentService, action.payload.id);
        yield put({
            type: GET_EMPLOYMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getActiveEmployment(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getActiveEmploymentService, action?.payload?.id);
        yield put({
            type: GET_ACTIVE_EMPLOYMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_ACTIVE_EMPLOYMENT_SUCCESS,
            payload: null,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createEmployment(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createEmploymentService, action.payload.body);
        yield put({
            type: GET_EMPLOYMENT,
            payload: { id: action.payload.id },
        });
        yield put({
            type: GET_ACTIVE_EMPLOYMENT,
            payload: { id: action.payload.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editEmployment(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editEmploymentService, action.payload.body, action.payload.id);
        yield put({
            type: GET_EMPLOYMENT,
            payload: { id: action.payload.staffId, load: 'noLoad' },
        });
        yield put({
            type: GET_ACTIVE_EMPLOYMENT,
            payload: { id: action?.payload?.staffId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteEmployment(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteEmploymentService, action.payload.id);
        yield put({
            type: GET_EMPLOYMENT,
            payload: { id: action.payload.staffId },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

/** Staff PayCode */

function* getPayCode(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    }
    try {
        const res = yield call(authService.getPayCodeService, action.payload.id, action.payload?.data);
        yield put({
            type: GET_PAY_CODE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_PAY_CODE_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createPayCode(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createPayCodeService, action.payload.body);
        yield put({
            type: GET_PAY_CODE,
            payload: { id: action.payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editPayCode(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editPayCodeService, action.payload.body, action.payload.payCodeId);
        yield put({
            type: GET_PAY_CODE,
            payload: { id: action.payload.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* terminatePayCode(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.terminatePayCodeService, action.payload.payCodeId);
        yield put({
            type: GET_PAY_CODE,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getAllPayCodes(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getAllPayCodesService, action?.payload?.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_ALL_PAY_CODES_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put({
            type: GET_ALL_PAY_CODES_FAIL,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

/** End */

/** Staff Service */

function* getStaffService(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getStaffServService, action.payload.id);
        yield put({
            type: GET_STAFF_SERVICE_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_STAFF_SERVICE_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createStaffService(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.createStaffServService, action?.payload?.id, action?.payload?.serviceId);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_STAFF_SERVICE,
            payload: { id: action.payload.id, load: 'noLoad' },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* deleteStaffService(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.deleteStaffServService, action.payload.id, action.payload.serviceId);
        yield put({
            type: GET_STAFF_SERVICE,
            payload: { id: action.payload.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

/** End */

/** Staff Timesheet */

function* getTimesheet(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getTimesheetService, action.payload.id, action.payload?.data);
        yield put({
            type: GET_TIMESHEET_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put({
            type: GET_TIMESHEET_SUCCESS,
            payload: [],
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* getTimesheetById(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getTimesheetById, action.payload.id);
        yield put({
            type: GET_TIMESHEET_BY_ID_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* createTimesheet(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.createTimesheetService, action.payload.body);
        yield put({
            type: GET_TIMESHEET,
            payload: { id: action.payload.body.staffId, data: { status: res?.data?.status }, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editTimesheet(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const timeRes = yield call(authService.editTimesheetService, action.payload.body, action.payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_TIMESHEET,
            payload: { id: action.payload.params, data: { status: timeRes?.data?.status }, load: 'noLoad' },
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

/** End */

function* isClinician(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.isClinicianService, action.payload.id, action.payload.isClinical);
        yield put({
            type: GET_ADMIN_BY_ID,
            payload: { adminId: action.payload.id },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** Staff Documents */
function* uploadStaffDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.uploadService, action.payload.file, action.payload.id);
        yield put({
            type: GET_STAFF_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* editStaffDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.editService, action?.payload);
        yield put({
            type: GET_STAFF_DOCUMENT,
            payload: { id: action?.payload?.id, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getStaffDocument(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getFilesService, action.payload.id);
        yield put({
            type: GET_STAFF_DOCUMENT_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* deleteStaffDocument(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.deleteFilesService, action.payload.staffId, action.payload.id);
        yield put({
            type: GET_STAFF_DOCUMENT,
            payload: { id: action?.payload?.staffId, load: 'noLoad' },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

function* getAllServices(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.getAllServicesService);
        yield put({
            type: GET_FOUNDING_ALL_SERVICES_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess('MINI_LOAD'));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}
function* getStaffForAppt(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getStaffForApptService);
        yield put({
            type: GET_STAFF_FOR_APPT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}


export const watchAdmin = function* watchAdminSaga() {
    /** Staff */
    yield takeLatest(CREATE_ADMIN, createAdmin);
    yield takeLatest(REINVITE_ADMIN, reinviteAdmin);
    yield takeLatest(CREATE_ADMIN_DETAILS, createAdminDetails);
    yield takeLatest(GET_ADMINS, getAdmins);
    yield takeLatest(GET_ALL_ADMINS, getAllAdmins);
    yield takeLatest(GET_ADMIN_BY_ID, getAdminById);
    yield takeLatest(CHANGE_ADMIN_STATUS, changeAdminStatus);
    yield takeLatest(EDIT_ADMIN_BY_ID, editAdminById);
    yield takeLatest(UPLOAD_STAFF_AVATAR, uploadAvatar);
    /** End */

    /** Staff Credentials */
    yield takeLatest(CREATE_CREDENTIAL, createCredential);
    yield takeLatest(GET_CREDENTIAL, getCredential);
    yield takeLatest(EDIT_CREDENTIAL_BY_ID, editCredentialById);
    yield takeLatest(DELETE_CREDENTIAL_BY_ID, deleteCredentialById);
    /** End */

    /** Staff Employment */
    yield takeLatest(GET_EMPLOYMENT, getEmployment);
    yield takeLatest(GET_ACTIVE_EMPLOYMENT, getActiveEmployment);
    yield takeLatest(CREATE_EMPLOYMENT, createEmployment);
    yield takeLatest(EDIT_EMPLOYMENT, editEmployment);
    yield takeLatest(DELETE_EMPLOYMENT, deleteEmployment);
    /** End */

    /** Staff PayCode */
    yield takeLatest(GET_PAY_CODE, getPayCode);
    yield takeLatest(CREATE_PAY_CODE, createPayCode);
    yield takeLatest(EDIT_PAY_CODE, editPayCode);
    yield takeLatest(TERMINATE_PAY_CODE, terminatePayCode);
    yield takeLatest(GET_ALL_PAY_CODES, getAllPayCodes);
    /** End */

    /** Staff Service */
    yield takeLatest(GET_STAFF_SERVICE, getStaffService);
    yield takeLatest(CREATE_STAFF_SERVICE, createStaffService);
    yield takeLatest(DELETE_STAFF_SERVICE, deleteStaffService);
    /** End */

    /** Staff Timesheet */
    yield takeLatest(GET_TIMESHEET, getTimesheet);
    yield takeLatest(GET_TIMESHEET_BY_ID, getTimesheetById);
    yield takeLatest(CREATE_TIMESHEET, createTimesheet);
    yield takeLatest(EDIT_TIMESHEET, editTimesheet);
    /** End */

    yield takeLatest(IS_CLINICIAN, isClinician);

    /** Staff Documents */
    yield takeLatest(UPLOAD_STAFF_DOCUMENT, uploadStaffDocument);
    yield takeLatest(EDIT_STAFF_DOCUMENT, editStaffDocument);
    yield takeLatest(GET_STAFF_DOCUMENT, getStaffDocument);
    yield takeLatest(DELETE_STAFF_DOCUMENT, deleteStaffDocument);
    /** End */

    yield takeLatest(GET_FOUNDING_ALL_SERVICES, getAllServices);

    yield takeLatest(GET_STAFF_FOR_APPT, getStaffForAppt);

};
