import {
    ActiveInactiveStatus,
    Colors,
    getDisplayDependsOnWidth,
    Images, PermissionList, RolePermission,
} from 'utils';
import { cardStyle } from '.';
import { DateRow, LinkRow, TextRow } from '..';
import React from 'react';

export function getTitleDisplay(givenTitle = '', givenValue = '', width, isBig) {
    const classes = cardStyle();

    const getCurrentValue = (value) => value;
    // getDisplayDependsOnWidth(value, width, 8, isBig ? 1440 : 1920);

    return typeof givenTitle === 'object' && typeof givenValue === 'object' ? (
        <div className={classes.titleBoxStyle}>
            {givenTitle.first && givenValue.first && (
                <p className={`${classes.titleStyle} several`}>
                    {getCurrentValue(`${givenTitle.first}:`)}
                </p>
            )}
            {givenTitle.second && givenValue.second && (
                <p className={`${classes.titleStyle} several`}>
                    {getCurrentValue(`${givenTitle.second}:`)}
                </p>
            )}
        </div>
    ) : (
        <p className={classes.titleStyle}>{getCurrentValue(`${givenTitle}:`)}</p>
    );
}

export function getValueDisplay(givenValue = '', givenTitle = '', width, isBig) {
    const classes = cardStyle();

    const getCurrentValue = (value) =>
        getDisplayDependsOnWidth(value, width, 8, isBig ? 1440 : 1920);

    return typeof givenTitle === 'object' && typeof givenValue === 'object' ? (
        givenTitle.first === 'Status' ? (
            <div className={classes.valueBoxStyle}>
                {givenValue.first && givenTitle.first && (
                    <p className={`${classes.valueStyle} several statusStyle ${ActiveInactiveStatus(givenValue.first)}`}>
                        {givenValue.first}
                    </p>
                )}
                {givenValue.second && givenTitle.second && (
                    <p className={`${classes.valueStyle} several`}>
                        {getCurrentValue(givenValue.second)}
                    </p>
                )}
            </div>
        ) : (
            <div className={classes.valueBoxStyle}>
                {givenValue.first && givenTitle.first && (
                    <p className={`${classes.valueStyle} several`}>{givenValue.first}</p>
                )}
                {givenValue.second && givenTitle.second && (
                    <p className={`${classes.valueStyle} several`}>
                        {getCurrentValue(givenValue.second)}
                    </p>
                )}
            </div>
        )
    ) : givenTitle === 'Website' ? (
        <a href={givenValue} target="_blank" className={classes.linkStyle}>
            {getCurrentValue(givenValue)}
        </a>
    ) : givenTitle === 'Status' ? (
        <p
            className={`${classes.valueStyle} statusStyle ${ActiveInactiveStatus(
                givenValue,
            )}`}
        >
            {givenValue}
        </p>
    ) : (
        <p className={classes.valueStyle}>{getCurrentValue(givenValue)}</p>
    );
}

export function getCardBorderTop(cardTitle) {
    switch (cardTitle) {
        case 'General Info':
            return `4px solid ${Colors.BackgroundBlue}`;
        case 'Address':
            return `4px solid ${Colors.BackgroundMango}`;
        case 'Other Details':
            return `4px solid ${Colors.ThemeRed}`;
        case 'Employment':
            return `4px solid ${Colors.BackgroundMango}`;

        default:
            return 'unset';
    }
}

// Notes Table config
export const noteHead = (permissions) => {
    return [
        {
            name: 'date',
            title: 'Created Date',
            icon: 'date',
            disabled: true,
            custom: false,
            noSearch: true,
            width: '150px',
        },
        { name: 'user', rowName: 'firstName', title: 'Creator Name', custom: false, noSearch: true,  width: '200px', },
        { name: 'subject', title: 'Subject', custom: false, noSearch: true },
        { name: 'description', title: 'Body', custom: false, noSearch: true },
        RolePermission([...permissions]) &&
        { name: 'action', title: 'Action', custom: false, disabled: true, noSearch: true, width: '100px' },
    ];
};

export const noteBody = (permissions) => {
    return [
        { rowText: (item) => <DateRow date={item?.created} /> },
        {
            rowText: (item) => <LinkRow
                name={item?.user ? `${item?.user?.firstName} ${item?.user?.lastName ? item?.user?.lastName : ''}` : 'N/A'}
                href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.user?.id || item?.user?._id}` : ''}
                checkPermission={item?.user ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                textWidth={8}
            />,
            notClickable: true,
        },
        { rowText: (item) => <TextRow name={item?.subject}/> },
        { rowText: (item) => <TextRow name={item?.text}/> },
        RolePermission([...permissions]) &&
        {
            button: (cb) => (
                <div style={{ display: 'flex', alignItems: 'center', gap:'10px' }}>
                    <button  onClick={() => cb('editNote')} className='reset-btn' style={{padding: 0}}>
                        <img src={Images.edit} alt="edit" />
                    </button>
                    <button  onClick={() => cb('deleteNote')} className='reset-btn' style={{padding: 0}}>
                    <img
                        style={{ cursor: 'pointer' }}
                        src={Images.remove}
                        alt="remove"

                    />
                    </button>
                </div>
            ),
            notClickable: true,
        },
    ];
};

export const ACTION_TYPE = 'GET_GLOBAL_NOTES';
