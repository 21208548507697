import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FindLoad, SendPageSave, useGlobalStyles, SavePage, filterFromTable, SaveParams } from 'utils';
import { Loader, NoItemsYet, TableNoInfo } from 'components';
import { tableStyle } from '../table/styles';
import { PaginationFillTable } from '../pagination';
import { SearchAndFilerFull } from './searchAndFilerFull';
import { FindSuccessItem } from '../../utils';
import { handleSelectSort, handleSetName } from './fullTableCore/constants';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

export const FullTable = ({
                              head,
                              body,
                              list,
                              loadingType,
                              handleClick,
                              noText,
                              margin,
                              listCount,
                              handleClickButton,
                              activeRowId,
                              height, generateTable, renderBorder
                          }) => {

    const classes = tableStyle();
    const globalStyle = useGlobalStyles();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const info = history?.location?.state;
    const loader = FindLoad(loadingType ? loadingType : '');
    const miniLoadSuccess = FindSuccessItem('MINI_LOAD');
    const downloadMiniLoadSuccess = FindSuccessItem('MINI_LOAD_DOWNLOAD');
    const location = useLocation();
    const [listInfo, setListInfo] = useState(list);
    const [searched, setSearched] = useState({ name: '', text: '' });

    useEffect(() => {
        if (list) {
            setListInfo(list);
        }
    }, [list]);


    useEffect(() => {
        if (info?.searchKey) {
            setSearched({
                value: info?.searchValue,
                name: info?.name,
                searchKey: info?.searchKey,
                // name: e.target.name,
            });
        }
    }, [info]);

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history, location);
    };

    const handleName = (item, name) => {
        handleClickButton && handleClickButton(item, name);
    };

    const renderItems = (i, item) => {
        if (item?.button) {
            return item?.button((name) => handleName(i, name), i, (miniLoadSuccess?.data === i.id || i.staffId), downloadMiniLoadSuccess?.data === i?.id);
        } else {
            return item?.rowText(i);
        }
    };

    const debounced = useDebouncedCallback((name, value, searchKey) => {
            const params = {
                ...info,
            };
            params.limit = 50;
            params.skip = 0;
            params.page = 1;

            if (value) {
                params.searchKey = searchKey;
                params.searchValue = value;
                params.name = name;
            } else {
                delete params.searchKey;
                delete params.searchValue;
                delete params.name;
            }
            SaveParams(history, params);
        },
        500,
        { maxWait: 2000 },
    );
    const handleChange = (e, nameRow, searchKey) => {
        if (searchKey) {
            setSearched({
                value: e.target.value,
                searchKey: searchKey,
                name: e.target.name,
            });
            debounced(e.target.name, e.target.value, searchKey);
        } else {
            setSearched({
                name: e.target.name,
                value: e.target.value,
            });
            if (e.target.name) {
                if (!e.target.value) {
                    setListInfo(list);
                } else {
                    const info = filterFromTable(e, nameRow, list);
                    setListInfo(info);
                }
            }
        }
    };

    // if (loader.length && !searchValue?.searchKey) return <Loader />;

    return (
        <div style={{ width: '100%' }}>
            <div style={margin ? { margin: 0 } : {}} className={globalStyle.tableWrapper}>
                <TableContainer
                    style={{ padding: '0 2px' }}
                    className={height === 'auto' ? globalStyle.tableContainerAutoHeight : globalStyle.tableContainer}
                    component={Paper}>
                    <Table
                        stickyHeader
                        // aria-label="sticky table"
                        className={globalStyle.table}
                        style={{ position: 'relative' }}
                        size="small"
                        aria-label="a dense table"
                    >
                        <TableHead className={generateTable ? classes.tableHeadGenerate : classes.tableHead}>
                            <TableRow>
                                {head ? head.map((i, j) => (i &&
                                    <TableCell key={j} style={i?.width ? { width: i.width } : {}}>
                                        {i?.renderView ?
                                            <div>
                                                {i?.renderView}
                                            </div>
                                            :
                                            <SearchAndFilerFull
                                                handleSearch={(e) => handleChange(e, i?.rowName, i?.searchKey)}
                                                item={i}
                                                searched={searched}
                                            />
                                        }
                                    </TableCell>
                                )) : ''}
                            </TableRow>
                        </TableHead>

                        {loader.length ?
                            <div style={{ position: 'absolute', width: '100%' }}>
                                <Loader height="70vh" />
                            </div>
                            :
                            <TableBody>
                                {listInfo?.length ? listInfo?.map((i, j) => (
                                        <React.Fragment key={j}>
                                            <TableRow
                                                className={generateTable ? classes.generateTableRow :
                                                    `${classes.tableRow} ${i.id === activeRowId ? 'active' : ''}`}
                                            >
                                                {body?.map((item, ke) => {
                                                    if (item) {
                                                        return (
                                                            <Fragment key={ke}>
                                                                <TableCell
                                                                    className={classes.customRow}
                                                                    onClick={() => item?.notClickable !== true && handleClick && handleClick(i?.id || i?._id, i)}
                                                                    style={renderBorder && ke === 0 ? { ...renderBorder(i) } : {}
                                                                }
                                                                >
                                                                    {renderItems(i, item) || 'Not Set'}
                                                                </TableCell>
                                                            </Fragment>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                    :
                                    <NoItemsYet text={`No ${noText} yet`}/>
                                    // <TableNoInfo  text={`No ${noText} yet`} />
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                {listCount > 0 &&
                    <div style={{ height: '76px' }}>
                        <PaginationFillTable
                            listLength={listInfo?.length}
                            page={page}
                            handleReturn={(number) => changePage(number)}
                            count={listCount}
                        />
                    </div>
                }
            </div>
        </div>
    );
};