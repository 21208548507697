import React, { Fragment, useEffect, useState } from 'react';
import { AddButton, Loader, NoItemsYet, NoItemText, SlicedText, ValidationInput } from 'components';
import { useDispatch } from 'react-redux';
import {
    ErrorText,
    FindLoad,
    FindSuccess, FindSuccessItem,
    Images,
    isNotEmpty, useModal,
} from 'utils';
import { systemItemStyles } from './styles';
import { httpRequestsOnSuccessActions, systemActions } from 'store';
import { EditPlace } from './modals/editPlace';

const credentialBtn = {
    maxWidth: '209px',
    width: '100%',
    flex: '0 0 209px',
    padding: 0,
};

const ACTION_TYPE = 'CREATE_PLACE_GLOBAL';

export const PlaceOfService = ({ globalJobs }) => {
    const classes = systemItemStyles();
    const dispatch = useDispatch();
    const getLoader = FindLoad('GET_PLACES');
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const { open } = useModal();

    useEffect(() => {
        dispatch(systemActions.getPlaces());
    }, []);

    useEffect(() => {
        if (success) {
            setInputs({});
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    function handleChange(e) {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        error === e.target.name && setError('');
    }

    function handleSubmit() {
        const dataIsValid = isNotEmpty(inputs.name) && isNotEmpty(inputs.code);
        if (dataIsValid) {
            const data = {
                name: inputs.name,
                code: inputs.code,
            };
            dispatch(systemActions.createPlaceGlobal(data));
        } else {
            const dataErrorText = !isNotEmpty(inputs.name)
                ? 'name'
                : !isNotEmpty(inputs.code)
                    ? 'code'
                    : '';
            setError(dataErrorText);
        }
    }

    if (getLoader?.length) {
        return <Loader />;
    }

    return (
        <Fragment>
            <div className={`${classes.flexContainer} ${classes.headerSize}`}>
                <ValidationInput
                    style={classes.credentialInputStyle}
                    onChange={handleChange}
                    value={inputs.name}
                    variant={'outlined'}
                    name={'name'}
                    type={'text'}
                    placeholder={'Name*'}
                    typeError={error === 'name' ? `Name ${ErrorText.isRequired}` : ''}
                    Length={50}
                />
                <ValidationInput
                    style={classes.codInputStyle}
                    onChange={handleChange}
                    value={inputs.code}
                    variant={'outlined'}
                    name={'code'}
                    type={'number'}
                    placeholder={'Code*'}
                    typeError={error === 'code' ? `Code ${ErrorText.isRequired}` : ''}
                    Length={10}
                />
                <AddButton
                    type={ACTION_TYPE}
                    styles={credentialBtn}
                    loader={!!loader.length}
                    handleClick={handleSubmit}
                    text="Add Place of Service"
                />
            </div>
            <p className={classes.title}>Place of Services</p>
            {globalJobs?.length ? (
                    <div className={classes.credentialTable}>
                        {globalJobs.map((item, index) => {
                            return (
                                <div className={classes.item} key={index}>
                                    <div className={classes.text}>
                                        <SlicedText
                                            type={'responsive'}
                                            size={25}
                                            data={`${item.name} - ${item.code}`}
                                        />
                                    </div>
                                    <div className={classes.icons}>
                                        <img
                                            src={Images.edit}
                                            onClick={() => open(<EditPlace info={item} />)}
                                            alt="edit"
                                        />
                                        {/* <img
                              src={Images.remove}
                              alt="delete"
                              onClick={() =>
                                 removeItem({
                                    id: item?.id,
                                    name: item.name,
                                    type: "editPlaceTitles",
                                 })
                              }
                           /> */}
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div> )
                    :
                <NoItemsYet text="No place of services yet" />
            }
        </Fragment>
    );
};
