import React from 'react';
import { useWidth } from 'utils';

export const ControlPanelContentBilling = ({ changingMenu }) => {
    const width = useWidth();

    const images = {
        desktop: {
            billing: '/assets/images/controlPanel/cpBilling.png',
        },
        tablet: {
            billing: '/assets/images/controlPanel/cpBillingTablet.png',
        },
        mobile: {
            billing: '/assets/images/controlPanel/cpBillingMobile.png',
        },
    };


    const renderImage = (src) => {
        if (width > 1240) {
            return images.desktop[src];
        } else if (width > 767) {
            return images.tablet[src];
        } else {
            return images.mobile[src];
        }
    };

    const changeSubMenuItem = (title) => {
        changingMenu('subMenu', title);
    };

    return (
        <div className='control-panel-content-menus menu-items'>
            <div className='controlPanelContentMenus-menu-design  billing-wrapper'>

                <div className='texts-wrapper'>
                    <h2 className='title'>
                        Billing
                    </h2>

                    <p className='description qr-subtitle-margin' style={{ maxWidth: '676px' }}>
                        Explore various packages and manage your payments by accessing details about your active plan.
                    </p>

                    <p className='description' style={{ maxWidth: '676px' }}>
                        The: Billing section is organized into two main segments
                        <span style={{ fontWeight: '400' }}>
                            Plans and Pricing
                        </span>
                        and
                        <span style={{ fontWeight: '500' }}>Payments</span>.
                    </p>
                </div>
            </div>
        </div>
    );
};

