import React from 'react';
import Carousel from 'nuka-carousel';
import { motion } from 'framer-motion';
import { CustomerCard } from './customerCard';
import { customersTestimonials } from '../constants';
import { useWidth } from 'utils';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';;

export const CustomerTestimonials = () => {
    const width = useWidth();

    const returnSlidesToShow = () => {
        if (width < 768) {
            return 1;
        } else if (width < 1280) {
            return 1.5;
        } else {
            return 2;
        }
    };

    return (
        <div className='customer-testimonials-wrapper'>
            <div className='container'>
                <div className='customer-testimonials-box'>
                    <div className='what-customers-say'>
                        What customers say
                    </div>

                    <div style={{overflow:'hidden'}}>
                        <motion.h2
                            className='customer-testimonials-title'
                            {...animationParams}
                            variants={animationVariants.titleVariant}
                        >
                            Customer testimonials
                        </motion.h2>
                    </div>

                    <div className='customer-carousel-wrapper'>
                        <Carousel
                            slidesToShow={returnSlidesToShow()}
                            cellSpacing={32}
                            renderCenterLeftControls={() => null}
                            renderCenterRightControls={() => null}
                            renderBottomCenterControls={({ currentSlide, slideCount, goToSlide }) => (
                                <div className='render-carousel-center-control'>
                                    <LandingSvg.ArrowLeftCarousel
                                        className='carousel-left-button'
                                        onClick={() => goToSlide(currentSlide - 1)}
                                    />

                                    <div className='carousel-dots-wrapper'>
                                        {Array.from(Array(slideCount).keys()).map(index => (
                                            <div
                                                key={index}
                                                className={`carousel-dots ${index === currentSlide ? 'active-dots' : ''}`}
                                                onClick={() => goToSlide(index)}
                                            />
                                        ))}
                                    </div>

                                    <LandingSvg.ArrowRightCarousel
                                        className='carousel-right-button'
                                        onClick={() => goToSlide(currentSlide + 1)}
                                    />
                                </div>
                            )}

                        >
                            {customersTestimonials.map((
                                {
                                    customerAvatar,
                                    customerComment,
                                    customerProfession,
                                    customerName,
                                    rating,
                                }, index) => (
                                <CustomerCard
                                    className='customer-card'
                                    key={index}
                                    customerAvatar={customerAvatar}
                                    customerComment={customerComment}
                                    customerProfession={customerProfession}
                                    customerName={customerName}
                                    rating={rating}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};

