import React from 'react';
import { useHistory } from 'react-router-dom';
import { Colors, Images, SavePage } from 'utils';
import { MenuTool } from './fullTableCore/menu';
import { MinLoader } from '../loader';
import { bigSizeText, handleSelectSort, smallSizeText } from './fullTableCore/constants';
import { TextRow } from '../table';

export const SearchAndFilerFull = ({
                                       width,
                                       handleSearch,
                                       item,
                                       searched,
                                       loader,
                                   }) => {
    const history = useHistory();
    const info = history?.location?.state;
    const active =
        info?.[item?.name] === item?.name ? true :
            info?.hasOwnProperty(item?.name) ? true :
                info?.sortType === item?.name ? true :
                    ((searched?.name === item?.name) && searched?.value);

    const handleClickSort = (name) => {
        if (item?.custom !== false) {
            const anotherInfo = {
                ...info,
            };
            anotherInfo.sortType = name;
            if (info?.orderType === 'DESC') {
                anotherInfo.orderType = 'ASC';
            } else {
                anotherInfo.orderType = 'DESC';
            }
            SavePage(history, info, { ...anotherInfo });
        }
    };


    const handleFilter = (e) => {
        handleSelectSort(e, history, info);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
    };

    return (
        <div className="search-and-filer-box">
            <div
                className={active ? 'active-box' : 'passive-box'}
                style={width ? { width: width } : {}}
            >
                {!item?.filterList &&
                    <div
                        onClick={() => handleClickSort(item?.name)}
                        className="search-input-title"
                        style={{ cursor: item?.custom !== false ? 'pointer' : 'default', whiteSpace:'nowrap' }}
                    >
                        <TextRow name={item?.title} textWidth={8} />
                        {item?.custom !== false &&
                            <div className="sort-btn-box">
                                <img
                                    style={(info?.orderType && info?.sortType === item?.name) ? { opacity: 1 } : { opacity: 0.6 }}
                                    src={
                                        item?.iconType === 'date' ?
                                            info?.sortType === item?.name && info?.orderType === 'DESC' ?
                                                Images.latestEarliest : Images.earliestLatestBlue
                                            :

                                            info?.sortType === item?.name && info?.orderType === 'DESC' ?
                                                Images.zToA : Images.aToZ
                                    }
                                    alt={'filter icon'}
                                />
                            </div>
                        }
                    </div>
                }

                {item?.custom !== false && item?.filterList &&
                    <MenuTool
                        item={item}
                        handleFilter={handleFilter}
                        selectedFilter={info?.type ? info?.type : 'All'}
                    />
                }

                {!item?.noSearch &&
                    <div style={{ position: 'relative' }}>
                        <form autoComplete="off" onSubmit={handleSubmit} >
                            <input
                                autoComplete={'new-password'}
                                disabled={item?.disabled}
                                name={item?.name}
                                value={searched?.name === item?.name ? searched?.value : ''}
                                defaultValue={null}
                                onChange={handleSearch}
                                type="search"
                            />
                        </form>
                            {!!loader && <MinLoader margin={'6px 0 0 -20px'} color={Colors.BackgroundBlue} />}
                    </div>
                }
            </div>
        </div>
    );
};