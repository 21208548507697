import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fundingSourceSingleStyles, editButtonStyle } from './styles';
import { FindLoad, Images, PermissionList, RolePermission, useModal } from 'utils';
import {
    AddButton,
    AddModalButton,
    AddNotes,
    ValidationInput,
    NameAndAvatar,
    DetailInfoCard, CustomDelete, UploadModal, UserInputsDropdown,
} from 'components';
import { FundingSourceServiceAdd } from './modals';
import { CreateFundingSource } from '../../createFundingSource';
import { fundingSourceActions, historyActions } from 'store';
import { FS_STATUSES } from './constants';

const filterBtn = {
    width: 93,
    height: 36,
};

const ACTION_TYPE = 'DELETE_FUNDING_SOURCE';
export const FundingSourceSingleHeader = ({}) => {
    const classes = fundingSourceSingleStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const fsSource = useSelector((state) => state.fundingSource.fundingSourceItem);
    const [searchDate, setSearchDate] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const fsHistoryLoader = !!FindLoad('GET_HISTORY').length;
    const history = useHistory();
    const info = history?.location?.state;
    const { open } = useModal();

    function handleChangeFile(e) {
        setIsDisabled(false);
        setSearchDate(e.target.value);
    }

    function handleSubmit() {
        setIsDisabled(true);
        const paramsForFunderHistory = {
            onResource: params.id,
            start: searchDate && new Date(searchDate).toISOString(),
        };
        dispatch(historyActions.getHistory('Funder', paramsForFunderHistory));
    }

    const handleSelectionChange = (currentStatus) => {
        dispatch(fundingSourceActions.changeFundingSourceStatus(params.id, currentStatus?.id));
    };

    return (
        <div className="tabs-wrapper">
            <div className='full-width'>
                <div className="space-between-flex-start" >
                    <NameAndAvatar
                        name={fsSource?.name}
                        type={'fundingSource'}
                    />
                    <div className='flex-align-start' style={{gap: 16}}>
                        {RolePermission([PermissionList.FS_STATUS?.code]) &&
                                <UserInputsDropdown
                                    dropdownOptions={FS_STATUSES}
                                    onPass={handleSelectionChange}
                                    selected={fsSource?.status}
                                />
                        }

                        {(info?.activeTab === 'General Information' || info?.activeTab === undefined) ?
                            <div className="flex-align-center" style={{ gap: '16px' }}>
                                {RolePermission([PermissionList.FS_CREATE?.code]) &&
                                    <button
                                        className='table-action-btn'
                                        onClick={() => open(<CreateFundingSource info={fsSource} />)}>
                                        <img src={Images.edit} alt="icon" />
                                    </button>
                                }

                                {fsSource?.canDelete && RolePermission([PermissionList.FS_DELETE?.code]) &&
                                    <button
                                        className='table-action-btn'
                                        onClick={() => open(
                                            <CustomDelete
                                                text={`Deleting this funding source will permanently remove it from the system.`}
                                                info="Are you sure you want to delete this funding source?"
                                                innerText={'Delete'}
                                                handleDel={() => dispatch(fundingSourceActions.deleteFundingSource(fsSource?.id))}
                                                actionType={ACTION_TYPE}
                                                afterSuccess={() => history.push('/fundingSource')}
                                            />)
                                        }
                                    >
                                        <img src={Images.redTrash} alt={'icon'}  />
                                    </button>
                                }
                            </div>
                            :
                            info?.activeTab === 'History' ? (
                                    <>
                                        <div className={classes.searchContainer} >
                                            <ValidationInput
                                                className={classes.dateInput}
                                                errorFalse={true}
                                                variant={'outlined'}
                                                onChange={handleChangeFile}
                                                value={searchDate}
                                                type={'date'}
                                                name="searchDate"
                                            />
                                            <AddModalButton
                                                handleClick={handleSubmit}
                                                text="Search"
                                                loader={fsHistoryLoader}
                                                btnStyles={filterBtn}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    </>
                                ) :
                                info?.activeTab === 'Services' && RolePermission([PermissionList.FS_SERVICE_CREATE?.code]) ?
                                    <AddButton
                                        text={'Add Service'}
                                        handleClick={() => open(<FundingSourceServiceAdd />)}
                                    />
                                    :
                                    info?.activeTab === 'Notes' && RolePermission([PermissionList.FS_NOTE_MANAGE?.code]) ?
                                        <AddButton
                                            text={'Add Note'}
                                            handleClick={() => open(<AddNotes model="Funder" id={fsSource?.id} />)}
                                        />
                                        :
                                        info?.activeTab === 'Files' && RolePermission([PermissionList.FS_FILE_MANAGE?.code]) ?
                                            <AddButton
                                                Icon={true}
                                                customIcon={Images.uploadCloud}
                                                text={'Upload Document'}
                                                handleClick={() => open(
                                                    <UploadModal
                                                        currentId={fsSource?.id}
                                                        onResource={'fs'}
                                                    />,
                                                )}
                                            />
                                            :
                                            <div className={classes.clear} />
                        }
                    </div>
                </div>

                <div className={classes.fsSourceInfoWrapper}>
                    {fsSource?.email &&
                        <DetailInfoCard image={Images.sms} title={fsSource?.email} type={'email'} />
                    }
                    {fsSource?.phoneNumber &&
                        <DetailInfoCard image={Images.callCalling} title={fsSource?.phoneNumber} type={'phone'} />
                    }
                    {fsSource?.address &&
                        <DetailInfoCard image={Images.grayLocation} title={fsSource?.address?.formattedAddress}
                                        type={'direction'} address={fsSource?.address} />
                    }
                </div>
            </div>
        </div>
    );
};
