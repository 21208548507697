import { makeStyles } from '@material-ui/core/styles';
import { Backgrounds, Colors, Shadow } from 'utils';

export const scheduleStyle = makeStyles(() => ({
    wrapper: {
        // padding: '20px 0 ',
        // backgroundColor: "white",
        height: '100%',
        borderRadius: 8,
        position: 'relative',
        // height: "100%",
        // minHeight: '100vh',
        paddingBottom: '40px',
    },
    dateStyle: {
        fontSize: '18px',
        fontWeight: '600',
        color: Colors.TextSecondary,
    },
    selectButtonsLabel: {
        marginBottom: '24px',
    },
    calendarNextPrewButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    navigationButtons: {
        margin: '0 8px 0 0',
        marginTop: '2px',

        '& svg': {
            filter: 'brightness(0) saturate(100%) invert(32%) sepia(44%) saturate(222%) hue-rotate(161deg) brightness(93%) contrast(88%)',
        },
    },
    searchWrapper: {
        display: 'flex',
        '& button': {
            marginLeft: '24px'
        },
    },
    filtersWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
    },
    filtersWrapperRow: {
        display: 'flex',
        gap: '16px',
        '& div.MuiInputBase-root': {
            width: '150px',
            '@media (min-width: 1919px)': {
                width: '180px',
            },
        },

        '& .MuiOutlinedInput-root': {
            height: '36px',
            marginTop: '8px',
            background: 'white',
            width: '150px',
            '@media (min-width: 1919px)': {
                width: '180px',
            },
        },

        '& select:focus': {
            background: 'transparent',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(163, 178, 189, 0.50)',
        },
    },
    label: {
        color: Colors.ThemeBlue,
        fontWeight: 600,
        fontSize: '14px',
    },
    dosInputsBox: {
        display: 'flex',
        alignItems: 'center',
    },
    dateInput: {
        height: '36px',
        marginTop: '8px',
        width: '150px',
        borderColor: 'rgba(163, 178, 189, 0.50)',
        borderRadius: '4px',
        padding: '16px',
        border: '1px solid rgba(163, 178, 189, 0.50)',
        background: '#FFF',
        '@media (min-width: 1919px)': {
            width: '180px',
        },
    },
    listWrapper: {
        display: 'flex',
        width: '100%',
    },
    wrapp: {
        width: '100%',
        maxHeight: '569px',
        height: '100%',
        overflow: 'auto',
        marginTop: '13px',
    },
    cardWrapper: {
        background: Backgrounds.whiteModal,
        boxShadow: '0px 0px 6px #347AF04D',
        borderRadius: '8px',
        margin: '3px 16px 16px 5px ',
        padding: '16px',
    },
    cardTitle: {
        marginBottom: '16px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: Colors.ThemeBlue,
    },
    hoverClass: {
        height: '50px',
        background: 'white',
        marginBottom: '8px',
        '& :hover': {
            background: '#EBF2FD80 0% 0% no-repeat padding-box',
            '& div': { background: 'none' },
            '& p': { background: 'none' },
        },
    },
    cardItemWrapper: {
        cursor: 'pointer',
        height: '50px',
        background: 'none',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 0px 6px #347AF04D',
        '& p': {
            fontSize: '12px',
            fontWeight: '400',
            color: '#4B5C68',
            marginRight: '8px',
            '@media (min-width: 1919px)': {
                fontSize: '14px',
            },
        },
        '& :hover': {
            '& p': { background: 'none' },
        },
    },
    cardItem: {
        cursor: 'pointer',
        width: '100%',
        padding: '0 4px 0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        lineHeight: '25px',
        '& > p': { minWidth: '50px' },
    },
    infoItems: {
        minHeight: '36px',
        background: '#F2F4F8 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        marginBottom: '8px',
        padding: '9px 16px',
        display: 'flex',
        alignItems: 'flex-start',
        '& p': {
            fontSize: '14px',
            fontWeight: '600',
            color: Colors.TextSecondary,
            whiteSpace: 'nowrap',
            lineHeight: '19px',
        },
        '& span': {
            fontSize: '14px',
            color: Colors.TextMiddleGray,
            marginLeft: '8px',
            lineHeight: '19px',
        },
    },
    link: { color: Colors.ThemeBlue },
    download: { marginLeft: '8px' },
    addEvent: {
        height: '36px',
        width: '100px',
        padding: '13px 24px',
        borderRadius: '4px',
        background: '#347AF0',
        boxShadow: '0px 0px 6px 0px rgba(52, 122, 240, 0.30)',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        '& p': {
            fontSize: '14px',
            fontWeight: '600',
            color: '#FFF',
        },
    },

    // notSet:{
    //    maxWidth:'100px',
    //    width:'auto',
    //    "@media (min-width: 1650px)": {
    //       width:'100%'
    //    }
    //
    // }
    scheduleViewBtnsBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        '& button': {
            width: '40px',
            height: '40px',
            minWidth: '40px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    scheduleViewActiveBtn: {
        background: '#347AF0',
        border: 'none',

        '& img': {
            filter: 'brightness(0) invert(1)',
        },
    },
    scheduleViewPassiveBtn: {
        border: '1px solid rgba(163, 178, 189, 0.50)',
        background: '#FFF',
    },
    bigSizeView: {
        display: 'none',
        '@media (min-width: 1500px)': {
            display: 'block',
        },
    },
    smallSizeButtonsTab: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '4px',
        '@media (min-width: 1500px)': {
            display: 'none',
            marginTop: 0,
        },
    },
}));
