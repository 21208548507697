import React, { useEffect, useState } from 'react';
import { Images, UrlRegex } from 'utils';
import { ErrMessage } from 'components';

export const ScheduleLinks = ({ changeLinks, item, linkError, setLinkError }) => {
    const [list, setList] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        changeLinks(list);
    }, [list]);

    useEffect(() => {
        if (item?.links?.length) {
            setList(item?.links);
        }
    }, [item]);

    const removeError = () => {
        setError('');
        if (linkError === 'notValidLinks') {
            setLinkError('');
        }
    };

    const handleAdd = () => {
        if (!error && error !== 0) {
            const newList = [
                ...list,
            ];
            if (newList?.length === 0) {
                removeError();
                newList.push({ title: '', url: '' });
            } else {
                const last = newList.at(-1);
                if (last?.title && last?.url) {
                    removeError();
                    newList.push({ title: '', url: '' });
                } else {
                    setError(newList?.length - 1);
                }
            }
            setList(newList);
        }
    };

    const handleDelete = (index) => {
        const newList = [
            ...list,
        ];
        newList.splice(index, 1);
        setList(newList);
        removeError();
        if(linkError === 'notValidLinks' || linkError === 'emptyTitle'){
            setLinkError('');
        }
    };

    const handleChange = (e, i) => {
        if (linkError === 'emptyTitle' && e.target.name === 'title') {
            setLinkError('');
            setError('');
        }
        if (linkError === 'notValidLinks' && e.target.name === 'url') {
            setLinkError('');
            setError('');
        }

        const newList = [
            ...list,
        ];
        newList[i][e.target.name] = e.target.value;

        if (newList[i].name && UrlRegex.test(newList[i].url)) {
            removeError();
        }
        setList(newList);
    };

    const checkValidation = (e, i) => {
        if (UrlRegex.test(e.target.value)) {
            if (i === error) {
                removeError();
            }
        } else {
            setLinkError('notValidLinks');
            setError(i);
        }
    };

    const checkLink = (e, i) => {
        if (e.target.value) {
            if (i === error && linkError === 'emptyTitle') {
                setError('');
                setLinkError('');
            }
        } else {
            setLinkError('emptyTitle');
            setError(i);
        }
    };

    return (
        <div className="schedule-links-box">
            <button className="add-link-btn" onClick={handleAdd}>
                <img src={Images.link} alt="icon" />
                <p>Add Link</p>
            </button>
            {!!list?.length &&
                <div className="schedule-link-inputs-box"
                     style={
                         linkError === 'notValidLinks' ? { borderColor: '#F07379' } :
                             linkError === 'emptyTitle' ? { borderColor: '#F07379' } :
                                 {}
                     }
                >
                    {list?.map((i, j) => (
                        <div key={j} className="schedule-link-inputs">
                            <div className="flex-align-start" style={{ gap: '16px' }}>
                                <div>
                                    <p>Link Title</p>
                                    <input
                                        style={{ borderColor: (error === j) ? '#F07379' : '#347AF0' }}
                                        value={i?.title}
                                        type="text"
                                        name={'title'}
                                        onChange={(e) => handleChange(e, j)}
                                        placeholder={'Enter Link Title'}
                                        maxLength={30}
                                        onBlur={(e) => checkLink(e, j)}
                                    />
                                </div>
                                <div>
                                    <p>Link URL</p>
                                    <input
                                        style={{ borderColor: (error === j) ? '#F07379' : '#347AF0' }}
                                        value={i?.url}
                                        type="text"
                                        name={'url'}
                                        onChange={(e) => handleChange(e, j)}
                                        placeholder={'Enter Link URL'}
                                        onBlur={(e) => checkValidation(e, j)}
                                    />
                                </div>
                            </div>
                            <div className="cancel-link-btn">
                                <button onClick={() => handleDelete(j)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ))}

                    <ErrMessage
                        text={
                            linkError === 'notValidLinks' ? 'Url must be valid url address' :
                                linkError === 'emptyTitle' ? 'Link Title cannot be empty' : ''
                        }
                    />
                </div>
            }
        </div>
    );
};