import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButtonLight, FullTable, Loader } from 'components';
import { serviceSingleStyles } from './styles';
import { FindLoad, FindSuccess, PermissionList, RolePermission, useModal } from 'utils';
import { clientActions, httpRequestsOnSuccessActions } from 'store';
import { AddAuthorization, AddAuthorizationService } from '../../clientModals';
import { AUTH_ACTION_TYPE, authorizationBody, authorizationHead } from './constants';
import { AuthHeader } from './auth/authHeader';
import { AuthorizationFiles } from './authorizationFiles';

const DELETE_CLIENT_AUTHORIZATION = 'DELETE_CLIENT_AUTHORIZATION';
export const ClientAuthorization = ({ info }) => {
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const services = useSelector((state) => state.client.clientsAuthorizationsServices);
    const [enteredFileName, setEnteredFileName] = useState('');
    const [chosenFile, setChosenFile] = useState();
    const success = FindSuccess(DELETE_CLIENT_AUTHORIZATION);
    const [authIndex, setAuthIndex] = useState('');
    const loader = FindLoad('GET_CLIENT_AUTHORIZATION_SERV');
    const { open, close } = useModal();

    useEffect(() => {
        if (info?.length) {
            const active = info.find((i) => i.status === 'ACTIVE');
            const passive = info.find((i) => i.status === 'INACTIVE');
            if (active || passive) {
                setAuthIndex(active ? active : passive);
            }
        }
    }, [info]);

    useEffect(() => {
        if (authIndex) {
            dispatch(clientActions.getClientsAuthorizationsServ(authIndex?.id));
        }
    }, [authIndex]);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_CLIENT_AUTHORIZATION));
        }
    }, [success]);

    useEffect(() => {
        if (!!chosenFile) {
            const fileData = {
                file: chosenFile,
                name: enteredFileName,
            };
            dispatch(clientActions.addFilesToClientAuth(authIndex?.id, fileData));
            setChosenFile();
            setEnteredFileName('');
        }
    }, [chosenFile]);

    const handleOpenModal = () => {
        open(<AuthorizationFiles authId={authIndex?.id} />);
    };

    const handleEdit = (item, name) => {
        if (name === 'edit') {
            open(
                <AddAuthorizationService
                    currentAuthorization={authIndex}
                    info={item}
                    authId={authIndex?.id}
                    fundingId={authIndex?.funderId?._id}
                    services={services}
                />,
            );
        }
    };

    if (loader?.length) {
        return <Loader />;
    }

    return (
        <div className={classes.staffGeneralWrapper}>
            <div className={classes.notesWrap}>
                <AuthHeader
                    openModal={handleOpenModal}
                    info={authIndex}
                    setAuthIndex={setAuthIndex}
                    setToggleModal={() => open(
                        <AddAuthorization
                            fundingId={authIndex?.funderId?.id}
                            info={authIndex}
                            allList={info}
                        />,
                    )}
                />
                <div className={classes.authServiceContainerStyle}>
                    <div className={classes.authorizationServices}>
                        <p className={classes.authorizationServicesTitle}>
                            Authorized Services
                        </p>
                        {RolePermission([PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code]) &&
                            <AddButtonLight
                                addButnLightClassName={classes.addAuthServiceButnStyle}
                                addButnLightInnerText={'Add Authorized Service'}
                                onAddButnLightClick={() => open(
                                    <AddAuthorizationService
                                        currentAuthorization={authIndex}
                                        authId={authIndex?.id}
                                        fundingId={authIndex?.funderId?._id}
                                        services={services}
                                    />,
                                )}
                            />
                        }
                    </div>
                    <FullTable
                        head={authorizationHead}
                        body={authorizationBody}
                        loadingType={AUTH_ACTION_TYPE}
                        list={services}
                        height={'auto'}
                        handleClickButton={handleEdit}
                        noText={'authorized services '}
                        activeRowId={''}
                    />
                </div>
            </div>
        </div>
    );
};
