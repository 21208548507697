import React from 'react';
import { useHistory } from 'react-router-dom';
// import { UserInput } from "components";
import { useWidth } from 'utils';

export const MenuControlPanel = ({ currentMenuItem, changingMenu, openSubMenu, menu }) => {
    const width = useWidth();
    const history = useHistory();

    const handleClickMenuItem = (item) => {
        changingMenu('menu', item?.title);

        if (item?.hash) {
            const element = document.getElementById(item?.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const handleClickSubMenuItem = (e, item) => {
        e.stopPropagation();

        if (item?.hash) {
            const element = document.getElementById(item?.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const headerHeight = document.querySelector('.topbar-container')?.offsetHeight;


    return (
        <div
            className='menu-control-panel-wrapper'
            style={{ top: headerHeight, height: '90%' }}
        >
            <div className='menu-control-panel-box'>
                {/*{width > 767 &&*/}
                {/*  <UserInput*/}
                {/*    inputType="text"*/}
                {/*    inputName="search"*/}
                {/*    inputPlaceholder="Search here"*/}
                {/*    inputBoxClassName="search-input-box"*/}
                {/*    inputClassName="search-input-wrapper"*/}
                {/*    inputIcon={<Icons.SearchIcon />}*/}
                {/*  />*/}
                {/*}*/}

                {width > 767 &&
                    <div className='MenuControlPanel-header'
                         onClick={() => history.push('/helpCenter')}
                    >
                        <div className='icon-help-text'>
                            {/*<Icons.bookHelp className="MenuControlPanel-header-icon" />*/}
                            <p className='text-help'>
                                Help
                            </p>
                        </div>
                        <p className='text-description'>
                            Articles for Control Panel
                        </p>
                    </div>
                }

                <div className='menu-items-wrapper'>
                    {menu?.map((item, index) => (
                        <div
                            className='menu-items-box'
                            key={index}
                        >
                            <div
                                className={`menu-items ${(currentMenuItem?.menu === item?.title) ? 'active-menu-item' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickMenuItem(item);
                                }}
                            >
                                {/*<Icons.arrowRightBold*/}
                                {/*  className={`menu-item_svg ${!item?.subMenu?.length || !openSubMenu ? "menu-item_svg_noRotate" : ""}`}*/}
                                {/*/>*/}

                                <p className={`menu-item_text `}>
                                    {item.title}
                                </p>
                            </div>

                            {(currentMenuItem.menu === item?.title && !!item?.subMenu?.length && openSubMenu) &&
                                <div className='menu-sub-items-wrapper'>
                                    {item?.subMenu?.map((item, index) => (
                                        <p
                                            className={`menu-sub-items-text ${currentMenuItem?.subMenu === item?.title ? 'active-sub-menu-item' : ''}`}
                                            key={index}
                                            onClick={(e) => handleClickSubMenuItem(e, item)}
                                        >
                                            {item?.title}
                                        </p>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

