import { Svg } from 'assets';

export const socialMediaIcons = [
    {
        Icon: Svg.FacebookFooter,
        path: 'https://www.facebook.com/',
    },

    {
        Icon: Svg.LinkedinFooter,
        path: 'https://www.linkedin.com/',
    },

    {
        Icon: Svg.YoutubeFooter,
        path: 'https://www.youtube.com/',
    },
    {
        Icon: Svg.InstagramFooter,
        path: 'https://www.instagram.com/',
    },
    {
        Icon: Svg.TwitterFooter,
        path: 'https://twitter.com/',
    },
];

export const firstBlock = [
    {
        title: 'Home',
        path: '/',
    },

    {
        title: 'Features',
        path: '/features',
    },
];

export const secondBlock = [
    {
        title: 'Solutions',
        path: '',
    },

    {
        title: 'Resources',
        path: '',
    },
];

export const thirdBlock = [
    {
        title: 'about us',
        path: '/about-us',
    },

    {
        title: 'contact us',
        path: '/contact-us',
    },
];




