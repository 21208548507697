import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const claimPostStyles = makeStyles(() => ({
    saveBtn: {
        borderRadius: '4px',
        border: '1px solid #D0D5DD',
        background: '#FFF',
        width: '56px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '12px',
    },
    closeButtonBox: {
        borderRadius: '4px',
        border: '1px solid #D0D5DD',
        background: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        marginRight: '12px',
        '& img': {
            filter: 'brightness(0) saturate(100%) invert(35%) sepia(0%) saturate(0%) hue-rotate(325deg) brightness(96%) contrast(96%)',
        },
    },
    postTheadStyle: {
        display: 'flex',
        backgroundColor: `${Colors.BackgroundWhite}`,
        borderRadius: '8px',
        height: '50px',
        width: '100%',
        alignItems: 'center',
    },
    postThStyle: {
        padding: '0 12px',
        '@media(min-width: 1919px)': {
            padding: '0 32px',
        },
        '& p': {
            fontSize: '14px',
            fontWeight: 700,
            color: `${Colors.TextSecondary}`,
        },
    },

    claimPaymentBodyWrapper: {
        marginTop: '8px',
    },
    claimPaymentBodyHead: {
        display: 'flex',
        backgroundColor: `${Colors.BackgroundCatskillWhite}`,
        borderRadius: '0px 8px 0px 0px',
        height: '50px',
        alignItems: 'center',
        borderBottom: '1px solid #D8E5FF',
    },
    claimPaymentBodyHeadItem: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '19px',
        color: '#4B5C68',
        padding: '0 12px',
        '@media(min-width: 1919px)': {
            padding: '0 32px',
        },
    },

    claimPaymentBodyHeadNoPaddings: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px 0 0',
        '@media(min-width: 1919px)': {
            padding: '0 32px 0 0'
        },
    },

    editButton: {
        background: 'transparent',
        border: 'none',
        // marginRight: '16px',
        width: '50px',
        color: '#6FD231',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '19px',
    },
    payInFull: {
        width: '101px',
        height: '32px',
        background: `${Colors.ThemeBlue}`,
        borderRadius: '4px',
        marginRight: '16px',
        fontWeight: '600',
        fontSize: '14',
        lineHeight: '19px',
        color: '#FFFFFF',
        border: 'none',
        whiteSpace: 'nowrap',
    },
    appeal: {
        width: '79px',
        height: '32px',
        background: `${Colors.ThemeRed}`,
        borderRadius: '4px',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#FFFFFF',
        border: 'none',
    },
    bodyItemWrapper: {
        width: '100%',
        height: 'auto',
        background: '#FFFFFF',
        borderRadius: '0px 0px 8px 8px',
        padding: '8px',

        '@media(min-width: 1919px)': {
            padding: '16px',
        },
    },
    bodyItemHead: {
        display: 'flex',
        height: '36px',
        alignItems: 'center',
    },
    bodyItemBody: {
        display: 'flex',
        // height: '36px',
        alignItems: 'center',
        borderBottom: '1px solid #D8E5FF',
    },
    bodyItemHeadRow: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '16px',
        color: `${Colors.BackgroundGrayCircle}`,
        padding: '0 12px',
    },
    bodyItemBodyRow: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        padding: '0 12px',
        color: `${Colors.TextSecondary}`,

        '& input': {
            border: 'none',
            background: 'transparent',
            height: '40px',
            outline: 'none',
            padding: 0,
        },
        '& .numeric-format-input': {
            outline: 'none !important',
        },
    },
    payInFullBill: {
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '19px',
        color: `${Colors.BackgroundBlue}`,
        background: 'transparent',
        border: 'none',
        padding: '0',
        width: '72px',
        whiteSpace: 'nowrap',
    },
    paidStatusText: {
        fontWeight: 600,
        background:'transparent',
        fontSize: '14px',
        color: `#027A48`,
    },
    paidStatus: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px',
        height:'22px',
        background: '#ECFDF3',
        padding:'2px 8px',
        gap: '4px',
        '& p': {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '12px',
            color: `#027A48`,
        },
    },
    claimPaymentBodyHeadItemButtons: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    commentTextAreaStyle: {
        margin: 0,
        '& textarea': {
            maxWidth: '406px',
            width: '100%',
            height: '160px',
        },
    },
    closeOrCancelButnStyle: {
        width: '195px',
        '&.cancel': { backgroundColor: `${Colors.BackgroundWater} !important` },
        '&.create': { backgroundColor: `${Colors.ThemeRed} !important` },
    },
    paymentRefInput: {
        border: 'none',
        background: 'transparent',
        outline: 'none',
        color: `${Colors.TextSecondary}`,
        fontSize: '14px',
        fontWeight: '400',
    },
    dollar: {
        marginBottom: '1px',
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        borderRight: '1px solid #475467',
        marginRight: '12px',
    },
    closeButton: {
        background: 'transparent',
        border: 'none',
        marginTop: '2px',
    },
    billedAmount: {
        display: 'flex',
        alignItems: 'center',
        height: '40px',
    },
}));