import React from "react";
import { inputsStyle } from "./styles";
import { Images } from "utils";
import {SimpleTooltip, TextRow} from "..";

export const SearchAndFilter = ({
   selectedFilter,
   title,
   type,
   custom,
   handleClick,
   style,
   iconsAreLight,
   name,
   tooltipTitle,
   tooltipPlace = "top-start",
}) => {
   const classes = inputsStyle();

   const _iconSrc = iconsAreLight
      ? type === "arrow"
         ? Images.dropdownArrowWhite
         : type === "latestEarliest"
         ? Images.latestEarliestWhite
         : Images.aToZWhite
      : type === "arrow"
      ? Images.dropdownArrowBlue
      : type === "latestEarliest"
      ? selectedFilter === name
         ? Images.latestEarliest
         : Images.earliestLatestBlue
      : selectedFilter === name
      ? Images.zToA
      : Images.aToZ;

   let titleDisplay = <span>{title
      // <TextRow name={title}/>
      }</span>;

   if (!!tooltipTitle && typeof tooltipTitle === "string") {
      const tableHeader = title.endsWith("...")
         ? title.substring(0, title.length - 3)
         : title;

      const withTooltip = tooltipTitle.length !== tableHeader.length;

      if (withTooltip) {
         titleDisplay = (
            <SimpleTooltip title={tooltipTitle} placement={tooltipPlace}>
               <span>{title}</span>
            </SimpleTooltip>
         );
      } else {
         titleDisplay = <span>{title}</span>;
      }
   }

   return (
      <div className={classes.searchInputWrapper}>
         <div className={`${classes.searchInputTitle} ${style}`}>
            {titleDisplay}

            {custom !== false && selectedFilter === name ? (
               <img
                  onClick={() =>handleClick && handleClick(name)}
                  src={_iconSrc}
                  alt={"filter icon"}
               />
            ) : (
               custom !== false && (
                  <img
                     onClick={() => handleClick && handleClick(name)}
                     src={_iconSrc}
                     alt={"filter icon"}
                  />
               )
            )}
         </div>
      </div>
   );
};
