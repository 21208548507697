import React from "react";

export const HelpCenterCard = ({ onClick, Icon, title, description }) => {
  return (
    <div
        className='help-center-card-wrapper'
        onClick={onClick}>
        {Icon && Icon}
      <p className='title'>
        {title}
      </p>

      <p className='description'>
        {description}
      </p>
    </div>
  );
};

