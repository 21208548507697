import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const authHeaderStyles = makeStyles(() => ({
    AuthHeader: {
        width: '100%',
        borderRadius: 8,
        boxShadow: '0px 0px 6px #8A8A8A3D',
        padding: '16px',
        marginTop: 30,
    },
    AuthHeaderTop: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    AuthHeaderTopLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    AuthHeaderTopLeftTitle: {
        fontSize: 18,
        color: Colors.TextPrimary,
        fontWeight: 'bold',
        marginRight: 16,
    },
    activeInactive: {
        display: 'flex',
        marginLeft: 24,
        alignItems: 'center',
    },
    AuthHeaderTopLeftText: {
        fontSize: 14,
        color: Colors.TextPrimary,
    },
    circle: {
        width: '12px',
        height: '12px',
        marginLeft: 8,
        borderRadius: '50%',
    },
    AuthHeaderBottom: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    AuthHeaderBottomBox: {
        width: '32.5%',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        background: '#F2F4F8',
        borderRadius: ' 8px',
    },
    AuthHeaderBottomBoxTitle: {
        fontSize: '14px',
        fontWeight: '600',
        color: Colors.TextPrimary,
        whiteSpace: 'nowrap',
    },
    AuthHeaderBottomBoxText: {
        width:'100%',
        fontSize: '14px',
        color: Colors.TextPrimary,
        marginLeft: 8,
        '&.statusStyle': {
            display: 'flex',
            alignItems: 'center',
            '&::before': {
                content: '\'\'',
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginRight: '8px',
            },
            '&.ACTIVE::before': { backgroundColor: Colors.ThemeGreen },
            '&.INACTIVE::before': { backgroundColor: Colors.TextSecondary },
            '&.HOLD::before': { backgroundColor: Colors.ThemeBlue },
            '&.TERMINATED::before': { backgroundColor: Colors.ThemeRed },
        },
    },
    AuthHeaderBottomBoxTextLocation: {
        fontSize: '14px',
        color: Colors.TextPrimary,


        lineHeight: '21px',
        '&.statusStyle': {
            display: 'flex',
            alignItems: 'center',
            '&::before': {
                content: '\'\'',
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginRight: '8px',
            },
            '&.ACTIVE::before': { backgroundColor: Colors.ThemeGreen },
            '&.INACTIVE::before': { backgroundColor: Colors.TextSecondary },
            '&.HOLD::before': { backgroundColor: Colors.ThemeBlue },
            '&.TERMINATED::before': { backgroundColor: Colors.ThemeRed },
        },
    },
    AuthHeaderTopRight: {
        display: 'flex',
        alignItems: 'center',
    },
    fileListStyle: {
        width: '24px',
        height: '24px',
        marginRight: '16px',
        cursor: 'pointer',
        '& > img': { width: '100%' },
    },
    editIconStyle: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        '& > img': { width: '100%' },
    },
    removeIconStyle: {
        width: '24px',
        height: '24px',
        marginLeft: '16px',
        cursor: 'pointer',
        '& > img': { width: '100%' },
    },
    employmentEditBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    editTextStyle: {
        color: Colors.ThemeBlue,
        fontSize: '14px',
        fontWeight: 700,
        marginLeft: '8px',
    },

    diagnosticCodesWrapper: {

        '& .MuiAccordionDetails-root': {
            padding: 0,
        },
        '& .MuiAccordionSummary-root': {
            background: 'transparent',
            padding: 0,
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
        },
    },
    downUp: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginLeft: '8px',
        },
    },

    addCodeBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        '& p': {
            color: '#4B5C68',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '24px',
        },
    },
    itemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: '8px',
        background: '#F2F4F8',
        marginBottom: '4px',
        padding: '4px 16px',
        minHeight: '38px',
        '& p': {
            color: '#4B5C68',
            fontSize: '14px',
        },
    },
    itemButtonsBox: {
        display: 'flex',
        alignItems: 'center',

        '& button': {
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
        },
    },
    diagnosticCodes: {
        maxHeight: '200px',
        overflow: 'auto',
        width: '100%',
    },
    pastAuthorizationsModal: {
        width: '600px',
    },
    pastAuthorizationsBody: {
        marginTop: '40px',
    },
    pastAuthorizationsTableHead: {
        display: 'flex',
        alignItems: 'center',
        '& div': {
            width: '33%',
            padding: '10px 8px',
            color: '#4B5C68',
            fontSize: '14px',
            fontWeight: '700',
        },
    },
    pastAuthorizationsTableBody: {
        height: '200px',
        overflow: 'auto',
        padding: '0 2px',
    },
    pastAuthorizationsTableBodyItem: {
        display: 'flex',
        alignItems: 'center',
        height: '36px',
        padding: '8px 0',
        marginTop: '8px',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 0px 6px 0px rgba(138, 138, 138, 0.24)',
    },

    item: {
        display:'flex',
        alignItems:'center',
        width: '33%',
        padding: '0 8px',
        fontSize: '14px',
        color: '#4B5C68',
        fontWeight: '400',

        '& .text-style':{
            fontWeight: 600
        }
    },
}));
