import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ForgotModal,
    LoginModal,
    ResetModal,
} from 'fragments';
import { Toast } from 'components';
import { ToastFail } from 'utils';
import { landingImages, Images } from 'assets';


export function LoginPage() {
    const { httpOnError } = useSelector((state) => ({
        httpOnError: state.httpOnError,
    }));
    const history = useHistory();

    const error = httpOnError.length && httpOnError[0].type;
    const toastFail = ToastFail(error);
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search);
    const resetToken = searchParams.get('resetToken');
    const registerToken = searchParams.get('token');
    const userEmail = searchParams.get('email');

    const headerRef = useRef(null);

    const renderScreen = () => {
        if (pathname === '/forgot') {
            return <ForgotModal />;

        } else {
            if (registerToken || userEmail || resetToken) {
                return <ResetModal />;
            } else {
                return <LoginModal />;
            }
        }
    };


    return (
        <div className='auth-page-wrapper'>
            <div className='auth-page_left-side'>
                <div className='auth-page_left-side_header' ref={headerRef}>
                    <landingImages.BlackLogo
                        className='auth-page_left-side_header-svg'
                        onClick={() => history.push('/')}
                    />
                </div>

                <div className='auth-page_left-side_content'>
                    {renderScreen()}
                </div>
            </div>

            <div className='auth-page_right-side'>
                <img src={Images.authImage} alt='Auth Image' />
            </div>

            <Toast type={'error'} text={toastFail} info={toastFail} />
        </div>
    );
}
