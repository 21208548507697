import React, { useEffect } from 'react';
import { CreateChancel, Input, SelectAutocompleteMultiple } from 'components';
import { FindErrorItem, FindLoad, FindSuccess, useModal } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    httpRequestsOnErrorsActions,
    httpRequestsOnSuccessActions,
    roleActions,
} from 'store';
import { useForm } from 'react-hook-form';

export const AddRoleModal = ({ item }) => {
    const { permissionsList } = useSelector((state) => ({
        permissionsList: state.permissions.permissionsList,
    }));
    const ACTION_TYPE = item ? 'EDIT_ROLE' : 'CREATE_ROLE';
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const { close } = useModal();

    const { handleSubmit, control, setValue, setError, clearErrors } = useForm({
        defaultValues: {
            title: '',
            permissions: [],
            description: ' ',
        },
    });

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
            clearErrors();
        };
    }, []);

    useEffect(() => {
        if (!!success.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        if (item) {
            if (item?.title) setValue('title', item?.title);
            if (item?.permissions) setValue('permissions', item?.permissions);

        }
    }, [item]);


    useEffect(() => {
        if (backError?.error === 'A role with this title already exists.') {
            setError('title', {
                type: 'required',
                message: 'A role with this title already exists.',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }

        if (backError?.error === 'Please select at least one permission.') {
            setError('permissions', {
                type: 'required',
                message: 'Please select at least one permission.',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
    }, []);


    function addRole(data) {
        const { title, permissions, description } = data;

        const sandedData = {
            title,
            permissions: permissions.map((item) => item.id),
            description,
        };

        const params = {};
        if (item) {
            dispatch(roleActions.editRole(item?.id, { title }));
        } else {
            dispatch(roleActions.createRole(sandedData, params));
        }
    }

    return (
        <div className="add-role-modal">
            <p className="modal-header-title">{item ? 'Edit Role' : 'Add New Role'}</p>
            <form onSubmit={handleSubmit(addRole)}>
                <div className="modal-body-wrapper">
                    <Input
                        name="title"
                        label="Set Role Name*"
                        variant="outlined"
                        control={control}
                        maxLength={50}
                        rules={{
                            maxLength: {
                                value: 50,
                                message: 'Max length is 50 digits.',
                            },
                            required: 'Role Name is required.',
                        }}
                    />
                    {!item &&
                        <SelectAutocompleteMultiple
                            options={permissionsList}
                            label={'Select Permissions*'}
                            name="permissions"
                            control={control}
                            selectedType="title"
                            rules={{
                                required: 'Please select at least one permission.',
                            }}
                            multiple={true}
                        />
                    }
                </div>
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    buttonWidth="224px"
                    create={!!item ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onClose={close}
                />
            </form>
        </div>
    );
};
