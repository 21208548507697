import React, { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';

export const MaskInputCustom = forwardRef((
    {
        className,
        name,
        label,
        value,
        error,
        mask,
        styles,
        control,
        rules,
        originalValue,
    }, ref) => {

    const [isFocused, setIsFocused] = useState(false);

    return (
        <div
            className={`main-input-wrapper  ${className ? className : ''}`}
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field, formState: { errors } }) => {
                    return (
                        <>
                            <InputMask
                                {...field}
                                ref={ref}
                                mask={mask || '(999) 999-9999'}
                                maskChar={isFocused || field?.value ? '_' : ''}
                                onChange={({ target }) => field.onChange( originalValue ? target.value : target.value.replace(/[^0-9:]+/g, ''))}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                            >
                                {(inputProps) =>
                                    <TextField
                                        {...inputProps}
                                        focused={isFocused}
                                        variant={'outlined'}
                                        label={label}
                                        error={!!errors[field?.name]?.message}
                                        InputLabelProps={{
                                            shrink: isFocused || field?.value,
                                        }}
                                    />
                                }
                            </InputMask>

                            <p className='error-message'>{errors[field?.name]?.message}</p>
                        </>
                    );
                }}
            />
        </div>
    );
});

MaskInputCustom.displayName = 'MaskInputCustom';