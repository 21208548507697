import {
    ASSIGN_ACCESS,
    CHANGE_PASSWORD_REQUEST,
    GET_ACCESS, GET_BASE_URL,
    GET_MY_PROFILE,
    GET_RECOVERY_LINK,
    GET_RECOVERY_LINK_TRY_AGAIN,
    LOG_IN,
    LOG_OUT,
    REMOVE_ACCESS,
    RESET_PASSWORD_REQUEST, SET_BASE_URL,
} from './auth.types';

export const logIn = (user) => {
    return {
        type: LOG_IN,
        payload: { user },
    };
};

export const logOut = () => {
    return {
        type: LOG_OUT,
    };
};

export const getMyProfile = () => {
    return {
        type: GET_MY_PROFILE,
    };
};

export const getRecoveryLink = (email) => {
    return {
        type: GET_RECOVERY_LINK,
        payload: email,
    };
};

export const tryAgain = () => {
    return {
        type: GET_RECOVERY_LINK_TRY_AGAIN,
    };
};

export const resetPassword = (passwords, email) => {
    return {
        type: RESET_PASSWORD_REQUEST,
        payload: { passwords, email },
    };
};

export const changePassword = (data) => {
    return {
        type: CHANGE_PASSWORD_REQUEST,
        payload: { data },
    };
};

/** Access service */
export const getAccess = (userId) => {
    return {
        type: GET_ACCESS,
        payload: { userId },
    };
};

export const assignAccess = (userId, roleId) => {
    return {
        type: ASSIGN_ACCESS,
        payload: { userId, roleId },
    };
};

export const removeAccess = (userId, roleId) => {
    return {
        type: REMOVE_ACCESS,
        payload: { userId, roleId },
    };
};

/** End */

