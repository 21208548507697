import {
    GET_CLAIMS_SUCCESS,
    GET_CLAIM_BY_ID_SUCCESS,
    GET_BILL_INVOICE_PAYMENT_SUCCESS,
    GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS,
    GET_SUBMITTED_CLAIMS_SUCCESS,
} from './claim.type';

const initialState = {
    claims: [],
    submittedClaims: [],
    claimById: {},
    billClaimPmt: null,
    invoiceClaimPmt: null,
};

export const claimReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLAIMS_SUCCESS:
            return {
                ...state,
                claims: action.payload.claims,
            };

        case GET_SUBMITTED_CLAIMS_SUCCESS:
            return {
                ...state,
                submittedClaims: action.payload,
            };

        case GET_CLAIM_BY_ID_SUCCESS:
            return {
                ...state,
                claimById: action.payload.claimById,
            };

        case GET_BILL_CLAIM_PAYMENT_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                billClaimPmt: action.payload,
            };

        case GET_BILL_INVOICE_PAYMENT_SUCCESS:
            return {
                ...state,
                invoiceClaimPmt: action.payload,
            };

        default:
            return state;
    }
};
