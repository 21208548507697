import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RadioButton, DateRow, StatusRow, TextRow  } from 'components';
import { useModal } from 'utils';
import { authHeaderStyles } from './styles';

const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
};

export const PastAuthorizations = ({ authIndex, setAuthIndex }) => {
    const classes = authHeaderStyles();
    const clientsAuthorizations = useSelector((state) => state.client.clientsAuthorizations);
    const [selected, setSelected] = useState(authIndex?.id ? authIndex?.id : '');
    const { close } = useModal();

    const change = (item) => {
        setSelected(item?.id);
        setAuthIndex(item);
        close();
    };

    const renderItemBody = (i, k) => {
        return (
            <div className={classes.pastAuthorizationsTableBodyItem} key={k}>
                <div className={classes.item}>
                    <RadioButton
                        styles={checkboxStyle}
                        value={selected}
                        onChange={() => change(i)}
                        radioData={[
                            {
                                value: i?.id,
                            },
                        ]}
                    />
                    <TextRow name={i?.authId} />
                </div>
                <div className={classes.item}>
                    <DateRow date={i?.startDate} /> - <DateRow date={i?.endDate} />
                </div>
                <div className={classes.item}>
                    <StatusRow status={i?.status} />
                </div>
            </div>
        );
    };

    return (
        <div className={classes.pastAuthorizationsModal}>
            <p className="modal-header-title">Past Authorizations</p>
            <div className={classes.pastAuthorizationsBody}>
                <div className={classes.pastAuthorizationsTableHead}>
                    <div>Number</div>
                    <div>Date Range</div>
                    <div>Status</div>
                </div>
                <div className={classes.pastAuthorizationsTableBody}>
                    {clientsAuthorizations?.map((i, k) => i.status === 'ACTIVE' && (
                        renderItemBody(i, k)
                    ))}
                    {clientsAuthorizations?.map((i, k) => i.status === 'INACTIVE' && (
                        renderItemBody(i, k)
                    ))}
                </div>
            </div>
        </div>
    );
};