import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { NumericFormat } from 'react-number-format';
import { CloseButton, DateRow, FullTable, Loader, TextRow } from 'components';
import { FindLoad, Images, manageType, PermissionList, RolePermission, useModal } from 'utils';
import { TimesheetModal } from './timesheetModal';
import { serviceSingleStyles } from '../styles';
import { timesheetDetailBody, timesheetDetailHead } from '../constants';

const ACTION_TYPE = 'GET_TIMESHEET_BY_ID';
export const TimesheetDetails = ({ activeTab }) => {
    const { timesheetById } = useSelector((state) => ({
        timesheetById: state.admins.timesheetById,
    }));
    const classes = serviceSingleStyles();
    const { open } = useModal();
    const loader = FindLoad(ACTION_TYPE);

    const staffTimesheetOvertimes =
        timesheetById?.overtimes?.length > 0 ? timesheetById?.regularHours > 0 ? [
                    {
                        amount: timesheetById?.regularPay,
                        hours: timesheetById?.regularHours,
                        name: 'Regular',
                        rateType: 'Regular',
                    },
                    ...timesheetById?.overtimes,
                ]
                : timesheetById?.overtimes
            : [{ ...timesheetById }];

    const handleOpenDetails = () => {
        open(
            <TimesheetModal
                info={timesheetById}
                type={timesheetById?.payCode?._id ? 'payCodes' : 'fixed'}
                activeTab={activeTab}
            />);
    };

    return (
        <div style={{ width: 550, minHeight: 300 }}>

            {timesheetById &&
                <div>
                    {loader.length ? (
                        <Loader height={'40vh'} />
                    ) : (
                        <>
                            <div className={classes.bcbaHeader}>
                                <p className={classes.bcbaHeaderTitle}>
                                    {
                                        timesheetById?.payCode?.payCodeTypeId?.name ?
                                            `${timesheetById?.payCode?.payCodeTypeId?.name} (${manageType(timesheetById?.payCode?.payCodeTypeId?.type?.toLowerCase())})`
                                            :
                                            'Fixed'
                                    }
                                </p>

                                <div className="space-between" style={{ alignItems: 'flex-start' }}>
                                    <div className={classes.dateEdite} style={{marginTop: 5}}>
                                        <p>{timesheetById ? timesheetById.description : ''}</p>
                                    </div>
                                    <div className={classes.dateEdite}>
                                        <p>
                                            {moment.utc(timesheetById.timesheetDate).format('MM/DD/YYYY')}
                                        </p>
                                        {RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) &&
                                            <img
                                                src={Images.edit}
                                                alt="edit"
                                                onClick={handleOpenDetails}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            <FullTable
                                height={'auto'}
                                head={timesheetDetailHead}
                                body={timesheetDetailBody}
                                list={timesheetById.overtimes ? staffTimesheetOvertimes : []}
                                noText={'overtimes'}
                            />


                            <div className={classes.timeAndHoursWrapper}>
                                <div className={classes.timeAndHoursBox} style={{width:'100%'}}>
                                    {timesheetById?.createdDate &&
                                        <div className={classes.titleAndPrice}>
                                            <p className={classes.boldText}> Created Date:</p>
                                            <p className={classes.whiteText}>
                                                <DateRow date={timesheetById?.createdDate} />
                                            </p>
                                        </div>
                                    }
                                    {timesheetById?.creator &&
                                        <div className={classes.titleAndPrice} style={{width:'100%'}}>
                                            <p className={classes.boldText}> Created By:</p>
                                            <p className={classes.whiteText} style={{width:'100%'}}>
                                                <TextRow name={timesheetById.creator} />
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div className={classes.timeAndHoursBox}>
                                    {timesheetById?.regularHours !== 'NaNhNaNm' &&
                                        <div className={classes.titleAndPrice}>
                                            <p className={classes.whiteText}>Total Time:</p>
                                            <p className={classes.boldText}>
                                                {timesheetById ? `${timesheetById.hours ? timesheetById.hours.replace('h', 'h ') : timesheetById.regularHours ? timesheetById.regularHours.replace('h', 'h ') : ''}` : ''}
                                            </p>
                                        </div>
                                    }
                                    <div className={classes.titleAndPrice}>
                                        <p className={classes.whiteText}>Total Amount:</p>
                                        <p className={classes.boldText}>
                                            <NumericFormat
                                                value={timesheetById?.totalAmount ? timesheetById.totalAmount.toFixed(2) : 0}
                                                displayType={'text'} thousandSeparator={true} prefix={'$'}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </div>
            }
        </div>
    );
};