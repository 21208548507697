import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { credentialsStyles } from './styles';
import { AddCircle, CheckboxesTags, CloseButton, CustomDelete, TypeRow } from 'components';
import { Images, useModal } from 'utils';
import { systemActions } from 'store';

const DELETE_TYPE = 'EDIT_CREDENTIAL_BY_ID_GLOBAL';
export const CoveredCredentials = ({}) => {
    const classes = credentialsStyles();
    const dispatch = useDispatch();
    const { open, close } = useModal();
    const selectedCredential = useSelector((state) => state.system.selectedCredential);
    const globalCredentials = useSelector((state) => state.system.credentials);
    const [openCredentialSelect, setOpenCredentialSelect] = useState(false);

    const filteredList = globalCredentials?.filter(function(array_el) {
        return (
            selectedCredential?.coveredCredentials?.filter(function(anotherOne_el) {
                return anotherOne_el?._id === array_el.id;
            }).length === 0
        );
    });

    const toCamelCase = (text) => {
        if (text && text !== undefined) {
            return (
                text?.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                    return g1.toUpperCase() + g2.toLowerCase();
                })
            );
        }
    };

    const addCoveredCredential = (selectedList) => {
        let arr = [];
        selectedList?.forEach((i) => {
            arr.push(i?.id);
        });
        selectedCredential?.coveredCredentials?.forEach((i) => arr.push(i?._id));

        let data = {
            'name': selectedCredential?.name,
            'type': selectedCredential?.type,
            coveredCredentialIds: [
                ...arr,
            ],
        };
        dispatch(systemActions.editCredentialByIdGlobal(data, selectedCredential?.id));
    };

    const handleDelete = (item) => {
        let arr = [];
        selectedCredential?.coveredCredentials?.forEach((i) => {
            if (i?._id !== item?._id) {
                arr.push(i);
            }
        });

        let data = {
            'name': selectedCredential?.name,
            'type': selectedCredential?.type,
            coveredCredentialIds: [
                ...arr,
            ],
        };
        dispatch(systemActions.editCredentialByIdGlobal(data, selectedCredential?.id));
    };

    return (
        <div className={classes.coveredCredentialsWrapper}>
            {selectedCredential ?
                <div>
                    <div className={classes.coveredCredentialsHead}>
                        <p className={classes.coveredCredentialsHeadTitle}>Covered Credentials
                            of {selectedCredential?.name}</p>
                        <div>
                            {openCredentialSelect ?
                                <CloseButton handleCLic={() => setOpenCredentialSelect(false)} />
                                :
                                <AddCircle
                                    textStyle={{ color: '#4B5C68', fontWeight: 600 }}
                                    handleCLic={() => setOpenCredentialSelect(true)}
                                    text={'Add Covered Credential'}
                                />
                            }
                        </div>
                    </div>

                    {openCredentialSelect &&
                        <div className={classes.checkboxesTagsBox}>
                            <CheckboxesTags
                                name="permissions"
                                handleChange={addCoveredCredential}
                                renderValue={(item) => `${toCamelCase(item?.type)} - ${item?.name}`}
                                permissionsList={filteredList?.length ? filteredList?.filter((i) => i?.id !== selectedCredential?.id) : []}
                                label={'Select Covered Credentials'}
                                placeholder={'Credentials'}
                                selectedKey={true}
                            />
                        </div>
                    }

                    <div className={classes.coveredCredentialsBody}>
                        {selectedCredential?.coveredCredentials?.length ?
                            selectedCredential?.coveredCredentials?.map((i, j) => (
                                <div className={classes.credentialCoveredItem} key={j}>
                                    <div className="flex-align-center">
                                        <img src={Images.licenseIcon} alt="icon" />
                                        <p className={classes.licenseTitle}><b>{<TypeRow
                                            text={i?.type} />} -</b> {i?.name} </p>
                                    </div>
                                    <div className={classes.credentialActionBox}>
                                        <button onClick={() => open(
                                            <CustomDelete
                                                text={`Are you sure you want remove credential?`}
                                                info="Remove Credential"
                                                innerText={'Remove'}
                                                handleDel={() => handleDelete(i)}
                                                handleClose={() => close()}
                                                body={
                                                    <div className="deleting-client-info-box">
                                                        <img src={Images.deleteLicenseIcon} alt="icon" />
                                                        <p> <b style={{fontWeight: 600}}>{i?.type}</b> <span style={{fontWeight:400}}>{i?.name ? ` - ${i?.name}` : ''}</span></p>
                                                    </div>
                                                }
                                                actionType={DELETE_TYPE}
                                            />)}>
                                            <img src={Images.remove} alt={'icon'} />
                                        </button>
                                    </div>
                                </div>
                            ))
                            :
                            <div className={classes.selectCredential}>
                                <img src={Images.selectCredential} alt="" />
                                <p>No Covered Credentials Yet</p>
                            </div>
                        }
                    </div>
                </div>

                :
                <div className={classes.selectCredential}>
                    <img src={Images.selectCredential} alt="" />
                    <p>Select Credential</p>
                </div>
            }

        </div>
    );
};