import React from 'react';
import { Button } from '@material-ui/core';
import { Colors } from 'utils';
import { MinLoader } from 'components';

export const SignIn = ({ text, width, handleClick, loader, type = 'button', disabled }) => {
    return (
        <Button
            disabled={disabled || loader}
            type={type}
            onClick={handleClick}
            style={{
                width: `${width}`,
                background: `${Colors.BackgroundBlue}`,
                height: '48px',
                borderRadius: '4px',
                color: `${Colors.TextWhite}`,
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: '600',
                textTransform: 'capitalize',
            }}
        >
            {loader === true ? <MinLoader color={Colors.TextWhite} /> : text}
        </Button>
    );
};
