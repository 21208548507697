import {call, put, takeLatest} from 'redux-saga/effects';
import {authService} from './auth.service';
import {
    LOG_IN,
    LOG_OUT,
    GET_RECOVERY_LINK,
    RESET_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    GET_MY_PROFILE,
    GET_ACCESS,
    GET_ACCESS_SUCCESS,
    ASSIGN_ACCESS,
    REMOVE_ACCESS, GET_MY_PROFILE_SUCCESS,
} from './auth.types';
import {httpRequestsOnErrorsActions} from '../http_requests_on_errors';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';
import {httpRequestsOnSuccessActions} from '../http_requests_on_success';

function* logIn({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const base = yield call(authService.getBaseUrlService, payload?.user?.email);
        const res = yield call(authService.signIn, payload?.user, base?.data?.url);
        yield put(httpRequestsOnLoadActions.removeLoading(type));


        if (res.data?.permissions) {
            localStorage.setItem('permissions', JSON.stringify(res.data?.permissions || []));
        }
        localStorage.setItem('baseUrl', base?.data?.url);
        localStorage.setItem('access-token', res?.data?.token);
        window.location.replace('/schedule');
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        const error = err?.data?.message ? err?.data?.message : err?.response?.data?.message;
        yield put(httpRequestsOnErrorsActions.appendError(type, error));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* logOut(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.logOut);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    } finally {
        // remove everything from local storage and cache of the browser
        localStorage.clear();
        // Clear session storage
        sessionStorage.clear();
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
        }
        window.location.replace('/');
    }
}

function* getLink({payload, type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const base = yield call(authService.getBaseUrlService, payload?.email);
        yield call(authService.getLink, payload?.email, base?.data?.url);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.response?.data?.message || err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* resetPassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {

        const base = yield call(authService.getBaseUrlService, action?.payload?.email);
        if (action?.payload?.passwords?.type === 'reset') {
            const res = yield call(authService.resetPass, action?.payload?.passwords, base?.data?.url);
            localStorage.setItem('baseUrl', base?.data?.url);

            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
            localStorage.setItem('access-token', res.data.token);

            if (res.data?.permissions) {
                localStorage.setItem('permissions', JSON.stringify(res.data?.permissions || []),
                );
            }
            window.location.replace('/schedule');
        } else {
            const res = yield call(authService.confirmAccount, action?.payload?.passwords, base?.data?.url);
            localStorage.setItem('baseUrl', base?.data?.url);

            yield put(httpRequestsOnLoadActions.removeLoading(action.type));
            localStorage.setItem('access-token', res.data.token);
            if (res.data?.permissions) {
                localStorage.setItem('permissions', JSON.stringify(res.data?.permissions || [])
                );
            }
            window.location.replace('/schedule');
        }
    } catch (err) {
        const errMessage = err?.response?.data?.message ? err?.response?.data?.message : err?.data?.message;
        yield put(httpRequestsOnErrorsActions.appendError(action.type, errMessage));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* changePassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.changePasswordService, action.payload.data);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        localStorage.setItem('access-token', res?.data?.accessToken);
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* getMuProfile(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.myProfileService);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: res?.data,
        });
        if (res.data) {
            localStorage.setItem('wellUserInfo', JSON.stringify(res.data));
        }
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** Access service */
function* getAccessService(action) {
    if (action?.payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    try {
        const res = yield call(authService.getAccessService, action.payload.userId);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put({
            type: GET_ACCESS_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        if (err?.data?.message === 'Internal server error') {
            yield put(
                httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
            );
        }
    }
}

function* assignAccess(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.addAccessService, action?.payload?.userId, action?.payload?.roleId);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_ACCESS,
            payload: {userId: action.payload.userId, load: 'noLoad'},
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

function* removeAccess(action) {
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.removeAccessService, action?.payload?.userId, action?.payload?.roleId);
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put({
            type: GET_ACCESS,
            payload: {userId: action?.payload?.userId, load: 'noLoad'},
        });
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
    }
}

/** End */

export const watchAuth = function* watchUserAuth() {
    yield takeLatest(LOG_IN, logIn);
    yield takeLatest(LOG_OUT, logOut);
    yield takeLatest(GET_MY_PROFILE, getMuProfile);
    yield takeLatest(GET_RECOVERY_LINK, getLink);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);

    /** Access service */
    yield takeLatest(GET_ACCESS, getAccessService);
    yield takeLatest(ASSIGN_ACCESS, assignAccess);
    yield takeLatest(REMOVE_ACCESS, removeAccess);
    /** End */
};
