import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Controller } from 'react-hook-form';

const getNestedError = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const DateInput = forwardRef(({
                                         label,
                                         labelHeader,
                                         placeholder,
                                         rules,
                                         control,
                                         name,
                                         type = 'text',
                                         maxLength,
                                         minLength,
                                         startAdornment,
                                         endAdornment,
                                         className,
                                         onClickIconStart,
                                         onClickIconEnd,
                                         showPassword,
                                         disabled,
                                         min,
                                         max,
                                         value
                                     }, ref) => {

    const ExampleCustomInput = forwardRef(({ onClick, className }, ref) => {
            return (
                <button type="button" className={className} onClick={onClick} ref={ref}>
                    {value ? moment(value).format('MM/DD/YYYY') : 'MM/DD/YYYY'}
                </button>
            );
        },
    );

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getNestedError(errors, name);
                return (
                    <div className="date-input-box">
                        <div>
                            <p style={
                                error ? { color: '#f44336' } :
                                    field?.value ? { color: '#347AF0' } : {}
                            } className="date-input-title">{label}</p>
                            <DatePicker
                                className={`date-picker-box ${error ? 'date-picker-error' : ''}`}
                                isClearable={!!field?.value}
                                showIcon
                                disabled={disabled}
                                onChange={(date) => {
                                    if (date) {
                                        const converted = moment(date);
                                        converted.hour(10);
                                        const momentConverted = moment(converted).format('YYYY-MM-DD');
                                        field.onChange(momentConverted);
                                    } else {
                                        field.onChange(null);
                                    }
                                }}
                                selected={field?.value}
                                customInput={
                                    <ExampleCustomInput
                                        className="example-custom-input"
                                        value={field?.value}
                                    />
                                }
                                minDate={min}
                                maxDate={max}
                            />
                            <p className="error-message">{error?.message}</p>
                        </div>
                    </div>);
            }
            }
        />
    );
});
DateInput.displayName = 'DateInput';