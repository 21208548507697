import { LandingSvg } from 'assets';


export const contactOptions = [
    {
        Icon: LandingSvg.callIconSvg,
        details: 'tel:+313 533 5333',
        label: '(313) 533-5333',

    },
    {
        Icon: LandingSvg.SmsStarSvg,
        details: 'mailto:wellnessdaisy@gmail.com',
        label: 'wellnessdaisy@gmail.com',
    },
];

export const contactSocial = [
    {
        Icon: LandingSvg.FacebookContactSvg,
        label: 'facebook',
        details: 'https://www.facebook.com/',

    },
    {
        Icon: LandingSvg.LinkedinContactSvg,
        label: 'Linkedin',
        details: 'https://www.linkedin.com/',
    },

    {
        Icon: LandingSvg.TwitterContactSvg,
        label: 'Twitter',
        details: 'https://twitter.com/',
    },
];


export const faqs = [
    {
        title: 'What is TherapyLake and how can it benefit my ABA practice?',
        description: 'TherapyLake is a comprehensive practice management software designed specifically for' +
            ' ABA practices. It streamlines administrative tasks such as EMR, billing, scheduling, and credentialing.' +
            ' With features like automated workflows, data integrity checks, and advanced reporting, TherapyLake' +
            ' enhances efficiency, reduces errors, and allows practitioners to focus more on client care.',
    },
    {
        title: 'Is TherapyLake HIPAA compliant?',
        description: 'Creating an account on Croill.com is quick and easy, taking just two minutes. This account' +
            ' will link directly to your property listings, providing a seamless management experience.',
    },
    {
        title: 'How does TherapyLake ensure data integrity?',
        description: 'Listing your property on Croill.com is completely free. However, additional promotional' +
            ' features to improve the visibility of your listings are not free, see the Promotions section.',
    },
    {
        title: 'Does TherapyLake offer a mobile app?',
        description: 'We offer a variety of commercial spaces including industrial, office, and retail options,' +
            ' each customized to meet specific business needs and designed to enhance the growth and efficiency ' +
            'of diverse business models.',
    },
    {
        title: 'How does TherapyLake handle billing and payment posting?',
        description: 'We offers extensive support to ensure you can manage your listings and accounts ' +
            'effectively. For immediate assistance, you can reach our customer service team via email at' +
            ' info@croill.com. ',
    },
    {
        title: 'What reporting capabilities does TherapyLake provide??',
        description: 'Yes, you can list multiple properties under a single account.',
    },
    {
        title: 'How do I get started with TherapyLake?',
        description: 'To update or edit a listing, log in to your account, navigate to "My Listings," select the ' +
            'listing you want to update, and click "Edit." Make the necessary changes and save them to ' +
            'update your listing immediately.',
    },
];






