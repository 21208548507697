import { scheduleModalsStyle } from './styles';
import { CreateChancel } from 'components';
import React, { useEffect, useState } from 'react';
import { FindLoad, formatAMPM, useModal } from 'utils';
import moment from 'moment/moment';
import { appointmentService } from '../../../../store/appointment/appointment.service';
import axios from 'axios';

export const ConfMultiple = ({ handleSubmit, loadType, recurInfo, occurrence, checkParams, staffTb }) => {
    const classes = scheduleModalsStyle();
    const loader = FindLoad(loadType);
    const { close } = useModal();
    const [overlappList, setOverlappList] = useState([]);
    const [loadList, setLoadList] = useState(true);
    const check = overlappList?.length === occurrence;
    const tb = overlappList?.staffCredentials?.find(i => i?.type === 'TB');

    useEffect(() => {
        appointmentService.checkApptOverlapping(checkParams, recurInfo).then((res) => {
            setOverlappList(res?.data);
            setLoadList(false);
        }).catch(() => {
            setLoadList(false);
        });
    }, []);

    const renderMode = () => {
        if (recurInfo?.mode === 'DAILY') {
            return 'Daily';
        } else if (recurInfo?.mode === 'WEEKLY') {
            return 'Weekly';
        } else if (recurInfo?.mode === 'MONTHLY') {
            return 'Monthly';
        }
    };

    return (
        <div className={classes.lockUnlockWrapper}>
            <p className={classes.confirmTitle}>Confirmation</p>
            {/*<p className={classes.confirmTitle}>Confirmation for Multiple Appointments</p>*/}
            <p className={classes.confirmSubTitle}>
                {`You are about to create ${occurrence} appointments with the following recurring pattern. Confirm to proceed.`}
            </p>

            <div className={classes.confirmModalInfoBox}>
                <p>{`Recurrence Mode: ${renderMode()}`}</p>
                <p>{`Occurrence: ${occurrence ? occurrence : 0}`}</p>
                <p>{`Date Range: ${moment.utc(recurInfo?.startDate).format('MM/DD/YYYY')} to ${moment.utc(recurInfo?.endDate).format('MM/DD/YYYY')} `}</p>
            </div>
            {!!overlappList?.overlap?.length &&
                <div className={classes.confirmModalWornBox}>
                    <p style={{lineHeight:'22px'}}>{`Cannot create the following appointments due to overlapping schedules.`}</p>
                    <div style={{maxHeight:'150px', overflow:'auto'}}>
                    {overlappList?.overlap?.map((i, j) => (
                        <p style={{fontWeight: 600}} key={j}>{`${i?.displayID} - ${moment.utc(i?.date).format('MM/DD/YYYY')} (${formatAMPM(i?.startTime)} - ${formatAMPM(i?.endTime)})`}</p>
                        // <p key={j}>{moment.utc(i?.overlappingDate).format('MM/DD/YYYY')}</p>
                    ))}
                </div>
                </div>
            }
            {/*{tb?.expired &&*/}
            {/*    <div className={classes.confirmModalWornBox}>*/}
            {/*        <p style={{margin: 0}}>{`The staff TB will expire on ${moment.utc(staffTb?.expireDate).format('MM/DD/YYYY')} No appointment will be created after this date.`}</p>*/}
            {/*    </div>*/}
            {/*}*/}
            <CreateChancel
                disabled={check ? check : loadList}
                loader={!!loader?.length || loadList}
                create={'Confirm'}
                chancel={'Cancel'}
                onCreate={handleSubmit}
                onClose={() => close()}
                buttonWidth="223px"
                createButnMargin={'16px'}
            />
        </div>
    );
};