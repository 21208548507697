import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, NoItemsYet, PaginationFillTable, SearchAndFilerFull, TableNoInfo } from 'components';
import { FindLoad, FindSuccessItem, Images, PermissionList, RolePermission, SendPageSave, useModal } from 'utils';
import { ListViewTable } from './listView/listViewTable';
import { Checkbox } from '@material-ui/core';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { InfoModal } from './modals/infoModal';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

const ACTION_TYPE = 'GET_APPOINTMENT';
const LOCK_UNLOCK = 'CHANGE_APPOINTMENT_LOCK_UNLOCK';

export const ScheduleList = ({ handleEdit, openCloseRecur, renderParams }) => {
    const { appointments } = useSelector((state) => ({
        appointments: state.appointment.appointments,
    }));
    const dispatch = useDispatch();
    const { open } = useModal();
    const history = useHistory();
    const info = history?.location?.state;
    const [expanded, setExpanded] = useState('');
    const [selectedList, setSelectedList] = useState([]);
    const [selectAllList, setSelectAllList] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const lockUnlockSuccess = FindSuccessItem(LOCK_UNLOCK);
    const showSelectedMessages = selectAllList ? true : appointments?.appts?.length === selectedList?.length;
    const [page, setPage] = useState(info?.page ? info.page : 1);

    useEffect(() => {
        if (lockUnlockSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(LOCK_UNLOCK));
            setSelectedList([]);
            setSelectAllList(false);
        }
        if (info) {
            setSelectedList([]);
            setSelectAllList(false);
        }
    }, [lockUnlockSuccess, info]);

    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        setSelectAllList(false);
        if (name === 'all') {
            let allList = appointments?.appts?.length === selectedList?.length ? [] : appointments?.appts?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            const uniqSet = new Set(list);

            if (!uniqSet.has(item?.id)) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };

    const lockUnlock = (name) => {
        const lockUnlockType = name === 'lock';
        const subText = name === 'lock' ?
            'Are you sure you want to lock the selected appointments? Once locked, they cannot be edited until unlocked.'
            :
            'Are you sure you want to unlock the selected appointments? Once unlocked, they can be edited again.';

        const list = {
            apptIds: selectedList,
            all: selectAllList,
        };

        open(
            <InfoModal
                loaderType={'CHANGE_APPOINTMENT_LOCK_UNLOCK'}
                titleText={name === 'lock' ? 'Confirm Lock Selected' : 'Confirm Unlock Selected'}
                btnText={name === 'lock' ? 'Lock Selected' : 'Unlock Selected'}
                handleSubmit={() => dispatch(appointmentActions.changeApptLockUnlock(list, lockUnlockType, renderParams))}
                subText={subText}
            />,
        );
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history, location);
    };


    return (
        <div className="schedule-fragment-page">
            {RolePermission([PermissionList.APPT_LOCK?.code]) && selectedList?.length ?
                <div className="lock-unlock-btns">
                    <div>
                        {showSelectedMessages &&
                            <div className="appt-selected-info">
                                {selectAllList ?
                                    <p>All <b>{appointments?.count} appointments across all pages</b> have been
                                        selected.</p>
                                    :
                                    <p>All <b>{appointments?.appts?.length}</b> appointments <b>on this page</b> are
                                        selected.</p>
                                }
                                {selectAllList ?
                                    <button onClick={() => {
                                        setSelectAllList(false);
                                        setSelectedList([]);
                                    }}>Clear Selection</button>
                                    :
                                    <button onClick={() => setSelectAllList(true)}>Select
                                        all {appointments?.count} appointments across all pages</button>
                                }
                            </div>
                        }

                    </div>
                    <div className="flex-lock">
                        <button className="lock-btn" onClick={() => lockUnlock('lock')}>
                            <img src={Images.lock} alt="" />
                            Lock Selected
                        </button>
                        <button className="unlock-btn" onClick={() => lockUnlock('unlock')}>
                            <img src={Images.unlock} alt="" />
                            Unlock Selected
                        </button>
                    </div>
                </div>
                : ''
            }
            <div className="members-container page-container">
                <div className="desktop-page-view">
                    {
                        loader?.length ?
                            <Loader />
                            :
                            <>
                                <div className="accordion-head-wrapper" style={{ borderLeft: '4px solid #EBF2FD' }}>
                                    {RolePermission([PermissionList.APPT_LOCK?.code]) &&
                                        <div className="accordion-head-item-dark" style={{ width: '4%' }}>
                                            <Checkbox
                                                disableRipple={true}
                                                onClick={(e) => handleCheck(e, '', 'all')}
                                                name={'checkBox'}
                                                style={{ padding: 0, color: '#347AF0', width: '35px', height: '30px' }}
                                                checked={selectAllList ? true : appointments?.appts?.length === selectedList?.length}
                                            />
                                        </div>
                                    }
                                    <div className="accordion-head-item-dark" style={{ width: '10%' }}>
                                        ID
                                    </div>
                                    <div className="accordion-head-item-dark" style={{ width: '15%' }}>
                                        <SearchAndFilerFull
                                            item={{
                                                name: 'createdAt',
                                                title: 'Date/Time',
                                                searchKey: 'createdAt',
                                                noSearch: true,
                                                iconType: 'date',
                                            }}
                                        />
                                    </div>
                                    <div className="accordion-head-item-dark" style={{ width: '15%' }}>
                                        Staff
                                    </div>
                                    <div className="accordion-head-item-dark" style={{ width: '15%' }}>
                                        Client
                                    </div>
                                    <div className="accordion-head-item-dark" style={{ width: '10%' }}>
                                        Event
                                    </div>
                                    <div className="accordion-head-item-dark" style={{ width: '13%' }}>
                                        Status
                                    </div>
                                    <div className="accordion-head-item-dark" style={{ width: '16%' }}>
                                        Actions
                                    </div>
                                    <div style={{ width: '2%' }} />
                                </div>

                                {appointments?.appts?.length ?
                                    <div className="accordion-schedule-body">
                                        {appointments?.appts?.map((i, j) => (
                                            <React.Fragment key={j}>
                                                <ListViewTable
                                                    item={i}
                                                    openCloseRecur={openCloseRecur}
                                                    handleEdit={handleEdit}
                                                    expanded={expanded}
                                                    setExpanded={setExpanded}
                                                    handleSelect={handleCheck}
                                                    selected={selectedList}
                                                    selectAllList={selectAllList}
                                                />
                                            </React.Fragment>
                                        ))
                                        }
                                    </div>
                                    :
                                    <NoItemsYet text={`No appointments yet`} />
                                }

                                {!!appointments?.appts?.length &&
                                    <PaginationFillTable
                                        listLength={appointments?.appts?.length}
                                        page={page}
                                        handleReturn={(number) => changePage(number)}
                                        count={appointments?.count}
                                    />
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    );
};