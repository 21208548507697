import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {SaveParams} from "utils";
import {clientActions} from "store";
import {DownloadCsv, SelectTypeAutocomplete } from 'components';

export const InvoiceFilters = ({}) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const pushInfo = history?.location?.state
    const clientList = useSelector((state) => state.client.clientList);

    useEffect(() => {
        dispatch(clientActions.getClients());
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo
        }
        e.target.value !== 'All' ? info[e.target.name] = e.target.value : delete info[e.target.name]
        SaveParams(history, {...info})
    }

    return (
        <div className="claim-invoice-filters-wrapper">
            <div className="filter-inputs-align-end">
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.client ? '' : 'All'}
                    name={'client'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.client}
                    list={clientList?.clients ?
                        [{ id: 'All', firstName: 'All', lastName: '' }, ...clientList?.clients]
                        :
                        [{ id: 'All', firstName: 'All', lastName: '' }]
                    }
                    renderValue={(i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`}
                    uiType={'tableFilter'}
                    outLabel={'Client'}
                    noError={true}
                />
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.status ? '' : 'All'}
                    name={'status'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.status}
                    list={
                        [
                            { id: 'All', label: 'All' },
                            { id: 'OPEN', label: 'Open' },
                            { id: 'CLOSED', label: 'Closed' },
                            { id: 'VOIDED', label: 'Voided' },
                            { id: 'COMPLETED', label: 'Completed' },
                        ]
                    }
                    renderValue={(i) => i?.label}
                    uiType={'tableFilter'}
                    outLabel={'Status'}
                    noError={true}
                />
            </div>
            <DownloadCsv type={'invoicePayment'} />
        </div>

        // <div className={classes.tableWrapper}>
        //     <div className={classes.inputWrapper}>
        //         <p className={classes.title}>Client</p>
        //         <div className={classes.inputStyle}>
    //             <select
    //                 value={pushInfo?.client}
    //                 name={'client'}
    //                 onChange={handleChange}
    //             >
    //                 <option value="All">All</option>
    //                 {clientList?.clients && clientList?.clients.map((i, k) => (
    //                     <option key={i?.id} value={i?.id}>{`${i?.firstName} ${i?.lastName}`}</option>
    //                 ))}
    //             </select>
    //         </div>
    //     </div>
    //     <div className={classes.inputWrapper}>
    //         <p className={classes.title}>Status</p>
    //         <div className={classes.inputStyle}>
    //             <select
    //                 value={pushInfo?.status}
    //                 name={'status'}
    //                 onChange={handleChange}
    //             >
    //                 <option value="All">All</option>
    //                 <option value="OPEN">Open</option>
    //                 <option value="CLOSED">Closed</option>
    //                 <option value="VOIDED">Voided</option>
    //             </select>
    //         </div>
    //     </div>
    // </div>
)
}