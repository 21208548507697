import { makeStyles, Switch, withStyles } from '@material-ui/core';
import { Backgrounds as Background, Colors, Shadow } from './globalColors';

export const useGlobalStyles = makeStyles({
    inputTextFieldError: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F07379',
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F07379',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `#F07379!important`,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color: '#F07379',
        },
    },

    inputTextFieldBlue: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.ThemeBlue,
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9cb9f3',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: `#9cb9f3!important`,
        },
        '& .MuiOutlinedInput-root': {
            height: '48px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-3px',
            color: '#51566D',
        },
        '& .MuiAutocomplete-input': {
            color: '#51566D',
            padding: '7px 4px !important',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -2px) scale(0.75)',
            color: '#51566D',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: 'black',
        },
        '& .MuiSelect-icon': {
            color: Colors.ThemeBlue,
        },
    },


    tableContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '70vh',
        // height: '110vh',
        // '@media (min-width: 1919px)': {
        //     height: '85vh',
        // },
    },


    tableContainerAutoHeight: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: '200px',
        maxHeight:'65vh',
        overflowY: 'auto',
    },


    previewModal: {
        width: '100%',
        maxHeight: '38vh',
        height: '100%',
        background: Colors.BackgroundWhite,
        padding: 16,
        borderRadius: 8,
        '& h1': {
            fontSize: 18,
            color: Colors.BackgroundBlue,
            fontWeight: 'bold',
            paddingBottom: 5,
        },
        '& > p': {},
        '& > div': {
            marginBottom: 20,
        },
        '& > div > p': {
            fontSize: 14,
            color: Colors.TextSecondary,
        },
        '& > span': {
            display: 'block',
            fontSize: 14,
            color: Colors.TextLightGray,
            paddingBottom: 32,
        },
    },
    noteTextStyle: {
        fontSize: '14px',
        color: Colors.TextSecondary,
        lineHeight: '24px',
        padding: '16px',
        height: 'auto',
        backgroundColor: Colors.BackgroundWater,
        borderRadius: '8px',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    subject: {
        fonSize: '18px',
        fontWeight: 'bold',
        lineHeight: '22px',
        color: Colors.ThemeBlue,
        margin: '20px 0 8px',
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    positionedButton: {
        position: 'absolute',
        right: '0',
        top: '8px',
    },
    icons: {
        cursor: 'pointer',
        display: 'flex',
        '& img:last-child': {
            marginLeft: 16,
        },
    },

    tableStyle: {
        background: 'red',
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        boxShadow: '0px 0px 12px #0052E01A',
        borderRadius: '8px',
        marginTop: '16px',
        marginBottom: '24px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
    },
    simpleInputFull: {
        width: '100%',
    },
    tableWrapper: {
        background: 'none',
        width: '100%',
        borderRadius: '8px',
        marginTop: '24px',

        // '@media (min-width: 1919px)': {
        //     marginTop: '32px',
        // },
        position: 'relative',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
            background: 'none',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        '& .MuiTable-root':{
            marginTop:'-8px',
            // borderSpacing: '0!important'
        }
    },
    tableBack: {
        width: '100%',
        backgroundColor: 'transparent!important',
        boxShadow: 'none!important',
    },
    table: {
        '&.MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '0px 8px',
        },
    },
    tableWrapperSmall: {
        height: '100%',
        width: '100%',
        boxShadow: '0px 0px 12px #0052E01A',
        borderRadius: '8px',
        marginTop: '16px',
        marginBottom: '24px',
        '& .MuiTableContainer-root': {
            boxShadow: 'none',
        },
    },

    simpleInput: {
        marginRight: '30px',
        width: '100%',
        '@media (min-width: 1919px)': {
            marginRight: '56px',
        },
    },

    InfoAndImage: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: '600',
            color: Colors.TextSecondary,
        },
        '& img': {
            width: '24px',
            height: '24px',
            marginRight: '4px',
            '@media (min-width: 1920px)': {
                marginRight: '8px',
            },
        },
    },

    buttonsStyle: {
        marginTop: '14px',
    },

    disabledButton: {
        background: 'red!important',
    },

    createOfficeTableHead: {
        display: 'flex',
        alignItems: 'center',

        '& p': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,

            '@media (max-width: 1919px)': {
                marginLeft: '4px',
            },
            '@media (min-width: 1920px)': {
                marginLeft: '8px',
            },
        },
    },

    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },

    centerItem: {
        display: 'flex',
        alignItems: 'center',
    },

    paginateContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: Background.headerLightBlue,
        height: '76px',
        borderRadius: '8px',
        padding: '0 16px',
        '& p': {
            fontSize: '14px',
            fontWeight: '600',
        },
    },
    noData: {
        width: '100%',
        fontSize: 18,
        color: Colors.TextLightGray,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '40px',
    },

    globalHistory: {
        height: 'calc(100vh - 380px)',
        overflow: 'auto',
    },

    loaderContainerStyle: {
        minHeight: '695px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export const useGlobalTextStyles = makeStyles({
    modalTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: Colors.TextSecondary,
        lineHeight:'normal',
    },
    modalText: {
        color: Colors.TextPrimary,
        fontSize: '16px',
        lineHeight: '22px',
        margin: '12px 0 24px 0',
    },
});

export const AntSwitch = withStyles((theme) => ({
    root: {
        width: 32,
        height: 16,
        padding: 0,
        display: 'flex',
        marginTop: 0,
        marginLeft: '8px',
    },
    switchBase: {
        paddingTop: '2.2px',
        padding: 3,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(14px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: Colors.ThemeBlue,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        marginTop: 0,
        boxShadow: 'none',
    },
    track: {
        border: 'none',
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: theme.palette.grey[400],
    },
    checked: {},
}))(Switch);
