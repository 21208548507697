import {
    DELETE_APPOINTMENT_SUCCESS,
    EDIT_APPOINTMENT_POSITION,
    GET_APPOINTMENT_BY_ID_SUCCESS,
    GET_APPOINTMENT_SUCCESS, GET_CLIENT_STAFFS_SUCCESS, REMOVE_CLIENT_STAFFS, SAVE_CURRENT_LOCATION,
    SET_APPOINTMENT_STATUS_SUCCESS,
} from './appointment.type';
import { deleteAppt, handleReorderCategory } from './index';

const initialState = {
    appointments: [],
    appointmentById: [],
    geoPos: null,
    clientStaffs: []
};

export const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case  GET_APPOINTMENT_SUCCESS :
            return {
                ...state,
                appointments: action.payload,
            }

            case  DELETE_APPOINTMENT_SUCCESS :
            return {
                ...state,
                appointments: deleteAppt( state.appointments, action.payload)
            }

        case  SET_APPOINTMENT_STATUS_SUCCESS :
            return {
                ...state,
                appointments: state.appointments.map((post) =>

                    post?.id === action.payload?.id ? {...action.payload,} : post),
            }

        case  GET_APPOINTMENT_BY_ID_SUCCESS :
            return {
                ...state,
                appointmentById: action.payload
            }

        case EDIT_APPOINTMENT_POSITION :
            return {
                ...state,
                appointments: handleReorderCategory(action.payload.body, action.payload.id, state.appointments)
            }

        case SAVE_CURRENT_LOCATION :
            return {
                ...state,
                geoPos: action.payload
            }

        case GET_CLIENT_STAFFS_SUCCESS :
            return {
                ...state,
                clientStaffs: action.payload
            }

        case REMOVE_CLIENT_STAFFS :
            return {
                ...state,
                clientStaffs: []
            }

        default:
            return state;
    }
};