import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentTables = ({ changingMenu }) => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };

  const changeSubMenuItem = (title) => {
    changingMenu("subMenu", title);
  };

  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-menu-design  tables-wrapper">
        <div>
          <h2 className="title">
            Tables
          </h2>

          <p className="description table-subDescription-margin" style={{ maxWidth: "676px" }}>
            Here you can streamline your restaurant's seating management. By creating tables you can get orders.
          </p>

          <p className="description qr-subtitle-margin" style={{ maxWidth: "676px" }}>
            Organize and manage your restaurant's seating arrangements effectively
          </p>
        </div>


        <div className="tables-observer" id="addTables" style={{ maxWidth: "676px" }}>
          <h3 className="subtitle table-subtitle-margin">
            Add Tables
          </h3>

          <ul className="lists">
            <li className="description">
              Start by clicking on
              in the right corner.
            </li>

            <li className="description">
              This action enables you to input the names of your restaurant's tables, creating a digital representation of your seating layout.
            </li>
          </ul>
        </div>

        <div className="tables-observer" id="tableNames" style={{ maxWidth: "676px" }}>
          <h3 className="subtitle table-subtitle-margin">
            Table Names
          </h3>

          <ul className="lists">
            <li className="description">
              Input the names of each table in your restaurant.
            </li>

            <li className="description">
              These names assist in tracking seating arrangements and ensuring efficient customer seating management.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

