import React from "react";
import { useWidth } from "utils";

export const ControlPanelContentModifier = ( ) => {
  const width = useWidth();


  const images = {
    desktop: {
    },
    tablet: {
    },
    mobile: {
    }
  };


  const renderImage = (src) => {
    if (width > 1240) {
      return images.desktop[src];
    } else if (width > 767) {
      return images.tablet[src];
    } else {
      return images.mobile[src];
    }
  };

  return (
    <div className="control-panel-content-menus menu-items">
      <div className="controlPanelContentMenus-modifier_groups">
        <div className="menus-items_text-desc">
          <h2 className="title" style={{ marginBottom: "16px" }}>
            Modifier Groups
          </h2>

          <div style={{ maxWidth: "676px" }}>
            <p className="description">
              A modifier group is an option or addition that allows customers to choose to have the final option of the item of their food or drink orders according to their preferences.\n" +
              "Modifier groups not only increase customization but also provide recommendations to guide customers in creating their ideal dining experience.
            </p>

            <p className="subtitle subtitle-margin-8-0">
              Example 👇🏻
            </p>

            <p className="description" style={{ fontWeight: "400" }}>
              Pizza Dough Options: Customers can select the size of their pizza dough, whether they prefer it large/medium/small, tailored to their appetite and serving needs.
            </p>
          </div>
        </div>

      </div>
      <div>
        <div className="controlPanelContentMenus-start_adding max-width-661" id="gettingStarted">
          <div className="organize-box">
            <h2 className="title subtitle">
              Getting Started
            </h2>

            <div>
              <p className="description">
                <span className="description">
                 Begin by clicking the
                </span>

                <span className="description" style={{ fontWeight: "500" }}>
                    Add Modifier Group
                </span>

                <span className="description">
                  button.
                </span>
              </p>

              <p className="description">
                This is where you're creating options for all the extras and special touches your customers can choose from.
              </p>
            </div>
          </div>
        </div>

        <div className="controlPanelContentMenus-start_adding max-width-661" id="giveItAName">
          <div className="organize-box">
            <h2 className="title subtitle">
              Give It a Name
            </h2>

            <div>
              <p className="description">
                Give each modifier group a name:
              </p>

              <ul className="lists">
                <li className="description">
                  Ensure every modifier group has a name.
                </li>

                <li className="description">
                  Choose a simple name that helps your customers understand the options they're choosing from.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

