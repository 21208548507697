import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import {
    DateRow,
    Loader,
    TextRow,
    TypeRow,
    PaginationFillTable,
    SearchAndFilerFull, CustomDelete, NoItemsYet,
} from 'components';
import {
    FindLoad,
    FindSuccessItem,
    Images,
    makeCapitalize,
    PermissionList,
    RolePermission,
    SendPageSave,
    useModal,
} from 'utils';
import { adminActions, httpRequestsOnSuccessActions } from 'store';
import { CredentialModal } from '..';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

const DELETE_ACTION = 'DELETE_CREDENTIAL_BY_ID';
const CREATE_CREDENTIAL = 'CREATE_CREDENTIAL';
const EDIT_CREDENTIAL = 'EDIT_CREDENTIAL_BY_ID';
const GET_ACTION = 'GET_CREDENTIAL';

export const StaffCredentials = ({ globalCredentials }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const info = history?.location?.state;
    const deleteCredentialSuccess = FindSuccessItem(DELETE_ACTION);
    const createCredentialSuccess = FindSuccessItem(CREATE_CREDENTIAL);
    const editCredentialSuccess = FindSuccessItem(EDIT_CREDENTIAL);
    const loader = FindLoad(GET_ACTION);
    const staffCredentialList = useSelector((state) => state.admins.credential);
    const [expanded, setExpanded] = useState('');
    const { open, close } = useModal();

    const renderParams = useMemo(() => {
        const sendInfo = {
            ...info,
        };
        delete sendInfo.page;
        delete sendInfo.activeTab;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.limit = 10;

        return sendInfo;
    }, [info]);

    const getCredential = (checkLoad) => {
        dispatch(adminActions.getCredential(params.id, renderParams, checkLoad));
    };

    useEffect(() => {
        getCredential();
    }, [info]);

    useEffect(() => {
        if (deleteCredentialSuccess?.type === DELETE_ACTION) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION));
            getCredential('noLoad');
        }
        if (createCredentialSuccess?.type === CREATE_CREDENTIAL) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_CREDENTIAL));
            getCredential('noLoad');
        }
        if (editCredentialSuccess?.type === EDIT_CREDENTIAL) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_CREDENTIAL));
            getCredential('noLoad');
        }
    }, [deleteCredentialSuccess, createCredentialSuccess, editCredentialSuccess]);

    const handleClickButton = (ev, item, name) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (name === 'editCredential') {
            open(<CredentialModal item={item} />);
        }
        if (name === 'deleteCredential') {
            open(<CustomDelete
                text={`Are you sure you want to delete this staff credential?`}
                info="Delete Staff Credential"
                handleDel={() => dispatch(
                    adminActions.deleteCredentialById(item?.id, params.id),
                )}
                handleClose={() => close()}
                actionType={DELETE_ACTION}
            />);
        }
    };

    const handleChange = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        const currentId = expanded === id ? '' : id;
        setExpanded(currentId);
    };

    const changePage = (number) => {
        SendPageSave(number, info, history, location);
    };

    return (
        <>
            <div className="credential-accordion-box">
                <div>
                    {loader?.length ?
                        <Loader />
                        :
                        <>
                            <div className="accordion-head-box">
                                <div className="accordion-head-wrapper">
                                    <div className="accordion-head-item" style={{ width: '20%' }}>
                                        <SearchAndFilerFull
                                            item={{ noSearch: true, title: 'Name', name: 'name', custom: false }}
                                        />
                                    </div>
                                    <div className="accordion-head-item" style={{ width: '15%' }}>
                                        <SearchAndFilerFull
                                            item={{
                                                noSearch: true, title: 'Type', name: 'type', filterList: [
                                                    { name: 'All', id: 'All' },
                                                    { name: 'Degree', id: 'DEGREE' },
                                                    { name: 'Clearances', id: 'CLEARANCES' },
                                                    { name: 'License', id: 'LICENSE' },
                                                ],
                                            }}
                                        />
                                    </div>
                                    <div className="accordion-head-item" style={{ width: '15%' }}>
                                        <SearchAndFilerFull
                                            item={{
                                                noSearch: true,
                                                title: 'Received Date',
                                                name: 'receivedDate',
                                                custom: false,
                                            }}
                                        />
                                    </div>
                                    <div className="accordion-head-item" style={{ width: '15%' }}>
                                        <SearchAndFilerFull
                                            item={{
                                                noSearch: true,
                                                title: 'Expiration Date',
                                                name: 'expirationDate',
                                                custom: false,
                                            }}
                                        />
                                    </div>
                                    <div className="accordion-head-item" style={{ width: '20%' }}>
                                        <SearchAndFilerFull
                                            item={{
                                                noSearch: true,
                                                title: 'License Number',
                                                name: 'licenseNumber',
                                                custom: false,
                                            }}
                                        />
                                    </div>
                                    <div className="accordion-head-item" style={{ width: '10%' }}>
                                        Action
                                    </div>
                                </div>
                            </div>

                            {staffCredentialList?.credentials?.length ?
                                <div className="accordion-covered-credential-body">
                                    {staffCredentialList?.credentials?.map((i, j) => (
                                        <Accordion
                                            key={j}
                                            expanded={expanded === i?.id}
                                            onChange={(e) => handleChange(e, i?.id)}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1bh-content"
                                                id={i?.id}
                                            >
                                                <div className="accordion-body-wrapper">
                                                    <div className="accordion-body-item" style={{ width: '20%' }}>
                                                        <TextRow name={i?.credential?.name} />
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '15%' }}>
                                                        <TypeRow text={i?.credential?.type} />
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '15%' }}>
                                                        <DateRow date={i?.receiveDate} />
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '15%' }}>
                                                        <DateRow date={i?.expirationDate} />
                                                    </div>
                                                    <div className="accordion-body-item" style={{ width: '20%' }}>
                                                        <TextRow name={i?.licenseNumber ? i?.licenseNumber : 'N/A'} />
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                        }}
                                                        className="accordion-body-item"
                                                        style={{ width: '10%', display: 'flex', flexWrap: 'nowrap' }}>
                                                        {RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code]) &&
                                                            <>
                                                                <button
                                                                    onClick={(e) => handleClickButton(e, i, 'editCredential')}>
                                                                    <img src={Images.edit} alt="edit" />
                                                                </button>
                                                                <button
                                                                    onClick={(e) => handleClickButton(e, i, 'deleteCredential')}>
                                                                    <img src={Images.remove} alt="remove" />
                                                                </button>
                                                            </>
                                                        }
                                                        <button onClick={(e) => handleChange(e, i?.id)}>
                                                            <img
                                                                src={expanded === i?.id ? Images.dropdownArrowUpBlue : Images.dropdownBlue}
                                                                alt="icon"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="covered-credentials-details-body">
                                                    <p className="covered-credentials-details-title">Covered Credentials</p>
                                                    {i?.credential?.coveredCredentials?.length ?
                                                        <div className="covered-credentials-details">
                                                            {
                                                                i?.credential?.coveredCredentials?.map((k) => (
                                                                    <div className="covered-credentials">
                                                                        {k?.name} - {makeCapitalize(k?.type)}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        <div style={{ position: 'relative' }}>
                                                            <NoItemsYet text="No covered credentials yet" containerStyles={{padding:'10px', position:'relative'}} />
                                                        </div>
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                    }
                                </div>
                                :
                                <div style={{ position: 'relative' }}>
                                    <NoItemsYet text="No credentials yet" />
                                </div>
                            }
                        </>
                    }

                    {!!staffCredentialList?.credentials?.length &&
                        <div style={{ height: '76px' }}>
                            <PaginationFillTable
                                listLength={staffCredentialList?.credentials?.length}
                                page={info?.page ? info?.page : 1}
                                handleReturn={(number) => changePage(number)}
                                count={staffCredentialList?.count}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    );
};
