import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { CreateChancel, SimpleTooltip, SelectAutocompleteMultiple, Input, CheckboxCustom } from 'components';
import { FindLoad, FindSuccess, useModal } from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { createClientStyle } from 'fragments/client';
import { FindErrorItem } from 'utils/hooks/findError';
import { EMPLOYMENT_TYPES } from '../constants';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const JOB_ACTIONS = 'GET_JOBS';
const SUPERVISOR_ACTIONS = 'GET_ALL_ADMINS';
const DEPARTMENT_ACTIONS = 'GET_DEPARTMENTS';
export const EmploymentModal = ({ edit, past }) => {
    const { activeEmployment, staffGeneral } = useSelector((state) => ({
        activeEmployment: state.admins.activeEmployment,
        staffGeneral: state.admins.adminInfoById,
    }));
    const { close } = useModal();
    const ACTION_TYPE = edit ? 'EDIT_EMPLOYMENT' : 'CREATE_EMPLOYMENT';
    const classes = createClientStyle();
    const params = useParams();
    const dispatch = useDispatch();
    const jobTitles = useSelector((state) => state.system.jobs);
    const departments = useSelector((state) => state.system.departments);
    const staffList = useSelector((state) => state.admins.adminsAllList.staffs)?.filter((item) => item.id !== params.id && item);
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const [active, setActive] = useState(false);
    const { handleSubmit, control, clearErrors, setError, reset, watch } = useForm({});

    useEffect(() => {
        dispatch(systemActions.getJobs());
        dispatch(systemActions.getDepartments());
        dispatch(adminActions.getAllAdmins());
    }, []);

    useEffect(() => {
        if (edit?.id) {
            const params = {
                ...edit,
            };
            if (params?.id) delete params?.id;
            if (params?.schedule) delete params?.schedule;

            params.startDate = moment.utc(edit?.startDate).format('YYYY-MM-DD');
            params.endDate = moment.utc(edit?.endDate).format('YYYY-MM-DD');
            params.departmentId = edit?.departmentId?._id;
            params.supervisor = edit?.supervisor?._id;
            params.title = edit?.title?._id;
            reset(params);
            setActive(edit?.active !== true);
        } else {
            setActive(!!activeEmployment?.id);
        }
    }, [edit]);


    useEffect(() => {
        if (backError?.error?.length) {
            if (backError?.error === 'employment overlapping') {
                setError('startDate', {
                    message: 'Employments cannot overlap, please change the date!',
                });
            }

            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [backError]);


    useEffect(() => {
        if (!!success?.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);


    function handleCreate(data) {
        const scheduleEnum = data.type?.id === 'FULL' ? 1 : data.type?.id === 'PART' ? 0 : 0;

        const sendParams = {
            ...data,
            title: data?.title,
            departmentId: data?.departmentId,
            supervisor: data?.supervisor,
            type: data?.type,
            active: !active,
            staffId: staffGeneral?.id,
            schedule: scheduleEnum,
        };

        if (data?.endDate) {
            moment.utc(data.endDate).format('YYYY-MM-DD');
        } else {
            sendParams.endDate = null;
        }

        if (edit) {
            dispatch(adminActions.editEmployment(sendParams, edit?.id, staffGeneral?.id));
        } else {
            dispatch(adminActions.createEmployment(sendParams, staffGeneral?.id));
        }
    }

    const renderToolTip = () => {
        return <FormGroup
            style={{ marginBottom: '20px' }}
            onChange={(e) => setActive(e?.target?.checked)}
        >
            <FormControlLabel
                style={{ opacity: !!activeEmployment && !edit ? '0.7' : 1 }}
                disabled={activeEmployment?.id && !edit}
                control={
                    <Checkbox
                        style={{ color: '#347AF0' }}
                        value={active === false}
                        checked={!!active}
                    />
                } label="Past Employment"
            />
        </FormGroup>;
    };

    return (
        <form
            className="employment-modal"
            onSubmit={handleSubmit(handleCreate)}
        >
            <p className="modal-header-title">{edit ? 'Edit Employment' : 'Add Employment'}</p>
            <div className="modal-body-wrapper">
                <SelectAutocompleteMultiple
                    name="title"
                    label={'Title*'}
                    control={control}
                    options={jobTitles}
                    loadType={JOB_ACTIONS}
                    rules={{
                        required: 'Title is required.',
                    }}
                    renderValue={(option) => option?.name}
                />
                <SelectAutocompleteMultiple
                    name="supervisor"
                    label={'Supervisor'}
                    control={control}
                    options={staffList}
                    loadType={SUPERVISOR_ACTIONS}
                    renderValue={((i) => `${i?.firstName ? i?.firstName : ''} ${i?.lastName ? i?.lastName : ''}`)}
                />
                <SelectAutocompleteMultiple
                    name="departmentId"
                    label={'Department*'}
                    control={control}
                    options={departments}
                    loadType={DEPARTMENT_ACTIONS}
                    rules={{
                        required: 'Department is required.',
                    }}
                    renderValue={(option) => option?.name}
                />
                <SelectAutocompleteMultiple
                    name="type"
                    label={'Employment Type*'}
                    control={control}
                    options={EMPLOYMENT_TYPES}
                    loadType={DEPARTMENT_ACTIONS}
                    rules={{
                        required: 'Employment is required.',
                    }}
                    renderValue={((i) => i?.name)}
                />
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Input
                        name="startDate"
                        label={'Start Date*'}
                        type="date"
                        max={watch('endDate') ? moment.utc(watch('endDate')).format('YYYY-MM-DD') : ''}
                        variant="outlined"
                        control={control}
                        rules={{
                            required: 'Start date is required.',
                        }}
                    />
                    {active ?
                        <Input
                            name="endDate"
                            label={'End Date*'}
                            disabled={!watch('startDate')}
                            type="date"
                            min={watch('startDate') ? moment.utc(watch('startDate')).format('YYYY-MM-DD') : ''}
                            variant="outlined"
                            control={control}
                            rules={{ required: 'End date is required.' }}
                        />
                        :
                        <Input
                            name="endDate"
                            label={ 'End Date' }
                            disabled={!watch('startDate')}
                            type="date"
                            min={watch('startDate') ? moment.utc(watch('startDate')).format('YYYY-MM-DD') : ''}
                            variant="outlined"
                            control={control}
                        />
                    }
                </div>
                {!!activeEmployment ?
                    <SimpleTooltip
                        title={
                            <p className={classes.infoTextForAuthStyle}>
                                There is already an active employment for this staff. Inactivate it to set this one
                                active
                            </p>
                        }
                        placement="top-start"
                    >
                        {renderToolTip()}

                    </SimpleTooltip>
                    :
                    renderToolTip()
                }
            </div>

            <CreateChancel
                type="submit"
                loader={!!loader.length}
                create={edit ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onClose={close}
                buttonWidth="224px"
            />
        </form>
    );
};
