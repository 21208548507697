import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {invoiceActions} from "store";
import {FullTable} from "components";
import {invoiceBody, invoiceHead, ACTION_TYPE} from "./constants";
import { InvoiceFilters } from './invoiceFilters';


export const Invoices = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state
    const invoicesData = useSelector((state) => state.invoice.invoices);

    useEffect(() => {
        const sendInfo = {
            ...info
        };
        delete sendInfo.page
        sendInfo.skip = info?.skip ? info?.skip : 0
        sendInfo.limit = 50
        if (info?.firstDate) {
            sendInfo.from = info?.firstDate;
            delete sendInfo.firstDate;
        }else{
            delete sendInfo.from;
        }
        if (info?.lastDate) {
            sendInfo.to = info?.lastDate;
            delete sendInfo.lastDate;
        }else{
            delete sendInfo.to;
        }
        dispatch(invoiceActions.getInvoices({...sendInfo}));
    }, [info]);

    return (
        <div>
            <InvoiceFilters/>
            <FullTable
                head={invoiceHead}
                body={invoiceBody}
                loadingType={ACTION_TYPE}
                list={invoicesData?.invoices}
                listCount={invoicesData?.count}
                handleClick={(id) => history.push(`/invoice/${id}`)}
                noText={'invoices'}
                activeRowId={""}
            />
        </div>
    );
};
