import {
   createFundingSource,
   getFundingSource,
   getFundingSourceById,
   getFoundingSourceServiceById,
   createFoundingSourceServiceById,
   editFundingSource,
   editFoundingSourceServiceById,
   createFundingModifier,
   editFundingModifier,
   changeFundingModifierStatus,
   changeFundingSourceStatus,
   setStatus,
   uploadFsDocument,
   editFsDocument,
   getFsDocument,
   deleteFsDocument, getServiceModifier, editFoundingSourceServiceStatus,deleteFundingSource,
} from './fundingSource.action';
export { fundingSourceReducer } from "./fundingSource.reducer";
export { watchFundingSource } from "./fundingSource.saga";

export const fundingSourceActions = {
   /** Founding Source */
   createFundingSource,
   editFundingSource,
   changeFundingSourceStatus,
   getFundingSource,
   getFundingSourceById,
   deleteFundingSource,
   /** End */

   /** Founding Source Service */
   createFoundingSourceServiceById,
   getFoundingSourceServiceById,
   editFoundingSourceServiceById,
   editFoundingSourceServiceStatus,
   /** End */

   /** Modifiers */
   getServiceModifier,
   createFundingModifier,
   editFundingModifier,
   changeFundingModifierStatus,
   /** End */

   setStatus,

   /** Staff Documents */
   uploadFsDocument,
   editFsDocument,
   getFsDocument,
   deleteFsDocument,
   /** End */
};
