import React from 'react';
import { useWidth } from 'utils';

export const ControlPanelContentMenuItem = () => {
    const width = useWidth();


    const images = {
        desktop: {

        },
        tablet: {

        },
        mobile: {

        },
    };


    const renderImage = (src) => {
        if (width > 1240) {
            return images.desktop[src];
        } else if (width > 767) {
            return images.tablet[src];
        } else {
            return images.mobile[src];
        }
    };

    return (
        <div className='control-panel-content-menus menu-items'>
            <div className='controlPanelContentMenus-menus-items'>
                <div className='menus-items_text-desc'>
                    <h2 className='title'>
                        Menu Items
                    </h2>

                    <div>
                        <p className='description'>
                            Here you'll have the opportunity to showcase the essence of your menu offerings. This is
                            where you can start bringing your menu to life.
                        </p>

                        <p className='description'>
                            Notably, you'll find separate sections dedicated to both food and drinks, allowing you;
                        </p>

                        <div className='description'>
                            👉🏻 Add and highlight each item on your menu, ensuring a comprehensive representation of
                            your offerings.
                            <p className='description'>
                <span className='description'>
                  You can also create your menu item not only here, but also in the
                </span>

                                <span className='description' style={{ fontWeight: '500' }}>
                    Menus
                </span>

                                <span className='description'>
                  section for added flexibility and customization.
                </span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div>
            </div>
        </div>
    );
};

