import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { scheduleModalsStyle } from './styles';
import { AddCircleBlue, CloseButton, CreateChancel, TextRow } from 'components';
import { FindLoad, FindSuccessItem, Images, PermissionList, RolePermission } from 'utils';
import { appointmentActions } from 'store';

const ACTION_TYPE = 'MANAGE_NOTES';
export const NoteModal = ({ modalDate, handleClose }) => {
    const userInfo = JSON.parse(localStorage.getItem('wellUserInfo'));
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch();
    const success = FindSuccessItem(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const [error, setError] = useState('');
    const [addNote, setAddNote] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [inputs, setInputs] = useState({});
    const [selectedNote, setSelectedNote] = useState('');
    const inputRef = useRef();
    const textAreaRef = useRef();

    useEffect(() => {
        if (success?.type === ACTION_TYPE && addNote) {
            setAddNote(false);
        }
        if (success?.type === ACTION_TYPE && selectedNote) {
            setSelectedNote('');
        }
    }, [success]);

    const handleSubmit = () => {
        if (inputs?.noteTitle && inputs?.noteDescription) {
            const params = {
                'title': inputs?.noteTitle,
                'description': inputs?.noteDescription,
            };
            inputs?.id ? params.id = inputs?.id : delete params.id;
            dispatch(appointmentActions.manageNotes(modalDate?.id, params));
        } else {
            if (!inputs?.noteTitle) {
                inputRef.current.focus();
            }
            if (inputs?.noteTitle && !inputs?.noteDescription) {
                textAreaRef.current.focus();
            }
        }
    };

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        error === e.target.name && setError('');
    };

    const handleCancel = () => {
        setInputs({});
        setAddNote(false);
    };

    const handleAddNote = () => {
        setAddNote(true);
        setEditNote(false);
        setInputs({});
        setSelectedNote('');
    };

    const handleEditNote = () => {
        setEditNote(true);
        setAddNote(true);
        setInputs({
            noteTitle: selectedNote?.title,
            noteDescription: selectedNote?.description,
            id: selectedNote?.id,
        });
    };

    const noteLeftSide = () => {

        if (addNote) {
            return (
                <div className={classes.noteLetSide}>
                    <input
                        ref={inputRef}
                        onChange={handleChange}
                        value={inputs?.noteTitle ? inputs?.noteTitle : ''}
                        name={'noteTitle'}
                        type="text"
                        placeholder={'Untitled'}
                        className="note-title-input"
                        // className={classes.noteTitleInput}
                        maxLength={50}
                    />

                    <div className={classes.noteSubjectBox}>
                        <div className={classes.characters}>
                            <p>{`${inputs?.noteTitle?.length ? inputs?.noteTitle?.length : 0}/50 characters`}</p>
                        </div>
                        <textarea
                            ref={textAreaRef}
                            onChange={handleChange}
                            value={inputs?.noteDescription ? inputs?.noteDescription : ''}
                            name={'noteDescription'}
                            placeholder={'Provide details for your note...'}
                            className={classes.noteSubjectArea}
                        />
                    </div>
                    <CreateChancel
                        justifyContent={'flex-end'}
                        alignItems={'flex-end'}
                        loader={!!loader.length}
                        create={'Save'}
                        chancel={'Cancel'}
                        onCreate={handleSubmit}
                        onClose={handleCancel}
                        buttonWidth="93px"
                    />
                </div>
            );
        }

        if (modalDate?.notes?.length && !selectedNote && !addNote) {
            return (
                <div className={classes.noteLetSide}>
                    <img className={classes.noteImage} src={Images.notes} alt="icon" />
                    <p className={classes.noteInformationTitle} style={{ margin: '16px 0 8px' }}>Select a note to
                        view</p>
                    <p className={classes.noteInformationSubject}>Choose a note from the list on the left to view its
                        contents, <br /> or create a new note to add to your collection.</p>
                </div>
            );
        }

        if (!modalDate?.notes?.length && !addNote) {
            return (
                <div className={classes.noteLetSide} style={{ background: 'transparent' }}>
                    <img className={classes.noteImage} src={Images.notes} alt="icon" />
                    <p className={classes.noteInformationTitle}>No notes available for this appointment.</p>
                        <AddCircleBlue handleClick={handleAddNote} text={'Add Note'} />
                </div>
            );
        }

        if (selectedNote) {
            return (
                <div className={classes.noteLetSide} style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div className="space-between-align-start">
                        <p className={classes.selectedNoteTitle}>{selectedNote?.title}</p>
                        {selectedNote?.creatorId === userInfo?.id &&
                            <button className="button-reset" onClick={handleEditNote}>
                                <img src={Images.edit} alt="edit" />
                            </button>
                        }
                    </div>
                    <div className={classes.noteInformationBox}>
                        <div className={classes.noteInformation}>
                            <img src={Images.noteEdit} alt="icon" />
                            <p>Created
                                By: <span>{selectedNote?.creatorFullName ? selectedNote?.creatorFullName : 'Not Set'}</span>
                            </p>
                        </div>
                        <div className={classes.noteInformation}>
                            <img src={Images.noteCalendar} alt="icon" />
                            <p>
                                Created Date: <span>{moment.utc(selectedNote?.createdAt).format('MM/DD/YYYY')}</span>
                            </p>
                        </div>
                        <div className={classes.noteInformation}>
                            <img src={Images.noteClock} alt="icon" />
                            <p>Created Time: <span>{moment.utc(selectedNote?.createdAt).format('LT')}</span></p>
                        </div>
                    </div>
                    <p className={classes.noteSubjectText}>
                        {selectedNote?.description}
                    </p>
                </div>
            );
        }

        if (!modalDate?.notes?.length && !addNote) {
            return (
                <div className={classes.noteLetSide}>
                    <img className={classes.noteImage} src={Images.notes} alt="icon" />
                    <p className={classes.noteInformationTitle}>No notes available for this appointment.</p>
                        <AddCircleBlue handleClick={handleAddNote} text={'Add Note'} />
                </div>
            );
        }
    };

    const handleSelectNote = (item) => {
        setSelectedNote(item);
        setAddNote(false);
    };

    return (
        <div style={{ fontFamily: 'Open Sans, sans-serif',   wordWrap: 'break-word'}}>
            <div className={classes.flexEnd}>
                <CloseButton
                    handleCLic={handleClose}
                    styles={{ marginRight: '10px' }}
                />
            </div>
            <div className={classes.noteModalBox}>
                <div className="space-between-align-center">
                    <p className={classes.noteModalTitle}>Session Notes</p>
                    {!!modalDate?.notes?.length  &&
                        <AddCircleBlue handleClick={handleAddNote} text={'Add Note'} />
                    }
                </div>
                <div className={classes.nodeModalBody}>
                    {!!modalDate?.notes?.length &&
                        <div className={classes.userNotes}>
                            {addNote && !editNote &&
                                <div
                                    style={{ background: '#F8FAFB' }}
                                    className={classes.noteBox}
                                >
                                    <p className={classes.noteTitle}>
                                       {inputs?.noteTitle}
                                    </p>
                                    <p className={classes.noteInfo}>
                                        {moment().format('LT')}
                                    </p>
                                </div>
                            }
                            {!!modalDate?.notes?.length &&
                                <div className={classes.reverse}>
                                    {modalDate?.notes?.map((i, j) => (
                                        <div
                                            style={
                                                selectedNote?.id === i?.id ?
                                                    {
                                                        background: 'rgba(235, 242, 253, 0.50)',
                                                        border: '1px solid rgba(208, 213, 221, 0.25)',
                                                    }
                                                    :
                                                    {
                                                        background: 'transparent',
                                                        border: '1px solid rgba(208, 213, 221, 0.50)',
                                                    }
                                            }
                                            className={classes.noteBox} key={j} onClick={() => handleSelectNote(i)}
                                        >
                                            <p
                                                style={{ color: selectedNote?.id === i?.id ? '#347AF0' : '#4B5C68' }}
                                                className={classes.noteTitle}>{i?.title}</p>
                                            <p className={classes.noteSubject}>
                                                {i?.description}
                                            </p>

                                            <div className={classes.noteInfo}>
                                                <div>
                                                    <p>
                                                        <b>Date:&nbsp;</b>
                                                        {moment.utc(inputs?.createdAt).format('MM/DD/YYYY')}
                                                    </p>
                                                    <p>
                                                        <b>Time:&nbsp;</b>
                                                        {moment.utc(inputs?.createdAt).format('LT')}
                                                    </p>
                                                    <p>
                                                        <b>By:&nbsp;</b>
                                                        <TextRow
                                                            name={i?.creatorFullName} />
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }

                    <div style={{ width: '100%' }}>
                        {noteLeftSide()}
                    </div>
                </div>
            </div>
        </div>
    );
};