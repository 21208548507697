import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { staffPayrollActions } from 'store';
import { FindLoad, Images, PermissionList, RolePermission, SendPageSave } from 'utils';
import { PROCESSED_ACTION_TYPE, processedHead } from './constants';
import {
    DateRow,
    DisplayIdRow,
    Loader,
    PriceRow,
    TableNoInfo,
    TextRow,
    TypeRow,
    PaginationFillTable, LinkRow, NoItemsYet,
} from 'components';
import { Filters } from './fragments/filters';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

export const PassivePayroll = ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const processed = useSelector((state) => state?.staffPayroll?.processedPayroll);
    const loader = FindLoad(PROCESSED_ACTION_TYPE);
    const [expanded, setExpanded] = React.useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.skip = info?.skip ? info?.skip : 0;
        filteredInfo.limit = 50;
        delete filteredInfo.page;
        delete filteredInfo.activeTab;
        if (info?.staffs?.length) {
            filteredInfo.staffs = info?.staffs?.map((i) => i.id);
        } else {
            delete filteredInfo.staffs;
        }
        dispatch(staffPayrollActions.getProcessedPayroll({ ...filteredInfo }));
    }, [info]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const changePage = (number) => {
        setPage(number);
        SendPageSave(number, info, history);
    };

    return (
        <div>
            <Filters noBtn={true} type={'passive'} />
            {loader.length ?
                <Loader />
                :
                <div className="processed-table-wrapper">
                    <div className="table-head-wrapper">
                        {processedHead?.map((i, j) => (
                            <div className="table-head-row" style={i?.width ? { width: i?.width } : {}} key={j}>
                                <p><TextRow name={i?.title} /></p>
                            </div>
                        ))}
                    </div>
                    {processed?.batches?.length ?
                        <div className="table-body-wrapper">
                            {processed?.batches?.map((i, k) => (
                                <React.Fragment key={k}>
                                    <Accordion
                                        style={{ background: expanded === k ? 'rgba(235, 242, 253, 0.50)' : 'white' }}
                                        className="accordion-wrapper" expanded={expanded === k}
                                        onChange={handleChange(k)}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <div className="table-body-row">
                                                <TextRow name={i?.displayId ? i?.displayId : '...'} textWidth={10} />
                                            </div>
                                            <div className="table-body-row">
                                                <div className="staff-name-btn" style={{width:'100%'}}>
                                                    <LinkRow
                                                        name={i?.processor ? `${i?.processor?.firstName} ${i?.processor?.lastName ? i?.processor?.lastName : ''}` : 'N/A'}
                                                        href={
                                                            RolePermission([PermissionList.STAFF_READ.code]) ?
                                                                `/staff/${i?.processor?.id || i?.processor?._id}` : ''
                                                        }
                                                        checkPermission={i?.staff ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                                                        textWidth={8}
                                                    />
                                                </div>
                                            </div>
                                            <div className="table-body-row">
                                                <DateRow date={i?.startDate} />
                                            </div>
                                            <div className="table-body-row">
                                                <DateRow date={i?.endDate} />
                                            </div>
                                            <div className="table-body-row">
                                                <DateRow date={i?.processedDate} />
                                            </div>
                                            <div className="table-body-row">
                                                <PriceRow info={i?.totalPay} textWidth={10} />
                                            </div>
                                            <div className="table-body-row"
                                                 style={{ width: '50px', display: 'flex', justifyContent: 'center' }}>
                                                <img
                                                    className="down-up-icon"
                                                    style={expanded === k ? { transform: 'rotate(-180deg)' } : {}}
                                                    src={Images.dropDown}
                                                    alt="icon"
                                                />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="accordion-details-wrapper">
                                                <div className="accordion-details-head-wrapper">
                                                    <div className="accordion-details-head-row"
                                                         style={{ width: '20%' }}>Timesheet ID
                                                    </div>
                                                    <div className="accordion-details-head-row"
                                                         style={{ width: '20%' }}>Staff name
                                                    </div>
                                                    <div className="accordion-details-head-row"
                                                         style={{ width: '10%' }}>Type
                                                    </div>
                                                    <div className="accordion-details-head-row"
                                                         style={{ width: '15%' }}>CPT Code
                                                    </div>
                                                    <div className="accordion-details-head-row"
                                                         style={{ width: '15%' }}>Total Hours
                                                    </div>
                                                    <div className="accordion-details-head-row"
                                                         style={{ width: '20%' }}>Total Amount
                                                    </div>
                                                </div>
                                                {i?.payrolls?.length ?
                                                    i?.payrolls?.map((item, j) => (
                                                        <div className="accordion-details-body-wrapper" key={j}>
                                                            <div className="accordion-details-body-row"
                                                                 style={{ width: '20%' }}>
                                                                <DisplayIdRow id={item?.displayId} />
                                                            </div>
                                                            <div className="accordion-details-body-row"
                                                                 style={{ width: '20%' }}>
                                                                <div className="staff-name-btn">
                                                                    <LinkRow
                                                                        name={item?.staff ? `${item?.staff?.firstName} ${item?.staff?.lastName ? item?.staff?.lastName : ''}` : 'N/A'}
                                                                        href={
                                                                            RolePermission([PermissionList.STAFF_READ.code]) ?
                                                                                `/staff/${item?.staff?.id || item?.staff?._id}` : ''
                                                                        }
                                                                        checkPermission={item?.staff ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                                                                        textWidth={8}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="accordion-details-body-row"
                                                                 style={{ width: '10%' }}>
                                                                <TypeRow
                                                                    text={item?.payCode?.payCodeTypeId ? item?.payCode?.payCodeTypeId?.type : 'Fixed'} />
                                                            </div>
                                                            <div className="accordion-details-body-row"
                                                                 style={{ width: '15%' }}>
                                                                <TextRow name={item?.cptCode} textWidth={9} />
                                                            </div>
                                                            <div className="accordion-details-body-row"
                                                                 style={{ width: '15%' }}>
                                                                <TextRow
                                                                    name={item?.regularHours === 'NaNhNaNm' ? 'N/A' : item?.regularHours}
                                                                    textWidth={9} />
                                                            </div>
                                                            <div className="accordion-details-body-row"
                                                                 style={{ width: '20%' }}>
                                                                <PriceRow info={item?.totalAmount} textWidth={10} />
                                                            </div>
                                                        </div>
                                                    )) :
                                                    <p className="not-yet">Not Yet</p>
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>
                            ))}
                        </div>
                        :
                        <div style={{position:'relative'}}>
                          <NoItemsYet text={`No processed payroll yet`}/>
                        </div>
                    }
                    {processed?.count > 0 ?
                        <div style={{ height: '76px' }}>
                            <PaginationFillTable
                                listLength={processed?.batches?.length}
                                page={page}
                                handleReturn={(number) => changePage(number)}
                                count={processed?.count}
                            />
                        </div>
                        : ''
                    }
                </div>
            }
        </div>
    );
};