import {
   CLOSE_CLAIM,
   GENERATE_CLAIM,
   GET_CLAIMS,
   GET_CLAIM_BY_ID,
   SUBMIT_CLAIM,
   GET_BILL_INVOICE_PAYMENT,
   GET_BILL_CLAIM_PAYMENT_TRANSACTIONS, GET_SUBMITTED_CLAIMS, SUBMIT_CLAIM_BY_ID,
} from './claim.type';

export const getPendingClaims = (data) => {
   return {
      type: GET_CLAIMS,
      payload: { data },
   };
};

export const getSubmittedClaims = (data) => {
   return {
      type: GET_SUBMITTED_CLAIMS,
      payload: { data },
   };
};

export const getClaimById = (id) => {
   return {
      type: GET_CLAIM_BY_ID,
      payload: { id },
   };
};

export const generateClaim = (group, merge) => {
   return {
      type: GENERATE_CLAIM,
      payload: { group, merge },
   };
};

export const closeClaim = (id, details) => {
   return {
      type: CLOSE_CLAIM,
      payload: { id, details },
   };
};

export const submitClaim = (id, requestParams) => {
   return {
      type: SUBMIT_CLAIM,
      payload: { id, requestParams },
   };
};

export const submitClaimById = (id) => {
   return {
      type: SUBMIT_CLAIM_BY_ID,
      payload: { id },
   };
};

/**Bill Details */

export const bilClaimPmtTr = (id) => {
   return {
      type: GET_BILL_CLAIM_PAYMENT_TRANSACTIONS,
      payload: { id },
   };
};

export const bilInvoicePmt = (id) => {
   return {
      type: GET_BILL_INVOICE_PAYMENT,
      payload: { id },
   };
};
