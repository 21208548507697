import { makeStyles } from '@material-ui/styles';
import { Images, Backgrounds, Shadow, Colors } from 'utils';

export const scheduleCommonStyle = makeStyles(() => ({
    signatureCsvWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        height: '18px',
    },
    linkBtn: {
        cursor: 'pointer',
        border: 'none',
        marginTop: '2px',
        textDecoration: 'none',
        marginLeft: '4px',
    },
    infoWrapper: {
        position: 'relative',
        background: Backgrounds.whiteModal,
        boxShadow: Shadow.blueButton,
        borderRadius: '8px',
        width: '640px',
        minWidth: '640px',
        minHeight: '300px',
        height: 'auto',
        padding: '32px',
        marginTop: '16px',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
        '& p': {
            fontSize: '18px',
            fontWeight: 'bold',
            color: Colors.TextSecondary,
        },
        '& button': {
            border: 'none',
            outline: 'none',
            background: 'transparent',
        },
        '& button:first-of-type': {
            marginRight: '16px',
        },
    },
    recurAndEditBoxStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    recurEdit: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'default',
        '& > p': {
            fontSize: '14px',
            color: Colors.ThemeBlue,
        },
        '& > img': { cursor: 'pointer' },
    },
    recurButnStyle: { marginRight: '0px !important' },
    editButnStyle: {
        marginRight: '0px !important',
        marginLeft: '8px',
    },
    infoDate: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#4B5C68',
    },
    dateAndStatusBoxStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    statusBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px',
    },
    statusMarkStyle: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        marginRight: '8px',
    },
    eventStatusStyle: {
        fontSize: '14px',
        fontWeight: 700,
    },
    itemsWrap: { margin: '32px 0 18px' },
    signatureActionsBoxStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    signatureActionsBoxStyleWrapper: {
        margin: '12px 0 0 0',
        // marginBottom: '40px',

    },
    signatureBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px',
    },
    signatureTextStyle: {
        fontSize: '14px',
        fontWeight: 600,
        color: `${Colors.BackgroundBlue}!important`,
        marginRight: '16px',
    },
    downloadSignatureStyle: {
        fontSize: '12px',
        fontWeight: 600,
        '& img': { width: '18px', height: '18px' },
        // marginTop: "8px",
        marginRight: '9px',
        width: 'auto',
    },
    openModalButnStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        border: 'none',
        outline: 'none',
        backgroundColor: 'inherit',
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundBlue,
        gap: '16px',
        '& img': {
            // content: "''",
            width: '24px',
            height: '24px',
            // backgroundImage: `url(${Images.upload})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "contain",
            // marginLeft: "16px",
        },
    },
    statusActionsBoxStyle: {
        marginTop: '16px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '24px',
    },
    changeStatusButnStyle: {
        padding: '7px 24px',
        background: Colors.ThemeBlue,
        height: '36px',
        width: '240px',
    },
    chancelStatusButnStyle: {
        padding: '7px 0',
        width: '206px',
        background: '#EBF2FD !important',
        height: '36px',
        color: '#4B5C68',
        '& :hover': {
            color: 'black',
        },
    },
    signatureModalWrapperStyle: {
        '& > button': { backgroundColor: Colors.BackgroundWater },
        '& > div:first-of-type': {
            textAlign: 'left',
            '& > p': { marginBottom: '0px' },
        },
        '& > div:last-of-type': { paddingTop: '0px' },
    },
    addAuthFilesButnStyle: { marginTop: '32px' },
    signatureTitle: {
        fontWeight: '700',
        fontSize: '18px',
        color: Colors.TextSecondary,
        margin: '16px 0',
    },
    signatureWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
    },
    signatureCardWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        borderRadius: '8px',
        background: Colors.BackgroundCatskillWhite,
        padding: '8px 16px',
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '16px',
        width: '100%',
        '& p': {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            color: Colors.TextSecondary,
        },
        '& button': {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '19px',
            color: '#A3B2BD',
            marginTop: '4px',
            border: 'none',
            background: 'transparent',
            padding: 0,
            textAlign: 'start',
        },
        '& .showTextStyle': {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '19px',
            color: '#A3B2BD !important',
        },
    },
    imageWrapper: {
        width: '49px',
        height: '38px',
        background: 'rgba(52, 122, 240, 0.1)',
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeInput: {
        display: 'flex',


        '& input[type="time"]::-webkit-calendar-picker-indicator': {
            background: 'none',
            display: 'none',
        },
        '& input': {
            background: 'transparent',
            border: 'none',
            borderBottom: '1px solid #347AF0',
            width: '100%',
            outline: 'none',

            '& svg': {
                display: 'none',
            },
        },
    },
    textStyle: {
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '19px',
        color: '#A3B2BD !important',
        marginTop: '4px',
    },
    uploadOneFileStyle: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.BackgroundBlue,
        cursor: 'pointer',
        marginTop: '42px',
        '&::before': {
            content: '\'\'',
            width: '24px',
            height: '24px',
            backgroundImage: `url(${Images.download})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            marginRight: '8px',
        },
    },
    errorStyle: {
        display: 'flex',
        width: '100%',
        padding: '4px 0 4px 16px',
        minHeight: '22px',
        fontSize: '14px',
        fontWeight: 600,
        color: Colors.ThemeRed,
    },
    fileToShow: {
        background: 'white',
        borderRadius: '8px',
        height: '73px',
        width: '60px',
        boxShadow: '0px 0px 6px rgba(52, 122, 240, 0.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    showType: {
        background: '#F36FA0',
        marginTop: '-16px',
        height: '10px',
        width: '26px',
        position: 'relative',
        zIndex: '2',
        left: 8,
    },
    showImage: {
        position: 'relative',
        zIndex: '1',
    },
    showFileWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '32px',
    },
    showFileName: {
        fontWeight: '600',
        fontSize: '12px',
        color: '#4B5C68',
        marginLeft: '24px',
    },
    imageAndText: {
        display: 'flex',
        alignItems: 'center',
    },
    downloadButton: {
        border: 'none',
        background: 'transparent',
    },
    removeButton: {
        background: 'transparent',
        border: 'none',
        position: 'absolute',
        marginLeft: '16px',
        marginTop: '-9px',
    },
    warning: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '30px',
        background: 'rgba(255, 163, 48, 0.1)',
        borderRadius: '4px',
        padding: '8px',
        width: '100%',
        textAlign: 'start',
        '& img': {
            marginRight: '8px',
        },
        '& p': {
            fontWeight: '600',
            fontSize: '16px',
            color: '#51566D',
            lineHeight: '21px',
        },
    },
    displayId: {
        color: '#4B5C68',
        fontSize: '14px',
        marginBottom: '8px',
    },

    noteDrawerModal: {
        '& .MuiDrawer-paper': {
            maxHeight: '595px',
            right: '0',
            bottom: '0',
            top: 'unset',
            width: '955px',
            borderRadius: '8px',
            border: '1px solid rgba(52, 122, 240, 0.50)',

            '@media (min-width: 1919px)': {
                right: '32px',
                bottom: '24px',
            },
        },
    },
}));
