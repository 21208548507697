import React, { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { FormControl, Input, InputLabel } from '@material-ui/core';


export const MaskInputMain = forwardRef((
    {
        onClickButton,
        name,
        value,
        type = 'text',
        placeholder,
        label,
        disabled,
        classNameWrapper,
        error = '',
        control = {},
        rules,
        mask,
    }, ref,
) => {

    const { field = {}, fieldState = {} } = useController({ name, control, rules });


    return (
        <div className={`input-main-wrapper input-custom-wrapper  ${classNameWrapper ? classNameWrapper : ''}`}>
            <FormControl
                variant='standard'
                error={error || fieldState?.error?.message}
                disabled={disabled}
            >
                {label &&
                    <InputLabel htmlFor={`standard-adornment-${type}-${name}`}>
                        {label}
                    </InputLabel>
                }

                <InputMask
                    mask={mask ? mask : '(999) 999-9999'}
                    {...field}
                >
                    {() => (
                        <Input
                            id={`standard-adornment-${type}-${name}`}
                            inputRef={ref}
                            error={!!fieldState?.error?.message}
                            defaultValue={value}
                            name={name}
                            value={field.value}
                            placeholder={placeholder}

                        />
                    )}
                </InputMask>
                <p className='input-error-text'>{error || fieldState?.error?.message}</p>
            </FormControl>
        </div>

    );
});

