

export const helpCenterCardInfo = [
  {
    title: "Getting Started",
    description: "Discover how to set up your restaurant's digital menu with ease",
    Icon: <div />,
    path: "/help-center/getting-started"

  },

  {
    title: "Control Panel",
    description: "Manage your restaurant operations efficiently with our intuitive control panel",
    Icon: <div />,
    path: "/help-center/control-panel"
  },
];

export const controlPanelMenu = [
  {
    title: "Menu",
    path: "",
    subMenu: [
      {
        title: "Create Your Menu",
        hash: "createMenu"
      },
      {
        title: "Organize with Categories and Items",
        hash: "categories"
      },
      {
        title: "Add Your Menu Items",
        hash: "menuItems"
      },
      {
        title: "Add Items in Menu Page",
        hash: "itemsMenuPage"
      },
    ]
  },
  {
    title: "Menu Items",
    path: "",
    subMenu: [
      {
        title: "Start Adding Your Items",
        hash: "addingYourItem"
      },
      {
        title: "Describe Each Item",
        hash: "describeEachItem"
      },
      {
        title: "Enhance with Tags and Options",
        hash: "tagsAndOptions"
      },
      {
        title: "Visual Appeal with Photos",
        hash: "withPhotos"
      },
    ]
  },

  {
    title: "Modifier Groups",
    path: "",
    subMenu: [
      {
        title: "Getting Started",
        hash: "gettingStarted"
      },
      {
        title: "Give It a Name",
        hash: "giveItAName"
      },
      {
        title: "How Will They Choose?",
        hash: "howWillTheyChoose"
      },
      {
        title: "Filling It With Choices",
        hash: "fillingItWithChoices"
      },
    ]
  },
  {
    title: "Menu Design",
    path: "",
    subMenu: []
  },
  {
    title: "Orders",
    path: "",
    subMenu: [
      {
        title: "Pending Orders",
        hash: "pendingOrders"
      },
      {
        title: "Processed Orders",
        hash: "processedOrders"
      }
    ]
  },

  {
    title: "Transactions",
    path: "",
    subMenu: []
  },

  {
    title: "Notification",
    path: "",
    subMenu: []
  },
  {
    title: "QR",
    path: "",
    subMenu: [
      {
        title: "Create QR Code",
        hash: "createQRCode"
      },
      {
        title: "QR Design Customization",
        hash: "qrDesignCustomization"
      },
      {
        title: "QR Design Preview",
        hash: "qrDesignPreview"
      },
      {
        title: "Set as Default",
        hash: "setAsDefault"
      },
      {
        title: "Download QR",
        hash: "downloadQR"
      }
    ]
  },
  {
    title: "Tables",
    path: "",
    subMenu: [
      {
        title: "Add Tables",
        hash: "addTables"
      },
      {
        title: "Table Names",
        hash: "tableNames"
      },
      {
        title: "Download QR Code",
        hash: "downloadQRCode"
      },
    ]
  },
  {
    title: "Settings",
    path: "",
    subMenu: [
      {
        title: "Restaurant Settings",
        hash: "restaurantSettings"
      },
      {
        title: "Social Accounts",
        hash: "socialAccounts"
      },
    ]
  },

  {
    title: "Billing",
    path: "",
    subMenu: [
      {
        title: "Plans and Pricing",
        hash: "plansAndPricing"
      },
      {
        title: "Payments",
        hash: "payments"
      },
    ]
  },
];



export const gettingStartedMenu = [
  {
    title: "Getting Started",
    hash: "gettingStarted",
  },

  {
    title: "Registration Process",
    hash: "registrationProcess",
  },

  {
    title: "Completing Registration",
    hash: "completingRegistration",
  },

  {
    title: "Subscription Selection",
    hash: "subscriptionSelection",
  },

  {
    title: "Personalizing Your Profile",
    hash: "personalizingYourProfile",
  },

  {
    title: "Adding Your Menu",
    hash: "addingYourMenu",
  },

  {
    title: "Adding categories",
    hash: "addingCategories",
  },

  {
    title: "Menu Item Management",
    hash: "menuItemManagement",
  },

  {
    title: "Generating QR Codes",
    hash: "generatingQrCodes",
  },

  {
    title: "Downloading QR Codes",
    hash: "downloadingQrCodes",
  },
];







