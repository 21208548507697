import React from 'react';
import { motion } from 'framer-motion';
import { LandingSvg } from 'assets';
import { animationParams, animationVariants } from 'fragments';

export const CustomerCard = (
    {
        className,
        customerAvatar,
        customerComment,
        customerName,
        customerProfession,
        rating,
        styles,
    }) => {

    return (
        <motion.div
            className={`customer-card-wrapper ${className ? className : ''}`}
            {...animationParams}
            variants={animationVariants.cardsVariant}
            style={styles}
        >
            <div className='stars-block'>
                {rating && new Array(rating).fill(0).map((_, i) => (
                    <LandingSvg.StarOrangeSvg
                        className='stars-block-svg'
                        key={i}
                    />
                ))}
            </div>
            <p className='customer-card-text'>
                {customerComment}
            </p>

            <div className='customer-info'>
                <div className='customer-info-img'>
                    <img src={customerAvatar} alt='Customer Img' />
                </div>

                <div className='name-position'>
                    <p className='customer-name'>
                        -{customerName}-
                    </p>

                    <p className='customer-position'>
                        {customerProfession}
                    </p>
                </div>

            </div>
        </motion.div>
    );
};

