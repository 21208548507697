import {GET_SIGNATURE_REPORTS_SUCCESS} from "./reports.types";

const initialState = {
    signatureReports: null
};

export const reportsReducer =  (state = initialState, action) => {
    switch (action.type) {

        /** Signature Reports */

        case  GET_SIGNATURE_REPORTS_SUCCESS:
            return {
                ...state,
                signatureReports: action?.payload
            };

        /** End */

        default:
            return state;
    }
};
