import { useRef } from 'react';
import { cardStyle } from './style';
import Box from '@material-ui/core/Box';
import { getTitleDisplay, getValueDisplay } from './constants';
import { makeCapitalize, useWidth } from 'utils';
import { HtmlTooltip } from '../tabs/fullTableCore/htmlTool';

export const CardItem = ({
                             title,
                             value,
                             auth,
                             authId,
                             click,
                             index,
                             active,
                             employment,
                             isBig,
                             handleShowHistory,
                             maxWidth,
                             item,
                         }) => {
    const classes = cardStyle();
    const width = useWidth();
    const titleDisplay = getTitleDisplay(title, value, width, isBig);
    const valueDisplay = getValueDisplay(value, title, width, isBig);
    const currentText = useRef();

    const RenderText = (width, text, textWidth) => {
        if (text) {
            const count = Math.ceil(width / (textWidth ? textWidth : 11));
            const slicedText = '' + text?.slice(0, count);
            return <div className='text-style'>
                {text?.length > count ?
                    <HtmlTooltip
                        title={<p>{text}</p>}
                        placement='top-start'
                    >
                        <p style={{ textOverflow: 'ellipsis' }}>
                            {`${slicedText}...`}
                        </p>
                    </HtmlTooltip>
                    :
                    <p>{text}</p>
                }
            </div>;
        } else {
            return '...';
        }
    };

    return (
        <>
            {auth ? (
                <Box
                    onClick={() => click(index)}
                    className={classes.cardItem}
                    style={active === index ? { background: '#347AF0', cursor: 'pointer' } : { cursor: 'pointer' }}
                >
                    <p ref={currentText}
                       style={active === index ? { color: 'white', width: '100%' } : { width: '100%' }}>
                        {employment ? makeCapitalize(title?.name) :
                            RenderText(currentText?.current?.offsetWidth, `#${authId}`)
                        }
                    </p>
                </Box>
            ) : (
                <Box className={classes.cardItem}>
                    {item?.type === 'multiple' ?
                        <div>
                            {item?.list.map((i, j) => (
                                <div key={j} className={classes.cardItemContainerStyle}>
                                    <p style={{ whiteSpace: 'nowrap' }} className={classes.titleStyle}>
                                        {i?.title}:
                                    </p>
                                    <span
                                        style={{ width: '100%' }}
                                        className={classes.cardValue}
                                    >
                                        {i?.value}
                                    </span>
                                </div>
                            ))}
                        </div>
                        :
                        <div className={classes.cardItemContainerStyle}>
                            <p style={{ whiteSpace: 'nowrap' }}>
                                {titleDisplay}
                            </p>

                            <span
                                style={{ width: '100%' }}
                                onClick={() => value === 'view history' ? handleShowHistory() : {}}
                                className={
                                    value === 'view history' ? classes.blueText :
                                        typeof value === 'object' ? '' :
                                            title === 'Status' ? '' :
                                                classes.cardValue}
                            >
                            {
                                typeof value === 'object' ? valueDisplay :
                                    title === 'Status' ? valueDisplay :
                                        value === 'view history' ? 'view history' :
                                            value
                            }
                        </span>
                        </div>
                    }
                </Box>
            )}
        </>
    );
};
