import {DateRow, PriceRow, TextRow, UnitsRow} from "components";
import moment from "moment";
import React from "react";
import {convertHours} from "../../../../../utils";

export const headList = [
    {name: 'Staff', width: '10%',},
    {name: 'Service Date', width: '9%', slice: true, },
    {name: 'Service Code', width: '10%', slice: true},
    {name: 'Start Time', width: '10%', slice: true,},
    {name: 'End Time', width: '10%', slice: true,},
    {name: 'Hours', width: '7%',},
    {name: 'Total Amount', width: '11%', slice: true},
    {name: 'Copay', width: '10%',},
    {name: 'Prior Paid', width: '10%', slice: true},
    {name: 'Current Balance', width: '11%', slice: true},
]

export const bodyList = [
    {type: 'staff', width: '10%',},
    {type: 'serviceDate', width: '9%',},
    {type: 'serviceCode', width: '10%',},
    {type: 'startTime', width: '10%',},
    {type: 'endTime', width: '10%',},
    {type: 'hours', width: '7%',},
    {type: 'totalAmount', width: '11%',},
    {type: 'copay', width: '10%',},
    {type: 'priorPaid', width: '10%',},
    {type: 'currentBalance', width: '11%',},
]

export const renderBodyRow = (item, body, currentWidth) => {
    switch (body?.type) {
        case 'staff':
            return <TextRow name={`${item?.staff?.firstName} ${item?.staff?.lastName}`} textWidth={10} width={currentWidth}/>

        case 'serviceDate':
            return <DateRow date={item?.dateOfService}/>

        case 'serviceCode':
            return <TextRow name={item?.cptCode} textWidth={10} width={currentWidth}/>

        case 'startTime':
            return moment(item?.dateOfService?.start).format("LT")

        case 'endTime':
            return moment(item?.dateOfService?.end).format("LT")

        case 'hours':
            return convertHours(item?.hours ? item?.hours : 0)

        case 'totalAmount':
            return <PriceRow info={item?.billedAmount} textWidth={10} width={currentWidth}/>

        case 'copay':
            return <PriceRow info={item?.clientTotal} textWidth={10} width={currentWidth}/>

        case 'priorPaid':
            return <PriceRow info={item?.clientPaid} textWidth={10} width={currentWidth}/>

        case 'currentBalance':
            return <PriceRow info={item?.clientBalance} textWidth={10} width={currentWidth}/>

        default:
            return '...'
    }
}