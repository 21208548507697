import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PayrollSetupStyles } from './styles';
import { CustomDelete, FullTable } from 'components';
import { PermissionList, RolePermission, SaveParams, useModal, useWidth } from 'utils';
import { adminActions, payrollActions } from 'store';
import {
    MILEAGE_ACTION, mileageBody, mileageHead,
    OVERTIME_ACTION,
    overtimeBody,
    overtimeHead,
    PAYCODE_ACTION,
    paycodeBody,
    paycodeHead, TERMINATE_MILEAGE,
} from './constants';
import { OvertimeSettings, PayCodeType, MileageCompensation } from './modals';

export const PayrollSetup = ({ globalOvertimeSettings, globalPayCodes }) => {
    const { close, open } = useModal();
    const classes = PayrollSetupStyles();
    const mileages = useSelector((state) => state.payroll.mileages);
    const [activeStep, setActiveStep] = useState(
        RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) ? 0 :
            RolePermission([PermissionList.SYSTEM_OVERTIME?.code]) ? 1 :
                RolePermission([PermissionList.SYSTEM_MILEAGE?.code]) ? 2 :
                    null);
    const width = useWidth();
    const cardMaxWidth = width <= 1440 ? '440px' : '508px';
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        const params = {
            ...info,
        };
        delete params.activeTab;
        if (RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) && activeStep === 0) {
            dispatch(payrollActions.getPayCodeGlobal(params));
        }
        if (RolePermission([PermissionList.SYSTEM_OVERTIME?.code]) && activeStep === 1) {
            dispatch(payrollActions.getOvertimeSettingsGlobal(params));
        }
        if (RolePermission([PermissionList.SYSTEM_MILEAGE?.code]) && activeStep === 2) {
            dispatch(payrollActions.getMileages());
        }
    }, [info, activeStep]);

    const handleOpen = (item, name) => {
        if (name === 'inactive') {
            open(<CustomDelete
                info="Terminate mileage"
                text="Are you sure you want to terminate this mileage?"
                innerText="Inactivate"
                handleDel={() => dispatch(payrollActions.terminateMileage(item?.id))}
                actionType={TERMINATE_MILEAGE}
            />);
        } else {
            open(
                <div>
                    <p style={{ marginBottom: '32px' }} className="modal-header-title">Edit Mileage Compensation</p>
                    <MileageCompensation
                        type={'modal'}
                        maxWidth="480px"
                        editedData={item}
                        handleClose={close}
                    />
                </div>,
            );
        }
    };

    const showActiveStep = (activeStep) => {
        if (activeStep === 0) {
            return (
                <div className={classes.wrapper}>
                    <PayCodeType
                        marginTop={'9px'}
                        marginRight="16px"
                        maxWidth={cardMaxWidth}
                    />
                    <FullTable
                        margin={'0'}
                        head={paycodeHead}
                        body={paycodeBody}
                        loadingType={PAYCODE_ACTION}
                        list={globalPayCodes?.length ? globalPayCodes : []}
                        noText={'paycodes'}
                        handleClickButton={(i) => open(
                            <div>
                                <p style={{ marginBottom: '32px' }} className="modal-header-title">Edit Paycode Type</p>
                                <PayCodeType
                                    minWidth="480px"
                                    editedData={i}
                                    handleClose={close} />
                            </div>,
                        )}
                    />
                </div>
            )
                ;
        } else if (activeStep === 1) {
            return (
                <div className={classes.wrapper}>
                    <OvertimeSettings
                        marginTop={'9px'}
                        marginRight="16px"
                        maxWidth={cardMaxWidth}
                    />
                    <FullTable
                        margin={'0'}
                        head={overtimeHead}
                        body={overtimeBody}
                        loadingType={OVERTIME_ACTION}
                        list={globalOvertimeSettings?.length ? globalOvertimeSettings : []}
                        noText={'overtime settings'}
                        handleClickButton={(i) => open(
                            <div>
                                <p style={{ marginBottom: '32px' }} className="modal-header-title">Edit Overtime Setting</p>
                                <OvertimeSettings
                                    maxWidth="480px"
                                    editedData={i}
                                    handleClose={close}
                                />
                            </div>,
                        )}
                    />
                </div>
            );
        } else if (activeStep === 2) {
            return (
                <div className={classes.wrapper}>
                    <MileageCompensation
                        marginTop={'9px'}
                        marginRight="16px"
                        maxWidth={cardMaxWidth}
                    />
                    <FullTable
                        margin={'0'}
                        head={mileageHead}
                        body={mileageBody(handleOpen)}
                        loadingType={MILEAGE_ACTION}
                        list={mileages?.length ? mileages : []}
                        noText={'mileage compensations'}
                    />
                </div>
            );
        }
    };

    const changeActiveStep = (stepNumber) => {
        const params = {
            ...info,
        };
        delete params.type;
        SaveParams(history, params);
        setActiveStep(stepNumber);
    };

    const tabNavigation = () => {
        return (
            <>
                {RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) &&
                    <p
                        className={
                            activeStep === 0
                                ? [`${classes.activeStepText} ${classes.stepText}`]
                                : classes.stepText
                        }
                        onClick={() => changeActiveStep(0)}
                    >
                        PayCode Types
                    </p>
                }

                {RolePermission([PermissionList.SYSTEM_OVERTIME?.code]) &&
                    <p
                        className={
                            activeStep === 1
                                ? [`${classes.activeStepText} ${classes.stepText}`]
                                : classes.stepText
                        }
                        onClick={() => changeActiveStep(1)}
                    >
                        Overtime Settings
                    </p>
                }
                {
                    RolePermission([PermissionList.SYSTEM_MILEAGE?.code]) &&
                    <p
                        className={
                            activeStep === 2
                                ? [`${classes.activeStepText} ${classes.stepText}`]
                                : classes.stepText
                        }
                        onClick={() => changeActiveStep(2)}
                    >
                        Mileage Compensation
                    </p>
                }
            </>
        );
    };

    return (
        <>
            <div className={classes.tabContainer}>{tabNavigation()}</div>
            <div className={classes.payrollSetupWrapper}>{showActiveStep(activeStep)}</div>
        </>
    );
};
