import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FullTable } from 'components';
import { systemActions } from 'store';
import { useModal } from 'utils';
import { systemItemStyles } from './styles';
import { CreateEditServiceType } from './common/createEditServiceType';
import { systemTypeBody, systemTypeHead, ACTION_TYPE } from './constants';

export const ServiceType = ({}) => {
    const classes = systemItemStyles();
    const globalServices = useSelector((state) => state.system.services);
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const { open } = useModal();

    const getServices = (noLoad) => {
        const loading = noLoad ? 'noLoad' : ''
        const sendInfo = {
            ...pushInfo,
        };
        delete sendInfo.page;
        delete sendInfo.activeTab;
        dispatch(systemActions.getServices( sendInfo, loading));
    }

    useEffect(() => {
        getServices()
    }, [pushInfo]);

    const handleClickButton = (info) => {
        open(
            <div style={{ width: 400 }}>
                <p className="modal-header-title" style={{ marginBottom: '32px' }}>Edit Service Type</p>
                <CreateEditServiceType info={info} afterSuccess={() => getServices(true)} />
            </div>,
        );
    };

    return (
        <Fragment>
            <CreateEditServiceType afterSuccess={() => getServices(true)} />
            <p className={classes.title}>Service Type</p>
            <FullTable
                head={systemTypeHead}
                body={systemTypeBody}
                loadingType={ACTION_TYPE}
                list={globalServices?.serviceTypes}
                handleClickButton={handleClickButton}
                noText={'service type'}
            />
        </Fragment>
    );
};