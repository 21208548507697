import {
    getPendingClaims,
    getClaimById,
    generateClaim,
    closeClaim,
    submitClaim,
    bilClaimPmtTr,
    bilInvoicePmt, getSubmittedClaims, submitClaimById,
} from './claim.action';

export {claimReducer} from "./claim.reducer";
export {watchClaim} from "./claim.saga";

export const claimActions = {
    getPendingClaims,
    getSubmittedClaims,
    getClaimById,
    generateClaim,
    closeClaim,
    submitClaim,
    submitClaimById,
    /**Bill Details */
    bilClaimPmtTr,
    bilInvoicePmt,
};