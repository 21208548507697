import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FindLoad } from "utils";
import { CustomBreadcrumbs, Loader } from "components";
import { invoiceActions } from "store";
import { InvoiceDetailsFragment } from "fragments";

export const InvoiceDetails = () => {
   const params = useParams();
   const dispatch = useDispatch();
   const invoiceById = useSelector((state) => state.invoice.invoiceById);
   const loader = FindLoad("GET_INVOICE_BY_ID");

   useEffect(() => {
      dispatch(invoiceActions.getInvoiceById(params.id));
      return () => {
         dispatch({
            type: "GET_INVOICE_BY_ID_SUCCESS",
            payload: { invoiceById: null },
         });
      };
   }, [params.id]);

   if (!!loader.length) return <Loader />;

   return (
      <>
         <InvoiceDetailsFragment invoiceDetails={invoiceById} />
      </>
   );
};
