import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generateClaimStyle } from './styles';
import { CreateChancel, CustomizedSwitch, FullTable, SimpleModal, SimpleTooltip } from 'components';
import { FindLoad, FindSuccessItem, Images, PermissionList, RolePermission } from 'utils';
import { adminActions, claimActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { generateClaimBody, generateClaimHead, ACTION_TYPE } from './constants';
import { GenerateClaimFilters } from './generateClaimFilters';
import { Service } from '../../../schedule/core/modals/service';
import { scheduleModalsStyle } from '../../../schedule/core/modals/styles';

const EIT_ACTION ='EDIT_APPOINTMENT'
export const GenerateClaimFragment = ({}) => {
    const { appointments } = useSelector((state) => ({
        appointments: state.appointment.appointments,
    }));
    const classes = generateClaimStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const generateClaimLoader = FindLoad('GENERATE_CLAIM');
    const [merge, setMerge] = useState(true);
    const modalClasses = scheduleModalsStyle();
    const success = FindSuccessItem(EIT_ACTION);
    const [selectedList, setSelectedList] = useState([]);
    const [item, setItem] = useState(null);

    useEffect(() => {
        if(success){
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EIT_ACTION))
            setItem(null)
        }
    }, [success]);

    const handleCloseModal = () => {
        setItem(null);
    }
    const handleCheck = (ev, item, name) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (name === 'all') {
            let allList = appointments?.appts?.length === selectedList?.length ? [] : appointments?.appts?.map((i) => i?.id);
            setSelectedList(allList);
        } else {
            const list = [...selectedList];
            const uniqSet = new Set(list);

            if (!uniqSet.has(item?.id)) {
                list.push(item?.id);
            } else {
                list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
            }
            setSelectedList(list);
        }
    };
    const handleEdit = (item) => {
        setItem(item);
        dispatch(adminActions.getAllPayCodes(item.staff?._id));
        dispatch(systemActions.getPlaces());
    }

    return (
        <div className={classes.generateClaimContainerStyle}>
            <div className={classes.generateClaimActionsStyle}>
                <GenerateClaimFilters />
                <div className={classes.mergeBillsBoxStyle}>
                    <p className={classes.mergeBillsTitleStyle}>Merge Appointments</p>
                    <CustomizedSwitch
                        checked={merge}
                        handleClick={() => setMerge((prevState) => !prevState)}
                    />
                    <div className={classes.infoIcon}>
                        <SimpleTooltip
                            title={<p>
                                {'In order for 2 or more appointments to be used in the same bill, the appointments must be from the same Funding Source, have the same client, date, CPT code and modifier.'}
                            </p>
                            }
                            placement="top-end"
                        >
                            <img src={Images.infoIcon} alt={'icon'} />
                        </SimpleTooltip>
                    </div>
                </div>
            </div>

            <FullTable
                head={generateClaimHead(handleCheck, appointments?.appts, selectedList)}
                body={generateClaimBody(handleCheck, appointments?.appts, selectedList, handleEdit)}
                loadingType={ACTION_TYPE}
                list={appointments?.appts}
                listCount={appointments?.count}
                noText={'appointments'}
                generateTable={true}
            />

            {!!appointments?.appts?.length &&
                <div className={classes.notClaimedBillsFooterStyle}>
                    <div />
                    {RolePermission([PermissionList.CLAIMS_CREATE?.code]) &&
                        <CreateChancel
                            classes={classes.generateOrCancelButnStyle}
                            loader={!!generateClaimLoader.length}
                            create={'Generate Claims'}
                            chancel={'Cancel'}
                            onCreate={() =>
                                dispatch(claimActions.generateClaim({ appts: selectedList }, merge),)
                            }
                            onClose={() => history.push('/claims')}
                            disabled={!selectedList.length}
                        />
                    }
                </div>
            }

            <SimpleModal
                handleOpenClose={handleCloseModal}
                openDefault={!!item}
                content={
                    <div className={modalClasses.bigModal}>
                        <Service
                            modalDate={item}
                            date={{
                                startTime: item?.startTime?.slice(11, 16),
                                endTime: item?.endTime?.slice(11, 16),
                            }}
                            handleOpenClose={handleCloseModal}
                        />
                    </div>
                }
                secondary={true}
            />
        </div>
    );
};
