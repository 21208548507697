import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    CreateChancel, CustomNumericFormat,
    ErrMessage,
    SelectTypeAutocomplete,
    DateInput,TimeInput
} from 'components';
import {
    ErrorText,
    FindLoad,
    Images,
    isNotEmpty,
    TimeIsGreat,
    FindErrorItem,
    UrlRegex,
    PermissionList,
} from 'utils';
import { scheduleModalsStyle } from './styles';
import { adminActions, appointmentActions, httpRequestsOnErrorsActions } from 'store';
import { renderTimes } from './constants';
import { DRIVE, scheduleStatuses, UNPAID } from '../../constants';
import { ModalHeader } from './serviceAppt/modalHeader';
import { Recur } from './recur';
import { ScheduleLinks } from './scheduleLinks';
import { RolePermissionNoAdmin } from '../../../../utils/hooks/rolePermissions';

const GET_ALL_PAY_CODES = 'GET_ALL_PAY_CODES';

export const Break = ({ handleOpenClose, type, date, modalDate, day }) => {
    const { adminsList, allPaycodes, userInfo } = useSelector((state) => ({
        adminsList: state.admins.adminsList,
        allPaycodes: state.admins.allPaycodes,
        userInfo: state.auth.userInfo,
    }));
    const editActionType = modalDate ? 'EDIT_APPOINTMENT' : 'CREATE_APPOINTMENT';
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch();
    let history = useHistory();
    const info = history?.location?.state;
    const createModalDate = history?.location?.state;
    const [inputs, setInputs] = useState(
        day ? { ...day, ...createModalDate } :
            createModalDate ? { ...createModalDate } :
                {},
    );
    const [times, setTimes] = useState(date ? { ...date } : {});
    const [error, setError] = useState({});
    const backError = FindErrorItem(editActionType);
    const appmtIsOverlapping = backError?.error === 'appointment overlapping';
    const loader = FindLoad(editActionType);
    const apptBackError = FindErrorItem('APPOINTMENT_OVERLAPPING');
    const [step, setStep] = useState('first');
    const [createMultiple, setCreateMultiple] = useState(false);
    const [links, setLinks] = useState();

    useEffect(() => {
        if (error) {
            setError('');
            dispatch(httpRequestsOnErrorsActions.removeError(editActionType));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, []);

    useEffect(() => {
        if (modalDate) {
            const params = {
                type: modalDate?.type,
                staff: modalDate?.staff?._id,
                staffPayCode: modalDate?.staffPayCode?._id || modalDate?.staffPayCodeId,
                startDate: modalDate?.startDate,
            };

            if (modalDate?.miles) {
                params.miles = modalDate.miles;
            }
            setInputs(params);
            if (modalDate?.links?.length) {
                setLinks(modalDate?.links);
            }
        }
    }, [modalDate]);

    const apptInfo = () => {
        const startDate = moment.utc(inputs?.startDate);
        startDate.set({
            hour: times?.startTime?.slice(0, 2),
            minute: times?.startTime?.slice(3, 5),
            second: '00',
        });
        const endDate = moment.utc(inputs?.startDate);
        endDate.set({
            hour: times?.endTime?.slice(0, 2),
            minute: times?.endTime?.slice(3, 5),
            second: '00',
        });

        const apptDate = {
            type: type,
            startDate: inputs?.startDate && moment.utc(inputs?.startDate).format('YYYY-MM-DD'),
            startTime: startDate.format(),
            endTime: endDate.format(),
            staff: inputs?.staff,
            staffPayCode: inputs?.staffPayCode,
            editTemplate: createMultiple,
        };
        if (type === DRIVE) {
            apptDate.miles = +inputs.miles;
        }
        if (type === UNPAID) {
            delete apptDate.staffPayCode;
        }

        if (links?.length) {
            const newList = links.filter((i) => i?.title && i?.url);
            const list = newList?.map((i) => {
                if (!i?.url.startsWith('http://') && !i?.url.startsWith('https://')) {
                    return { title: i?.title, url: `https://${i?.url}` };
                } else {
                    return { title: i?.title, url: i?.url };
                }
            });
            list?.length ? apptDate.links = list : delete apptDate.links;
        } else {
            delete apptDate.links;
        }

        return apptDate;
    };

    useEffect(() => {
        if (!!appmtIsOverlapping) {
            setError(ErrorText.overlappingError('Appointments'));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, [appmtIsOverlapping]);

    useEffect(() => {
        if (createModalDate && createModalDate?.staff) {
            dispatch(adminActions.getAllPayCodes(createModalDate.staff));
        }
    }, [createModalDate]);

    useEffect(() => {
        if (info?.staff) {
            dispatch(adminActions.getAllPayCodes(info?.staff));
        }
    }, [info]);

    useEffect(() => {
        return () => dispatch(adminActions.clearAllPayCodes());
    }, []);

    const handleCloseModal = () => {
        handleOpenClose && handleOpenClose();
        setInputs('');
        setTimes('');
    };

    // const changeStartDate = (value) => {
    //     setInputs((prevState) => ({ ...prevState, ['startDate']: value }));
    // };

    const handleChange = (e) => {
        error === e.target.name && setError('');

        if (e.target.name === 'staff') {
            dispatch(adminActions.clearAllPayCodes());
            dispatch(adminActions.getAllPayCodes(e.target.value));
            const newParams = {
                ...inputs,
                staff: e.target.value,
            };
            delete newParams.staffPayCode;
            setInputs(newParams);
        } else {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }

        if (error === e.target.name || error === ErrorText.timeError || error === ErrorText.overlappingError('Appointments')) {
            setError('');
        }
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };

    const handleChangeMiles = (value) => {
        setInputs((prevState) => ({ ...prevState, ['miles']: value?.value }));
        if (error === 'miles') setError('');

    };

    const handleChangeDate = (e) => {
        if (apptBackError?.error === 'appointment overlapping') {
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
        setTimes((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        (e.target.name === error || error === ErrorText.timeError) && setError('');
    };

    const handleCreate = () => {
        const timeComparingIsValid = createMultiple ? true : !!times.startTime && !!times.endTime && TimeIsGreat(times.startTime, times.endTime);
        const checkStartDate = createMultiple ? true : !!inputs.startDate;
        const staffPayCode = type === UNPAID ? true : isNotEmpty(inputs?.staffPayCode);
        const dataIsValid = isNotEmpty(inputs.staff) && staffPayCode && checkStartDate && timeComparingIsValid;
        const modalBool = type === DRIVE ? dataIsValid && isNotEmpty(inputs.miles) : dataIsValid;
        const date = apptInfo();
        const linksChecker = links?.length ? links?.find((i) => UrlRegex.test(i?.url)) : true;
        const emptyLink = links?.length ? links?.find((i) => i?.title !== '') : true;

        if (modalBool && linksChecker && emptyLink) {
            if (modalDate) {

                const checkActivePayCode = allPaycodes?.find((i) => i?.id === inputs?.staffPayCode);

                if (type === UNPAID ? true : (checkActivePayCode && !checkActivePayCode?.terminationDate)) {

                    if (createMultiple) {
                        setStep('second');
                    } else {
                        dispatch(appointmentActions.editAppointment(date, modalDate?.id));
                    }
                } else {
                    setError('staffPayCode');
                }
            } else {
                if (createMultiple) {
                    setStep('second');
                } else {
                    dispatch(appointmentActions.createAppointment(date));
                }
            }
        } else {
            const errorText =
                createMultiple ?
                    (!inputs.staff ? 'staff' :
                            !inputs.staffPayCode ? 'staffPayCode' :
                                !linksChecker ? 'notValidLinks' :
                                    !emptyLink ? 'emptyTitle' :
                                        null
                    )
                    :
                    (!inputs.staff ? 'staff' :
                        !inputs.staffPayCode ? 'staffPayCode' :
                            !inputs.startDate ? 'startDate' :
                                !times.startTime ? 'startTime' :
                                    !times.endTime ? 'endTime' :
                                        !timeComparingIsValid ? ErrorText.timeError :
                                            !linksChecker ? 'notValidLinks' :
                                                !emptyLink ? 'emptyTitle' :
                                                    null);

            if (type === DRIVE) {
                setError(errorText ? errorText : !inputs.miles ? 'miles' : '');
            } else if (type === UNPAID) {
                setError(
                    createMultiple ? (!inputs.staff ? 'staff' : null)
                        :
                        !inputs.staff ? 'staff' :
                            !inputs.startDate ? 'startDate' :
                                !times.startTime ? 'startTime' :
                                    !times.endTime ? 'endTime' :
                                        !timeComparingIsValid ? ErrorText.timeError :
                                            !linksChecker ? 'notValidLinks' :
                                                !emptyLink ? 'emptyTitle' :
                                                    '');
            } else {
                setError(errorText);
            }
        }
    };
    const setMultipleAppts = () => {
        if (createMultiple) {
            setStep('first');
        }
        setCreateMultiple(!createMultiple);
    };

    return (
        <>
            <ModalHeader
                modalDate={modalDate}
                multiple={createMultiple}
                step={step}
                setStep={setStep}
                type={type}
                createMultiple={createMultiple}
                setMultipleAppts={setMultipleAppts}
            />
            {step === 'first' ?
                <div className={classes.breakWrapper}>
                    <SelectTypeAutocomplete
                        title={'Staff Member*'}
                        name={'staff'}
                        handleSelect={handleChange}
                        defaultValue={inputs.staff}
                        list={
                            RolePermissionNoAdmin([PermissionList.APPT_SELF_CREATE?.code]) ?
                                [{
                                    id: userInfo?.id,
                                    firstName: userInfo?.firstName,
                                    lastName: userInfo?.lastName,
                                }]
                                :
                                adminsList?.staffs ? adminsList?.staffs : []}
                        error={error}
                        typeError={error === 'staff' ? `Staff member ${ErrorText.isRequired}` : ''}
                        renderValue={(i) => `${i?.firstName} ${i?.lastName ? i?.lastName : null}`}
                    />
                    {type !== UNPAID &&
                        <SelectTypeAutocomplete
                            disabled={!inputs?.staff}
                            loadType={GET_ALL_PAY_CODES}
                            title={'Staff Paycode*'}
                            name={'staffPayCode'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.staffPayCode}
                            list={allPaycodes?.filter((data) => !data?.terminationDate) || []}
                            error={error}
                            typeError={error === 'staffPayCode' ? `Staff paycode ${ErrorText.isRequired}` : ''}
                            renderValue={(i) => i?.payCodeTypeId?.name}
                        />
                    }

                    {!createMultiple &&
                        <>
                            <DateInput
                                name={'startDate'}
                                label={'Start Date*'}
                                handleChange={handleChange}
                                value={inputs?.startDate ? moment.utc(inputs.startDate).format('MM/DD/YYYY') : inputs.startDate}
                                typeError={error === 'startDate' && `Start date ${ErrorText.isRequired}`}
                            />
                            {/*<ValidationInput*/}
                            {/*    variant={'outlined'}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    value={*/}
                            {/*        inputs?.startDate*/}
                            {/*            ? moment.utc(inputs.startDate).format('YYYY-MM-DD')*/}
                            {/*            : inputs.startDate*/}
                            {/*    }*/}
                            {/*    type={'date'}*/}
                            {/*    label={'Start Date*'}*/}
                            {/*    name="startDate"*/}
                            {/*    typeError={error === 'startDate' && `Start date ${ErrorText.isRequired}`}*/}
                            {/*/>*/}
                            <div className={classes.timeInputs} style={{gap:'16px'}}>
                                <TimeInput
                                    label={'Start Time*'}
                                    name={'startTime'}
                                    onChange={handleChangeDate}
                                    typeError={
                                        error === 'startTime' ? `Start time ${ErrorText.isRequired}` :
                                            backError?.error === 'appointment overlapping' ? ErrorText.overlappingError('Appointments')
                                                : ''
                                    }
                                    defaultValue={modalDate ?
                                        times?.startTime ? renderTimes(times.startTime) || times.startTime : ''
                                        : null}
                                />
                                <TimeInput
                                    label={'End Time*'}
                                    name={'endTime'}
                                    onChange={handleChangeDate}
                                    typeError={
                                        error === 'endTime' ? `End time ${ErrorText.isRequired}` :
                                            error === ErrorText.timeError ? ErrorText.timeError
                                                : ''
                                    }
                                    defaultValue={modalDate ?
                                        times?.endTime ? renderTimes(times.endTime) || times.endTime : ''
                                        : null
                                    }
                                />

                                {/*<ValidationInput*/}
                                {/*    variant={'outlined'}*/}
                                {/*    onChange={handleChangeDate}*/}
                                {/*    value={times?.startTime ? renderTimes(times.startTime) || times.startTime : ''}*/}
                                {/*    type={'time'}*/}
                                {/*    label={'Start Time*'}*/}
                                {/*    name="startTime"*/}
                                {/*    style={classes.startTime}*/}
                                {/*    typeError={*/}
                                {/*        error === 'startTime' ? `Start time ${ErrorText.isRequired}` :*/}
                                {/*            backError?.error === 'appointment overlapping' ? ErrorText.overlappingError('Appointments')*/}
                                {/*                : ''*/}
                                {/*    }*/}
                                {/*/>*/}
                                {/*<ValidationInput*/}
                                {/*    variant={'outlined'}*/}
                                {/*    onChange={handleChangeDate}*/}
                                {/*    value={times?.endTime ? renderTimes(times.endTime) || times.endTime : ''}*/}
                                {/*    type={'time'}*/}
                                {/*    label={'End Time*'}*/}
                                {/*    name="endTime"*/}
                                {/*    disabled={!times?.startTime}*/}
                                {/*    typeError={*/}
                                {/*        error === 'endTime' ? `End time ${ErrorText.isRequired}` :*/}
                                {/*            error === ErrorText.timeError ? ErrorText.timeError*/}
                                {/*                : ''*/}
                                {/*    }*/}
                                {/*/>*/}
                            </div>
                        </>
                    }
                    {type === DRIVE && (
                        <CustomNumericFormat
                            name={'miles'}
                            label={'Miles*'}
                            value={inputs?.miles}
                            error={error === 'miles' ? `Miles ${ErrorText.isRequired}` : ''}
                            handleChangeMiles={handleChangeMiles}
                            thousandSeparator={','}
                            errorName={error}
                        />
                    )}

                    <ScheduleLinks
                        changeLinks={setLinks}
                        item={modalDate}
                        linkError={error}
                        setLinkError={setError}
                    />

                    {backError?.error === 'TB credential expired' &&
                        <ErrMessage
                            styles={{ margin: '0 0 16px' }}
                            text={
                                backError?.error === 'TB credential expired' ? 'TB credential expired' :
                                    ''} />
                    }

                    {modalDate?.status === scheduleStatuses?.RENDER &&
                        <div
                            style={{ margin: '0 0 24px' }}
                            className={classes.warning}
                        >
                            <img src={Images.warning} alt="icon" />
                            <p>Warning! Since this appointment is already complete, processed timesheets will not be
                                updated</p>
                        </div>
                    }

                    <div>
                        <CreateChancel
                            loader={!!loader.length}
                            create={
                                createMultiple ? 'Continue' :
                                    modalDate ? 'Save' : 'Add'
                            }
                            chancel={'Cancel'}
                            onCreate={handleCreate}
                            onClose={handleCloseModal}
                            buttonWidth="300px"
                        />
                    </div>
                </div>
                :
                <div>
                    <Recur
                        setStep={() => setStep('first')}
                        date={apptInfo()}
                        modalDate={modalDate}
                        multiple={createMultiple}
                        handleClose={handleOpenClose}
                    />
                </div>
            }
        </>
    );
};
