import { DateRow, DisplayIdRow, IdRow, PriceRow, TextRow, UnitsRow } from 'components';
import React from "react";

export const claimPaymentTableHead = [
    {name: 'id', title: 'ID', custom: false, noSearch:true, width: '100px'},
    {name: '', title: 'Created Date', custom: false, noSearch:true, width: '120px'},
    {name: '', title: 'Voided Date', custom: false, noSearch:true, width: '120px'},
    {name: '', title: 'Allowed AMT', custom: false, noSearch:true},
    {name: '', title: 'Deductible', custom: false, noSearch:true},
    {name: '', title: 'Coinsurance', custom: false, noSearch:true},
    {name: '', title: 'Copay', custom: false, noSearch:true},
    {name: '', title: 'Paid Amt', custom: false, noSearch:true},
    {name: '', title: 'Ref. number', custom: false, noSearch:true},
    {name: '', title: 'User name', custom: false, noSearch:true},
    {name: '', title: 'Status', custom: false, noSearch:true},
]

export const claimPaymentTableBody = [
    {rowText: (item) => <DisplayIdRow id={item?.displayId ? item?.displayId : item?.id } />},
    {rowText: (item) => <DateRow date={item?.createdDate}/>},
    {rowText: (item) => <DateRow date={item?.voidedDate}/>},
    {rowText:  (item) => <PriceRow info={item?.allowedAMT}  />},
    {rowText:  (item) => <PriceRow info={item?.deductible}  />},
    {rowText:  (item) => <PriceRow info={item?.coIns}  />},
    {rowText:  (item) => <PriceRow info={item?.copay}  />},
    {rowText:  (item) => <PriceRow info={item?.paidAMT}  />},
    {rowText:  (item) => <TextRow name={item?.paymentRef}  />},
    {rowText:  (item) => <TextRow name={item?.creator?.firstName ? `${item?.creator?.firstName} ${item?.creator?.lastName}`: 'N/A'}  />},
    {rowText: (item) => <p className={item?.status === 'ACTIVE' ? 'active-status' : 'inactive-status'}>{item?.status === 'ACTIVE' ? 'Active' : 'Voided'}</p>}
]



export const invoicePaymentTableHead = [
    {name: '', title: 'ID', custom: false, noSearch:true, width: '100px'},
    {name: '', title: 'Payment ID', custom: false, noSearch:true},
    {name: '', title: 'Bill ID', custom: false, noSearch:true},
    {name: '', title: 'Created Date', custom: false, noSearch:true},
    {name: '', title: 'Updated Date', custom: false, noSearch:true},
    {name: '', title: 'Paid Amt', custom: false, noSearch:true},
    {name: '', title: 'Status', custom: false, noSearch:true},
]

export const invoicePaymentTableBody = [
    {rowText: (item) => <DisplayIdRow id={item?.displayId} />},
    {rowText: (item) => <IdRow id={item?.paymentRef ? item?.paymentRef : 'N/A'} size={4}/>},
    {rowText: (item) => <IdRow id={item?.bill} size={4}/>},
    {rowText: (item) => <DateRow date={item?.createdDate}/>},
    {rowText: (item) => <DateRow date={item?.voidedDate}/>},
    {rowText:  (item) => <PriceRow info={item?.paidAMT}  />},
    {rowText: (item) => <p className={item?.status === 'ACTIVE' ? 'active-status' : 'inactive-status'}>{item?.status === 'ACTIVE' ? 'Active' : 'Voided'}</p>},
]


export const billTotalHead = [
    {name: '', title: 'Billed Rate', custom: false, noSearch:true},
    {name: '', title: 'Billed Amount', custom: false, noSearch:true},
    {name: '', title: 'Total Units', custom: false, noSearch:true},
    {name: '', title: 'Payor Total', custom: false, noSearch:true},
    {name: '', title: 'Payor Paid', custom: false, noSearch:true},
    {name: '', title: 'Payor Balance', custom: false, noSearch:true},
    {name: '', title: 'Client Total', custom: false, noSearch:true},
    {name: '', title: 'Client Paid', custom: false, noSearch:true},
    {name: '', title: 'Client Balance', custom: false, noSearch:true},
]

export const billTotalBody = [
    { rowText: (item) => <PriceRow info={item?.billRate}/>, },
    { rowText: (item) => <PriceRow info={item?.billedAmount}/>, },
    { rowText: (item) => <UnitsRow info={item?.units}/>, },
    { rowText: (item) => <PriceRow info={item?.fsTotal}/>, },
    { rowText: (item) => <PriceRow info={item?.fsPaid}/>, },
    { rowText: (item) => <PriceRow info={item?.fsBalance}/>, },
    { rowText: (item) => <PriceRow info={item?.clientTotal}/>, },
    { rowText: (item) => <PriceRow info={item?.clientPaid}/>, },
    { rowText: (item) => <PriceRow info={item?.clientBalance}/>, },
]
