import {useHistory} from "react-router-dom";
import {buttonsStyle} from "./styles";
import {PermissionList, RolePermission} from "../../utils";

export const TabButtons = ({handleChangeTab}) => {
    const classes = buttonsStyle()
    const history = useHistory();
    const pushInfo = history?.location?.state

    return (
        <div className={classes.buttonsTabWrapper}>
            <button
                onClick={() => handleChangeTab('OPEN')}
                className={pushInfo?.tabType === 'OPEN' || !pushInfo?.tabType ? classes.active : ''}
            >
                Open
            </button>
            {RolePermission([
                    PermissionList.CLAIM_PMT_APPEAL_READ?.code,
                    PermissionList.CLAIM_PMT_SEND_APPEAL?.code,
                ]) &&
                <button
                    onClick={() => handleChangeTab('APPEAL')}
                    className={pushInfo?.tabType === 'APPEAL' ? classes.active : ''}
                >
                    Appeals
                </button>
            }
            <button
                onClick={() => handleChangeTab('COMPLETED')}
                className={pushInfo?.tabType === 'COMPLETED' ? classes.active : ''}
            >
                Paid
            </button>
        </div>

    )
}