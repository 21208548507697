import { convertHours, hooksForTable, makeCapitalize, manageStatus } from 'utils';
import React from 'react';
import { DownloadInvoicePdf } from './core/downloadInvoicePdf';

export function getInvoiceDetails(invoice) {
    const {dateRange, pdfDocument, client, dueDate, status, totalHours, totalAMT} = invoice || {};
    const {addSignToValueFromStart, getValueByFixedNumber, handleCreatedAtDate} = hooksForTable;
    const early = handleCreatedAtDate(dateRange?.early);
    const latest = handleCreatedAtDate(dateRange?.latest);
    const invoiceEarly = handleCreatedAtDate(dueDate?.early)
    const invoiceLatest = handleCreatedAtDate(dueDate?.latest)

    return [
        {
            detailText: "DOS:",
            detail: `${early} - ${latest}`,
        },
        {
            detailText: "Client:",
            detail: !!client && makeCapitalize(`${client?.firstName} ${client?.lastName}`),
        },
        {
            detailText: "Total Hours:",
            detail: convertHours(totalHours ? totalHours : 0),
        },
        {
            detailText: "Invoice Date:",
            detail: `${invoiceEarly} - ${invoiceLatest}`,
        },
        {
            detailText: "Total Amount:",
            detail: addSignToValueFromStart(getValueByFixedNumber(totalAMT)),
        },
        {
            detailText: "Status",
            detail: manageStatus(status),
        },
        {
            detailText: "PDF Document:",
            detail: !!pdfDocument || !!"file_pdf.pdf" ? <DownloadInvoicePdf /> : null,
        },
    ];
}
