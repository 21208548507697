import { ChangePassword, myProfileFragment, UploadAvatar } from './core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Colors, FindLoad, Images } from 'utils';
import React from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';
import { MinLoader, TextRow } from 'components';

export const MyProfile = ({ info }) => {
    const classes = myProfileFragment();
    const dispatch = useDispatch();
    const loader = FindLoad('LOG_OUT');

    const handleSignOut = () => {
        dispatch(authActions.logOut());
    };

    return (
        <div className={classes.wrapper}>

            <div className={classes.staffInfoWrapper}>
                <div className={classes.staffAvatar}>
                    {info?.avatar?.url ?
                        <img src={info?.avatar?.url} alt="avatar" />
                        :
                        <AccountCircleIcon style={{ fontSize: 56, color: Colors.ThemeBlue }} />
                    }

                    <div className={classes.online}/>
                </div>
                <div>
                    <p className={classes.staffName}>
                        <TextRow name={`${info?.firstName} ${info?.lastName ? info.lastName : ''}`} />
                    </p>
                    <p className={classes.staffEmail}>{info?.email ? info?.email : 'N/A'}</p>
                </div>
            </div>

            <UploadAvatar />

            <ChangePassword />
            <button onClick={handleSignOut} className={classes.signOutButton}>
                {!!loader.length ?
                    <MinLoader margin={'0'} color={'#FE7070'} />
                    :
                    <>
                        <img src={Images.signOut} alt="signOut" />
                        <p>Sign Out</p>
                    </>
                }
            </button>
        </div>
    );
};