import { DateRow, PriceRow, TextRow, TypeRow, DateRowStartEnd, DisplayIdRow } from 'components';
import { convertHours } from 'utils';
import React from 'react';

export const invoiceHead = [
    { name: 'displayId', title: 'ID', custom: false },
    { name: 'serviceDates', title: 'DOS', icon: 'date', custom: false, disabled: true },
    { name: 'client', rowName: 'firstName', title: 'Client', custom: false },
    { name: 'totalHours', title: 'Total Hours', custom: false, disabled: true },
    { name: 'totalAMT', title: 'Total Amount', custom: false },
    { name: 'dateRange', title: 'Invoice Date', icon: 'date', disabled: true, custom: false },
    { name: 'status', title: 'Status', disabled: true,
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Open', id: 'OPEN' },
            { name: 'Pending', id: 'PENDING' },
            { name: 'Closed', id: 'CLOSED' },
            { name: 'Submitted', id: 'SUBMITTED' },
            { name: 'Posted', id: 'POSTED' },
        ],

    },
];

export const invoiceBody = [
    { rowText: (item) => <DisplayIdRow id={item?.displayId} /> },
    { rowText: (item) => <DateRowStartEnd start={item?.dateRange?.early} end={item?.dateRange?.latest} /> },
    {
        rowText: (item) => <TextRow
            name={`${item?.client?.firstName ? item?.client?.firstName : ''} ${item?.client?.lastName ? item?.client?.lastName : ''}`} /> },
    { rowText: (item) => convertHours(item?.totalHours ? item?.totalHours : 0) },
    { rowText: (item) => <PriceRow info={item?.totalAMT} /> },
    { rowText: (item) => <DateRow date={item?.invoiceDate} /> },
    { rowText: (item) => <TypeRow text={item?.status} /> },
];

export const ACTION_TYPE = 'GET_INVOICES';