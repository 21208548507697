import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Card, CustomDelete, DeleteElement, FullTable, SimpleModal } from 'components';
import { serviceSingleStyles } from './styles';
import { Colors, FindLoad, FindSuccess, Images, useModal, useWidth } from 'utils';
import { getGeneralInfo } from './constants';
import { contactHead, contactBody, ACTION_TYPE } from './constants';
import { clientActions, httpRequestsOnSuccessActions } from 'store';
import { AddContact } from '../..';

const DELETE_ACTION_TYPE = 'DELETE_CLIENT_CONTACT';

export const ClientContact = ({ clientContacts }) => {
    const client = useSelector((state) => state.client.clientItemInfo);
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const info = history?.location?.state;
    const deleteSuccess = FindSuccess(DELETE_ACTION_TYPE);
    const deleteLoader = FindLoad(DELETE_ACTION_TYPE);
    const [isOpen, setIsOpen] = useState(false);
    const { open } = useModal();

    useEffect(() => {
        if (info) {
            const pushInfo = {
                ...info,
            };
            delete pushInfo.page;
            delete pushInfo.activeTab;
            dispatch(clientActions.getClientsContacts(params.id, { ...pushInfo }));
        }
    }, [info]);

    useEffect(() => {
        if (deleteSuccess?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE));
        }
    }, [deleteSuccess]);


    const handleClickButton = (item, name) => {
        if (name === 'editContact') {
            open(<AddContact info={item} />,);
        } else if (name === 'deleteContact') {
            open(<CustomDelete
                text="Are you sure you want to delete this contact?"
                info="Delete Contact"
                handleDel={() => dispatch(clientActions.deleteClientContact(item?.id, client?.id))}
                handleClose={() => close()}
                actionType={DELETE_ACTION_TYPE}
                body={
                    <div className="deleting-client-info-box">
                        <img src={Images.client} alt="icon" />
                        <p>{`${item?.firstName} ${item?.lastName ? item?.lastName : ''}`}</p>
                    </div>
                }
            />);
        }
    };

    return (
        <>
            <div className={classes.staffGeneralWrapper}>
                <div className={classes.notesWrap}>
                    <FullTable
                        head={contactHead}
                        body={contactBody}
                        loadingType={ACTION_TYPE}
                        list={clientContacts}
                        handleClickButton={handleClickButton}
                        noText={'contacts'}
                    />
                </div>
            </div>
        </>
    );
};
