import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { serviceSingleStyles } from './styles';
import {
    AddButton,
    AddModalButton,
    AddNotes,
    ValidationInput,
    MinLoader, NameAndAvatar, DetailInfoCard, UploadModal, UserInputsDropdown,
} from 'components';
import { Colors, FindLoad, Images, makeCapitalize, PermissionList, RolePermission, useModal } from 'utils';
import { CredentialModal } from 'fragments';
import { adminActions, historyActions } from 'store';
import { filterBtnStyle, STAFF_STATUSES, staffStatus } from './constants';
import { ModalSelector } from './modals/modalSelector';

// const IS_CLINICIAN = 'IS_CLINICIAN';
export const StaffItemHeader = ({}) => {
    const { adminInfoById, activeEmployment } = useSelector((state) => ({
        adminInfoById: state.admins.adminInfoById,
        activeEmployment: state.admins.activeEmployment,
    }));
    const history = useHistory();
    const info = history?.location?.state;
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const staffHistoryLoader = FindLoad('GET_HISTORY');
    // const [switchBoolean, setSwitchBoolean] = useState(false);
    const [searchDate, setSearchDate] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const loadReinvite = FindLoad('REINVITE_ADMIN');
    const { open } = useModal();

    // useEffect(() => {
    //     if (adminInfoById?.id) {
    //         setSwitchBoolean(adminInfoById?.clinical);
    //     }
    // }, [adminInfoById]);

    function handleChange(e) {
        setIsDisabled(false);
        setSearchDate(e.target.value);
    }

    function handleSubmit() {
        setIsDisabled(true);
        const paramsForStaffHistory = {
            onResource: params.id,
            start: searchDate && new Date(searchDate).toISOString(),
        };
        dispatch(historyActions.getHistory('Staff', paramsForStaffHistory));
    }

    const reinvite = () => {
        dispatch(adminActions.reinviteAdmin(adminInfoById?.id));
    };

    const handleSelectionChange = (currentStatus) => {
        const sendStatus =  currentStatus?.id === 'ACTIVE' ? 'active' : 'inactive'
        dispatch(adminActions.changeAdminStatus(adminInfoById.id, sendStatus))
    };

    return (
        <Fragment>
            <div className="tabs-wrapper">
                <div className="full-width">
                    <div className="space-between-flex-start">
                        <NameAndAvatar
                            src={adminInfoById?.avatar?.url}
                            name={makeCapitalize(`${adminInfoById?.firstName} ${adminInfoById?.lastName}`)}
                        />

                        <div className="flex-align-start" style={{gap: 16}}>
                            {RolePermission([PermissionList.STAFF_STATUS?.code]) &&
                                    <UserInputsDropdown
                                        dropdownOptions={STAFF_STATUSES}
                                        onPass={handleSelectionChange}
                                        selected={adminInfoById?.status}
                                    />
                            }
                            {
                                (!info?.activeTab || info?.activeTab === 'General') && adminInfoById?.authStatus !== staffStatus?.ACTIVE &&
                                RolePermission([PermissionList.STAFF_CREATE.code]) &&
                                <button className={classes.reinvite} onClick={reinvite}>
                                    {loadReinvite?.length ?
                                        <MinLoader margin={'0'} color={Colors.TextWhite} position={'relative'} />
                                        :
                                        <>
                                            <img src={Images.reinvite} alt="icon" />
                                            <p>Reinvite</p>
                                        </>
                                    }
                                </button>
                            }

                            {
                                info?.activeTab === 'Timesheet' && RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) ? (
                                    <AddButton text="Add Timesheet" handleClick={() => open(
                                        <ModalSelector />,
                                    )} />


                                ) : info?.activeTab === 'Credentials' && RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code]) ? (
                                        <AddButton
                                            text="Add Credential"
                                            handleClick={() => open(<CredentialModal />)}
                                        />
                                    ) :
                                    info?.activeTab === 'Notes' && RolePermission([PermissionList.STAFF_NOTE_MANAGE?.code]) ? (
                                            <AddButton text="Add Note" handleClick={() => open(
                                                <AddNotes
                                                    id={adminInfoById?.id}
                                                    model="Staff"
                                                />,
                                            )} />
                                        ) :
                                        info?.activeTab === 'History' ? (
                                                <div className={classes.searchContainer}>
                                                    <ValidationInput
                                                        className={classes.dateInput}
                                                        errorFalse={true}
                                                        variant={'outlined'}
                                                        onChange={(e) => handleChange(e)}
                                                        value={searchDate}
                                                        type={'date'}
                                                        name="searchDate"
                                                    />
                                                    <AddModalButton
                                                        handleClick={handleSubmit}
                                                        text="Search"
                                                        loader={!!staffHistoryLoader.length}
                                                        btnStyles={filterBtnStyle}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            ) :
                                            info?.activeTab === 'Files' && RolePermission([PermissionList.STAFF_FILE_MANAGE?.code]) ?
                                                <AddButton
                                                    Icon={true}
                                                    customIcon={Images.uploadCloud}
                                                    text={'Upload Document'}
                                                    handleClick={() => open(
                                                        <UploadModal
                                                            currentId={adminInfoById?.id}
                                                            onResource={'staff'}
                                                        />,
                                                    )}
                                                />
                                                : null
                            }
                        </div>
                    </div>


                    <div className={classes.staffGeneralWrapper}>
                        {activeEmployment?.title?.name &&
                            <DetailInfoCard image={Images.staffPosition}
                                            title={activeEmployment?.title?.name} />
                        }
                        {adminInfoById?.email &&
                            <DetailInfoCard image={Images.sms} title={adminInfoById?.email}
                                            type={'email'} />
                        }
                        {adminInfoById?.phone &&
                            <DetailInfoCard image={Images.callCalling} title={adminInfoById?.phone}
                                            type={'phone'} />
                        }
                    </div>


                </div>
            </div>
            {/*<ul className="tabs-wrapper" style={{ alignItems: 'flex-start' }}>*/}

            {/*    <div>*/}
            {/*        <NameAndAvatar*/}
            {/*            src={adminInfoById?.avatar?.url}*/}
            {/*            name={makeCapitalize(`${adminInfoById?.firstName} ${adminInfoById?.lastName}`)}*/}
            {/*        />*/}
            {/*        <div className={classes.staffGeneralWrapper}>*/}
            {/*            {activeEmployment?.title?.name &&*/}
            {/*                <DetailInfoCard image={Images.staffPosition}*/}
            {/*                                title={activeEmployment?.title?.name} />*/}
            {/*            }*/}
            {/*            {adminInfoById?.email &&*/}
            {/*                <DetailInfoCard image={Images.sms} title={adminInfoById?.email}*/}
            {/*                                            type={'email'} />*/}
            {/*                        }*/}
            {/*                        {adminInfoById?.phone &&*/}
            {/*                            <DetailInfoCard image={Images.callCalling} title={adminInfoById?.phone}*/}
            {/*                                            type={'phone'} />*/}
            {/*                        }*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <li className={classes.headerRight}>*/}
            {/*                    /!*{RolePermission([PermissionList.STAFF_CREATE?.code]) &&*!/*/}
            {/*                    /!*    <div className={classes.clinicalWrapper}>*!/*/}
            {/*                    /!*        <p>Clinician</p>*!/*/}
            {/*                    /!*        <div>*!/*/}
            {/*                    /!*            <CustomizedSwitch*!/*/}
            {/*                    /!*                checked={switchBoolean}*!/*/}
            {/*                    /!*                handleClick={() =>*!/*/}
            {/*                    /!*                    open(<CustomDelete*!/*/}
            {/*                    /!*                        info={adminInfoById?.clinical ? 'Disconnect Clinical' : 'Change to Clinical'}*!/*/}
            {/*                    /!*                        text={adminInfoById?.clinical ? 'Are you sure you wont disconnect clinical?' : 'Are you sure you wont change to clinical?'}*!/*/}
            {/*                    /!*                        handleDel={() => dispatch(adminActions.isClinician(adminInfoById?.id, !adminInfoById?.clinical))}*!/*/}
            {/*                    /!*                        handleClose={() => close()}*!/*/}
            {/*                    /!*                        actionType={IS_CLINICIAN}*!/*/}
            {/*                    /!*                        innerText="Change"*!/*/}
            {/*                    /!*                    />)*!/*/}
            {/*                    /!*                }*!/*/}
            {/*                    /!*            />*!/*/}
            {/*                    /!*        </div>*!/*/}
            {/*                    /!*    </div>*!/*/}
            {/*                    /!*}*!/*/}

            {/*                    {*/}
            {/*                        (!info?.activeTab || info?.activeTab === 'General') && adminInfoById?.authStatus !== staffStatus?.ACTIVE &&*/}
            {/*                        RolePermission([PermissionList.STAFF_CREATE.code]) &&*/}
            {/*                        <button className={classes.reinvite} onClick={reinvite}>*/}
            {/*                            {loadReinvite?.length ?*/}
            {/*                                <MinLoader margin={'0'} color={Colors.TextWhite} position={'relative'} />*/}
            {/*                                :*/}
            {/*                                <>*/}
            {/*                                    <img src={Images.reinvite} alt="icon" />*/}
            {/*                                    <p>Reinvite</p>*/}
            {/*                                </>*/}
            {/*                            }*/}
            {/*                        </button>*/}
            {/*                    }*/}

            {/*                    {*/}
            {/*                        info?.activeTab === 'Timesheet' && RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) ? (*/}
            {/*                            <AddButton text="Add Timesheet" handleClick={() => open(*/}
            {/*                                <ModalSelector />,*/}
            {/*                            )} />*/}


            {/*                        ) : info?.activeTab === 'Credentials & Clearances' && RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code]) ? (*/}
            {/*                                <AddButton*/}
            {/*                                    text="Add Credential"*/}
            {/*                                    handleClick={() => open(<CredentialModal />)}*/}
            {/*                                />*/}
            {/*                            ) :*/}
            {/*                            info?.activeTab === 'Notes' && RolePermission([PermissionList.STAFF_NOTE_MANAGE?.code]) ? (*/}
            {/*                                    <AddButton text="Add Note" handleClick={() => open(*/}
            {/*                                        <AddNotes*/}
            {/*                                            id={adminInfoById?.id}*/}
            {/*                                            model="Staff"*/}
            {/*                                        />,*/}
            {/*                                    )} />*/}
            {/*                                ) :*/}
            {/*                                info?.activeTab === 'History' ? (*/}
            {/*                                        <div className={classes.searchContainer}>*/}
            {/*                                            <ValidationInput*/}
            {/*                                                className={classes.dateInput}*/}
            {/*                                                errorFalse={true}*/}
            {/*                                                variant={'outlined'}*/}
            {/*                                                onChange={(e) => handleChange(e)}*/}
            {/*                                                value={searchDate}*/}
            {/*                                                type={'date'}*/}
            {/*                                                name="searchDate"*/}
            {/*                                            />*/}
            {/*                                            <AddModalButton*/}
            {/*                                                handleClick={handleSubmit}*/}
            {/*                                                text="Search"*/}
            {/*                                                loader={!!staffHistoryLoader.length}*/}
            {/*                                                btnStyles={filterBtnStyle}*/}
            {/*                                                disabled={isDisabled}*/}
            {/*                                            />*/}
            {/*                                        </div>*/}
            {/*                                    ) :*/}
            {/*                                    info?.activeTab === 'Files' && RolePermission([PermissionList.STAFF_FILE_MANAGE?.code]) ?*/}
            {/*                                        <AddButton*/}
            {/*                                            Icon={true}*/}
            {/*                                            customIcon={Images.uploadCloud}*/}
            {/*                                            text={'Upload Document'}*/}
            {/*                                            handleClick={() => open(*/}
            {/*                                                <UploadModal*/}
            {/*                                                    currentId={adminInfoById?.id}*/}
            {/*                                                    onResource={'staff'}*/}
            {/*                                                />,*/}
            {/*                                            )}*/}
            {/*                                        />*/}
            {/*                                        : null*/}
            {/*                    }*/}
            {/*                </li>*/}
            {/*            </ul>*/}
        </Fragment>
    );
};
