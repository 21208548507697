import React, {useEffect, useState} from "react";
import {UserTextArea, CreateChancel} from "components";
import { ErrorText, FindLoad, FindSuccess, FindSuccessItem, isNotEmpty } from 'utils';
import {claimPostStyles} from "./styles";
import {useDispatch} from "react-redux";
import {claimActions, claimPaymentActions, httpRequestsOnSuccessActions} from "store";

const ACTION_TYPE = "APPEAL_CLAIM_PAYMENT"
export const AppealInputs = ({closeModal, claimId, tab}) => {
    const classes = claimPostStyles();
    const dispatch = useDispatch();
    const closingClaimLoader = FindLoad(ACTION_TYPE);
    const closingClaimSuccess = FindSuccessItem(ACTION_TYPE);
    const [closingComment, setClosingComment] = useState("");
    const [error, setError] = useState("");


    useEffect(() => {
        if (closingClaimSuccess) {
            closeModal();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [closingClaimSuccess]);

    function handleChange(event) {
        setClosingComment(event.target.value);
        !!error && setError("");
    }

    function handleSubmit() {
        if (isNotEmpty(closingComment)) {
            const body = {
                id: claimId,
                description: closingComment,
                status: tab,
            }
            dispatch(claimPaymentActions.appealClaimPayment(body, tab))
        } else {
            setError("closingComment");
        }
    }

    return (
        <div>
            <UserTextArea
                className={classes.commentTextAreaStyle}
                id={"closingComment"}
                name={"closingComment"}
                label={"Reason for appeal*"}
                value={closingComment}
                onChange={handleChange}
                hasText={!!closingComment}
                typeError={error === "closingComment" && 'Reason for appeal is required.'}
                max={100}
                maxCharsLabel={`${closingComment?.length}/100 characters `}
                errStyles={{whiteSpace: "nowrap"}}
            />
            <CreateChancel
                butnClassName={classes.closeOrCancelButnStyle}
                loader={!!closingClaimLoader.length}
                create={"Appeal"}
                chancel={"Cancel"}
                onCreate={handleSubmit}
                onClose={closeModal}
                buttonWidth={'100%'}
            />
        </div>
    );
};
