import React from 'react';
import moment from 'moment';
import { clientCommonStyle } from './styles';
import { NoItemText, TextRow } from 'components';
import { EnrolmentCard } from './core/enrolmentCard';

export const ClientEnrollmentTable = ({ enrollments = [], handleOpenCreate, handleEdit }) => {
    const classes = clientCommonStyle();
    const primaryEnrolment = enrollments?.active?.find((i) => i.type === 'PRIMARY');
    const secondaryEnrolment = enrollments?.active?.find((i) => i.type === 'SECONDARY');

    return (
        <div className={classes.enrollmentTableStyle}>
            <p className={classes.activeTitle}> Active Enrollments </p>
            <div className={classes.enrolmentTab}>
                <div className={classes.addRowFirst}>
                    <EnrolmentCard
                        info={primaryEnrolment}
                        type={'PRIMARY'}
                        handleOpenCreate={() => handleOpenCreate('PRIMARY')}
                        handleEdit={handleEdit}
                    />
                </div>
                <EnrolmentCard
                    info={secondaryEnrolment}
                    type={'SECONDARY'}
                    handleOpenCreate={() => handleOpenCreate('SECONDARY')}
                    handleEdit={handleEdit}
                />
            </div>
            <p className={classes.activeTitle}>Past Enrollments</p>
            {enrollments?.past?.length ?
                <div className={classes.pastEnrolmentTable}>
                    <div className={classes.pastEnrolmentTableHead}>
                        <div>ID</div>
                        <div>Funding Source</div>
                        <div>Enrollment Date</div>
                        <div>Termination Date</div>
                        <div>Type</div>
                    </div>
                    <div className={classes.pastEnrolmentTableBodyWrapper}>
                        {enrollments?.past?.map((i, k) => (
                            <div key={k} className={classes.pastEnrolmentTableBody}>
                                <div style={{ width: '25%' }}>
                                    <TextRow name={i?.enrolmentId} />
                                </div>
                                <div style={{ width: '25%' }}>
                                    <TextRow name={i?.funderName} />
                                </div>
                                <div style={{ width: '25%' }}>{moment.utc(i?.startDate).format('MM/DD/YYYY')}</div>
                                <div style={{ width: '25%' }}> {moment.utc(i?.terminationDate).format('MM/DD/YYYY')}</div>
                                <div style={{ width: '25%' }}>{i?.type === 'PRIMARY' ? 'Primary' : 'Secondary'}</div>
                            </div>
                        ))
                        }
                    </div>
                </div>
                :
                <NoItemText text={'No past enrollments item'} />
            }
        </div>
    );
};
