import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { SpeedDial } from '@material-ui/lab';
import {
  ControlPanelContentBilling,
  ControlPanelContentMenuDesign,
  ControlPanelContentMenuItem,
  ControlPanelContentMenus,
  ControlPanelContentModifier,
  ControlPanelContentNotification,
  ControlPanelContentOrders, ControlPanelContentQRCode, ControlPanelContentSettings, ControlPanelContentTables,
  ControlPanelContentTransactions,
  MenuControlPanel
} from "./fragments";
import { controlPanelMenu } from "./fragments/constants";
import { CustomBreadcrumbs } from 'components';
import { useScrollTop, useWidth } from "utils";




export const ControlPanelPage = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState({
    menu: "Menu",
    subMenu: "Create Your Menu"
  });
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(true);
  const history = useHistory();
  const width = useWidth();
  const contentRef = useRef(null);
  const scrollTop = useScrollTop(contentRef);


  useEffect(() => {
    contentRef.current?.scrollTo(0, 0);
  }, [currentMenuItem.menu]);


  useEffect(() => {
    const findItem = controlPanelMenu.find(item => item.title === currentMenuItem.menu);


    if (findItem.subMenu?.length) {
      findItem?.subMenu.forEach((item) => {
        const element = document.getElementById(item.hash);

        if (element?.offsetTop - 121 - scrollTop < 40 && element?.offsetTop) {
          changingMenu("subMenu", item?.title);
        }
      });
    }

  }, [scrollTop]);


  const changingMenu = (path, value) => {
    if (path === "menu") {
      if (value === currentMenuItem.menu) {
        setOpenSubMenu(prevState => !prevState);
      } else {
        setOpenSubMenu(true);
      }
    }

    setCurrentMenuItem(prev => ({
      ...prev,
      [path]: value
    }));
  };


  return (
    <div className='control-panel-page-wrapper'>
      {width > 767 &&
        <MenuControlPanel
          currentMenuItem={currentMenuItem}
          changingMenu={changingMenu}
          openSubMenu={openSubMenu}
          menu={controlPanelMenu}
        />
      }

      <div className="control-panel-content" ref={contentRef}>
        <CustomBreadcrumbs
            className={'help-center-breadcrumbs'}
            parent={'Help Center'}
            child={'Control Panel'}
            parentLink={'/helpCenter'}
        />

        {width < 768 &&
          <div className="content-header" onClick={() => {
            history.push("/helpCenter");
          }}>
            <div className="icon-help-text">
              {/*<Icons.bookHelp className="MenuControlPanel-header-icon" />*/}
              <p className="text-help">
                Help
              </p>
            </div>

            <p className="text-description">
              Articles for Control Panel
            </p>

            <SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{ position: "absolute", bottom: 16, right: 16 }}
              // icon={<MoreHorizIcon style={{ color: open ? "white" : "white", fontSize: "32px" }} />}
              // openIcon={<MoreHorizIcon />}
              onClose={(e) => {
                e.stopPropagation();
                setOpen(false);
              }
              }
              onOpen={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
              open={open}
            >
              {open &&
                <MenuControlPanel
                  currentMenuItem={currentMenuItem}
                  changingMenu={changingMenu}
                  openSubMenu={openSubMenu}
                  menu={controlPanelMenu}
                />
              }
            </SpeedDial>
          </div>
        }

        {currentMenuItem.menu === "Menu"
          &&
          <ControlPanelContentMenus
            currentMenuItem={currentMenuItem}
            changingMenu={changingMenu}
          />
        }


        {currentMenuItem.menu === "Menu Items"
          &&
          <ControlPanelContentMenuItem
            currentMenuItem={currentMenuItem}
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Modifier Groups"
          &&
          <ControlPanelContentModifier
            currentMenuItem={currentMenuItem}
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Menu Design"
          &&
          <ControlPanelContentMenuDesign />
        }

        {currentMenuItem.menu === "Orders"
          &&
          <ControlPanelContentOrders
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Transactions"
          &&
          <ControlPanelContentTransactions
            currentMenuItem={currentMenuItem}
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Notification"
          &&
          <ControlPanelContentNotification
            currentMenuItem={currentMenuItem}
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "QR"
          &&
          <ControlPanelContentQRCode
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Tables"
          &&
          <ControlPanelContentTables
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Settings"
          &&
          <ControlPanelContentSettings
            changingMenu={changingMenu}
          />
        }

        {currentMenuItem.menu === "Billing"
          &&
          <ControlPanelContentBilling
            changingMenu={changingMenu}
          />
        }

        <div className="controlPanelContentMenus-resource">
          <div>
            <p className="description" style={{ fontSize: "11px", textAlign: "center" }}>
              FEEDBACK
            </p>

            <p className="title ">
              Was this resource helpful?
            </p>

            <div className="feedback-svg-wrapper">
              <div className="feedback-svg-block">
                👍
              </div>

              <div className="feedback-svg-line" />


              <div className="feedback-svg-block">
                👎
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

