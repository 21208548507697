import React from "react";
import { inputsStyle } from "components/inputs/styles";
import {
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
} from "@material-ui/core";
import { makeCapitalize, makeEnum } from "utils";
import { scheduleModalsStyle } from '../styles';

const MODES = [
   { modeValue: "DAILY" },
   { modeValue: "WEEKLY" },
   { modeValue: "MONTHLY" },
];

export const Mode = ({ inputs, handleChange }) => {
   const classes = scheduleModalsStyle();
   const inputsClasses = inputsStyle();

   return (
      <>
         <p className={classes.recurTitle}>Mode</p>
         <div className={classes.spaceBetween}>
            <FormControl component="fieldset" style={{width:'100%'}}>
               <RadioGroup
                  onChange={handleChange}
                  row
                  aria-label="gender"
                  name="mode"
                  style={{display:'flex', justifyContent:'space-between'}}
               >
                  {MODES.map((mode, index) => {
                     const isChecked = inputs.mode === makeEnum(mode.modeValue);
                     const radioClasses = {
                        root: inputsClasses.radio,
                        checked: inputsClasses.checked,
                     };

                     return (
                        <FormControlLabel
                           key={index}
                           className={inputsClasses.radioInputLabel}
                           value={makeEnum(mode.modeValue)}
                           label={makeCapitalize(mode.modeValue)}
                           style={{width:'30%'}}
                           control={
                              <Radio
                                 checked={isChecked}
                                 classes={radioClasses}
                              />
                           }
                        />
                     );
                  })}
               </RadioGroup>
            </FormControl>
         </div>
      </>
   );
};
